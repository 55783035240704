import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { useGetCoinGainersQuery } from "../../store/services/services";
import { useSelector } from "react-redux";
import { selectCurrentTheme } from "../../store/slices/themeSlice";
import CryptoIcon from "../../assets/icons/crypto-icon.svg";

const CoinDetailTrending = () => {
  const { data, isLoading, isError, error } = useGetCoinGainersQuery();
  const currentTheme = useSelector(selectCurrentTheme);
  return (
    <div
      className={`shadow-navbar-button rounded-[28px] py-7 px-6 mt-6 sm:mt-1 h-full ${
        currentTheme === "dark" ? "bg-cardsColor" : "bg-mainBg"
      }`}
    >
      <div className="mb-5">
        <p
          className={`text-[22px] font-semibold ${
            currentTheme === "dark" ? "text-mainBg" : "text-filter"
          }`}
        >
          Trending Coins and Tokens 🔥
        </p>
      </div>
      <div>
        {isLoading && (
          <div className="w-full">
            <SkeletonTheme
              baseColor={`${currentTheme === "dark" ? "#323546" : "#d8d8d8"}`}
              highlightColor={`${currentTheme === "dark" ? "#444" : "#e0e0e0"}`}
            >
              <Skeleton width="100%" height={37} count={3} />
            </SkeletonTheme>
          </div>
        )}
        {isError && <p>{error.message}</p>}
        <div className=" text-sm">
          {!isLoading && !isError && (
            <div>
              {data?.data?.slice(0, 5).map(item => (
                <div
                  className={`flex justify-between items-center border-t-[.5px] py-2 ${
                    currentTheme === "dark" ? "border-darkBorder" : "border-lightBorder"
                  }`}
                  key={item.id}
                >
                  <div className="flex gap-3 items-center">
                    <img
                      className="h-5"
                      src={item?.img ? item?.img : CryptoIcon}
                      alt="coin-logo"
                    />
                    <p
                      className={`font-semibold ${
                        currentTheme === "dark" ? "text-mainBg" : "text-filter"
                      }`}
                    >
                      {item?.name}
                    </p>
                  </div>
                  <div>
                    <p
                      className={`font-semibold  rounded-[6px] px-2 py-[2px] text-xs ${
                        currentTheme === "dark"
                          ? "bg-filter text-mainBg"
                          : "bg-coinDetailBg text-filter"
                      }`}
                    >
                      #{item?.rank}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CoinDetailTrending;
