import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isResetPasswordOpen: false,
  isLoginOpen: false
};

export const popupSlice = createSlice({
  name: "authPopup",
  initialState,
  reducers: {
    openResetPassword: state => {
      state.isResetPasswordOpen = true;
    },
    closeResetPassword: state => {
      state.isResetPasswordOpen = false;
    },
    openLoginPopup: state => {
      state.isLoginOpen = true;
    },
    closeLoginPopup: state => {
      state.isLoginOpen = false;
    },
    resetPopups: state => {
      state.isResetPasswordOpen = false;
      state.isLoginOpen = false;
    }
  }
});

export default popupSlice.reducer;
export const {
  openResetPassword,
  closeResetPassword,
  openLoginPopup,
  closeLoginPopup,
  resetPopups
} = popupSlice.actions;

