import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { faTrash, faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { commafy } from "../../utils/commafy";
import { useGetPortfolioByUserQuery } from "../../store/services/services";
import { useSelector, useDispatch } from "react-redux";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { toDecimals } from "../../utils/toDecimals";
import { openTransactionRemove } from "../../store/slices/popupSlice";
import TransactionRemovePopup from "../TransactionRemovePopup/TransactionRemovePopup";
import { selectCurrentTheme } from "../../store/slices/themeSlice";

const PortfolioTable = ({ handleCoin }) => {
  const [transactionsId, setTransactionsId] = useState(null);
  const currentTheme = useSelector(selectCurrentTheme);
  const dispatch = useDispatch();
  // getting id from redux
  const comparePortfolio = useSelector(state => state.portfolioChange.portfolioId);
  const userId = useSelector(state => state.auth.userId);

  const { data, isLoading, isFetching, isError, error } =
    useGetPortfolioByUserQuery(userId);
  const tableData = JSON.parse(localStorage.getItem("activePortfolio"));
  let filteredTransactions;
  if (!isLoading && !isError) {
    filteredTransactions = data?.portfolioData?.find(
      item => item.id === comparePortfolio
    );
  }

  const transactionRemove = useSelector(state => state.popup.TransactionRemoveIsVisible);
  useEffect(() => {
    if (transactionsId !== null) {
    }
  }, [transactionsId]);
  return (
    <div
      className={`px-24 sm:px-8 xs:px-4 mt-16  ${
        currentTheme === "dark" ? "bg-filter" : "bg-mainBg"
      }`}
    >
      <div>
        <p
          className={`font-bold text-2xl mb-5 xs:text-xl ${
            currentTheme === "dark" ? " text-mainBg" : "text-filter"
          }`}
        >
          Your Assets
        </p>
      </div>
      <div className="mb-8 overflow-x-auto scrollbar-hide shadow-header-shadow rounded-[20px]">
        {tableData && (
          <table
            className={`w-full min-w-[1100px] xs:min-w-[800px] relative xs:text-xs ${
              currentTheme === "dark" ? "bg-cardsColor" : "bg-mainBg"
            }`}
          >
            <thead>
              <tr
                className={`border-b-[1px] ${
                  currentTheme === "dark" ? "border-darkBorder" : "border-lightBorder"
                }`}
              >
                <th
                  className={`w-32 xs:w-20 shadow-sm text-left pb-2 pt-3 font-medium  sticky left-0  before  pl-4 ${
                    currentTheme === "dark"
                      ? "bg-cardsColor text-mainBg"
                      : "text-filter bg-mainBg"
                  }`}
                >
                  Name
                </th>
                <th
                  className={`w-32 xs:w-20 text-left  pb-2 pt-3 font-medium pl-5 ${
                    currentTheme === "dark" ? " text-mainBg" : "text-filter"
                  }`}
                >
                  Price
                </th>
                <th
                  className={`w-20 xs:w-12 text-left  pb-2 pt-3 font-medium ${
                    currentTheme === "dark" ? " text-mainBg" : "text-filter"
                  }`}
                >
                  24h
                </th>
                <th
                  className={`w-36 xs:w-24 text-left  pb-2 pt-3 font-medium ${
                    currentTheme === "dark" ? " text-mainBg" : "text-filter"
                  }`}
                >
                  Holdings
                </th>
                <th
                  className={`w-36  xs:w-24 text-left  pb-2 pt-3 font-medium ${
                    currentTheme === "dark" ? " text-mainBg" : "text-filter"
                  }`}
                >
                  Avg. Buy Price
                </th>
                <th
                  className={`w-36 xs:w-24 text-left pb-2 pt-3 font-medium ${
                    currentTheme === "dark" ? " text-mainBg" : "text-filter"
                  }`}
                >
                  Profit/Loss
                </th>
                <th
                  className={`w-20 xs:w-24 text-center pb-2 pt-3 font-medium ${
                    currentTheme === "dark" ? " text-mainBg" : "text-filter"
                  }`}
                >
                  Actions
                </th>
              </tr>
            </thead>
            {isLoading && isFetching && (
              <div className="w-full">
                <SkeletonTheme
                  baseColor={`${currentTheme === "dark" ? "#323546" : "#d8d8d8"}`}
                  highlightColor={`${currentTheme === "dark" ? "#444" : "#e0e0e0"}`}
                >
                  <Skeleton width="100%" height={37} count={3} />
                </SkeletonTheme>
              </div>
            )}
            {isError && <p>{error.message}</p>}
            {!isFetching && !isLoading && !isError && (
              <tbody className="xs:text-xs">
                {filteredTransactions?.transactions?.length > 0 ? (
                  filteredTransactions?.transactions?.map(item => (
                    <tr
                      className={`text-sm xs:text-xs border-b-[1px] ${
                        currentTheme === "dark"
                          ? "border-darkBorder"
                          : "border-lightBorder"
                      }`}
                      key={item.id}
                    >
                      <td
                        className={`sticky left-0  pl-4  ${
                          currentTheme === "dark" ? "bg-cardsColor" : "bg-mainBg"
                        }`}
                      >
                        <Link
                          to={`/coin-detail/${item.coin}`}
                          className={`flex items-center h-16 gap-[6px]  before ${
                            currentTheme === "dark" ? "bg-cardsColor" : "bg-mainBg"
                          }`}
                        >
                          <img className="w-6" src={item?.coinImg} alt="coin" />
                          <div className="flex items-center xs:flex-col xs:items-start xs:gap-0 gap-2 xs:justify-center ">
                            <span
                              className={`font-medium ${
                                currentTheme === "dark" ? " text-mainBg" : "text-filter"
                              }`}
                            >
                              {item?.coin}
                            </span>
                            <span>{item.coinCode}</span>
                          </div>
                        </Link>
                      </td>
                      <td
                        className={`pl-5 ${
                          currentTheme === "dark" ? " text-mainBg" : "text-filter"
                        }`}
                      >
                        ${commafy(toDecimals(item?.coinPrice))}
                      </td>
                      <td
                        className={
                          item.percentChangeDay > 0 ? "text-increase" : "text-decrease"
                        }
                      >
                        {toDecimals(item.percentChangeDay)}%
                      </td>
                      <td>
                        <p
                          className={`${
                            currentTheme === "dark" ? " text-mainBg" : "text-portfolioGreyText"
                          }`}
                        >
                          ${commafy(toDecimals(item?.totalHoldings, 3))}
                        </p>
                        <p
                          className={`text-xs  ${
                            currentTheme === "dark" ? " text-mainBg" : "text-portfolioGreyText"
                          }`}
                        >
                          {item?.quantity} {item?.coin}
                        </p>
                      </td>
                      <td>
                        <p
                          className={`${
                            currentTheme === "dark" ? " text-mainBg" : "text-portfolioGreyText"
                          }`}
                        >
                          ${commafy(toDecimals(item.averagePrice, 3))}
                        </p>
                      </td>
                      <td>
                        <p
                          className={`${
                            currentTheme === "dark" ? " text-mainBg" : "text-portfolioGreyText"
                          }`}
                        >
                          ${commafy(toDecimals(item?.profitLoss))}
                        </p>
                        <p
                          className={`text-xs ${
                            currentTheme === "dark" ? " text-mainBg" : "text-portfolioGreyText"
                          }`}
                        >
                          {commafy(toDecimals(item?.profitLossPercentage))}%
                        </p>
                      </td>
                      <td className="text-center">
                        <div
                          className={`flex justify-center gap-5 ${
                            currentTheme === "dark" ? " text-mainBg" : "text-portfolioGreyText"
                          }`}
                        >
                          <button
                            onClick={() => {
                              dispatch(openTransactionRemove());
                              const coinSymbol = item.coin;
                              const transactionId = comparePortfolio;
                              setTransactionsId(
                                { coinSymbol, transactionId },
                                "these are selected items and id"
                              );
                            }}
                          >
                            <FontAwesomeIcon icon={faTrash} />
                          </button>
                          <Link onClick={() => handleCoin(item.coin)}>
                            <FontAwesomeIcon icon={faEye} />
                          </Link>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan="7"
                      className={`text-center h-[40vh] text-lg semi-bold ${
                        currentTheme === "dark" ? " text-mainBg" : "text-filter"
                      }`}
                    >
                      No transactions available.
                    </td>
                  </tr>
                )}
              </tbody>
            )}
          </table>
        )}
        {!tableData && (
          <table
            className={`w-full min-w-[1100px] xs:min-w-[800px] relative xs:text-xs ${
              currentTheme === "dark" ? "bg-cardsColor" : "bg-mainBg"
            }`}
          >
            <thead>
              <tr
                className={`border-b-[1px] ${
                  currentTheme === "dark" ? "border-darkBorder" : "border-lightBorder"
                }`}
              >
                <th
                  className={`w-32 xs:w-20 shadow-sm text-left pb-2 pt-3 font-medium  sticky left-0  before  pl-4 ${
                    currentTheme === "dark"
                      ? "bg-cardsColor text-mainBg"
                      : "bg-mainBg text-cardsColor"
                  }`}
                >
                  Name
                </th>
                <th
                  className={`w-32 xs:w-20 text-left  pb-2 pt-3 font-medium pl-5 ${
                    currentTheme === "dark" ? " text-mainBg" : "text-filter"
                  }`}
                >
                  Price
                </th>
                <th
                  className={`w-36 xs:w-24 text-left  pb-2 pt-3 font-medium ${
                    currentTheme === "dark" ? " text-mainBg" : "text-filter"
                  }`}
                >
                  24h
                </th>
                <th
                  className={`w-36 xs:w-24 text-left pb-2 pt-3 font-medium ${
                    currentTheme === "dark" ? " text-mainBg" : "text-filter"
                  }`}
                >
                  Quantity
                </th>
                <th
                  className={`w-36 xs:w-24 text-left pb-2 pt-3 font-medium ${
                    currentTheme === "dark" ? " text-mainBg" : "text-filter"
                  }`}
                >
                  Balance
                </th>
              </tr>
            </thead>
            {isLoading && isFetching && (
              <div className="w-full">
                <SkeletonTheme
                  baseColor={`${currentTheme === "dark" ? "#323546" : "#d8d8d8"}`}
                  highlightColor={`${currentTheme === "dark" ? "#444" : "#e0e0e0"}`}
                >
                  <Skeleton width="100%" height={37} count={3} />
                </SkeletonTheme>
              </div>
            )}
            {isError && <p>{error.message}</p>}
            {!isFetching && !isLoading && !isError && (
              <tbody className="xs:text-xs">
                {data && data?.overallCoinsData[0].length > 0 ? (
                  data?.overallCoinsData[0].map(item => (
                    <tr
                      className={`text-sm xs:text-xs border-b-[1px] ${
                        currentTheme === "dark"
                          ? "border-darkBorder"
                          : "border-lightBorder"
                      }`}
                      key={item.id}
                    >
                      <td
                        className={`sticky left-0 pl-4 ${
                          currentTheme === "dark" ? "bg-cardsColor" : "bg-mainBg"
                        }`}
                      >
                        <div
                          className={`flex items-center h-16 gap-[6px]  before ${
                            currentTheme === "dark" ? "bg-cardsColor" : "bg-mainBg"
                          }`}
                        >
                          <img className="w-6" src={item?.coinImg} alt="coin" />
                          <div className="flex items-center xs:flex-col xs:items-start xs:gap-0 gap-2 xs:justify-center">
                            <span
                              className={`font-medium ${
                                currentTheme === "dark" ? "text-mainBg" : "text-filter"
                              }`}
                            >
                              {item?.coin}
                            </span>
                          </div>
                        </div>
                      </td>
                      <td
                        className={`pl-5 ${
                          currentTheme === "dark" ? "text-mainBg" : "text-filter"
                        }`}
                      >
                        ${commafy(toDecimals(item?.coinPrice))}
                      </td>
                      <td
                        className={
                          item.percentChangeDay > 0 ? "text-increase" : "text-decrease"
                        }
                      >
                        {toDecimals(item.percentChangeDay)}%
                      </td>
                      <td>
                        <p
                          className={`${
                            currentTheme === "dark" ? "text-mainBg" : "text-filter"
                          }`}
                        >
                          {commafy(toDecimals(item?.overallQuantityOfBuy, 3))}
                        </p>
                      </td>
                      <td>
                        <p
                          className={`${
                            currentTheme === "dark" ? "text-mainBg" : "text-filter"
                          }`}
                        >
                          ${commafy(toDecimals(item.overallCurrentBalance, 3))}
                        </p>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan="7"
                      className={`text-center h-[40vh] text-lg semi-bold ${
                        currentTheme === "dark" ? " text-mainBg" : "text-filter"
                      }`}
                    >
                      No transactions available.
                    </td>
                  </tr>
                )}
              </tbody>
            )}
          </table>
        )}
      </div>
      {transactionRemove && <TransactionRemovePopup transactionsId={transactionsId} />}
    </div>
  );
};

export default PortfolioTable;
