import React, { useEffect } from "react";
import Modal from "../../utils/Modal";
import { useDeletePortfolioMutation } from "../../store/services/services";
import { useDispatch, useSelector } from "react-redux";
import { portfolioChange } from "../../store/slices/portfolioChangeSlice";
import { closePortfolioRemove } from "../../store/slices/popupSlice";
import { selectCurrentTheme } from "../../store/slices/themeSlice";
import { closeModal } from "../../store/slices/popupSlice";

const PortfolioRemovePopup = ({ portfolioId, selectDashboardHandler }) => {
  const dispatch = useDispatch();

  const [removePortfolio, { isLoading, isSuccess }] = useDeletePortfolioMutation();
  const currentTheme = useSelector(selectCurrentTheme);
  const userId = useSelector(state => state.auth.userId);

  const removePortfolioHandler = async () => {
    await removePortfolio({ userId, portfolioId });
    selectDashboardHandler();
  };

  useEffect(() => {
    if (isSuccess) {
      dispatch(portfolioChange());
      dispatch(closePortfolioRemove());
    }
  });

  return (
    <Modal maxWidth={600}>
      <div className="p-7 xs:px-4">
        <div className="mb-6">
          <p
            className={`text-[22px] xs:text-xl font-semibold mb-2  ${
              currentTheme === "dark" ? " text-mainBg" : "text-secondary"
            }`}
          >
            Remove Portfolio
          </p>
          <p className={`${currentTheme === "dark" ? " text-mainBg" : "text-filter"}`}>
            Do you want to delete your Portfolio? Performing this action you understand
            that you would not be able to recover your Portfolio.
          </p>
        </div>
        <div>
          <button
            className="w-full text-center bg-decrease py-2 rounded-[6px] mb-4 text-mainBg font-semibold disabled:bg-removeDisabledColor"
            onClick={removePortfolioHandler}
            disabled={isLoading}
          >
            {isLoading ? "Removing..." : "Remove"}
          </button>
          <button
            className={`w-full text-center py-2 rounded-[6px] font-semibold ${
              currentTheme === "dark"
                ? "bg-inputColor text-mainBg"
                : "bg-popupBg text-popupText"
            }`}
            onClick={() => {
              dispatch(closeModal());
            }}
          >
            Cancel
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default PortfolioRemovePopup;
