import React from "react";
import Recent from "./Recent";
import TopGainers from "./TopGainers";
import CoinLosers from "./CoinLosers";

const HomeCryptoStats = () => {
  return (
    <div className="mt-12">
      <div className="flex justify-center flex-wrap gap-2 items-center">
        <Recent />
        <TopGainers />
        <CoinLosers />
      </div>
    </div>
  );
};

export default HomeCryptoStats;
