import React from "react";
import { Link, useParams } from "react-router-dom";
import { useGetAllNewsPostQuery } from "../../store/services/services";
import Avalanche from "../../assets/images/avalancheBanner.jpg";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { selectCurrentTheme } from "../../store/slices/themeSlice";
import { useSelector } from "react-redux";

const SingleNewsInfo = ({ id }) => {
  const { page } = useParams();
  const currentTheme = useSelector(selectCurrentTheme);
  const { data, isLoading, isError, error } = useGetAllNewsPostQuery(page);
  let filteredNews;
  if (!isLoading && !isError) {
    filteredNews = data?.data?.filter(item => item.id !== +id);
  }
  return (
    <div>
      {isLoading && (
        <SkeletonTheme
          baseColor={`${currentTheme === "dark" ? "#323546" : "#d8d8d8"}`}
          highlightColor={`${currentTheme === "dark" ? "#444" : "#e0e0e0"}`}
        >
          <Skeleton
            width="32%"
            height={450}
            count={6}
            inline={true}
            borderRadius="30px"
            className="w-[32%] sm:w-full min-w-[320px] mb-10 mr-4"
          />
        </SkeletonTheme>
      )}
      {isError && <p>{error.message}</p>}
      {!isLoading && !isError && (
        <div>
          <div className="mt-10">
            <h1
              className={`text-2xl font-semibold ${
                currentTheme === "dark" ? "text-mainBg " : "text-filter"
              }`}
            >
              Most Read News
            </h1>
            <div className="flex flex-wrap justify-center mt-7 gap-5">
              {filteredNews.map(items => {
                return (
                  <div
                    key={items.id}
                    className="w-[32%] sm:w-full min-w-[320px] pb-5 rounded-[15px]"
                  >
                    <Link to={`/singleNewsProduct/${page}/${items.id}`}>
                      <div
                        className={`shadow-navbar-button rounded-[40px] px-5 py-2 pb-7 ${
                          currentTheme === "dark" ? "bg-cardsColor " : "bg-mainBg"
                        }`}
                      >
                        <div className="mt-6 relative">
                          <img
                            src={items?.url_to_image ? items.url_to_image : Avalanche}
                            alt="pic"
                            className="h-[221px] w-full rounded-[40px] object-cover"
                          />

                          <h2 className="text-lg font-semibold lg:text-lg text-blueColor pt-4">
                            {items?.author}
                          </h2>
                          <h2
                            className={`text-lg lg:text-sm font-semibold pt-3 pb-2  ${
                              currentTheme === "dark" ? " text-mainBg" : "text-filter"
                            }`}
                          >
                            {items?.title.substring(0, 60)}
                          </h2>
                          <p
                            className={`text-base lg:text-sm text-dimBlack font-normal ${
                              currentTheme === "dark" ? "text-coinNewsGreyColor" : "text-dimBlack"
                            }`}
                          >
                            {items?.description.substring(0, 100)}
                          </p>
                          <p
                            className={`text-base lg:text-sm text-dimBlack font-normal pt-2 ${
                              currentTheme === "dark" ? "text-coinNewsGreyColor" : "text-dimBlack"
                            }`}
                          >
                            {new Date(items?.published_at).toDateString()}
                          </p>
                        </div>
                      </div>
                    </Link>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
      {/* <NewsPagination /> */}
    </div>
  );
};

export default SingleNewsInfo;
