import React, { useState, useEffect, useRef } from "react";
import BuyIcon from "../../assets/icons/buy-arrow.svg";
import DropDown from "../../assets/icons/drop-down.svg";
import DropDownDark from "../../assets/icons/dark-dropdown.svg";
import DropdownMenu from "../../utils/DropdownMenu/DropdownMenu";
import { useGetAffiliateLinksQuery } from "../../store/services/services";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { selectCurrentTheme } from "../../store/slices/themeSlice";
import { useSelector } from "react-redux";

const CoinDetailCardMenu = () => {
  const [buyToggle, setBuyToggle] = useState(false);
  const [exchangeToggle, setExchangeToggle] = useState(false);
  // const [gamingToggle, setGamingToggle] = useState(false);
  // const [earnToggle, setEarnToggle] = useState(false);
  const { data, isLoading, isError, error } = useGetAffiliateLinksQuery();
  const currentTheme = useSelector(selectCurrentTheme);
  const buyDropdownRef = useRef();
  const exchangeDropdownRef = useRef();
  // const gamingDropdownRef = useRef();
  // const earnDropdownRef = useRef();

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleClickOutside = event => {
    if (buyDropdownRef.current && !buyDropdownRef.current.contains(event.target)) {
      setBuyToggle(false);
    }
    if (
      exchangeDropdownRef.current &&
      !exchangeDropdownRef.current.contains(event.target)
    ) {
      setExchangeToggle(false);
    }
    // if (gamingDropdownRef.current && !gamingDropdownRef.current.contains(event.target)) {
    //   setGamingToggle(false);
    // }
    // if (earnDropdownRef.current && !earnDropdownRef.current.contains(event.target)) {
    //   setEarnToggle(false);
    // }
  };

  return (
    <div className="lg:hidden">
      <div className="relative" ref={buyDropdownRef}>
        <div
          className="flex mt-[4rem] items-center lg:min-w-[130px] justify-center cursor-pointer gap-2 py-4 md:py-3 bg-primary text-mainBg shadow-navbar-button rounded-full text-center"
          onClick={() => setBuyToggle(!buyToggle)}
        >
          Buy
          <img src={BuyIcon} alt="buy" />
        </div>
        {buyToggle && (
          <DropdownMenu>
            {isLoading && (
              <div className="w-full">
                <SkeletonTheme
                  baseColor={`${currentTheme === "dark" ? "#323546" : "#d8d8d8"}`}
                  highlightColor={`${currentTheme === "dark" ? "#444" : "#e0e0e0"}`}
                >
                  <Skeleton width="100%" height={35} count={3} />
                </SkeletonTheme>
              </div>
            )}
            {isError && <p>{error.message}</p>}
            {!isLoading && !isError && (
              <>
                {data.map(item => {
                  return (
                    <a href={item?.affiliateUrl} target="_blank" rel="noreferrer">
                      <div
                        className={`border-b pb-2 ${
                          currentTheme === "dark"
                            ? "border-darkBorder"
                            : "border-lightBorder"
                        }`}
                      >
                        <div className="flex mr-24 gap-2 mt-4">
                          <img
                            src={item?.coinLogo}
                            alt="buy-crypto"
                            className="w-[7rem]"
                          />
                          <div>
                            <div className="flex justify-between items-center w-[350px]">
                              <div className="flex gap-1">
                                <p className="font-semibold text-sm">{item?.name}</p>
                              </div>
                            </div>
                            <div>
                              <p
                                className={`text-base pt-1  ${
                                  currentTheme === "dark" ? "text-mainBg " : "text-grey"
                                }`}
                              >
                                {item?.description.substring(0, 100)}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </a>
                  );
                })}
              </>
            )}
          </DropdownMenu>
        )}
      </div>
      <div className="relative" ref={exchangeDropdownRef}>
        <div
          className={`flex items-center mt-4 lg:min-w-[130px] justify-center gap-2 py-4 md:py-3 cursor-pointer shadow-navbar-button rounded-full text-center ${
            currentTheme === "dark"
              ? "bg-cardsColor text-mainBg"
              : "bg-mainBg text-icosBlackColor"
          }`}
          onClick={() => setExchangeToggle(!exchangeToggle)}
        >
          Exchange
          {currentTheme === "dark" ? (
            <img src={DropDownDark} alt="dark" />
          ) : (
            <img src={DropDown} alt="exchange" />
          )}
        </div>
        {exchangeToggle && (
          <DropdownMenu>
            {isLoading && (
              <div className="w-full">
                <Skeleton width="100%" height={35} count={3} />
              </div>
            )}
            {isError && <p>{error.message}</p>}
            {!isLoading && !isError && (
              <>
                {data.map(item => {
                  return (
                    <a href={item?.affiliateUrl} target="_blank" rel="noreferrer">
                      <div
                        className={`border-b pb-2 ${
                          currentTheme === "dark"
                            ? "border-darkBorder"
                            : "border-lightBorder"
                        }`}
                      >
                        <div className="flex mr-24 gap-2 mt-4">
                          <img
                            src={item?.coinLogo}
                            alt="buy-crypto"
                            className="w-[7rem]"
                          />

                          <div
                            className={`${
                              currentTheme === "dark" ? "text-mainBg" : " text-grey"
                            }`}
                          >
                            <div className="flex justify-between items-center w-[350px]">
                              <div className="flex gap-1">
                                <p className="font-semibold text-sm">{item?.name}</p>
                              </div>
                            </div>

                            <div>
                              <p className="text-base pt-1">
                                {item?.description.substring(0, 100)}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </a>
                  );
                })}
              </>
            )}
          </DropdownMenu>
        )}
      </div>
    </div>
  );
};

export default CoinDetailCardMenu;
