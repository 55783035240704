import React from "react";
import { selectCurrentTheme } from "../../store/slices/themeSlice";
import { useSelector } from "react-redux";
const ExchangeHead = () => {
  const currentTheme = useSelector(selectCurrentTheme);
  return (
    <div className="px-24 md:px-8 xs:px-4 my-12">
      <div className="w-3/5 sm:w-full">
        <h2
          className={`font-semibold text-[26px] mb-3 md:text-[24px] sm:text-[22px] xs:text-[20px] ${
            currentTheme === "dark" ? " text-mainBg" : "text-filter"
          }`}
        >
          Top Cryptocurrency Spot Exchanges
        </h2>
        <p
          className={`sm:text-sm ${
            currentTheme === "dark" ? "text-mainBg" : "text-filter"
          }`}
        >
          Coin ranks and scores exchanges based on traffic, liquidity, trading volumes,
          and confidence in the legitimacy of trading volumes reported.
        </p>
      </div>
    </div>
  );
};

export default ExchangeHead;
