import CoinsIcon from "../assets/icons/coins.svg";
import CoinsIconActive from "../assets/icons/coins-active.svg";
import WatchlistIcon from "../assets/icons/watchlist.svg";
import WatchlistIconActive from "../assets/icons/watchlist-active.svg";
import ExchangesIcon from "../assets/icons/exchanges.svg";
import ExchangesIconActive from "../assets/icons/exchanges-active.svg";
import PortfolioIcon from "../assets/icons/portfolio.svg";
import PortfolioIconActive from "../assets/icons/portfolio-active.svg";
import NewsIcon from "../assets/icons/news.svg";
import NewsIconActive from "../assets/icons/news-active.svg";
import IcosIcon from "../assets/icons/icos.svg";
import IcosIconActive from "../assets/icons/icos-active.svg";
import ToolsIcon from "../assets/icons/tools.svg";
import ToolsIconActive from "../assets/icons/tools-active.svg";
import CompareIcon from "../assets/icons/compare.svg";
import CompareIconActive from "../assets/icons/compare-active.svg";
import LightConverter from '../assets/images/converter-light.png' 
import DarkConverter from "../assets/images/converter-dark.png"; 

export const NAVBAR_DATA = [
  {
    id: "1",
    name: "Coins",
    page: "/",
    icon: CoinsIcon,
    iconAcive: CoinsIconActive,
    dropDown: false
  },
  {
    id: "2",
    name: "Watch List",
    page: "/watchlist",
    icon: WatchlistIcon,
    iconAcive: WatchlistIconActive,
    dropDown: false
  },
  {
    id: "3",
    name: "Exchanges",
    page: "/exchanges",
    icon: ExchangesIcon,
    iconAcive: ExchangesIconActive,
    dropDown: false
  },
  {
    id: "4",
    name: "Portfolio",
    page: "/portfolio",
    icon: PortfolioIcon,
    iconAcive: PortfolioIconActive,
    dropDown: false
  },
  {
    id: "5",
    name: "News",
    page: "/news",
    icon: NewsIcon,
    iconAcive: NewsIconActive,
    dropDown: false
  },
  // {
  //   id: "6",
  //   name: "ICO'S",
  //   page: "/ico's",
  //   icon: IcosIcon,
  //   iconAcive: IcosIconActive,
  //   dropDown: false
  // },
  {
    id: "7",
    name: "Compare",
    page: "/compare",
    icon: CompareIcon,
    iconAcive: CompareIconActive,
    dropDown: false
  },
  {
    id: "8",
    name: "Converter",
    page: "/converter",
    icon: LightConverter,
    iconAcive: DarkConverter,
    dropDown: false
  }
  // {
  //   id: "8",
  //   name: "Tools",
  //   icon: ToolsIcon,
  //   iconAcive: ToolsIconActive,
  //   dropDown: true,
  //   dropDownLinks: [
  //     // { linkName: "Currency Converter", page: "/" },
  //     { linkName: "Mining Equipment", page: "/tools" },
  //   ],
  // },
];
