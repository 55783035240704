import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

// api base url
const baseUrl = process.env.REACT_APP_BASE_URL;
const baseUrlV1 = process.env.REACT_APP_BASE_URL_V1;
const baseUrlV2 = process.env.REACT_APP_BASE_URL_V2;

// news api
export const newsApi = createApi({
  reducerPath: "newsApi",
  baseQuery: fetchBaseQuery({
    baseUrl
  }),
  endpoints: builder => ({
    getAllNewsPost: builder.query({
      query: currentPage => ({
        url: `v2/coin/news?page=${currentPage}`,
        method: "GET"
      })
    }),
    // api coin search
    getHeaderSearchCoin: builder.query({
      query: searchTerm => ({
        url: `v1/coin/markets/${searchTerm}`,
        method: "GET"
      })
    }),
    // api search
    getSearchNews: builder.query({
      query: searchTerm => ({
        url: `v2/search/news/${searchTerm}`,
        method: "GET"
      })
    }),
    getCoinMarket: builder.query({
      query: page => ({
        url: `v2/coin/markets?page=${page}`,
        method: "GET"
      })
    }),

    // get table filtered data
    getFilteredCoinMarket: builder.query({
      query: () => ({
        url: "v2/coin/coin_data",
        method: "GET"
      })
    }),
    getAllIcos: builder.query({
      query: () => ({
        url: "v2/coin/icos",
        method: "GET"
      })
    })
  })
});
// Header Api
export const coinGlobal = createApi({
  reducerPath: "coinGlobal",
  baseQuery: fetchBaseQuery({
    baseUrl
  }),
  endpoints: builder => ({
    getCoinGlobal: builder.query({
      query: () => ({
        url: "v1/coin/quotes/latest",
        method: "GET"
      })
    })
  })
});
// Auth api
export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: fetchBaseQuery({
    baseUrl
  }),
  endpoints: builder => ({
    register: builder.mutation({
      query: userData => ({
        url: "/register",
        method: "POST",
        body: userData
      })
    }),
    login: builder.mutation({
      query: credentials => ({
        url: "/login",
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: credentials
      })
    }),
    logout: builder.query({
      query: token => ({
        url: "/logout",
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + token
        }
      })
    }),
    getUser: builder.query({
      query: token => ({
        url: "/user",
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + token
        }
      })
    }),
    forgotPassword: builder.mutation({
      query: credentials => ({
        url: "/forgot-password",
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: credentials
      })
    }),
    verifyOTP: builder.mutation({
      query: credentials => ({
        url: "/verify",
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: credentials
      })
    }),
    resetPassword: builder.mutation({
      query: credentials => ({
        url: "/generate-new-password",
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: credentials
      })
    })
  })
});

export const coinListApi = createApi({
  reducerPath: "coinListApi",
  baseQuery: fetchBaseQuery({
    baseUrl
  }),
  endpoints: builder => ({
    getCoinList: builder.query({
      query: () => ({
        url: "/v2/coin/markets",
        method: "GET"
      })
    })
  })
});

//Single coin detail
export const singleCoinApi = createApi({
  reducerPath: "singleCoinApi",
  baseQuery: fetchBaseQuery({
    baseUrl
  }),
  endpoints: builder => ({
    getSingleCoinInfo: builder.query({
      query: coinSymbol => ({
        url: `v1/coin/${coinSymbol}`,
        method: "GET"
      })
    })
  })
});

//searched news
export const searchNews = createApi({
  reducerPath: "searchNews",
  baseQuery: fetchBaseQuery({
    baseUrl
  }),
  endpoints: builder => ({
    getSearchNewsCoin: builder.query({
      query: coin => ({
        url: `v2/search/news/${coin}`,
        method: "GET"
      })
    })
  })
});
// watchlist data
export const watchlist = createApi({
  reducerPath: "watchList",
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrlV1
  }),
  endpoints: builder => ({
    getWatchlist: builder.query({
      query: userId => ({
        url: `watchlist/${userId}`,
        method: "GET"
      })
    }),
    addWatchlist: builder.mutation({
      query: data => ({
        url: `save-watchlist/${data.userId}/${data.logo}`,
        method: "POST"
      })
    }),
    removeAllWatchlist: builder.mutation({
      query: data => ({
        url: `delete-watchlists/${data.userId}`,
        method: "DELETE"
      })
    })
  })
});

// portfolio data
export const portfolio = createApi({
  reducerPath: "portfolio",
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrlV1
  }),
  endpoints: builder => ({
    createPortfolio: builder.mutation({
      query: data => ({
        url: `create/portfolio/${data.userId}`,
        method: "POST",
        body: { name: data.name }
      })
    }),
    updatePortfolio: builder.mutation({
      query: data => ({
        url: `update/portfolio/${data.userId}/${data.portfolioId}`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        method: "POST",
        body: { name: data.name }
      })
    }),
    deletePortfolio: builder.mutation({
      query: data => ({
        url: `delete/portfolio/${data.userId}/${data.portfolioId}`,
        method: "DELETE"
      })
    }),
    getPortfolioByUser: builder.query({
      query: userId => ({
        url: `get/portfolio/${userId}`,
        method: "GET"
      })
    }),
    postTransaction: builder.mutation({
      query: data => ({
        url: "portfolio/save",
        method: "POST",
        body: data.formData
      })
    })
  })
});
// exchanges data
 export const exchangesTableData = createApi({
   reducerPath: "exchangesTableData",
   baseQuery: fetchBaseQuery({
     baseUrl: baseUrlV1
   }),
   endpoints: builder => ({
     getExchangesData: builder.query({
       query: () => ({
         url: "exchanges",
         method: "GET"
       })
     })
   })
 });

// remove transactions
export const transaction = createApi({
  reducerPath: "transaction",
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrlV2
  }),
  endpoints: builder => ({
    deleteTransaction: builder.mutation({
      query: ({ coinSymbol, transactionId }) => ({
        url: `remove-all-coin-transactions/${coinSymbol}/${transactionId}`,
        method: "DELETE"
      })
    })
  })
});

// remove single transactions
export const removeSingleTransaction = createApi({
  reducerPath: "removeSingleTransaction",
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrlV2
  }),
  endpoints: builder => ({
    deleteSingleTransaction: builder.mutation({
      query: transactionId => ({
        url: `delete-transaction/${transactionId}`,
        method: "DELETE"
      })
    })
  })
});

// edit single transaction
export const editSingleTransaction = createApi({
  reducerPath: "editSingleTransaction",
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrlV1
  }),
  endpoints: builder => ({
    updateSingleTransaction: builder.mutation({
      query: data => ({
        url: `update/transactions/${data.activePortfolioId}/${data.transactionId}`,
        method: "POST",
        body: data.formData
      })
    })
  })
});

// transactions detail
export const transactionsDetail = createApi({
  reducerPath: "transactionsDetail",
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrlV2
  }),
  endpoints: builder => ({
    transactionsDetails: builder.query({
      query: ({ user_id, activePortfolioId, portfolioCoin }) => ({
        url: `/transaction-details/${user_id}/${activePortfolioId}/${portfolioCoin}`,
        method: "GET"
      })
    })
  })
});

// Mining Equipments
export const miningEquipments = createApi({
  reducerPath: "miningEquipments",
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrlV1
  }),
  endpoints: builder => ({
    getMiningEquipments: builder.query({
      query: () => ({
        url: "mining-equipment",
        method: "GET"
      })
    })
  })
});

// coin gainer
export const coinGainers = createApi({
  reducerPath: "coinGainers",
  baseQuery: fetchBaseQuery({
    baseUrl
  }),
  endpoints: builder => ({
    getCoinGainers: builder.query({
      query: () => ({
        url: "v2/coin/gainers",
        method: "GET"
      })
    })
  })
});

// coin gainer
export const coinLosers = createApi({
  reducerPath: "coinLosers",
  baseQuery: fetchBaseQuery({
    baseUrl
  }),
  endpoints: builder => ({
    getCoinLosers: builder.query({
      query: () => ({
        url: "v2/coin/losers",
        method: "GET"
      })
    })
  })
});

// coin recently
export const recentlyCoins = createApi({
  reducerPath: "recentlyCoins",
  baseQuery: fetchBaseQuery({
    baseUrl
  }),
  endpoints: builder => ({
    getRecentlyCoins: builder.query({
      query: () => ({
        url: "v1/coin/recent",
        method: "GET"
      })
    })
  })
});

// reference links
export const affiliateLinks = createApi({
  reducerPath: "affiliateLinks",
  baseQuery: fetchBaseQuery({
    baseUrl
  }),
  endpoints: builder => ({
    getAffiliateLinks: builder.query({
      query: () => ({
        url: "v1/coin-exchanges",
        method: "GET"
      })
    })
  })
});
// chart data
export const chartData = createApi({
  reducerPath: "chartData",
  baseQuery: fetchBaseQuery({
    baseUrl
  }),
  endpoints: builder => ({
    getChartData: builder.query({
      query: ({ coin_name, periodActive }) => ({
        url: `v1/charts/${coin_name}/${periodActive}`,
        method: "GET"
      })
    })
  })
});

// CMC Crypto Fear & Greed Index chart
export const greedIndex = createApi({
  reducerPath: "greedIndex",
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrlV1
  }),
  endpoints: builder => ({
    getFngData: builder.query({
      query: periodActive => `fear-greed-index/${periodActive}`
    })
  })
});


export const {
  useGetAllNewsPostQuery,
  useGetAllIcosQuery,
  useGetCoinMarketQuery,
  useGetFilteredCoinMarketQuery,
  useGetHeaderSearchCoinQuery,
  useGetSearchNewsQuery
} = newsApi;
export const { useGetSearchNewsCoinQuery } = searchNews;
export const { useGetSingleCoinInfoQuery } = singleCoinApi;
export const { useGetCoinGlobalQuery } = coinGlobal;
export const {
  useRegisterMutation,
  useLoginMutation,
  useGetUserQuery,
  useLogoutQuery,
  useForgotPasswordMutation,
  useVerifyOTPMutation,
  useResetPasswordMutation
} = authApi;
export const { useGetCoinListQuery } = coinListApi;
export const { useGetExchangesDataQuery } = exchangesTableData;
export const { useGetMiningEquipmentsQuery } = miningEquipments;
export const { useGetCoinGainersQuery } = coinGainers;
export const { useGetCoinLosersQuery } = coinLosers;
export const { useGetRecentlyCoinsQuery } = recentlyCoins;

export const { useGetWatchlistQuery, useAddWatchlistMutation, useRemoveAllWatchlistMutation } =
  watchlist;
export const { useGetAffiliateLinksQuery } = affiliateLinks;
export const {
  useCreatePortfolioMutation,
  useUpdatePortfolioMutation,
  useDeletePortfolioMutation,
  useGetPortfolioByUserQuery,
  usePostTransactionMutation
} = portfolio;
export const { useDeleteTransactionMutation } = transaction;
export const { useDeleteSingleTransactionMutation } = removeSingleTransaction;
export const { useTransactionsDetailsQuery } = transactionsDetail;
export const { useUpdateSingleTransactionMutation } = editSingleTransaction;
export const { useGetChartDataQuery } = chartData;
export const { useGetFngDataQuery } = greedIndex;