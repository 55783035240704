import React, { useState } from "react";
import chevron from "../../assets/icons/chevron-down-grey.svg";
import { Accordion, AccordionItem as Item } from "@szhsin/react-accordion";
import { selectCurrentTheme } from "../../store/slices/themeSlice";
import Modal from "../../utils/Modal";
import {
  CIRCULATING_SUPPLY_RANGES,
  MARKET_CAP_RANGES,
  PER_RANGES,
  PRICE_RANGES,
  VOLUME_RANGES
} from "../../data/tableFilterData";
import { useDispatch, useSelector } from "react-redux";
import {
  changeMaxNumHandler,
  changeMinNumHandler,
  circulatingMaxNumHandler,
  circulatingMinNumHandler,
  marketCapMaxNumHanlder,
  marketCapMinNumHanlder,
  priceMaxNumHandler,
  priceMinNumHandler,
  volumeMaxNumHandler,
  volumeMinNumHandler,
  toggleAllFilters
} from "../../store/slices/filterSlice";
import FilterRangeInputs from "../FilterRangeInputs";
import FilterSwitches from "../FilterSwitches/FilterSwitches";
import { closeFilter } from "../../store/slices/popupSlice";

const AccordionItem = ({ header, ...rest }) => (
  <Item
    {...rest}
    header={({ state: { isEnter } }) => (
      <>
        {header}
        <img
          className={`w-5 ml-auto transition-transform duration-200 ease-in-out  ${
            isEnter && "-rotate-180"
          }`}
          src={chevron}
          alt="Chevron"
        />
      </>
    )}
    className={({ isEnter }) =>
      ` px-3 xs:px-1 overflow-hidden sm:text-sm xs:text-xs border-t text-filterSwitchColor border-lightBorder`
    }
    buttonProps={{
      className: ({ isEnter }) =>
        `flex items-center w-full p-4 text-left font-semibold  gap-3 ${
          isEnter ? "border-b" : ""
        }`
    }}
    contentProps={{
      className: "transition-height duration-200 ease-in-out"
    }}
    panelProps={{ className: "px-8" }}
  />
);

const FilterPopup = ({ filterDataHandler, handleAllFiltersSelected }) => {

  const dispatch = useDispatch();
 const currentTheme = useSelector(selectCurrentTheme);
  const marketCapMinToNumHandler = val => {
    dispatch(marketCapMinNumHanlder(val));
  };
  const marketCapMaxToNumHandler = val => {
    dispatch(marketCapMaxNumHanlder(val));
  };
  const priceMinToNumHandler = val => {
    dispatch(priceMinNumHandler(val));
  };
  const priceMaxToNumHandler = val => {
    dispatch(priceMaxNumHandler(val));
  };
  const changeMaxToNumHandler = val => {
    dispatch(changeMaxNumHandler(val));
  };
  const changeMinToNumHandler = val => {
    dispatch(changeMinNumHandler(val));
  };
  const volumeMaxToNumHandler = val => {
    dispatch(volumeMaxNumHandler(val));
  };
  const volumeMinToNumHandler = val => {
    dispatch(volumeMinNumHandler(val));
  };
  const circulatingMaxToNumHandler = val => {
    dispatch(circulatingMaxNumHandler(val));
  };
  const circulatingMinToNumHandler = val => {
    dispatch(circulatingMinNumHandler(val));
  };

  return (
    <Modal maxWidth={600}>
      <div className="relative">
        <div
          className={`mb-4 py-7 px-10 xs:px-4 fixed z-10 top-0 left-0 w-full ${
            currentTheme === "dark"
              ? "bg-cardsColor text-searchIconColor"
              : "bg-mainBg text-filter"
          }`}
        >
          <div className="w-[100%] text-center">
            <p className="text-[22px] xs:text-lg sm:text-xl font-semibold">
              More Filters
            </p>
          </div>
        </div>
        <div className="mt-20">
          <Accordion transition transitionTimeout={200} className="text-secondary">
            {/* <AccordionItem header={`All Cryptocurrencies`}> */}
            <div>
              {/* <div className="flex justify-between items-center border-t py-3 relative">
                  <label htmlFor="all-crypto" className="w-full">
                    All Cryptocurrencies
                  </label>
                  <input
                    type="radio"
                    name="crypto"
                    id="all-crypto"
                    className="absolute right-0 invisible checked:visible border-0 w-4 h-4"
                    checked={"all-crypto" === cryptoOption}
                    onChange={() => setCryptoOption("all-crypto")}
                  />
                </div> */}
              {/* <div className="flex justify-between items-center border-t py-3 relative">
                  <label htmlFor="coins" className="w-full">
                    Coins
                  </label>
                  <input
                    type="radio"
                    name="crypto"
                    id="coins"
                    className="absolute right-0 invisible checked:visible border-0 w-4 h-4"
                    checked={"coins" === cryptoOption}
                    onChange={() => setCryptoOption("coins")}
                  />
                </div> */}
              {/* <div className="flex justify-between items-center border-t py-3 relative">
                  <label htmlFor="tokens" className="w-full">
                    Tokens
                  </label>
                  <input
                    type="radio"
                    name="crypto"
                    id="tokens"
                    className="absolute right-0 invisible checked:visible border-0 w-4 h-4"
                    checked={"tokens" === cryptoOption}
                    onChange={() => setCryptoOption("tokens")}
                  />
                </div> */}
            </div>
            {/* </AccordionItem> */}

            <AccordionItem header={`Market Cap`}>
              <div className="mt-4">
                <div className="mb-3">
                  <p className="font-medium">Market Cap Range</p>
                </div>
                <FilterRangeInputs
                  maxNumValue={marketCapMaxToNumHandler}
                  minNumValue={marketCapMinToNumHandler}
                  unit="$"
                  minPlaceHolder="0"
                  maxPlaceHolder="999,999,999,999"
                  ranges={MARKET_CAP_RANGES}
                />
              </div>
            </AccordionItem>
            <AccordionItem header={`Price`}>
              <div className="mt-4">
                <div className="mb-3">
                  <p className="font-medium">Price Range</p>
                </div>
                <FilterRangeInputs
                  maxNumValue={priceMaxToNumHandler}
                  minNumValue={priceMinToNumHandler}
                  unit="$"
                  minPlaceHolder="0"
                  maxPlaceHolder="99,999"
                  ranges={PRICE_RANGES}
                />
              </div>
            </AccordionItem>

            <AccordionItem header={`% Change`}>
              <div className="mt-4">
                <div className="mb-3">
                  <p className="font-medium">Change Range</p>
                </div>
                <FilterRangeInputs
                  maxNumValue={changeMaxToNumHandler}
                  minNumValue={changeMinToNumHandler}
                  unit="%"
                  minPlaceHolder="-100"
                  maxPlaceHolder="1000"
                  ranges={PER_RANGES}
                />
              </div>
            </AccordionItem>
            <AccordionItem header={`Volume`}>
              <div className="mt-4">
                <div className="mb-3">
                  <p className="font-medium">Volume Range</p>
                </div>
                <FilterRangeInputs
                  maxNumValue={volumeMaxToNumHandler}
                  minNumValue={volumeMinToNumHandler}
                  minPlaceHolder="0"
                  unit="$"
                  maxPlaceHolder="999,999,999,999"
                  ranges={VOLUME_RANGES}
                />
              </div>
            </AccordionItem>
            <AccordionItem header={`Circulating Supply`}>
              <div className="mt-4">
                <div className="mb-3">
                  <p className="font-medium">Circulating Supply Range</p>
                </div>
                <FilterRangeInputs
                  maxNumValue={circulatingMaxToNumHandler}
                  minNumValue={circulatingMinToNumHandler}
                  minPlaceHolder="0"
                  maxPlaceHolder="999,999,999,999"
                  ranges={CIRCULATING_SUPPLY_RANGES}
                />
              </div>
            </AccordionItem>
          </Accordion>
        </div>
        {/* <div>
          <FilterSwitches />
        </div> */}
        <div className="my-4 px-7 flex gap-4 justify-end">
          <div onClick={() => dispatch(closeFilter())}>
            <button className="px-5 py-1 border text-opacityColor rounded-[5px] sm:px-3 sm:text-sm">
              Close
            </button>
          </div>
          <div>
            <button
              className="px-5 py-1 border text-opacityColor rounded-[5px] sm:px-3 sm:text-sm"
              onClick={handleAllFiltersSelected}
            >
              Clear Items
            </button>
          </div>
          <div>
            <button
              className="px-5 py-1 bg-primary text-mainBg rounded-[5px] sm:px-3 sm:text-sm"
              onClick={filterDataHandler}
            >
              Apply Filter
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default FilterPopup;
