import React, { useState } from "react";
import { useGetFngDataQuery } from "../../store/services/services";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import GaugeChart from "react-gauge-chart";
import { selectCurrentTheme } from "../../store/slices/themeSlice";
import { useSelector } from "react-redux";

const getStatus = percent => {
  if (percent < 20) {
    return "Extreme Fear";
  } else if (percent < 40) {
    return "Fear";
  } else if (percent < 60) {
    return "Neutral";
  } else if (percent < 80) {
    return "Greed";
  } else {
    return "Extreme Greed";
  }
};

const Recent = () => {
  const [periodActive, setPeriodActive] = useState("7d");
  const { data: chartData, isLoading, isError, error } = useGetFngDataQuery(periodActive);

  const apiPercent = chartData?.data[chartData?.data?.length - 1].value;

  const status = getStatus(apiPercent);
  const currentTheme = useSelector(selectCurrentTheme);
  return (
    <div
      className={`w-[32%] min-w-[308px] shadow-navbar-button p-5 rounded-[10px] mb-4 ${
        currentTheme === "dark" ? "bg-cardsColor" : "bg-mainBg"
      }`}
    >
      <div className="flex justify-between items-center">
        <div className="flex items-center gap-2">
          <p
            className={`${
              currentTheme === "dark" ? " text-mainBg" : "text-filter"
            } font-medium sm:text-sm`}
          >
            Fear & Greed Index
          </p>
        </div>
      </div>
      {isLoading ? (
        <SkeletonTheme
          baseColor={`${currentTheme === "dark" ? "#323546" : "#d8d8d8"}`}
          highlightColor={`${currentTheme === "dark" ? "#444" : "#e0e0e0"}`}
        >
          <Skeleton width="100%" height={100} count={1} />
        </SkeletonTheme>
      ) : isError ? (
        <div>Error: {error.message}</div>
      ) : (
        <>
          <div>
            <div style={{ width: "200px", margin: "0 auto", height: 80 }}>
              <GaugeChart
                id="gauge-chart5"
                nrOfLevels={20}
                arcsLength={[0.2, 0.2, 0.2, 0.2, 0.2]}
                colors={[
                  "#FF0000",
                  "#FF3300",
                  "#FF6600",
                  "#FF9900",
                  "#FFCC00",
                  "#FFFF00",
                  "#CCFF00",
                  "#99FF00",
                  "#66FF00",
                  "#33FF00"
                ]}
                hideText={true}
                percent={apiPercent / 100}
                arcPadding={0.02}
                textColor="#ffffff"
                arcWidth={0.2}
              />
            </div>
          </div>
          <p
            className={`${
              currentTheme === "dark" ? " text-mainBg" : "text-filter"
            } text-center`}
          >
            {apiPercent}
          </p>
          <div
            className={`${
              currentTheme === "dark" ? " text-mainBg" : "text-filter"
            } status-label text-center`}
          >
            {status}
          </div>
        </>
      )}
    </div>
  );
};

export default Recent;
