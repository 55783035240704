import React from "react";
import { Select } from "antd";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import DecreaseChangeIcon from "../../assets/icons/decrease-change.svg";
import IncreaseChangeIcon from "../../assets/icons/increase-change.svg";
import decreaseChangeIcon from "../../assets/icons/decrease-change.svg";
import { commafy } from "../../utils/commafy";
import { toDecimals } from "../../utils/toDecimals";
import { selectCurrentTheme } from "../../store/slices/themeSlice";
import { useSelector } from "react-redux";

const CoinData = ({ coin }) => {
  // get low 24h and high 24h
  const currentTheme = useSelector(selectCurrentTheme);
  const calculateHighLowPrices = () => {
    const currentPrice = parseFloat(coin?.price);
    const percentChangeDay = parseFloat(coin?.percentChangeDay);

    if (!isNaN(currentPrice) && !isNaN(percentChangeDay)) {
      const highPrice = currentPrice * (1 + percentChangeDay / 100);
      const lowPrice = currentPrice * (1 - percentChangeDay / 100);

      return {
        high: highPrice.toFixed(2),
        low: lowPrice.toFixed(2)
      };
    } else {
      return {
        high: "N/A",
        low: "N/A"
      };
    }
  };

  const { high, low } = calculateHighLowPrices();

  return (
    <div>
      {/* {isLoading && (
        <div className="w-full">
          <Skeleton width="100%" height={35} count={5} />
        </div>
      )}
      {isError && <p>{error.message}</p>}
      {!isError && !isError && ( */}
      <div className="">
        <div
          className={`border-b-[.5px] px-5 py-3 ${
            currentTheme === "dark" ? "border-darkBorder" : "border-lightBorder"
          }`}
        >
          {/* <p className="text-grey mb-3 xs:text-sm">{filterCoin.title}</p> */}
          <div className="flex items-center gap-3 mb-2">
            <p
              className={`text-[26px] font-semibold xs:text-2xl ${
                currentTheme === "dark" ? "text-mainBg " : "text-filter"
              }`}
            >
              ${commafy(toDecimals(coin?.price))}
            </p>
            {/* <p
                className={`flex items-center gap-2 ${
                  filterCoin.twentyfourh > 0 ? "bg-increase" : "bg-decrease"
                }  py-[2px] px-2 rounded-[7px] xs:text-sm text-mainBg`}
              >
                <FontAwesomeIcon
                  icon={filterCoin.twentyfourh > 0 ? faCaretUp : faCaretDown}
                />
                {filterCoin.twentyfourh}%
              </p> */}
          </div>
          <div className="flex items-center gap-1">
            <img
              className="sm:w-[6px]"
              src={coin?.percentChangeDay > 0 ? IncreaseChangeIcon : decreaseChangeIcon}
              alt={coin?.change > 0 ? "increase-icon" : "decrease-icon"}
            />
            <p
              className={`${
                coin.percentChangeDay > 0 ? "text-increase" : "text-decrease"
              } text-xs sm:text-[11px]`}
            >
              {commafy(toDecimals(coin?.percentChangeDay))}%
              {/* {Math.abs(item.percentChangeDay)} */}
            </p>
          </div>
          <div className="flex items-center gap-2 text-sm ">
            <p className={`${currentTheme === "dark" ? "text-mainBg " : "text-grey"}`}>
              Low:{" "}
              <span className="font-medium">
                ${commafy(toDecimals(coin?.percentChangeDay < 0 ? high : low))}
              </span>
            </p>
            <div className="h-[2px] w-28 bg-icosProjectsGrey"></div>
            <p className={`${currentTheme === "dark" ? "text-mainBg " : "text-grey"}`}>
              High:{" "}
              <span className="font-medium">
                ${commafy(toDecimals(coin?.percentChangeDay < 0 ? low : high))}
              </span>
            </p>
            <p className="border px-2 py-1 rounded-lg">24h</p>
            {/* <Select
            defaultValue="24h"
            size="small"
            options={[
              {
                value: "24h",
                label: "24h"
              }
              // { value: "1h", label: "1h" },
              // {
              //   value: "7d",
              //   label: "7d"
              // }
            ]}
          /> */}
          </div>
        </div>
        <div
          className={`flex items-center text-sm xs:flex-col border-b-[.5px] ${
            currentTheme === "dark" ? "border-darkBorder" : "border-lightBorder"
          }`}
        >
          <div
            className={`w-2/5 xs:w-full px-5 py-3 flex flex-col gap-1 border-r-[.5px] xs:border-r-0 xs:border-b-[.5px] ${
              currentTheme === "dark" ? "border-darkBorder" : "border-lightBorder"
            }`}
          >
            <p
              className={`font-medium  ${
                currentTheme === "dark" ? "text-mainBg " : "text-coinDataDarkGreyColor"
              }`}
            >
              Market Cap
            </p>
            <p
              className={`font-semibold  ${
                currentTheme === "dark" ? "text-mainBg " : "text-grey"
              }`}
            >
              ${commafy(toDecimals(coin?.market_cap))}
            </p>
            {/* <p className="flex items-center text-increase gap-1">
              <img src={IncreaseChangeIcon} alt="increase-change" />
              3.28%
            </p> */}
          </div>
          <div className="w-3/5 xs:w-full px-5 py-3 flex flex-col gap-1">
            <p
              className={`font-medium  ${
                currentTheme === "dark" ? "text-mainBg " : "text-coinDataDarkGreyColor"
              }`}
            >
              Fully Diluted Market Cap
            </p>
            <p
              className={`font-semibold  ${
                currentTheme === "dark" ? "text-mainBg " : "text-grey"
              }`}
            >
              ${commafy(toDecimals(coin?.fully_diluted_market_cap))}
            </p>
            {/* <p className="flex items-center text-increase gap-1">
              <img src={IncreaseChangeIcon} alt="increase-change" />
              5.22%
            </p> */}
          </div>
        </div>
        <div className="flex xs:flex-col items-center text-sm">
          <div
            className={`w-2/5 xs:w-full px-5 py-3 flex flex-col gap-1 border-r-[.5px] xs:border-r-0 xs:border-b-[.5px] ${
              currentTheme === "dark" ? "border-darkBorder" : "border-lightBorder"
            }`}
          >
            <div className="flex items-center gap-2">
              <p
                className={`font-medium ${
                  currentTheme === "dark" ? "text-mainBg " : "text-coinDataDarkGreyColor"
                }`}
              >
                Volume
              </p>
              <p
                className={`px-2 rounded-[7px] ${
                  currentTheme === "dark"
                    ? "bg-filter text-mainBg "
                    : "bg-coinTagsBg text-grey "
                }`}
              >
                24h
              </p>
            </div>
            <p
              className={`font-semibold  ${
                currentTheme === "dark" ? "text-mainBg " : "text-grey"
              }`}
            >
              ${commafy(toDecimals(coin?.volume_24h))}
            </p>
            {/* <p className="text-decrease flex items-center gap-1">
              <img src={DecreaseChangeIcon} alt="decrease-icon" /> 4.33%
            </p> */}
            <p
              className={` ${
                currentTheme === "dark" ? "text-mainBg " : "text-coinDataGreyColor"
              }`}
            >
              Volume / Market Cap
            </p>
            <div className="flex items-center gap-1">
              <img
                className="sm:w-[6px]"
                src={
                  coin?.volume_marketCap_24h > 0 ? IncreaseChangeIcon : decreaseChangeIcon
                }
                alt={coin?.change > 0 ? "increase-icon" : "decrease-icon"}
              />
              <p
                className={`${
                  coin.volume_marketCap_24h > 0 ? "text-increase" : "text-decrease"
                } text-xs sm:text-[11px]`}
              >
                {commafy(toDecimals(coin?.volume_marketCap_24h))}%
                {/* {Math.abs(item.percentChangeDay)} */}
              </p>
            </div>
          </div>
          <div className="w-3/5 xs:w-full flex flex-col gap-1 px-5 py-2">
            <p
              className={`font-medium ${
                currentTheme === "dark" ? "text-mainBg " : "text-coinDataGreyColor"
              }`}
            >
              Circulating Supply
            </p>
            <div className="font-semibold flex items-center justify-between mb-1">
              <p className={` ${currentTheme === "dark" ? "text-mainBg " : "text-grey"}`}>
                {commafy(toDecimals(coin?.circulating_supply))}
              </p>
              {/* <p className="text-[#A7A5A5]">90%</p> */}
            </div>
            <div>
              {/* <div className="h-1 w-full bg-icosProjectsGrey rounded-full mb-2">
                <div className="h-1 w-[90%] bg-[#969595] rounded-full"></div>
              </div> */}
            </div>
            <div className="flex justify-between items-center font-medium">
              <p
                className={` ${
                  currentTheme === "dark" ? "text-mainBg " : "text-coinDataGreyColor"
                }`}
              >
                Max Supply
              </p>
              <p
                className={`text-grey ${
                  currentTheme === "dark" ? "text-mainBg " : "text-coinDataGreyColor"
                }`}
              >
                {commafy(toDecimals(coin?.max_supply))}
              </p>
            </div>
            <div className="flex justify-between items-center font-medium">
              <p
                className={` ${
                  currentTheme === "dark" ? "text-mainBg " : "text-coinDataGreyColor"
                }`}
              >
                Total Supply
              </p>
              <p
                className={`text-grey ${
                  currentTheme === "dark" ? "text-mainBg " : "text-grey"
                }`}
              >
                {commafy(toDecimals(coin?.total_supply))}
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* )} */}
    </div>
  );
};

export default CoinData;
