import React, { useEffect, useState } from "react";
import {
  faChevronLeft,
  faPencil,
  faTrash,
  faCirclePlus
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import IncreaseChangeIcon from "../../assets/icons/increase-change.svg";
import {
  useGetPortfolioByUserQuery,
  useTransactionsDetailsQuery
} from "../../store/services/services";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { toDecimals } from "../../utils/toDecimals";
import { commafy } from "../../utils/commafy";
import { useSelector, useDispatch } from "react-redux";
import {
  closeModal,
  openSingleTransactionEdit,
  openSingleTransactionRemove,
  openTransactionNotePopup,
  openTransactionPopup
} from "../../store/slices/popupSlice";
import RemoveSingleTransactionPopup from "../RemoveSingleTransactionPopup/RemoveSingleTransactionPopup";
import SingleTransactionEditPopup from "../SingleTransactionEditPopup/SingleTransactionEditPopup";
import TransactionPopup from "../TransactionPopup/TransactionPopup";
import TransactionNotePopup from "../TransactionNotePopup/TransactionNotePopup";
import { selectCurrentTheme } from "../../store/slices/themeSlice";

const TransactionsDetail = ({ portfolioCoin, goBack }) => {
  const dispatch = useDispatch();
  const currentTheme = useSelector(selectCurrentTheme);
  const [transactionsId, setTransactionsId] = useState(null);
  const [transactions, setTransactions] = useState("");
  const [currentEditTransaction, setCurrentEditTransaction] = useState(null);

  const comparePortfolio = useSelector(state => state.portfolioChange.portfolioId);
  const user_id = useSelector(state => state.auth.userId);

  const activePortfolioId = localStorage.getItem("activePortfolio");

  const { data, refetch, isLoading, isError, error, isFetching } =
    useTransactionsDetailsQuery({
      user_id,
      activePortfolioId,
      portfolioCoin
    });
  const userId = useSelector(state => state.auth.userId);
  const {
    data: portFolioData,
    refetch: portfolioRefetch,
    isFetching: portfolioIsFetching,
    isLoading: portfolioisLoading,
    isError: portfolioisError
  } = useGetPortfolioByUserQuery(userId);

  let filteredTransactions;
  if (!portfolioisLoading && !portfolioisError) {
    filteredTransactions = portFolioData?.portfolioData?.find(
      item => item.id == activePortfolioId
    );
  }

  useEffect(() => {
    if (!isLoading) {
    }
  }, [isLoading]);

  let filteredCoin;
  if (!portfolioisLoading && !portfolioisError) {
    filteredCoin = filteredTransactions?.transactions?.find(
      item => item?.coin === portfolioCoin
    );
  }
  const portfolioChange = useSelector(state => state.portfolioChange.portfolioChange);
  // remove transaction
  const singleTransactionRemove = useSelector(
    state => state.popup.singleTransactionRemoveIsVisible
  );

  // add coins
  const addTransaction = useSelector(state => state.popup.transactionPopupIsVisible);

  useEffect(() => {
    if (transactionsId !== null) {
    }
  }, [transactionsId]);
  useEffect(() => {
    if (portfolioChange) {
      refetch();
    }
  }, [data, portfolioChange, refetch]);
  // refetch data
  useEffect(() => {
    portfolioRefetch();
  }, [data, portfolioRefetch]);

  // edit transaction
  const portfolioEdit = useSelector(state => state.popup.singleTransactionEditIsVisible);
  // transaction note
  const viewTransaction = useSelector(state => state.popup.transactionNotePopupIsVisible);

  return (
    <div
      className={`px-24 sm:px-8 xs:px-4 mt-8 ${
        currentTheme === "dark" ? "bg-filter" : "bg-mainBg"
      }`}
    >
      <div className="flex text-xs pb-4">
        <div className="cursor-pointer" onClick={goBack}>
          <div
            className={` font-semibold flex items-center gap-1 px-4 py-2 cursor-pointer rounded-md ${
              currentTheme === "dark"
                ? "bg-cardsColor text-mainBg"
                : "bg-exchangesTableColor text-filter"
            }`}
          >
            <FontAwesomeIcon icon={faChevronLeft} />
            Back
          </div>
        </div>
      </div>
      <div>
        <div className="flex items-center gap-2 text-grey">
          {portfolioisLoading && (
            <div className="w-full">
              <SkeletonTheme
                baseColor={`${currentTheme === "dark" ? "#323546" : "#d8d8d8"}`}
                highlightColor={`${currentTheme === "dark" ? "#444" : "#e0e0e0"}`}
              >
                <Skeleton width="10%" height={35} count={1} />
              </SkeletonTheme>
            </div>
          )}
          {portfolioisError && <p>{error.message}</p>}
          {!portfolioisLoading && !portfolioisError && (
            <p
              className={`flex gap-1 text-base md:text-sm md:pb-2 ${
                currentTheme === "dark" ? " text-mainBg" : "text-filter"
              }`}
            >
              <p>{filteredCoin?.coin_name}</p>({filteredCoin?.coin}) Balance
            </p>
          )}
          {/* <FontAwesomeIcon icon={faEye} className='text-xs' /> */}
        </div>
        <div className="flex justify-between items-center md:block pb-4">
          {portfolioisLoading && (
            <div className="w-full">
              <SkeletonTheme
                baseColor={`${currentTheme === "dark" ? "#323546" : "#d8d8d8"}`}
                highlightColor={`${currentTheme === "dark" ? "#444" : "#e0e0e0"}`}
              >
                <Skeleton width="15%" height={35} count={1} />
              </SkeletonTheme>
            </div>
          )}
          {portfolioisError && <p>{error.message}</p>}
          {!portfolioisLoading && !portfolioisError && (
            <div className="flex flex-wrap items-center md:pb-3 gap-3">
              <img src={filteredCoin?.coinImg} alt="bitcoin" className="w-7 xs:w-6" />
              <p
                className={`text-[30px] md:text-[22px] font-bold ${
                  currentTheme === "dark" ? " text-mainBg" : "text-filter"
                }`}
              >
                ${commafy(toDecimals(filteredCoin?.totalHoldings))}
              </p>
            </div>
          )}
          <div className="flex xs:flex-col gap-2">
            <button
              className="flex gap-1 items-center justify-center py-1 bg-primary px-5 text-darkBlue rounded-[5px]"
              onClick={() => {
                dispatch(closeModal());
                dispatch(openTransactionPopup());
              }}
            >
              <FontAwesomeIcon icon={faCirclePlus} />
              Add New
            </button>
          </div>
        </div>
        {portfolioisLoading ||
          (portfolioIsFetching && (
            <div className="w-full">
              <SkeletonTheme
                baseColor={`${currentTheme === "dark" ? "#323546" : "#d8d8d8"}`}
                highlightColor={`${currentTheme === "dark" ? "#444" : "#e0e0e0"}`}
              >
                <Skeleton width="30%" height={35} count={1} />
              </SkeletonTheme>
            </div>
          ))}
        {portfolioisError && <p>{error.message}</p>}
        {!portfolioisLoading && !portfolioIsFetching && !portfolioisError && (
          <div className="flex flex-wrap items-center gap-8 md:gap-4">
            <div>
              <p
                className={`text-grey text-sm ${
                  currentTheme === "dark" ? " text-mainBg" : "text-grey"
                }`}
              >
                Quantity
              </p>
              <p
                className={`font-semibold ${
                  currentTheme === "dark" ? " text-mainBg" : "text-filter"
                }`}
              >
                {commafy(toDecimals(filteredCoin?.quantity))} {filteredCoin?.coin}
              </p>
            </div>
            <div>
              <p
                className={` text-sm ${
                  currentTheme === "dark" ? " text-mainBg" : "text-grey"
                }`}
              >
                Avg. buy price
              </p>
              <p
                className={`font-semibold ${
                  currentTheme === "dark" ? " text-mainBg" : "text-filter"
                }`}
              >
                ${commafy(toDecimals(filteredCoin?.averagePrice))}
              </p>
            </div>
            <div className="text-increase">
              <p
                className={`text-base ${
                  currentTheme === "dark" ? " text-mainBg" : "text-grey"
                }`}
              >
                Total profit / loss
              </p>
              <div className="text-sm">
                <p className="font-semibold flex items-center gap-2">
                  <img src={IncreaseChangeIcon} alt="inc-icon" />1 {filteredCoin?.coin}
                  &nbsp;
                  {commafy(toDecimals(filteredCoin?.profitLossPercentage))}%(+$
                  {commafy(toDecimals(filteredCoin?.profitLoss))})
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="mb-8 overflow-x-auto scrollbar-hide shadow-header-shadow rounded-[20px] mt-6">
        <table
          className={`w-full min-w-[1100px] xs:min-w-[800px] relative xs:text-xs ${
            currentTheme === "dark" ? "bg-cardsColor" : "bg-mainBg"
          }`}
        >
          <thead>
            <tr
              className={`border-b-[1px] ${
                currentTheme === "dark" ? "border-darkBorder" : "border-lightBorder"
              }`}
            >
              <th
                className={`w-32 xs:w-20 shadow-sm text-left pb-2 pt-3 font-medium  sticky left-0  before  pl-4 ${
                  currentTheme === "dark"
                    ? "bg-cardsColor text-mainBg"
                    : "bg-mainBg text-filter"
                }`}
              >
                Type
              </th>
              <th
                className={`w-32 xs:w-20 text-left pb-2 pt-3 font-medium pl-5 ${
                  currentTheme === "dark" ? "text-mainBg" : "text-filter"
                }`}
              >
                Price
              </th>
              <th
                className={`w-36  xs:w-24 text-left  pb-2 pt-3 font-medium ${
                  currentTheme === "dark" ? "text-mainBg" : "text-filter"
                }`}
              >
                Amount
              </th>
              <th
                className={`w-36 xs:w-24 text-left pb-2 pt-3 font-medium ${
                  currentTheme === "dark" ? "text-mainBg" : "text-filter"
                }`}
              >
                Fees
              </th>
              <th
                className={`w-36 xs:w-24 text-left pb-2 pt-3 font-medium ${
                  currentTheme === "dark" ? "text-mainBg" : "text-filter"
                }`}
              >
                Note
              </th>
              <th
                className={`w-36 xs:w-24 text-left pb-2 pt-3 font-medium ${
                  currentTheme === "dark" ? "text-mainBg" : "text-filter"
                }`}
              >
                Actions
              </th>
            </tr>
          </thead>
          {(isLoading || isFetching) && (
            <div className="w-full">
              <SkeletonTheme
                baseColor={`${currentTheme === "dark" ? "#323546" : "#d8d8d8"}`}
                highlightColor={`${currentTheme === "dark" ? "#444" : "#e0e0e0"}`}
              >
                <Skeleton width="100%" height={37} count={3} />
              </SkeletonTheme>
            </div>
          )}
          {isError && <p>{error.message}</p>}
          {!isFetching && !isLoading && !isError && (
            <tbody className="xs:text-xs">
              {data?.transaction_details?.length > 0 ? (
                data?.transaction_details?.map(item => {
                  return (
                    <tr
                      className={`text-sm xs:text-xs border-b-[1px] ${
                        currentTheme === "dark"
                          ? "border-darkBorder"
                          : "border-lightBorder"
                      }`}
                      key={item.id}
                    >
                      <td
                        className={`sticky left-0 pl-4  ${
                          currentTheme === "dark" ? "bg-cardsColor" : "bg-mainBg"
                        }`}
                      >
                        <div
                          className={`flex items-center h-16 gap-[6px] before ${
                            currentTheme === "dark" ? "bg-cardsColor" : "bg-mainBg"
                          }`}
                        >
                          <div className="bg-primary w-[35px] rounded-full aspect-square flex justify-center items-center">
                            <p className={`font-bold text-[20px] text-mainBg`}>
                              {item?.type?.slice(0, 1).toUpperCase()}
                            </p>
                          </div>
                          <div className="pl-3">
                            <span
                              className={`text-base font-semibold ${
                                currentTheme === "dark"
                                  ? "text-mainBg bg-cardsColor"
                                  : "text-filter bg-mainBg"
                              }`}
                            >
                              {item?.type}
                            </span>
                            <p
                              className={`${
                                currentTheme === "dark" ? "text-mainBg" : "text-filter"
                              }`}
                            >
                              {new Date(item?.date).toDateString()}
                            </p>
                          </div>
                        </div>
                      </td>
                      <td
                        class={`px-6 py-3 ${
                          currentTheme === "dark" ? "text-mainBg" : "text-filter"
                        }`}
                      >
                        ${commafy(toDecimals(item?.price))}
                      </td>
                      <td
                        class={`px-6 py-3 ${
                          currentTheme === "dark" ? "text-mainBg" : "text-filter"
                        }`}
                      >
                        <p
                          className={`${
                            currentTheme === "dark" ? "text-mainBg" : "text-filter"
                          }`}
                        >
                          ${commafy(toDecimals(item?.amount))}
                        </p>
                        <p className="text-increase">
                          {item?.type === "buy" ? (
                            <p className="text-increase">
                              +{item?.quantity} {item?.coin}
                            </p>
                          ) : (
                            <p className="text-decrease">
                              -{item?.quantity} {item?.coin}
                            </p>
                          )}
                        </p>
                      </td>
                      <td
                        class={`px-6 py-3 ${
                          currentTheme === "dark" ? "text-mainBg" : "text-filter"
                        }`}
                      >
                        {item?.fee ? item?.fee : "--"}
                      </td>
                      <td class="px-6 py-3">
                        <button
                          className={` border border-primary font-medium text-xs px-4 py-1 rounded-[5px] ${
                            currentTheme === "dark"
                              ? "bg-cardsColor text-mainBg border border-mainBg"
                              : "bg-coinNewsBgColor text-filter"
                          }`}
                          onClick={() => {
                            dispatch(closeModal());
                            dispatch(openTransactionNotePopup());
                          }}
                        >
                          Note
                        </button>
                      </td>
                      <td class="px-6 py-3">
                        <div
                          className={`flex gap-5 text-compareCoinTextColor ${
                            currentTheme === "dark"
                              ? "text-mainBg"
                              : "text-compareCoinTextColor"
                          }`}
                        >
                          <button
                            onClick={() => {
                              dispatch(closeModal());
                              dispatch(openSingleTransactionRemove());
                              setTransactionsId(item.id);
                            }}
                          >
                            <FontAwesomeIcon icon={faTrash} />
                          </button>
                          <button
                            onClick={() => {
                              dispatch(closeModal());
                              setCurrentEditTransaction(item);
                              dispatch(openSingleTransactionEdit());
                              setTransactionsId(item.id);
                              setTransactions(comparePortfolio);
                            }}
                          >
                            <FontAwesomeIcon icon={faPencil} />
                          </button>
                        </div>
                      </td>
                      {viewTransaction && <TransactionNotePopup note={item?.note} />}
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td
                    colSpan="7"
                    className={`text-center h-[40vh] text-lg semi-bold ${
                      currentTheme === "dark" ? " text-mainBg" : "text-filter"
                    }`}
                  >
                    No transactions available.
                  </td>
                </tr>
              )}
            </tbody>
          )}
        </table>
      </div>
      {singleTransactionRemove && (
        <RemoveSingleTransactionPopup transactionsId={transactionsId} />
      )}
      {portfolioEdit && (
        <SingleTransactionEditPopup transaction={currentEditTransaction} />
      )}
      {addTransaction && (
        <TransactionPopup
          coin={portfolioCoin}
          user_id={user_id}
          activePortfolioId={activePortfolioId}
          filteredCoin={filteredCoin}
        />
      )}
    </div>
  );
};

export default TransactionsDetail;
