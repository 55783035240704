import React, { useState } from "react";
import MoreInfo from "../MoreInfo/MoreInfo";
import Social from "../Social/Social";

const MENU_DATA = [
  {
    name: "Project Info",
    value: "project-info",
  },
  {
    name: "Socials",
    value: "socials",
  },
];

const MoreInfoDetails = () => {
  const [active, setActive] = useState("project-info");

  let componentToRender;
  switch (active) {
    case "Project Info":
      componentToRender = <MoreInfo />;
      break;
    case "socials":
      // componentToRender = <Social />;
      break;
    default:
      componentToRender = <MoreInfo />;
  }

  return (
    <div className="mt-10">
      <div className="flex gap-4 flex-nowrap overflow-auto scrollbar py-3 xs:py-2">
        <div className="bg-primary text-mainBg px-7 py-3 rounded-full border-[0.3 px] cursor-pointer">
          project-info
        </div>
        {/* {MENU_DATA.map((item) => (
          <div
            className={`${
              active === item.value
                ? "bg-primary text-mainBg rounded-full border-[0.3 px]"
                : "bg-none rounded-full text-black border-[1px]"
            } py-3 px-7 whitespace-nowrap cursor-pointer`}
            onClick={() => {
              setActive(item.value);
            }}
          >
            {item.name}
          </div>
        ))} */}
      </div>
      <div>{componentToRender}</div>
    </div>
  );
};

export default MoreInfoDetails;
