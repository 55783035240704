import React from "react";
import { useSelector } from "react-redux";
import WatchlistLogin from "../components/WatchlistLogin";
import WatchListAccessed from "../components/WatchListAccessed/WatchListAccessed";

const WatchListAccessedPage = () => {
  const token = useSelector(state => state.auth.token);
  return <div>{token ? <WatchListAccessed /> : <WatchlistLogin />}</div>;
};

export default WatchListAccessedPage;
