import React from 'react'
import CurrencyConverterTools from '../components/CurrencyConverterTools/CurrencyConverterTools';

const CurrencyConverterTool = () => {
  return (
    <div>
      <CurrencyConverterTools />
    </div>
  );
}

export default CurrencyConverterTool