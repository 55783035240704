import React from "react";
import { Link } from "react-router-dom";
import notFound from "../../assets/images/404.PNG";
import { selectCurrentTheme } from "../../store/slices/themeSlice";
import { useDispatch, useSelector } from "react-redux";

const NotFound = () => {
  const currentTheme = useSelector(selectCurrentTheme);
  return (
    <div class="flex h-full w-full justify-center items-center mt-8">
      <div>
        <div class="w-[350px] flex justify-center items-center text-center m-auto pb-3">
          <img src={notFound} alt="notFound" />
        </div>
        <p
          class={`text-lg text-black mb-6 ${
            currentTheme === "dark" ? " text-mainBg" : "text-filter"
          }`}
        >
          Oops! The page you're looking for doesn't exist. It might have been moved or
          deleted
        </p>
        <div class="text-center">
          <Link to="/">
            <button class="py-[10px] px-5 bg-primary text-mainBg rounded-[6px]">
              Go Back to Home
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
