import React, { useState } from "react";
import { Switch } from "antd";
import { useGetCoinGlobalQuery } from "../../store/services/services";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import DecreaseIcon from "../../assets/icons/decrease.svg";
import CryptoStatsCards from "../CryptoStatsCards";
import { commafy } from "../../utils/commafy";
import { toDecimals } from "../../utils/toDecimals";
import HomeCryptoStats from "../HomeCryptoStats/HomeCryptoStats";
import { useSelector } from "react-redux";
import { selectCurrentTheme } from "../../store/slices/themeSlice";

const CryptoStats = () => {
  const [switchEnabled, setSwitchEnabled] = useState(true);
  const { data, isLoading, isError, error } = useGetCoinGlobalQuery();
  const currentTheme = useSelector(selectCurrentTheme);
  const switchChangeHandler = checked => {
    setSwitchEnabled(checked);
  };

  return (
    <div className="px-24 md:px-8 xs:px-4 mt-10 text-secondary">
      <div>
        <div className="flex md:flex-col gap-6 md:gap-3 md:items-start md:justify-center">
          <h1
            className={`text-${
              currentTheme === "light" ? "filter" : "mainBg"
            } text-[26px] md:text-[24px] sm:text-[22px] font-semibold`}
          >
            Cryptocurrency Prices by Market Cap
          </h1>
          <div className="flex items-center gap-2">
            <Switch
              className="bg-statsShowSwitch"
              defaultChecked
              onChange={switchChangeHandler}
            />
            <p
              className={`text-${
                currentTheme === "light" ? "filter" : "mainBg"
              } text-[23px] md:text-xl font-semibold`}
            >
              Show Stats
            </p>
          </div>
        </div>
        <div className="mt-3">
          {isLoading && (
            <div className={`w-full mt-3`}>
              <SkeletonTheme
                baseColor={`${currentTheme === "dark" ? "#323546" : "#d8d8d8"}`}
                highlightColor={`${currentTheme === "dark" ? "#444" : "#e0e0e0"}`}
              >
                <Skeleton width="50%" height={25} count={1} />
              </SkeletonTheme>
            </div>
          )}
          {isError && <p>{error.message}</p>}
          {!isLoading &&
            !isError &&
            data?.data?.map(item => {
              return (
                <div className="flex text-base md:text-sm gap-2">
                  <p
                    className={`text-${
                      currentTheme === "light" ? "filter" : "mainBg"
                    } text-base md:text-sm`}
                  >
                    The global cryptocurrency market cap today is $
                    {commafy(toDecimals(item?.total_market_cap_usd))}
                  </p>
                  {/* <p>${commafy(toDecimals(item?.total_market_cap_usd))}</p> */}
                  {/* <span className="flex text-decrease">
                  -1.23% <img src={DecreaseIcon} alt="change-icon" />
                </span> */}
                </div>
              );
            })}

          {/* <span className="mx-2 gap-1 text-decrease">
              -0.7%{" "}
              <img
                className="h-[12px] inline-block"
                src={DecreaseIcon}
                alt="DecreaseIcon"
              />
            </span> */}
          {/* change in the last 24 hours. */}
          {/* <span className=" ml-2 text-blueColor underline cursor-pointer">
              {" "}
              Read More
            </span> */}
        </div>
      </div>
      {switchEnabled && (
        <div>
          <CryptoStatsCards />
          <HomeCryptoStats />
        </div>
      )}
    </div>
  );
};

export default CryptoStats;
