import UserImg from '../assets/images/user.svg'
import SatoshiImg from '../assets/images/satoshi.jpg'
import ElonImg from '../assets/images/elon.jpg'
import BillImg from '../assets/images/bill.jpg'
import MarkImg from '../assets/images/mark.png'
import JeffImg from '../assets/images/jeff.jpg'

export const More_Info_Data = [
{
id: 1,
userImg: UserImg,
name: 'Peter Edyvean',
ceo: 'Founder'
},
{
id: 2,
userImg: SatoshiImg,
name: 'Satoshi Nakamoto',
ceo: 'Founder'
},
{
id: 3,
userImg: ElonImg,
name: 'Elon Musk',
ceo: 'Founder'
},
{
id: 4,
userImg: BillImg,
name: 'Peter Edyvean',
ceo: 'Founder'
},
{
id: 5,
userImg: MarkImg,
name: 'Bill Gates',
ceo: 'Founder'
},
{
id: 6,
userImg: JeffImg,
name: 'Jeff Bezos',
ceo: 'Founder'
},
]