import { createSlice } from "@reduxjs/toolkit";

// initial state
const initialState = {
  marketCapMinNum: "",
  marketCapMaxNum: "",
  priceMinNum: "",
  priceMaxNum: "",
  changeMinNum: "",
  changeMaxNum: "",
  volumeMinNum: "",
  volumeMaxNum: "",
  circulatingMinNum: "",
  circulatingMaxNum: "",
  allFiltersSelected: false,

  // top losers
  topLosers: ""
};

const filterSlice = createSlice({
  name: "filters",
  initialState,
  reducers: {
    marketCapMinNumHanlder: (state, action) => {
      state.marketCapMinNum = action.payload;
    },
    marketCapMaxNumHanlder: (state, action) => {
      state.marketCapMaxNum = action.payload;
    },
    priceMinNumHandler: (state, action) => {
      state.priceMinNum = action.payload;
    },
    priceMaxNumHandler: (state, action) => {
      state.priceMaxNum = action.payload;
    },
    changeMinNumHandler: (state, action) => {
      state.changeMinNum = action.payload;
    },
    changeMaxNumHandler: (state, action) => {
      state.changeMaxNum = action.payload;
    },
    volumeMinNumHandler: (state, action) => {
      state.volumeMinNum = action.payload;
    },
    volumeMaxNumHandler: (state, action) => {
      state.volumeMaxNum = action.payload;
    },
    circulatingMinNumHandler: (state, action) => {
      state.circulatingMinNum = action.payload;
    },
    circulatingMaxNumHandler: (state, action) => {
      state.circulatingMaxNum = action.payload;
    },
    toggleAllFilters: state => {
      state.marketCapMinNum = "";
      state.marketCapMaxNum = "";
      state.priceMinNum = "";
      state.priceMaxNum = "";
      state.changeMinNum = "";
      state.changeMaxNum = "";
      state.volumeMinNum = "";
      state.volumeMaxNum = "";
      state.circulatingMinNum = "";
      state.circulatingMaxNum = "";
      state.allFiltersSelected = !state.allFiltersSelected;
    },

    // top losers
    topLosersHandler: (state, action) => {
      state.topLosers = action.payload;
    }
  }
});

export const {
  marketCapMinNumHanlder,
  marketCapMaxNumHanlder,
  priceMinNumHandler,
  priceMaxNumHandler,
  changeMinNumHandler,
  changeMaxNumHandler,
  volumeMinNumHandler,
  volumeMaxNumHandler,
  circulatingMaxNumHandler,
  circulatingMinNumHandler,
  toggleAllFilters,
  topLosersHandler
} = filterSlice.actions;
export default filterSlice.reducer;
