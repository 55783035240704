import React from "react";
// import OngoingProjectModal from "../OngoingProjectsModal/OngoingProjectsModal";
import { useDispatch, useSelector } from "react-redux";
import OngoingProjectsTable from "../OngoingProjectsTable";
import { Link } from "react-router-dom";
import { useGetAllIcosQuery } from "../../store/services/services";

const OngoingProjects = () => {
  const { data } = useGetAllIcosQuery();
  return (
    <div className="w-[50%] md:w-full">
      <h1 className="text-2xl md:text-lg font-semibold mb-10 md:mb-5 sm:mb-3">
        Ongoing Projects
      </h1>

      <OngoingProjectsTable limit={6} />

      <Link to="icos-project-table/ongoing">
        <div
          className="mt-10 md:mt-7 sm:mt-5 flex justify-center cursor-pointer"
          // onClick={() => {
          //   dispatch(openOngoingProject());
          // }}
        >
          <p className="bg-icosProjectsGrey py-4 px-14 text-center rounded-[10px] font-medium text-icosProjectsGreyColor text-base xs:text-xs lg:text-sm">
            View all {data?.length} ongoing ICO
          </p>
        </div>
      </Link>
      {/* {ongoingPopup && <OngoingProjectModal data={data} />} */}
    </div>
  );
};

export default OngoingProjects;
