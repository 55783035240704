import { createSlice } from "@reduxjs/toolkit";

// initial state
const initialState = {
  sidebarNav: false,
};

const sidebarSlice = createSlice({
  name: "sidebar",
  initialState,
  reducers: {
    openSidebarNavHandler: (state) => {
      state.sidebarNav = true;
    },
    closeSidebarNavHandler: (state) => {
      state.sidebarNav = false;
    },
  },
});

export const { openSidebarNavHandler, closeSidebarNavHandler } =
  sidebarSlice.actions;

export default sidebarSlice.reducer;
