import { configureStore } from "@reduxjs/toolkit";
import popupReducer from "./slices/popupSlice";
import filterReducer from "./slices/filterSlice";
import compareReducer from "./slices/compareSlice";
import profileTransaction from "./slices/profileTransactionSlice";
import sidebarReducer from "./slices/sidebarSlice";
import authReducer from "./slices/authSlice";
import watchlistReducer from "./slices/watchlistSlice";
import portfolioReducer from "./slices/portfolioChangeSlice";
import { setupListeners } from "@reduxjs/toolkit/query";
import themeReducer from "./slices/themeSlice";
import authPopupReducer from './slices/authPopupSlice'

import {
  newsApi,
  coinListApi,
  watchlist,
  authApi,
  coinGlobal,
  singleCoinApi,
  miningEquipments,
  coinGainers,
  portfolio,
  transaction,
  removeSingleTransaction,
  coinLosers,
  recentlyCoins,
  affiliateLinks,
  transactionsDetail,
  editSingleTransaction,
  chartData,
  searchNews,
  greedIndex,
  exchangesTableData
} from "./services/services";
import coinMarketSlice from "./slices/coinMarketSlice";
import newsSlice from "./slices/newsSlice";
import exchangePaginationSlice from "./slices/exchangesPaginationSlice";

const store = configureStore({
  reducer: {
    [newsApi.reducerPath]: newsApi.reducer,
    [coinListApi.reducerPath]: coinListApi.reducer,
    [watchlist.reducerPath]: watchlist.reducer,
    [authApi.reducerPath]: authApi.reducer,
    [coinGlobal.reducerPath]: coinGlobal.reducer,
    [singleCoinApi.reducerPath]: singleCoinApi.reducer,
    [miningEquipments.reducerPath]: miningEquipments.reducer,
    [coinGainers.reducerPath]: coinGainers.reducer,
    [portfolio.reducerPath]: portfolio.reducer,
    [transaction.reducerPath]: transaction.reducer,
    [removeSingleTransaction.reducerPath]: removeSingleTransaction.reducer,
    [coinLosers.reducerPath]: coinLosers.reducer,
    [recentlyCoins.reducerPath]: recentlyCoins.reducer,
    [affiliateLinks.reducerPath]: affiliateLinks.reducer,
    [transactionsDetail.reducerPath]: transactionsDetail.reducer,
    [editSingleTransaction.reducerPath]: editSingleTransaction.reducer,
    [chartData.reducerPath]: chartData.reducer,
    [searchNews.reducerPath]: searchNews.reducer,
    [greedIndex.reducerPath]: greedIndex.reducer,
    [exchangesTableData.reducerPath]: exchangesTableData.reducer,

    // coin market pagination,
    coinMarket: coinMarketSlice,
    // news pagination
    newsPagination: newsSlice,
    // exchangePaginationSlice
    exchangePagination: exchangePaginationSlice,
    watchlistCoins: watchlistReducer,
    // theme toggle
    theme: themeReducer,
    popup: popupReducer,
    authPopup: authPopupReducer,
    filter: filterReducer,
    compare: compareReducer,
    profileTransaction: profileTransaction,
    sidebar: sidebarReducer,
    auth: authReducer,
    portfolioChange: portfolioReducer
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({ serializableCheck: false }).concat(
      newsApi.middleware,
      coinListApi.middleware,
      watchlist.middleware,
      authApi.middleware,
      coinGlobal.middleware,
      singleCoinApi.middleware,
      miningEquipments.middleware,
      coinGainers.middleware,
      portfolio.middleware,
      transaction.middleware,
      removeSingleTransaction.middleware,
      transactionsDetail.middleware,
      coinLosers.middleware,
      recentlyCoins.middleware,
      affiliateLinks.middleware,
      editSingleTransaction.middleware,
      chartData.middleware,
      searchNews.middleware,
      greedIndex.middleware,
      exchangesTableData.middleware
    )
});
setupListeners(store.dispatch);

export default store;
