import React, { useEffect } from "react";
import MainNews from "../components/MainNews";
const News = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <MainNews />
    </div>
  );
};

export default News;
