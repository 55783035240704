import React, { useEffect, useState, ConfigProvider } from "react";
import { Link, useLocation } from "react-router-dom";
import { commafy } from "../../utils/commafy";
import { Sparklines, SparklinesLine } from "react-sparklines";
import { toDecimals } from "../../utils/toDecimals";
import { Table } from "antd";
import {
  addWatchlist,
  resetWatchlist,
  watchlistMountAdd,
  watchlistMountRemove
} from "../../store/slices/watchlistSlice";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import {
  useAddWatchlistMutation,
  useGetCoinMarketQuery,
  useGetWatchlistQuery
} from "../../store/services/services";
import { useDispatch, useSelector } from "react-redux";
import TablePagination from "../TablePagination";
import { selectCurrentTheme } from "../../store/slices/themeSlice";
import CryptoIcon from "../../assets/icons/crypto-icon.svg";

const CurrencyTable = ({
  data,
  total_coins,
  per_page,
  isLoading,
  currentPage,
  setCurrentPage,
  filteredCoin
}) => {
  const [whatchlistLocalState, setWhatchlistLocalState] = useState();
  const currentTheme = useSelector(selectCurrentTheme);
  const paginate = pageNumber => {
    setCurrentPage(pageNumber);
  };
  // data: data
  const location = useLocation().pathname;

  const watchlistMount = useSelector(state => state.watchlistCoins.watchlistMount);

  const user = useSelector(state => state.auth);
  const watchlistCoins = useSelector(state => state.watchlistCoins.watchlistCoins);

  const dispatch = useDispatch();

  useEffect(() => {
    if (user.userId === null) {
      dispatch(resetWatchlist());
    }
  }, [user.userId, dispatch]);

  const [addWatchlistApi] = useAddWatchlistMutation();

  const { data: getWatchlist, isLoading: getWatchlistIsLoading } = useGetWatchlistQuery(
    user.userId
  );

  const { data: getCoins, isLoading: getCoinsIsLoading } = useGetCoinMarketQuery();
  useEffect(() => {
    if (!user.token) {
      dispatch(resetWatchlist());
      dispatch(watchlistMountAdd());
    }

    if (watchlistMount && user.token && !getWatchlistIsLoading) {
      const coinList = getWatchlist?.data?.map(item => item.logo);
      dispatch(addWatchlist(coinList));
      dispatch(watchlistMountRemove());
    }
  }, [user.token, getWatchlistIsLoading, watchlistMount, dispatch, getWatchlist?.data]);

  useEffect(() => {
    const watchlistDataLocal = JSON.parse(localStorage.getItem("watchlist")) || [];
    setWhatchlistLocalState(watchlistDataLocal);
  }, []);
  // all items in localstorage
  const addWatchListLocal = item => {
    if (whatchlistLocalState.some(row => item.logo === row.logo)) {
      const updatedWatchlist = whatchlistLocalState.filter(
        coin => coin.logo !== item.logo
      );
      localStorage.setItem("watchlist", [[JSON.stringify(updatedWatchlist)]]);
      setWhatchlistLocalState(updatedWatchlist);
    } else {
      const updatedWatchlist = [...whatchlistLocalState, item];
      setWhatchlistLocalState(updatedWatchlist);
      localStorage.setItem("watchlist", [[JSON.stringify(updatedWatchlist)]]);
    }
  };
  // add watchlist
  const addWatchlistHandler = async logo => {
    dispatch(addWatchlist([logo]));
    await addWatchlistApi({ userId: user.userId, logo });
  };

  const inWatchlist = coin => {
    return watchlistCoins?.includes(coin);
  };

  let filteredCoins;

  if (!getWatchlistIsLoading && !getCoinsIsLoading) {
    filteredCoins = getCoins?.data?.filter(item =>
      watchlistCoins?.some(filterItem => filterItem === item.logo)
    );
  }

  let coinsData;

  if (location === "/") {
    // coinsData = currentItems;
  } else if (location === "/watchlist-table") {
    coinsData = filteredCoins;
  }
// change color of icons
  const starCondition = user.token
    ? "inWatchlist(row?.logo)"
    : "whatchlistLocalState?.some(item => item.logo === row.logo)";
    
  // currency table
  const columns = [
    {
      title: "",
      dataIndex: "name",
      width: "1%",
      padding: 0,
      render: (text, row) => (
        <div
          className={`cursor-pointer ${inWatchlist(row?.logo) ? "text-primary" : ""}`}
          onClick={() => {
            user.token ? addWatchlistHandler(row?.logo) : addWatchListLocal(row);
          }}
        >
          <i
            className={`${
              eval(starCondition) ? "fa-solid text-primary" : "fa-regular"
            }  fa-star `}
          ></i>
        </div>
      ),
      fixed: "left",
      className: `${
        currentTheme === "dark" ? "table-dark bg-cardsColor" : "table-light bg-mainBg"
      }`
      // style: { transition: "background-color 0.3s" }
    },
    {
      title: "#",
      dataIndex: "sequence_number",
      width: "2px",
      fixed: "left",
      render: (text, row) => <span>{text}</span>,
      className: `${
        currentTheme === "dark" ? "table-dark bg-cardsColor" : "table-light bg-mainBg"
      }`
      // style: { transition: "background-color 0.3s" }
    },
    {
      title: "Coin",
      dataIndex: "name",
      width: "30px",
      fixed: "left",
      render: (text, row) => (
        <Link
          to={`/coin-detail/${row.logo}`}
          className="flex items-center h-8 sm:h-14 gap-2 before"
          style={{ color: "inherit", textDecoration: "none" }}
        >
          <img className="w-5" src={row.img ? row?.img : CryptoIcon} alt="coin" />
          <div className="w-[150px] sm:w-[120px] flex flex-wrap xs:flex-col xs:items-start xs:gap-0 gap-2 xs:justify-center">
            <span className="font-medium">{text?.substring(0, 10)}</span>
            <span className="">{row.logo}</span>
          </div>
        </Link>
      ),
      className: `${
        currentTheme === "dark" ? "table-dark bg-cardsColor" : "table-light bg-mainBg"
      }`
    },

    {
      title: "Price",
      dataIndex: "price",
      width: "100px",
      render: (text, row) => <span>${commafy(toDecimals(text))}</span>,
      className: `${
        currentTheme === "dark" ? "table-dark bg-cardsColor" : "table-light bg-mainBg"
      }`,
      highlightRow: false,
      sorter: (a, b) =>
        parseFloat(a.price.replace(/[^0-9.-]+/g, "")) -
        parseFloat(b.price.replace(/[^0-9.-]+/g, ""))
    },

    {
      title: "1h",
      width: "100px",
      dataIndex: "percentChangeHour",
      render: (text, row) => (
        <span className={text > 0 ? "text-increase" : "text-decrease"}>
          {commafy(toDecimals(text))}%
        </span>
      ),
      className: `${
        currentTheme === "dark" ? "table-dark bg-cardsColor" : "table-light bg-mainBg"
      }`,
      sorter: (a, b) =>
        parseFloat(a.percentChangeHour.replace(/[^0-9.-]+/g, "")) -
        parseFloat(b.percentChangeHour.replace(/[^0-9.-]+/g, ""))
    },
    {
      title: "24h",
      width: "100px",
      dataIndex: "percentChangeDay",
      render: (text, row) => (
        <span className={text > 0 ? "text-increase" : "text-decrease"}>
          {commafy(toDecimals(text))}%
        </span>
      ),
      className: `${
        currentTheme === "dark" ? "table-dark bg-cardsColor" : "table-light bg-mainBg"
      }`,
      sorter: (a, b) =>
        parseFloat(a.percentChangeDay.replace(/[^0-9.-]+/g, "")) -
        parseFloat(b.percentChangeDay.replace(/[^0-9.-]+/g, ""))
    },
    {
      title: "7d",
      width: "100px",
      dataIndex: "percentChangeWeek",
      render: (text, row) => (
        <span className={text > 0 ? "text-increase" : "text-decrease"}>
          {commafy(toDecimals(text))}%
        </span>
      ),
      className: `${
        currentTheme === "dark" ? "table-dark bg-cardsColor" : "table-light bg-mainBg"
      }`,
      sorter: (a, b) =>
        parseFloat(a.percentChangeWeek.replace(/[^0-9.-]+/g, "")) -
        parseFloat(b.percentChangeWeek.replace(/[^0-9.-]+/g, ""))
    },
    {
      title: "24h Volume",
      dataIndex: "volume_24h",
      width: "150px",
      render: (text, row) => <span>${commafy(toDecimals(text))}</span>,
      className: `${
        currentTheme === "dark" ? "table-dark bg-cardsColor" : "table-light bg-mainBg"
      }`,
      sorter: (a, b) =>
        parseFloat(a.volume_24h.replace(/[^0-9.-]+/g, "")) -
        parseFloat(b.volume_24h.replace(/[^0-9.-]+/g, ""))
    },
    {
      title: "Market Cap",
      dataIndex: "market_cap",
      width: "150px",
      render: (text, row) => <span>${commafy(toDecimals(text))}</span>,
      className: `${
        currentTheme === "dark" ? "table-dark bg-cardsColor" : "table-light bg-mainBg"
      }`,
      sorter: (a, b) =>
        parseFloat(a.market_cap.replace(/[^0-9.-]+/g, "")) -
        parseFloat(b.market_cap.replace(/[^0-9.-]+/g, ""))
    },
    {
      title: "Circulating Supply",
      dataIndex: "circulating_supply",
      width: "160px",
      render: (text, row) => (
        <div className="flex gap-2">
          <span>{commafy(toDecimals(text))}</span>
          <span className="">{row.logo}</span>
        </div>
      ),
      className: `${
        currentTheme === "dark" ? "table-dark bg-cardsColor" : "table-light bg-mainBg"
      }`,
      sorter: (a, b) =>
        parseFloat(a.circulating_supply.replace(/[^0-9.-]+/g, "")) -
        parseFloat(b.circulating_supply.replace(/[^0-9.-]+/g, ""))
    },
    {
      title: "Last 7 Days",
      width: "150px",
      render: (text, row) => {
        const sparklineData = JSON.parse(row?.sparkline_in_7d);

        if (
          !sparklineData ||
          !Array.isArray(sparklineData) ||
          sparklineData.length === 0
        ) {
          return null;
        }

        const color =
          sparklineData[sparklineData?.length - 1] <
          sparklineData[sparklineData?.length - 2]
            ? "#d2445b"
            : "#4ebf7e";

        return (
          <Sparklines data={sparklineData} width={100} height={20} margin={5}>
            <SparklinesLine color={color} />
          </Sparklines>
        );
      },
      className: `${
        currentTheme === "dark" ? "table-dark bg-cardsColor" : "table-light bg-mainBg"
      }`
    }
  ];
  return (
    <>
      {isLoading ? (
        <div className="w-full">
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column"
            }}
          >
            {Array.from({ length: 30 }).map((_, index) => (
              <SkeletonTheme
                baseColor={`${currentTheme === "dark" ? "#323546" : "#d8d8d8"}`}
                highlightColor={`${currentTheme === "dark" ? "#444" : "#e0e0e0"}`}
              >
                <Skeleton key={index} width="100%" height={50} />
              </SkeletonTheme>
            ))}
          </div>
        </div>
      ) : (
        <>
          <Table
            columns={columns}
            dataSource={data || []}
            pagination={false}
            scroll={{ x: "max-content" }}
            className={`mb-8 overflow-x-auto scrollbar-hide w-full ${
              currentTheme === "dark" ? "table-dark" : "table-light"
            }`}
          />
        </>
      )}
      <div className="py-5">
        {total_coins >= 100 && (
          <TablePagination
            total_coins={total_coins}
            per_page={per_page}
            currentPage={currentPage}
            setCurrentPage={paginate}
            fixedHeaderScrollHeight="300px"
          />
        )}
      </div>
    </>
  );
};

export default CurrencyTable;
