import React, { useState } from "react";
import DownIcon from "../../assets/icons/down-icon.svg";
import PopularCryptocurrency from "../PopularCryptocurrency";
import ConvertorIcon from "../../assets/icons/convertor.svg";
import { Select } from "antd";
const { Option } = Select;

const currencyList = [
  { name: "Bitcoin", code: "BTC", rate: 10000 },
  { name: "Ethereum", code: "ETH", rate: 3200 },
  { name: "Shiba Inu", code: "SHB", rate: 7342 },
  { name: "Dogecoin", code: "DOGE", rate: 12.33 },
  { name: "USD Dollar", code: "USD", rate: 1 },
];

const CurrencyConvertor = () => {
  const [fromCurrency, setFromCurrency] = useState(currencyList[0]);
  const [toCurrency, setToCurrency] = useState(currencyList[1]);
  const [amount, setAmount] = useState(1);

  const handleFromCurrencyChange = (event) => {
    const currency = currencyList.find((c) => c.name === event);
    setFromCurrency(currency);
  };
  const handleToCurrencyChange = (event) => {
    const currency = currencyList.find((c) => c.name === event);
    setToCurrency(currency);
  };

  const handleAmountChange = (event) => {
    setAmount(event.target.value);
  };
  const handleSwitch = () => {
    setFromCurrency(toCurrency);
    setToCurrency(fromCurrency);
  };
  const handleCurrencies = (convertFrom, convertTo) => {
    const fromCurrencies = currencyList.find(
      (currency) => currency.code === convertFrom
    );
    setFromCurrency(fromCurrencies);

    const toCurrencies = currencyList.find(
      (currency) => currency.code === convertTo
    );
    setToCurrency(toCurrencies);
  };
  const fromCurrencyRate = fromCurrency.rate;
  const toCurrencyRate = toCurrency.rate;
  const result = (amount * fromCurrencyRate) / toCurrencyRate;

  return (
    <>
      <h1 className="text-2xl font-semibold lg:text-base">
        Cryptocurrency Converter Calculator
      </h1>
      <div className="shadow-navbar-button px-6 py-3 pb-5 mt-5 rounded-[15px]">
        <div className="flex items-center border mt-3 border-lightBorder h-[45px] rounded-[10px] px-3 py-1 gap-3 focus-within:border-primary transition-all duration-200">
          <input
            className="border-0 outline-0 overflow-hidden bg-transparent text-base w-full"
            type="text"
            value={amount}
            onChange={handleAmountChange}
          />
        </div>
        <div className="flex lg:block gap-3 items-center justify-between mt-5">
          <div className="flex items-center w-full relative mt-3 rounded-[10px] py-1 gap-3 focus-within:border-primary transition-all duration-200">
            <div className="h-[25.5px] border absolute right-16"></div>
            <img
              src={DownIcon}
              alt="downIcon"
              className="h-2 absolute right-5"
            />
            <Select
              size="large"
              className="w-full"
              value={fromCurrency.name}
              onChange={handleFromCurrencyChange}
            >
              {currencyList.map((currency) => (
                <Option key={currency.id} value={currency.name}>
                  {currency.name}
                </Option>
              ))}
            </Select>
          </div>
          <div className="flex justify-center">
            <div
              className="bg-primary flex justify-center items-center h-[45px] w-[5rem] rounded-[15px] mt-3 cursor-pointer"
              onClick={handleSwitch}
            >
              <img src={ConvertorIcon} alt="convertor-icon" className="h-6" />
            </div>
          </div>
          <div className="flex items-center relative mt-3  rounded-[10px] w-full py-1 gap-3 focus-within:border-primary transition-all duration-200">
            <Select
              size="large"
              className="w-full"
              value={toCurrency.name}
              onChange={handleToCurrencyChange}
            >
              {currencyList.map((currency) => (
                <Option key={currency.id} value={currency.name}>
                  {currency.name}
                </Option>
              ))}
            </Select>
          </div>
        </div>
        <div className="mt-8">
          <p className="text-coinConverterColor text-sm xs:text-xs text-center">
            {amount} {fromCurrency.name} =
            <span className="font-semibold"> {result.toFixed(2)} </span>
            {toCurrency.name}
          </p>
        </div>
        {/* <div className="text-coinConverterColor text-sm mt-6 flex items-center xs:block justify-center gap-4">
          <button
            className="border border-[0.5px lightBorder] sm:text-xs xs:w-full xs:mb-3 py-3 px-8 rounded-[16px]"
            onClick={() => setAmount("")}
          >
            Refresh
          </button>
          <button className="border border-[0.5px #BFBFBF] sm:text-xs xs:w-full py-3 px-8 rounded-[16px]">
            Save This Conversion
          </button>
        </div> */}
      </div>
      <PopularCryptocurrency handleCurrencies={handleCurrencies} />
    </>
  );
};
export default CurrencyConvertor
