import React, { useState } from "react";
import { Select, Space } from "antd";
import FilterIcon from "../../assets/icons/filters.svg";
import DarkFilterIcon from "../../assets/icons/filters-dark.svg";
import FilterMenuIcon from "../../assets/icons/filter-menu.svg";
import ScreenIcon from "../../assets/icons/screen.svg";
import CostumizePopup from "../CostumizePopup/CostumizePopup";
import { openCustomize, openFilter } from "../../store/slices/popupSlice";
import { useDispatch, useSelector } from "react-redux";
import FilterPopup from "../FilterPopup";
import { selectCurrentTheme } from "../../store/slices/themeSlice";

const { Option } = Select;
const TableFilters = ({
  filterValue,
  handleFilterChange,
  filterDataHandler,
  handleAllFiltersSelected,
  searchCoinHandler,
  searchedTerm
}) => {
  const currentTheme = useSelector(selectCurrentTheme);
  // const handleFilterChange = (value) => {
  //   setSelectedFilter(value);
  // };
  // const customizePopup = useSelector(state => state.popup.customizeIsVisible);
  const filterPopup = useSelector(state => state.popup.filterIsVisible);

  const dispatch = useDispatch();
  return (
    <div className="mb-7 flex flex-wrap items-center justify-end gap-5 xs:text-sm">
      {/* <div className="flex items-center gap-3">
        <span className="xs:text-sm">Show rows</span>
        <Space wrap>
          <Select
            defaultValue={null}
            value={filterValue}
            onChange={handleFilterChange}
          >
            <Option value={null}>All</Option>
            <Option value={10}>10</Option>
            <Option value={20}>20</Option>
            <Option value={30}>30</Option>
          </Select>
        </Space>
      </div> */}
      <div className="flex items-center gap-3 xl:pb-3 sm:flex sm:justify-center sm:items-end">
        <div
          className={`flex items-center border-2 border-inputBorder h-[45px] sm:h-[30px] rounded-[10px] px-3 py-1 gap-3 focus-within:border-primary transition-all duration-200 ${
            currentTheme === "dark"
              ? "bg-inputColor text-mainBg"
              : "bg-mainBg text-filter"
          }`}
        >
          <input
            className={`border-0 outline-0 overflow-hidden bg-transparent text-base w-[250px] sm:w-[20rem] xs:sm:w-[15rem] ${
              currentTheme === "dark"
                ? "bg-inputColor text-mainBg"
                : "bg-mainBg text-filter"
            }`}
            type="text"
            placeholder="search"
            onChange={searchCoinHandler}
            value={searchedTerm}
          />
        </div>
      </div>
      <div
        className={`flex items-center gap-2 rounded-[14px] px-3 py-2 cursor-pointer ${
          currentTheme === "dark"
            ? "bg-cardsColor text-mainBg"
            : "bg-coinTagsBg text-filter"
        }`}
        onClick={() => dispatch(openFilter())}
      >
        <img
          className="w-4 xs:w-3"
          src={currentTheme === "dark" ? DarkFilterIcon : FilterIcon}
          alt="filter-icon"
        />
        <span>Filters</span>
      </div>
      {/* <div
        className="bg-filter px-3 py-2 rounded-[14px] cursor-pointer"
        onClick={() => dispatch(openCustomize())}
      >
        <span>Customize</span>
      </div> */}
      {/* <div className="flex items-center gap-3 bg-filter rounded-[14px] px-3 py-2 cursor-pointer">
        <img className="xs:w-[14px]" src={FilterMenuIcon} alt="filter-menu" />
        <img className="xs:w-[14px]" src={ScreenIcon} alt="screen-icon" />
      </div> */}
      {/* {customizePopup && <CostumizePopup />} */}
      {filterPopup && (
        <FilterPopup
          filterDataHandler={filterDataHandler}
          handleAllFiltersSelected={handleAllFiltersSelected}
        />
      )}
    </div>
  );
};

export default TableFilters;
