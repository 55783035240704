import React, { useEffect, useState } from "react";
import DollarIcon from "../../assets/icons/dollar.svg";
import { useSelector } from "react-redux";
import { selectCurrentTheme } from "../../store/slices/themeSlice";
import CryptoIcon from "../../assets/icons/crypto-icon.svg";
import ConvertorIcon from "../../assets/icons/convertor.svg";
import DownIcon from "../../assets/icons/down-icon.svg";
import { Select } from "antd";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import {
  useGetCoinMarketQuery,
  useGetHeaderSearchCoinQuery
} from "../../store/services/services";
import { commafy } from "../../utils/commafy";
import { toDecimals } from "../../utils/toDecimals";

const { Option } = Select;
const CoinConverter = ({ coin }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const coinPrice = coin.price;
  const { data: coins, isLoading } = useGetCoinMarketQuery();
  const [allCoins, setAllCoins] = useState(coin?.data);
  const dollar = {
    name: "US Dollars",
    logo: "$",
    price: 1
  };

  const [fromCurrency, setFromCurrency] = useState(coin);
  const [toCurrency, setToCurrency] = useState(dollar);
  const [amount, setAmount] = useState(1);

  useEffect(() => {
    if (!isLoading) {
      setAllCoins([dollar, ...coins?.data]);
    }
  }, [isLoading]);

  console.log(allCoins);

  const handleSearch = value => {
    setSearchTerm(value);
  };

  // search coin
  const {
    data: searchCoin,
    isError: searchIsError,
    isLoading: searchIsLoading,
    isFetching: searchIsFetching,
  } = useGetHeaderSearchCoinQuery(searchTerm);
  console.log(searchCoin, "this is searchCoin");

  const currentTheme = useSelector(selectCurrentTheme);

  const handleToCurrencyChange = value => {
    
    setToCurrency(JSON.parse(value));
    setSearchTerm('')
  };

  const handleAmountChange = e => {
    const value = e.target.value;
    if (!isNaN(value) || value === "") {
      setAmount(value);
    }
  };

  const fromCurrencyRate = fromCurrency?.price || 1;
  const toCurrencyRate = toCurrency?.price || 1;
  const result = (amount * fromCurrencyRate) / toCurrencyRate;
  const formattedResult = isNaN(result) ? "Invalid calculation" : result.toFixed(5);
  console.log(searchTerm, "this is searchCoin");
  return (
    <div
      className={`shadow-header-shadow rounded-[26px] my-10 px-5 py-7 md:py-5 lg:px-2 md:px-1 ${
        currentTheme === "dark" ? "dark bg-cardsColor" : "bg-mainBg"
      }`}
    >
      <div>
        <p
          className={`text-[26px] mb-4 font-semibold ${
            currentTheme === "dark" ? "text-mainBg" : "text-filter"
          }`}
        >
          {coin?.name} to USD Converter
        </p>
      </div>
      <div className="flex items-center border mt-3 border-lightBorder h-[45px] rounded-[10px] px-3 py-1 gap-3 focus-within:border-primary transition-all duration-200">
        <input
          className={`border-0 outline-0 overflow-hidden bg-transparent text-base w-full ${
            currentTheme === "dark"
              ? "bg-inputColor text-mainBg"
              : "bg-mainBg text-filter"
          }`}
          type="text"
          value={amount}
          onChange={handleAmountChange}
        />
      </div>
      <div className="flex lg:block gap-3 items-center justify-between mt-5">
        <div
          className={`flex items-center w-full relative mt-3 rounded-[10px] gap-3 focus-within:border-primary transition-all duration-200 ${
            currentTheme === "dark" ? "darkSelect" : "lightSelect"
          }`}
        >
          <div
            className={`flex items-center border border-lightBorder h-[42px] rounded-[10px] px-3 gap-3 focus-within:border-primary transition-all duration-200 w-full ${
              currentTheme === "dark" ? "dropdown-text-dark" : "dropdown-text-light"
            }`}
          >
            {fromCurrency?.name}
          </div>
        </div>
        <div
          className={`flex items-center relative mt-3  rounded-[10px] w-full py-1 gap-3 focus-within:border-primary transition-all duration-200 ${
            currentTheme === "dark" ? "darkSelect" : "lightSelect"
          }`}
        >
          {(searchIsLoading || searchIsFetching) && (
            <div className="h-[300px] absolute overflow-hidden -bottom-[300px] select-dropdown-loader  shadow-selectDropdown bg-cardsColor w-full">
              <div className="w-full">
                <SkeletonTheme
                  baseColor={`${currentTheme === "dark" ? "#323546" : "#d8d8d8"}`}
                  highlightColor={`${currentTheme === "dark" ? "#444" : "#e0e0e0"}`}
                >
                  <Skeleton width="100%" height={35} count={10} />
                </SkeletonTheme>
              </div>
            </div>
          )}
          <Select
            size="large"
            className={`w-full ${
              currentTheme === "dark" ? "dropdown-text-dark" : "dropdown-text-light"
            }`}
            value={toCurrency?.name}
            onChange={handleToCurrencyChange}
            showSearch
            optionFilterProp="value"
            loading={searchIsLoading || searchIsFetching}
            onSearch={handleSearch}
            dropdownStyle={{
              backgroundColor: currentTheme === "dark" ? " #222531 " : "#fff",
              color: currentTheme === "dark" ? "#fff !important" : "#000 !important"
            }}
          >
            {/* Display search results if there is a search term */}
            {(searchIsLoading || searchIsFetching) && (
              <div className="w-full">
                <SkeletonTheme
                  baseColor={`${currentTheme === "dark" ? "#323546" : "#d8d8d8"}`}
                  highlightColor={`${currentTheme === "dark" ? "#444" : "#e0e0e0"}`}
                >
                  <Skeleton width="100%" height={35} count={3} />
                </SkeletonTheme>
              </div>
            )}
            {!searchIsLoading &&
            !searchIsError &&
            !searchIsFetching &&
            searchTerm &&
            searchCoin
              ? searchCoin?.map(currency => (
                  <Option
                    key={currency.id}
                    value={JSON.stringify(currency)}
                    className={`w-full ${
                      currentTheme === "dark"
                        ? "dropdown-text-dark"
                        : "dropdown-text-light"
                    }`}
                  >
                    {currency.name}
                  </Option>
                ))
              : allCoins?.map(currency => (
                  <Option
                    key={currency.id}
                    value={JSON.stringify(currency)}
                    className={`w-full ${
                      currentTheme === "dark"
                        ? "dropdown-text-dark"
                        : "dropdown-text-light"
                    }`}
                  >
                    {currency.name}
                  </Option>
                ))}
          </Select>
        </div>
      </div>
      <div className="mt-8">
        <p
          className={`text-sm xs:text-xs text-center ${
            currentTheme === "dark" ? "text-mainBg" : "text-coinConverterColor"
          }`}
        >
          {amount} {coin?.name} =
          <span className="font-semibold"> {commafy(toDecimals(formattedResult))} </span>
          {toCurrency?.name}
        </p>
      </div>
      {/* <div
        className={`shadow-header-shadow rounded-[26px] flex items-center px-5 py-7 md:py-5 lg:px-2 md:px-1 gap-y-5 md:flex-col ${
          currentTheme === "dark" ? "bg-cardsColor" : "bg-mainBg"
        }`}
      >
        <div className="flex items-center justify-between w-1/2 md:w-full px-4 gap-2">
          <div className="flex items-center gap-3">
            <div className="min-w-10 w-10 max-w-10">
              <img
                className="w-full"
                src={coin.img ? coin.img : CryptoIcon}
                alt="bitcoin-logo"
              />
            </div>
            <div className="text-sm">
              <p
                className={`font-medium ${
                  currentTheme === "dark" ? "text-mainBg" : "text-coinStatisticsColor"
                }`}
              >
                {coin.logo}
              </p>
            </div>
          </div>
          <div className="xs:w-28">
            <input
              className={`w-full text-right outline-none text-lg font-semibold no-arrows ${
                currentTheme === "dark"
                  ? "bg-transparent text-mainBg"
                  : "bg-mainBg text-filter"
              }`}
              type="number"
              value={enteredCoin}
              onChange={coinChangeHandler}
              onFocus={coinFocusHanlder}
              onBlur={coinBlurHanlder}
              placeholder="0"
            />
          </div>
        </div>
        <div className="flex items-center justify-between w-1/2 md:w-full px-4">
          <div className="flex items-center gap-3">
            <div className="min-w-10 w-10">
              <img className="w-full" src={DollarIcon} alt="dollar-logo" />
            </div>
            <div className="text-sm">
              <p
                className={`font-medium ${
                  currentTheme === "dark" ? "text-mainBg" : "text-coinStatisticsColor"
                }`}
              >
                USD
              </p>
              <p
                className={`font-medium ${
                  currentTheme === "dark" ? "text-mainBg" : "text-coinStatisticsColor"
                }`}
              >
                United States Dollar
              </p>
            </div>
          </div>
          <div className="xs:w-28">
            <input
              className={`w-full outline-none text-lg font-semibold text-right no-arrows ${
                currentTheme === "dark"
                  ? "bg-transparent text-mainBg"
                  : "bg-mainBg text-filter"
              }`}
              type="number"
              value={enteredDollar}
              onChange={dollarChangeHandler}
              onFocus={dollarFocusHanlder}
              onBlur={dollarBlurHanlder}
              placeholder="0"
            />
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default CoinConverter;
