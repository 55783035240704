import React from "react";
import { selectCurrentTheme } from "../../store/slices/themeSlice";
import { useSelector } from "react-redux";
import RealTimeIcon from "../../assets/icons/real-time.svg";
import FreeIcon from "../../assets/icons/free.svg";
import TrackIcon from "../../assets/icons/track.svg";
import SafeIcon from "../../assets/icons/safe.svg";

const WATCHLIST_CARDS_DATA = [
  {
    id: 1,
    name: "Real-time price data",
    desc: "Updating 24/7 using price data from the biggest exchanges.",
    icon: RealTimeIcon
  },
  {
    id: 2,
    name: "Free to use",
    desc: "Top notch crypto portfolio tracking at no cost.",
    icon: FreeIcon
  },
  {
    id: 3,
    name: "Track your current portfolio balance and profit / loss",
    desc: "Thousands of coins and tokens available.",
    icon: TrackIcon
  },
  {
    id: 4,
    name: "Your data is safe and secure",
    desc: "We take data security and privacy very seriously.",
    icon: SafeIcon
  }
];

const WatchlistCards = () => {
  const currentTheme = useSelector(selectCurrentTheme);
  return (
    <div className="px-12 md:px-8 xs:px-4 mt-12 mb-16">
      <div className="flex flex-wrap justify-center gap-3">
        {WATCHLIST_CARDS_DATA.map(item => (
          <div
            key={item.id}
            className={`w-[23%] min-w-[220px] shadow-navbar-button  rounded-[13px] py-7 px-5 text-center ${
              currentTheme === "dark" ? "bg-cardsColor" : "bg-mainBg"
            }`}
          >
            <img
              className="mx-auto mb-3 w-16 md:w-14 xs:w-12"
              src={item.icon}
              alt={item.name}
            />
            <p
              className={`font-semibold text-lg md:text-base mb-2 ${
                currentTheme === "dark" ? " text-mainBg" : "text-filter"
              }`}
            >
              {item.name}
            </p>
            <p
              className={` md:text-sm ${
                currentTheme === "dark" ? " text-mainBg" : "text-lightGray"
              }`}
            >
              {item.desc}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default WatchlistCards;
