import React from "react";
import { Link } from "react-router-dom";
import { commafy } from "../../utils/commafy";
import { toDecimals } from "../../utils/toDecimals";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { useGetExchangesDataQuery } from "../../store/services/services";
import { Table } from "antd";
import { selectCurrentTheme } from "../../store/slices/themeSlice";
import { useSelector } from "react-redux";
import CryptoIcon from "../../assets/icons/crypto-icon.svg";

const ExchangeTable = () => {
  const currentTheme = useSelector(selectCurrentTheme);
  const { data: exchangesTableData, isLoading } = useGetExchangesDataQuery();

  
  const columns = [
    {
      title: "#",
      dataIndex: "sequence_number",
      width: "1px",
      fixed: "left",
      render: (text, row) => <span>{row.sequence}</span>,
      className: `${currentTheme === "dark" ? "table-dark" : "table-light"}`
    },
    {
      title: "Exchange",
      dataIndex: "name",
      width: "130px",
      fixed: "left",
      render: (text, row) => (
        <>
          <Link
            to={`/exchange-detail/${row.id}`}
            style={{ color: "inherit", textDecoration: "none" }}
          >
            <div className="flex items-center h-8 sm:h-12 gap-2 before">
              <img className="w-5" src={row.image ? row.image : CryptoIcon} alt="coin" />
              <div className="flex flex-col xs:flex-col xs:items-start xs:gap-0 gap-2 xs:justify-center">
                <span className="font-medium">{text?.substring(0, 10)}</span>
                <span className="md:hidden">{row.logo}</span>
              </div>
            </div>
          </Link>
        </>
      ),
      className: `${currentTheme === "dark" ? "table-dark" : "table-light"}`
    },

    {
      title: "Trust Score",
      dataIndex: "trust_score",
      width: "40px",
      render: (text, row) => (
        <span
          className={`px-4 py-1 rounded-lg ${
            row.trust_score > 0 ? "bg-increase text-mainBg" : "bg-decrease text-mainBg"
          }`}
        >
          {row.trust_score}
        </span>
      ),
      className: `${currentTheme === "dark" ? "table-dark" : "table-light"}`
    },
    {
      title: "Trust Score Rank",
      width: "100px",
      dataIndex: "trust score rank",
      render: (text, row) => <span>{row?.trust_score_rank}</span>,
      className: `${currentTheme === "dark" ? "table-dark" : "table-light"}`
    },

    {
      title: "Country",
      dataIndex: "country",
      width: "80px",
      render: (text, row) => <span>{row?.country ? row?.country : "--"}</span>,
      className: `${currentTheme === "dark" ? "table-dark" : "table-light"}`
    },
    {
      title: "Year Established",
      dataIndex: "year established",
      width: "100px",
      render: (text, row) => (
        <span>{row?.year_established ? row?.year_established : "--"}</span>
      ),
      className: `${currentTheme === "dark" ? "table-dark" : "table-light"}`
    },
    {
      title: "Trading volume(24h) BTC",
      dataIndex: "trade_volume_24h_btc",
      width: "140px",
      render: (text, row) => <span>₿{commafy(toDecimals(text))}</span>,
      sorter: (a, b) => {
        const valueA = parseFloat(
          String(a.trade_volume_24h_btc).replace(/[^0-9.-]+/g, "")
        );
        const valueB = parseFloat(
          String(b.trade_volume_24h_btc).replace(/[^0-9.-]+/g, "")
        );
        if (isNaN(valueA) && isNaN(valueB)) {
          return 0;
        } else if (isNaN(valueA)) {
          return -1;
        } else if (isNaN(valueB)) {
          return 1;
        }

        return valueA - valueB;
      },
      className: `${currentTheme === "dark" ? "table-dark" : "table-light"}`,
      sortDirections: ["ascend", "descend"]
    },
    {
      title: "24h Volume",
      dataIndex: "24h Volume",
      width: "100px",
      render: (text, row) => (
        <span>₿{commafy(toDecimals(row.trade_volume_24h_btc_normalized))}</span>
      ),
      className: `${currentTheme === "dark" ? "table-dark" : "table-light"}`,
      sorter: (a, b) =>
        parseFloat(String(a.trade_volume_24h_btc_normalized).replace(/[^0-9.-]+/g, "")) -
        parseFloat(String(b.trade_volume_24h_btc_normalized).replace(/[^0-9.-]+/g, "")),
      sortDirections: ["ascend", "descend"]
    }
  ];
  return (
    <div className="px-24 md:px-8 xs:px-4 mt-12 mb-8 ">
      {isLoading ? (
        <div className="w-full">
          <SkeletonTheme
            baseColor={`${currentTheme === "dark" ? "#323546" : "#d8d8d8"}`}
            highlightColor={`${currentTheme === "dark" ? "#444" : "#e0e0e0"}`}
          >
            <Skeleton width="100%" height={50} count={30} className="mt-1" />
          </SkeletonTheme>
        </div>
      ) : (
        <Table
          columns={columns}
          dataSource={exchangesTableData?.data}
          pagination={false}
          scroll={{ x: "max-content" }}
          className="mb-8 overflow-x-auto scrollbar-hide w-full"
        />
      )}
    </div>
  );
};

export default ExchangeTable;
