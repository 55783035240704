import React from "react";
import OngoingProjects from "../OngoingProjects";
import UpcomingProjects from "../UpcomingProjects/UpcomingProjects";
import EndedProjects from "../EndedProjects";

const MainIcos = () => {
  return (
    <div className="px-12 md:px-8 xs:px-4 mt-14 md:mt-5 sm:mt-3">
      <div className="mt-14 md:mt-5 sm:mt-3 flex gap-8 md:block">
        <OngoingProjects />
        <UpcomingProjects />
      </div>
      <EndedProjects />
    </div>
  );
};

export default MainIcos;
