import React from "react";
import { useDispatch } from "react-redux";
import { selectCurrentTheme } from "../../store/slices/themeSlice";
import { useSelector } from "react-redux";
import WatchlistLoginImage from "../../assets/images/watchlist-login.svg";
import { openLogin, openRegister } from "../../store/slices/popupSlice";
import PortFolioLightImage from "../../assets/images/portfolio-light.JPG";
import PortFolioDarkImage from "../../assets/images/portfolio-dark.JPG";

const PortfolioLogin = () => {
  const dispatch = useDispatch();
  const currentTheme = useSelector(selectCurrentTheme);
  return (
    <div>
      <div className="px-12 md:px-8 xs:px-4 mt-12">
        <div className="text-center w-1/2 md:w-2/3 xs:w-full mx-auto">
          <p
            className={`text-lg md:text-base xs:text-sm mb-3 ${
              currentTheme === "dark" ? " text-mainBg" : "text-lightBlue"
            }`}
          >
            Sign up Today
          </p>
          <p
            className={`text-[26px] md:text-2xl xs:text-[22px] font-bold mb-2 ${
              currentTheme === "dark" ? " text-mainBg" : "text-filter"
            }`}
          >
            Crypto Portfolio Tracker
          </p>
          <p
            className={`text-lg md:text-base xs:text-[15px]  ${
              currentTheme === "dark" ? " text-mainBg" : "text-lightGray"
            }`}
          >
            Keep track of your profits, losses and portfolio valuation with our easy to
            use platform.
          </p>
        </div>
        <div className="flex xs:flex-col  gap-x-5 gap-y-3 justify-center mt-4 md:mt-7 md:text-sm xs:text-xs">
          <button
            className={` shadow-navbar-button py-2 px-10 rounded-[7px] ${
              currentTheme === "dark"
                ? "bg-cardsColor text-mainBg"
                : "bg-customizePopupBg text-cardsColor"
            }`}
            onClick={() => dispatch(openRegister())}
          >
            Create your Portfolio
          </button>
          <button
            className="bg-primary py-2 px-10 rounded-[7px]"
            onClick={() => {
              dispatch(openLogin());
            }}
          >
            Login
          </button>
        </div>
        {/* <div className="mt-8">
          <img
            className="max-h-[450px] mx-auto"
            src={currentTheme === "dark" ? PortFolioDarkImage : PortFolioLightImage}
            alt="watch-demo"
          />
        </div> */}
      </div>
    </div>
  );
};

export default PortfolioLogin;
