import React from "react";
import { Link } from "react-router-dom";
import Avalanche from "../../assets/images/avalancheBanner.jpg";
import { selectCurrentTheme } from "../../store/slices/themeSlice";
import { useSelector } from "react-redux";

const NewsCard = ({ news, currentPage }) => {
  const currentTheme = useSelector(selectCurrentTheme);
  const description = news?.description;
  const truncatedDescription =
    description && description.length > 100
      ? `${description.substring(0, 100)}...`
      : description;
  return (
    <Link to={`/singleNewsProduct/${currentPage}/${news.id}`}>
      <div
        className={`shadow-navbar-button rounded-[40px] px-5 py-2 pb-7 ${
          currentTheme === "dark" ? "bg-cardsColor " : "bg-mainBg"
        }`}
      >
        <div className="mt-6">
          <img
            src={news?.url_to_image ? news.url_to_image : Avalanche}
            alt="pic"
            className="h-[221px] w-full rounded-[40px] object-cover"
          />
          <h2 className="text-lg font-semibold lg:text-lg text-blueColor pt-4">
            {news?.author}
          </h2>
          <h2
            className={`text-lg lg:text-sm font-semibold pt-3 pb-2 ${
              currentTheme === "dark" ? " text-mainBg" : "text-filter"
            }`}
          >
            {news?.title.substring(0, 60)}
          </h2>
          <p
            className={`text-base lg:text-sm  font-normal ${
              currentTheme === "dark" ? "text-coinNewsGreyColor" : "text-dimBlack"
            }`}
          >
            {truncatedDescription}
          </p>
          <div className="flex items-center gap-3">
            <p
              className={`text-base lg:text-sm font-normal ${
                currentTheme === "dark" ? "text-coinNewsGreyColor" : "text-dimBlack"
              }`}
            >
              {new Date(news?.published_at).toDateString()}
            </p>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default NewsCard;
