import { createSlice } from "@reduxjs/toolkit";

// initial state
const initialState = {
  selectedCoins: [],
};

const compareSlice = createSlice({
  name: "compare",
  initialState,
  reducers: {
    addCoin: (state, action) => {
      if (
        state.selectedCoins.some(
          item => item.logo === action.payload.logo || state.selectedCoins.length === 6
        )
      ) {
        return;
      } else {
        state.selectedCoins = [...state.selectedCoins, action.payload];
      }
    },
    removeCoin: (state, action) => {
      state.selectedCoins = state.selectedCoins.filter(
        (item) => item.logo !== action.payload
      );
    },
    resetCoins: (state) => {
      state.selectedCoins = [];
    },
  },
});

export const { addCoin, removeCoin, resetCoins } = compareSlice.actions;

export default compareSlice.reducer;
