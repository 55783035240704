import React from "react";
import { Popular_Crypto_Currency } from "../../data/PopularCrypto";
const PopularCryptocurrency = ({ handleCurrencies }) => {
  return (
    <div className="mt-14 sm:mt-7">
      <h3 className="font-semibold text-2xl sm:text-[22px]">
        Popular Cryptocurrency Conversions
      </h3>
      <div className="flex flex-wrap items-center shadow-navbar-button mt-5 rounded-[15px]">
        {Popular_Crypto_Currency.map((items) => {
          return (
            <div
              key={items.id}
              className="w-1/6 xl:w-1/4 md:w-1/3 sm:w-1/2 text-lg lg:text-sm text-center text-blueColor cursor-pointer px-10 xs:px-5 py-5 sm:py-4 border-b-[0.5px] border-r-[0.5px]"
              onClick={() => handleCurrencies(items.convertFrom, items.convertTo)}
            >
              <div>{items.currencyName}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default PopularCryptocurrency;
