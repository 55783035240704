import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { selectCurrentTheme } from "../../store/slices/themeSlice";
import { useSelector } from "react-redux";

const PortfolioNotesPopup = ({ onBack, enterNote, note }) => {
  const [enteredNote, setEnteredNote] = useState(note || "");
  const currentTheme = useSelector(selectCurrentTheme);

  const noteHandler = e => {
    if (!enterNote) {
      return;
    }
    setEnteredNote(e.target.value);
  };

  const addNoteHandler = () => {
    enterNote(enteredNote);
    onBack();
  };

  return (
    <div className="p-7 xs:px-4">
      <div className="flex items-center gap-3 mb-4 ">
        <FontAwesomeIcon
          className={`cursor-pointer ${
            currentTheme === "dark" ? "text-mainBg" : "text-filter"
          }`}
          icon={faChevronLeft}
          onClick={onBack}
        />
        <p
          className={`text-[22px] xs:text-xl font-semibold  ${
            currentTheme === "dark" ? "text-mainBg" : "text-secondary"
          }`}
        >
          Add Note
        </p>
      </div>
      <div className="mb-5">
        <textarea
          className={`w-full p-2 rounded-[6px] border border-darkBorder outline-none mt-1 mb-2 focus:border-primary resize-none ${
            currentTheme === "dark"
              ? "bg-inputColor text-mainBg"
              : "bg-mainBg text-filter"
          }`}
          placeholder="Write your note here...."
          rows={6}
          maxLength={200}
          onChange={noteHandler}
          value={enteredNote}
        />
        <p
          className={` xs:text-sm text-right ${
            currentTheme === "dark" ? "text-mainBg" : "text-portfolioGrayText"
          }`}
        >
          {enteredNote.length}/200
        </p>
      </div>
      <div>
        <button
          className="w-full text-center bg-primary py-2 rounded-[6px] text-popupText font-semibold"
          onClick={addNoteHandler}
        >
          Add Note
        </button>
      </div>
    </div>
  );
};

export default PortfolioNotesPopup;
