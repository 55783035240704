import React, { useState } from "react";
import Modal from "../../utils/Modal";
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
} from "react-share";
import { selectCurrentTheme } from "../../store/slices/themeSlice";
import { useSelector } from "react-redux";

const SocialMediaSharingPopup = () => {
  const [isUrlCopied, setIsUrlCopied] = useState(false);
  const url = window.location.href;
  const currentTheme = useSelector(selectCurrentTheme);
  // Function to copy the URL to the clipboard
  const copyUrlToClipboard = () => {
    const textField = document.createElement("textarea");
    textField.innerText = url;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();
    setIsUrlCopied(true);
  };

  return (
    <div>
      <Modal maxWidth={600}>
        <div className="p-7 xs:px-4">
          <h2
            className={`text-2xl font-semibold mb-4 ${
              currentTheme === "dark" ? "text-mainBg" : "text-filter"
            }`}
          >
            Share on Social Media
          </h2>
          <div
            className={`flex justify-center space-x-3 text-2xl ${
              currentTheme === "dark" ? " text-mainBg " : " text-filterSwitchColor"
            }`}
          >
            {/* Facebook Share Button */}
            <FacebookShareButton url={url}>
              <div className="p-2 rounded-full">
                <i class="fa-brands fa-facebook hover:text-primary"></i>
              </div>
            </FacebookShareButton>

            {/* Twitter Share Button */}
            <TwitterShareButton url={url}>
              <div className=" p-2 rounded-full">
                <i class="fa-brands fa-twitter hover:text-primary"></i>
              </div>
            </TwitterShareButton>

            {/* LinkedIn Share Button */}
            <LinkedinShareButton url={url}>
              <div className=" p-2 rounded-full">
                <i class="fa-brands fa-linkedin hover:text-primary"></i>
              </div>
            </LinkedinShareButton>

            {/* {whatsapp button} */}
            <WhatsappShareButton url={url}>
              <div className=" p-2 rounded-full">
                <i class="fa-brands fa-whatsapp hover:text-primary"></i>
              </div>
            </WhatsappShareButton>
          </div>

          <div className="mt-6">
            <button
              className={`w-full border text-center py-3 rounded-lg  font-semibold ${
                currentTheme === "dark" ? "text-mainBg bg-cardsColor" : "text-popupText"
              }`}
              onClick={copyUrlToClipboard}
            >
              {isUrlCopied ? "URL Copied" : "Copy Link"}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default SocialMediaSharingPopup;
