import React from "react";

import ReactDOM from "react-dom";
import { closeSidebarNavHandler } from "../../store/slices/sidebarSlice";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentTheme } from "../../store/slices/themeSlice";
import crossIcon from "../../assets/icons/cross.svg";

const Backdrop = () => {
  const dispatch = useDispatch();
  return (
    <div
      className={`h-screen w-screen fixed bg-modalBackdrop duration-200`}
      onClick={() => {
        dispatch(closeSidebarNavHandler());
      }}
    ></div>
  );
};

const Overlay = ({ children }) => {
  const dispatch = useDispatch();
  const currentTheme = useSelector(selectCurrentTheme);
  return (
    <div
      className={`fixed xs:w-full w-[300px] overflow-auto scrollbar h-screen top-0 right-0 py-2 ${
        currentTheme === "dark" ? "bg-cardsColor" : "bg-mainBg"
      }`}
    >
      <div
        className={`px-8 py-2 ${currentTheme === "dark" ? "bg-cardsColor" : "bg-mainBg"}`}
      >
        <button
          className=""
          onClick={() => {
            dispatch(closeSidebarNavHandler());
          }}
        >
          <img className="h-4" src={crossIcon} alt="cross-icon" />
        </button>
      </div>
      <div>{children}</div>
    </div>
  );
};

const Sidebar = ({ children }) => {
  return (
    <div className="hidden lg:block">
      {ReactDOM.createPortal(<Backdrop />, document.getElementById("backdrop"))}
      {ReactDOM.createPortal(
        <Overlay children={children} />,
        document.getElementById("overlay")
      )}
    </div>
  );
};

export default Sidebar;
