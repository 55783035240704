import React from "react";
import { useSelector } from "react-redux";
import { selectCurrentTheme } from "../../store/slices/themeSlice";

const Disclaimer = () => {
  const currentTheme = useSelector(selectCurrentTheme);
  return (
    <div className="md:px-8 xs:px-4 py-7 mt-14 md:mt-10">
      <div
        className={`rounded-[28px] shadow-navbar-button py-8 px-12 md:px-8 xs:px-4 ${
          currentTheme === "dark" ? "bg-cardsColor" : "bg-mainBg"
        }`}
      >
        <p
          className={`text-sm xs:text-xs ${
            currentTheme === "dark" ? "text-mainBg" : "text-secondary"
          }`}
        >
          <span className="text-decrease">IMPORTANT DISCLAIMER:</span> All content
          provided herein our website, hyperlinked sites, associated applications, forums,
          blogs, social media accounts and other platforms (“Site”) is for your general
          information only, procured from third party sources. We make no warranties of
          any kind in relation to our content, including but not limited to accuracy and
          updatedness. No part of the content that we provide constitutes financial
          advice, legal advice or any other form of advice meant for your specific
          reliance for any purpose. Any use or reliance on our content is solely at your
          own risk and discretion. You should conduct your own research, review, analyse
          and verify our content before relying on them. Trading is a highly risky
          activity that can lead to major losses, please therefore consult your financial
          advisor before making any decision. No content on our Site is meant to be a
          solicitation or offer.
        </p>
      </div>
    </div>
  );
};

export default Disclaimer;
