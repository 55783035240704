import React, { useEffect } from "react";
import Modal from "../../utils/Modal";
import { selectCurrentTheme } from "../../store/slices/themeSlice";
import { useSelector,useDispatch } from "react-redux";
import { closeRemoveWatchList } from "../../store/slices/popupSlice";
import { useRemoveAllWatchlistMutation } from "../../store/services/services";
import { resetWatchlist } from "../../store/slices/watchlistSlice";

const WatchListClearPopup = ({
  handleAllFiltersSelected,

}) => {
  const currentTheme = useSelector(selectCurrentTheme);
   const userId = useSelector(state => state.auth.userId);
    const [removeAllWatchlist, {
      isError: watchListIsError,
      isLoading: watchListIsLoading,
      isSuccess: watchlistIsSuccess
    }] = useRemoveAllWatchlistMutation();
  const dispatch = useDispatch();

  const handleRemoveAllWatchList = () => {
    removeAllWatchlist({ userId: userId });
  }
  useEffect(() => {
    if (watchlistIsSuccess) {
      dispatch(closeRemoveWatchList());
      dispatch(resetWatchlist())
    }
  }, [watchlistIsSuccess]);
  return (
    <Modal maxWidth="450">
      <div className="px-8 py-6 relative">
        <h2
          className={`text-2xl font-semibold mb-4  ${
            currentTheme === "dark" ? " text-mainBg" : "text-filter"
          }`}
        >
          Are you sure to remove all watchlist?
        </h2>
        <p
          className={`mb-6  ${currentTheme === "dark" ? " text-mainBg" : "text-filter"}`}
        >
          This action will permanently remove all items from your watchlist. Do you want
          to proceed?
        </p>
        <div
          className={`flex justify-end  ${
            currentTheme === "dark" ? " text-mainBg" : "text-filter"
          }`}
        >
          <button
            className={`px-4 py-2 mr-2 rounded ${
              currentTheme === "dark"
                ? "bg-inputColor text-mainBg"
                : "bg-gray-200 text-filter"
            }`}
            onClick={() => dispatch(closeRemoveWatchList())}
          >
            Cancel
          </button>
          <button
            className="px-4 py-2 mr-2 rounded bg-decrease text-mainBg"
            onClick={handleRemoveAllWatchList}
          >
            {watchListIsLoading ? "Confirm..." : "Confirm"}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default WatchListClearPopup;
