import React, { useState } from "react";
import { Dropdown, Space } from "antd";
import ChevronGreyIcon from "../../assets/icons/chevron-down-grey.svg";

const MENU_DATA = [
  {
    name: "ALL",
    value: "all",
  },
  {
    name: "CEX",
    value: "cex",
  },
  {
    name: "DEX",
    value: "dex",
  },
];
const Pair_DATA = [
  {
    name: "Spot",
    value: "spot",
  },
  {
    name: "Prepetual",
    value: "prepetual",
  },
  {
    name: "Futures",
    value: "futures",
  },
];
const items = [
  {
    label: <a href="https://www.antgroup.com">1st menu item</a>,
    key: "0",
  },
  {
    label: <a href="https://www.aliyun.com">2nd menu item</a>,
    key: "1",
  },
  {
    type: "divider",
  },
  {
    label: "3rd menu item",
    key: "3",
  },
];

const AussieDigitalMarkets = () => {
  const [active, setActive] = useState("all");
  const [pairActive, setPairActive] = useState("spot");
  return (
    <>
      <div className="flex sm:block items-center mt-12 gap-4">
        <h1 className="text-2xl sm:mb-5 font-semibold sm:text-sm">
          Aussie Digital Markets
        </h1>
        {/* <div className="flex">
          <div className="flex justify-between shadow-header-shadow rounded-[13px] items-center overflow-hidden px-6 xs:px-3 gap-1">
            <div className="flex gap-4 flex-nowrap overflow-auto scrollbar py-3 xs:py-2">
              {MENU_DATA.map((item) => (
                <div
                  className={`${
                    active === item.value
                      ? "bg-primary text-mainBg rounded-full border-[0.3 px]"
                      : "bg-none rounded-full text-black border-[1px]"
                  } py-3 px-7 whitespace-nowrap cursor-pointer`}
                  onClick={() => {
                    setActive(item.value);
                  }}
                >
                  {item.name}
                </div>
              ))}
            </div>
          </div>
        </div> */}
      </div>
      <div className="flex sm:block justify-end mt-12 items-center">
        {/* <div className="flex justify-between items-center overflow-hidden xs:px-3 gap-1">
          <div className="flex gap-4 flex-nowrap overflow-auto scrollbar py-3 xs:py-2">
            {Pair_DATA.map((item) => (
              <div
                className={`${
                  pairActive === item.value
                    ? "bg-primary text-mainBg rounded-full border-[0.3 px]"
                    : "bg-none rounded-full text-black border-[1px]"
                } py-3 px-7 whitespace-nowrap cursor-pointer`}
                onClick={() => {
                  setPairActive(item.value);
                }}
              >
                {item.name}
              </div>
            ))}
          </div>
        </div> */}
        <Dropdown
          className="cursor-pointer"
          menu={{
            items
          }}
          trigger={["click"]}
        >
          <div className="flex">
            <p
              className="flex gap-1 py-2 sm:mt-5 px-7 bg-mainBg border-[1px] border-lightBorder rounded-[40px] items-center shadow-navbar-button"
              onClick={e => e.preventDefault()}
            >
              <Space>
                Pair <span className="font-semibold">All</span>
              </Space>
              <img className="h-[6px]" src={ChevronGreyIcon} alt="down-icon" />
            </p>
          </div>
        </Dropdown>
      </div>
      <div className="shadow-header-shadow w-full rounded-[26px] mt-4 overflow-hidden md:mt-5 sm:mt-3">
        <div className="flex flex-nowrap overflow-auto scrollbar rounded-[26px]">
          <table class="w-full">
            <thead className="bg-mainBg">
              <tr className="border-b-darkBorder border-b">
                <th scope="col" className="px-6 py-5 text-base">
                  #
                </th>
                <th scope="col" className="px-6 py-5 text-base">
                  Currency
                </th>
                <th scope="col" className="px-6 py-5 text-base">
                  Pair
                </th>
                <th scope="col" className="px-6 py-5 text-base">
                  Price
                </th>
                <th scope="col" className="px-6 py-5 text-base">
                  +2% Depth
                </th>
                <th scope="col" className="px-6 py-5 text-base">
                  -2% Depth
                </th>
                <th scope="col" className="px-6 py-5 text-base">
                  Volume
                </th>
                <th scope="col" className="px-6 py-5 text-base">
                  Volume %
                </th>
                <th scope="col" className="px-6 py-5 text-base">
                  Liquidity
                </th>
                <th scope="col" className="px-6 py-5 text-base">
                  Updated
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td colSpan={10}>
                  <h1 className="text-center font-bold text-2xl flex justify-center items-center min-h-[207px]">
                    No data is available now
                  </h1>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default AussieDigitalMarkets;
