import React from 'react'
import UserProfile from '../components/UserProfile/UserProfile';

const UserProfilePage = () => {
  return (
    <div>
      <UserProfile />
    </div>
  );
}

export default UserProfilePage