import React, { useEffect } from "react";
import CryptoStats from "../components/CryptoStats";
import Disclaimer from "../components/Disclaimer";
import FAQ from "../components/FAQ";
// import Placeholder from "../components/Placeholder";
import TrendingCoins from "../components/TrendingCoins";
import HomeTable from "../components/HomeTable";

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <CryptoStats />
      <HomeTable />
      <TrendingCoins />
      <FAQ />
      {/* <Placeholder /> */}
      <Disclaimer />
    </div>
  );
};

export default Home;
