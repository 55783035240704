import BnbIcon from '../assets/icons/bnb-icon.svg'
import SeedLogo from '../assets/icons/seed-logo.svg'
import SeedonLogo from '../assets/icons/seedon-logo.svg'
import OtherArrow from '../assets/icons/other-arrow.svg'
import Ethereum from '../assets/icons/ethereum.svg'
import ShibaLogo from "../assets/icons/shiba.svg";
import Polygon from "../assets/images/polygon.png";
import Avalanche from "../assets/images/avalanche.png";
import Graph from "../assets/images/graph.png";
import Tether from "../assets/images/tether.png";
import XRP from "../assets/images/xrp.png";
import Solana from "../assets/images/solana.png";

// Ongoing table Data
export const Ongoing_Table_Data = [
{
id: 1,
bnbImg: BnbIcon,
logo: SeedLogo,
project: 'SeedOn',
seon:'SEON',
chain: 'Binance Smart Chain (BEP20)',
stage: 'ICO',
days: '2 days left',
date: 'jan 15, 2022',
goal: 1200000
},
{
id: 2,
bnbImg: Ethereum,
logo: SeedLogo,
project: 'SeedOn',
seon:'SEON',
chain: 'Ethereum Smart Chain (BEP20)',
stage: 'ICO',
days: '4 days left',
date: 'nov 10, 2022',
goal: 1244523
},
{
id: 3,
bnbImg: ShibaLogo,
logo: SeedLogo,
project: 'SeedOn',
seon:'SEON',
chain: 'Shiba Smart Chain (BEP20)',
stage: 'ICO',
days: '9 days left',
date: 'feb 17, 2022',
goal: 1345321
},
{
id: 4,
bnbImg: Polygon,
logo: SeedLogo,
project: 'SeedOn',
seon:'SEON',
chain: 'Polygon Smart Chain (BEP20)',
stage: 'ICO',
days: '6 days left',
date: 'jun 15, 2022',
goal: 2100453
},
{
id: 5,
bnbImg: Avalanche,
logo: SeedLogo,
project: 'SeedOn',
seon:'SEON',
chain: 'Avalanche Smart Chain (BEP20)',
stage: 'ICO',
days: '3 days left',
date: 'aug 22, 2022',
goal: 867549
},
{
id: 6,
bnbImg: Graph,
logo: SeedLogo,
project: 'SeedOn',
seon:'SEON',
chain: 'Graph Smart Chain (BEP20)',
stage: 'ICO',
days: '4 days left',
date: 'sep 8, 2022',
goal: 475632
},
]
// Ended projects
export const ended_table_data = [
{
id: 1,
seedonLogo: SeedonLogo,
bnbImg: BnbIcon,
otherArrow:OtherArrow,
desc:'The U.S. Consumer Price Index increased by 7% in December, marking the fastest year-on-year gains since 1982. Bitcoin is a decentralized digital currency that enables peer-to-peer transactions without the need for a central authority or intermediary. It was created in 2009 by an unknown person or group using the pseudonym Satoshi Nakamoto and has since grown to become the most popular and valuable cryptocurrency in the world. Bitcoin transactions are recorded on a public ledger called the blockchain, which is maintained by a decentralized network of. Bitcoin is a decentralized digital currency that was created in 2009 by an anonymous person or group using the pseudonym Satoshi Nakamoto. Here is a brief timeline of Bitcoins history  The Bitcoin white paper, titled Bitcoin: A Peer-to-Peer Electronic Cash System, is published by Satoshi Nakamoto.',
coin: "Bitcoin",
coinCode: "BTC",
project: 'SeedOn',
seon:'SEON',
chain: 'Binance Smart Chain (BEP20)',
price: 0.080000,
stage: 'IDO',
prev_date: 'ended 1 months ago',
start_date:'jan 12, 2022',
end_date:'fab 13, 2022',
goal: 400000,
launchpad:'other'
},{
id: 2,
seedonLogo: SeedonLogo,
bnbImg: Ethereum,
otherArrow:OtherArrow,
desc:'Ethereums native cryptocurrency is Ether (ETH), which is used to pay transaction fees and incentivize miners who verify and process transactions on the network. Ethereums smart contracts are programmed using a specialized coding language called Solidity, which allows developers to create custom applications with unique business logic. One of the key features of Ethereum is its ability to support the creation and execution of decentralized autonomous organizations (DAOs). These are organizations that are run by computer code rather. Ethereum is a decentralized blockchain platform that was launched in July 2015. Here is a brief timeline of Ethereums history. December 2013: Ethereums co-founder, Vitalik Buterin, publishes the Ethereum white paper outlining the vision for a decentralized blockchain platform that can support smart contracts.',
coin: "Ethereum",
coinCode: "ETH",
project: 'SeedOn',
seon:'SEON',
chain: 'Ethereum Smart Chain (BEP20)',
price: 1.082000,
stage: 'IDO',
prev_date: 'ended 3 months ago',
start_date:'fab 5, 2022',
end_date:'march 7, 2022',
goal: 640000,
launchpad:'other'
},
{
id: 3,
seedonLogo: SeedonLogo,
bnbImg: ShibaLogo,
otherArrow:OtherArrow,
desc:'Shiba is a cryptocurrency that was created in August 2020 as an ERC-20 token on the Ethereum blockchain It is named after the Japanese breed of dog known as the Shiba Inu and is often referred to as the due to its similarities to the popular Dogecoin cryptocurrency. The creators of Shiba aimed to create a decentralized ecosystem that allows for community-driven development and governance. Shibas main feature is the ShibaSwap decentralized exchange (DEX) which enables users to trade Shiba and other cryptocurrencies without the need for a central authority. The creators of Shiba aimed to create a decentralized ecosystem that allows for community-driven development and governance. Shibas main feature is the ShibaSwap decentralized exchange (DEX) which enables users to trade Shiba and other cryptocurrencies without the need for a central authority.',
coin: "Shiba",
coinCode: "SHB",
project: 'SeedOn',
seon:'SEON',
chain: 'Shiba Smart Chain (BEP20)',
price: 5467231,
stage: 'IDO',
prev_date: 'ended 15 days ago',
start_date:'may 24, 2022',
end_date:'aug 26, 2022',
goal: 600000,
launchpad:'other'
},
{
id: 4,
seedonLogo: SeedonLogo,
bnbImg: Polygon,
otherArrow:OtherArrow,
desc:'Polygon is a network of interconnected blockchains, or sidechains, that are built on top of Ethereum and operate in parallel with the main Ethereum network. This allows for faster transaction processing times and lower fees, making it an attractive option for developers and users who want to build and use decentralized applications (dApps) on the Ethereum network. Polygon, formerly known as Matic Network, is a Layer 2 scaling solution for the Ethereum blockchain. It was created in 2017 to address the issues of slow transaction times and high gas fees on Ethereum by providing a faster and more cost-effective alternative. Polygon (MATIC) is a Layer 2 scaling solution for Ethereum, designed to improve transaction speeds and reduce fees on the network.',
coin: "Polygon",
coinCode: "MATIC",
project: 'SeedOn',
seon:'SEON',
chain: 'Polygon Smart Chain (BEP20)',
price: 5461234,
stage: 'IDO',
prev_date: 'ended 1 months ago',
start_date:'sep 17, 2022',
end_date:'oct 19, 2022',
goal: 7859302,
launchpad:'other'
},
{
id: 5,
seedonLogo: SeedonLogo,
bnbImg: Avalanche,
otherArrow:OtherArrow,
 desc: "One of the unique features of Avalanche is its interoperability with other blockchain networks. It supports the creation of cross-chain bridges that enable users to transfer assets between different blockchain networks seamlessly. This interoperability also allows developers to build dApps that utilize multiple blockchain networks, increasing the flexibility and functionality of their applications. Avalanche is a decentralized platform for building custom blockchain networks and decentralized applications (dApps). It was created in 2018 by a team of blockchain experts led by Emin Gün Sirer, a renowned computer scientist and professor. Avalanche is a relatively new blockchain platform that was launched in September 2020. Here is a brief timeline of Avalanche's history.",
coin: "Avalanche",
coinCode: "AVAX",
project: 'SeedOn',
seon:'SEON',
chain: 'Avalanche Smart Chain (BEP20)',
price: 8473262,
stage: 'IDO',
prev_date: 'ended 1 months ago',
start_date:'april 2, 2022',
end_date:'may 3, 2022',
goal: 2475920,
launchpad:'other'
},
{
id: 6,
seedonLogo: SeedonLogo,
bnbImg: Graph,
otherArrow:OtherArrow,
desc: "The Graph allows developers to easily build decentralized applications (dApps) that require access to blockchain data without the need for them to run their own nodes. By providing a powerful indexing and querying system, The Graph enables developers to quickly access and analyze blockchain data, such as transaction history, balances, and other data. The Graph is a decentralized protocol that enables developers to efficiently index and query blockchain data from various networks, such as Ethereum, IPFS, and more. It is built on the Ethereum blockchain and uses the InterPlanetary File System (IPFS) for storing and retrieving data. The Graph (GRT) is a decentralized indexing protocol for querying data on the blockchain. Here is a brief timeline of The Graph's history.",
coin: "Graph",
coinCode: "GRT",
project: 'SeedOn',
seon:'SEON',
chain: 'Graph Smart Chain (BEP20)',
price: 5463728,
stage: 'IDO',
prev_date: 'ended 1 months ago',
start_date:'feb 12, 2022',
end_date:'march 13, 2022',
goal: 3000000,
launchpad:'other'
},
{
id: 7,
seedonLogo: SeedonLogo,
bnbImg: Tether,
otherArrow:OtherArrow,
desc:'Tether (USDT) is a popular cryptocurrency that was launched in 2014. It is a stablecoin, which means it is designed to maintain a stable value by being pegged to a reserve of assets, typically fiat currencies like the U.S. dollar. Tether is operated by Tether Limited, a company closely affiliated with the cryptocurrency exchange Bitfinex. Tether was founded in November 2014 under the name "Realcoin" by Reeve Collins, Brock Pierce, and Craig Sellars. Initially, Tether was launched on the Bitcoin blockchain using the Omni Layer protocol, which allowed the creation and transfer of tokens. Tether aimed to provide a stable alternative to volatile cryptocurrencies, with each USDT token being backed by one U.S. dollar held in reserves. ',
coin: "Tether",
coinCode: "USDT",
project: 'SeedOn',
seon:'SEON',
chain: 'Tether Smart Chain (BEP20)',
price: 3920857,
stage: 'IDO',
prev_date: 'ended 4 months ago',
start_date:'sep 19, 2022',
end_date:'dec 30, 2022',
goal: '$400,000',
launchpad:'other'
},
{
id: 8,
seedonLogo: SeedonLogo,
bnbImg: XRP,
otherArrow:OtherArrow,
desc:'XRP is a cryptocurrency that was created by Ripple Labs Inc., a technology company specializing in digital payment and remittance solutions. XRP was created by Jed McCaleb and Chris Larsen and released in 2012 as a digital asset within the Ripple payment protocol. The goal of XRP was to facilitate fast and low-cost international money transfers and provide liquidity for banks and financial institutions. In 2013, Ripple Labs took over the development and promotion of XRP, rebranding itself as Ripple. Ripple aimed to establish partnerships with banks and other financial institutions to adopt its payment protocol and use XRP as a bridge currency for cross-border transactions.',
coin: "XRP",
coinCode: "XRP",
project: 'SeedOn',
seon:'SEON',
chain: 'xrp Smart Chain (BEP20)',
price: 2910235,
stage: 'IDO',
prev_date: 'ended 1 months ago',
start_date:'jun 12, 2022',
end_date:'july 27, 2022',
goal: 5000000,
launchpad:'other'
},
{
id: 9,
seedonLogo: SeedonLogo,
bnbImg: Solana,
otherArrow:OtherArrow,
desc:'Solana (SOL) is a cryptocurrency and blockchain platform that was launched in 2017. Solana was founded by Anatoly Yakovenko, a former Qualcomm engineer, in 2017. The main goal of Solana was to address scalability issues faced by existing blockchain platforms by introducing a high-performance blockchain protocol. The development of Solanas protocol began in 2018, focusing on achieving high throughput, low latency, and low transaction costs. Solanas mainnet was launched in March 2020, marking the official release of the Solana blockchain. Solana gained attention from developers and projects seeking to build decentralized applications (dApps) and decentralized finance (DeFi) solutions.',
coin: "Solana",
coinCode: "SOL",
project: 'SeedOn',
seon:'SEON',
chain: 'Solana Smart Chain (BEP20)',
price: 7348321,
stage: 'IDO',
prev_date: 'ended 3 months ago',
start_date:'Jan 12, 2022',
end_date:'march 21, 2022',
goal: 7893210,
launchpad:'other'
},
]