import React, { useState } from "react";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { selectCurrentTheme } from "../../store/slices/themeSlice";
import { useSelector } from "react-redux";

const PortfolioFeePopup = ({ onBack, enterFee, fee }) => {
  const [enteredFee, setEnteredFee] = useState(fee);
  const currentTheme = useSelector(selectCurrentTheme);

// handle fee handler
  const feeHandler = e => {
    setEnteredFee(e.target.value);
  };
// handle add fee
  const addFeeHandler = () => {
    enterFee(enteredFee);
    onBack();
  };

  return (
    <div className="p-7 xs:px-4">
      <div className="flex items-center gap-3 mb-4 ">
        <FontAwesomeIcon
          className={`cursor-pointer ${
            currentTheme === "dark" ? "text-mainBg" : "text-filter"
          }`}
          icon={faChevronLeft}
          onClick={onBack}
        />
        <p
          className={`text-[22px] xs:text-xl font-semibold  ${
            currentTheme === "dark" ? "text-mainBg" : "text-secondary"
          }`}
        >
          Add Fee
        </p>
      </div>
      <div className="mb-5">
        <div
          className={`w-full flex border border-lightBorder p-2 rounded-[7px] mt-1 mb-3 focus-within:border-primary ${
            currentTheme === "dark"
              ? "bg-inputColor text-mainBg"
              : "bg-mainBg text-filter"
          }`}
        >
          <span className={`${currentTheme === "dark" ? " text-mainBg" : "text-filter"}`}>
            $
          </span>
          <input
            className={`outline-none w-full no-arrows ${
              currentTheme === "dark"
                ? "bg-inputColor text-mainBg"
                : "bg-mainBg text-filter"
            }`}
            type="number"
            id="price"
            placeholder="0.00"
            value={enteredFee}
            onChange={feeHandler}
          />
        </div>
        <p
          className={` ${
            currentTheme === "dark" ? " text-mainBg" : "text-portfolioCoinColor"
          }`}
        >
          The transaction fee entered will be included as part of the Profit/Loss
          calculation.
        </p>
      </div>
      <div></div>
      <div>
        <button
          className="w-full text-center bg-primary py-2 rounded-[6px] text-popupText font-semibold"
          onClick={addFeeHandler}
        >
          Add Fee
        </button>
      </div>
    </div>
  );
};

export default PortfolioFeePopup;
