import React, { useEffect, useState } from "react";
import Modal from "../../utils/Modal";
import { selectCurrentTheme } from "../../store/slices/themeSlice";
import { useSelector,useDispatch } from "react-redux";
import { closeRemoveLocalStorageWatchList } from "../../store/slices/popupSlice";

const WatchListLocalTablePopup = ({ handleAllFiltersSelected }) => {
const dispatch = useDispatch()
  const currentTheme = useSelector(selectCurrentTheme);

  return (
    <Modal maxWidth="450">
      <div className="px-8 py-6 relative">
        <h2
          className={`text-2xl font-semibold mb-4  ${
            currentTheme === "dark" ? " text-mainBg" : "text-filter"
          }`}
        >
          Are you sure to remove all watchlist?
        </h2>
        <p
          className={`mb-6  ${currentTheme === "dark" ? " text-mainBg" : "text-filter"}`}
        >
          This action will permanently remove all items from your watchlist. Do you want
          to proceed?
        </p>
        <div
          className={`flex justify-end  ${
            currentTheme === "dark" ? " text-mainBg" : "text-filter"
          }`}
        >
          <button
            className={`px-4 py-2 mr-2 rounded ${
              currentTheme === "dark"
                ? "bg-inputColor text-mainBg"
                : "bg-gray-200 text-filter"
            }`}
            onClick={() => dispatch(closeRemoveLocalStorageWatchList())}
          >
            Cancel
          </button>
          <button
            className="px-4 py-2 mr-2 rounded bg-decrease"
            onClick={handleAllFiltersSelected}
          >
            Confirm
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default WatchListLocalTablePopup;
