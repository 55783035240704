import React from "react";
import IncreaseChangeIcon from "../../assets/icons/increase-change.svg";
import decreaseChangeIcon from "../../assets/icons/decrease-change.svg";
import ChevronRightIcon from "../../assets/icons/chevron-right.svg";
import { useGetCoinGainersQuery } from "../../store/services/services";
import { commafy } from "../../utils/commafy";
import { toDecimals } from "../../utils/toDecimals";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { Link } from "react-router-dom";
import { selectCurrentTheme } from "../../store/slices/themeSlice";
import { useSelector } from "react-redux";
import CryptoIcon from "../../assets/icons/crypto-icon.svg";

const TopGainers = () => {
  const { data, isLoading, isError, error } = useGetCoinGainersQuery();
  const currentTheme = useSelector(selectCurrentTheme);
  return (
    <div
      className={`w-[32%] min-w-[308px] shadow-navbar-button p-5 rounded-[10px] mb-4 ${
        currentTheme === "dark" ? "bg-cardsColor" : "bg-mainBg"
      }`}
    >
      <div className="flex justify-between items-center">
        <div className="flex items-center gap-2">
          <p
            className={`${
              currentTheme === "dark" ? "text-mainBg" : "text-filter"
            } font-medium sm:text-sm`}
          >
            🔝 Top Gainers
          </p>
        </div>
        <Link to="/top-gainers">
          <div className="flex items-center gap-1 cursor-pointer">
            <p className="text-sm sm:text-xs text-blueColor font-medium">More</p>
            <img className="h-[10px] sm:h-[8px]" src={ChevronRightIcon} alt="right" />
          </div>
        </Link>
      </div>
      <div>
        {isLoading && (
          <div className="w-full">
            <SkeletonTheme
              baseColor={`${currentTheme === "dark" ? "#323546" : "#d8d8d8"}`}
              highlightColor={`${currentTheme === "dark" ? "#444" : "#e0e0e0"}`}
            >
              <Skeleton width="100%" height={37} count={3} />
            </SkeletonTheme>
          </div>
        )}
        {isError && <p>{error.message}</p>}
        {!isLoading && !isError && (
          <div className="my-2">
            {data?.data?.slice(0, 3).map((item, i) => (
              <div key={item.id} className="flex items-center gap-2">
                <span
                  className={`${
                    currentTheme === "dark" ? " text-mainBg" : "text-portfolioCoinColor"
                  } text-xs`}
                >
                  0{i + 1}
                </span>
                <div
                  className={`flex items-center justify-between w-full py-2 border-b-[1px] ${
                    currentTheme === "dark" ? " border-darkBorder" : "border-primary"
                  }`}
                >
                  <Link to={`/coin-detail/${item?.logo}`}>
                    <div className="flex items-center gap-2">
                      <img
                        className="w-[14px] sm:w-[12px]"
                        src={item?.img ? item?.img : CryptoIcon}
                        alt="logo"
                      />
                      <p
                        className={`${
                          currentTheme === "dark" ? " text-mainBg" : "text-filter"
                        } text-sm font-medium sm:text-xs`}
                      >
                        {item?.name.slice(0, 15)}
                      </p>
                      <p
                        className={`${
                          currentTheme === "dark"
                            ? " text-mainBg"
                            : "text-portfolioCoinColor"
                        } text-xs sm:text-[10px] ]`}
                      >
                        {item?.logo}
                      </p>
                    </div>
                  </Link>
                  <div className="flex items-center gap-1">
                    <img
                      className="sm:w-[6px]"
                      src={
                        item?.percentChangeDay > 0
                          ? IncreaseChangeIcon
                          : decreaseChangeIcon
                      }
                      alt={item?.percentChangeDay > 0 ? "increase-icon" : "decrease-icon"}
                    />
                    <p
                      className={`${
                        item?.percentChangeDay > 0 ? "text-increase" : "text-decrease"
                      } text-xs sm:text-[11px]`}
                    >
                      {commafy(toDecimals(item?.percentChangeDay))}%
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default TopGainers;
