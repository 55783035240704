export const Popular_Crypto_Currency = [
{
id: 1,
currencyName: 'BTC to USD',
convertFrom : 'BTC',
convertTo: 'USD',
},
{
id: 2,
currencyName: 'SHB to Eth',
convertFrom : 'SHB',
convertTo: 'ETH'
},
{
id: 3,
currencyName: 'DOGE to SHB',
convertFrom: 'DOGE',
convertTo: 'SHB'
},
{
id: 4,
currencyName: 'BTC to USD',
convertFrom : 'BTC',
convertTo: 'USD'
},
{
id: 5,
currencyName: 'SHB to ETH',
convertFrom : 'SHB',
convertTo: 'ETH'
},
{
id: 6,
currencyName: 'DOGE to SHB',
convertFrom: 'DOGE',
convertTo: 'SHB'
},
{
id: 7,
currencyName: 'BTC to USD',
convertFrom : 'BTC',
convertTo: 'USD'
},
{
id: 8,
currencyName: 'SHB to ETH',
convertFrom : 'SHB',
convertTo: 'ETH'
},
{
id: 9,
currencyName: 'DOGE to SHB',
convertFrom: 'DOGE',
convertTo: 'SHB'
},
{
id: 10,
currencyName: 'BTC to USD',
convertFrom : 'BTC',
convertTo: 'USD'
},
{
id: 11,
currencyName: 'SHB to ETH',
convertFrom : 'SHB',
convertTo: 'ETH'
},
{
id: 12,
currencyName: 'ETH to SHB',
convertFrom: 'ETH',
convertTo: 'SHB'
},
{
id: 13,
currencyName: 'BTC to USD',
convertFrom : 'BTC',
convertTo: 'USD'
},
{
id: 14,
currencyName: 'SHB to ETH',
convertFrom : 'SHB',
convertTo: 'ETH'
},
{
id: 15,
currencyName: 'ETH to SHB',
convertFrom: 'ETH',
convertTo: 'SHB'
},
{
id: 16,
currencyName: 'BTC to USD',
convertFrom : 'BTC',
convertTo: 'USD'
},
{
id: 17,
currencyName: 'SHB to ETH',
convertFrom : 'SHB',
convertTo: 'ETH'
},
{
id: 18,
currencyName: 'ETH to SHB',
convertFrom: 'ETH',
convertTo: 'SHB'
},
{
id: 19,
currencyName: 'BTC to USD',
convertFrom : 'BTC',
convertTo: 'USD'
},
{
id: 20,
currencyName: 'SHB to ETH',
convertFrom : 'SHB',
convertTo: 'ETH'
},
{
id: 21,
currencyName: 'ETH to SHB',
convertFrom: 'ETH',
convertTo: 'SHB'
},
{
id: 22,
currencyName: 'BTC to USD',
convertFrom : 'BTC',
convertTo: 'USD'
},
{
id: 23,
currencyName: 'SHB to ETH',
convertFrom : 'SHB',
convertTo: 'ETH'
},
{
id: 24,
currencyName: 'ETH to SHB',
convertFrom: 'ETH',
convertTo: 'SHB'
},
{
id: 25,
currencyName: 'BTC to USD',
convertFrom : 'BTC',
convertTo: 'USD'
},
{
id: 26,
currencyName: 'SHB to ETH',
convertFrom : 'SHB',
convertTo: 'ETH'
},
{
id: 27,
currencyName: 'ETH to SHB',
convertFrom: 'ETH',
convertTo: 'SHB'
},
{
id: 28,
currencyName: 'BTC to USD',
convertFrom : 'BTC',
convertTo: 'USD'
},
{
id: 29,
currencyName: 'SHB to ETH',
convertFrom : 'SHB',
convertTo: 'ETH'
},
{
id: 30,
currencyName: 'ETH to SHB',
convertFrom: 'ETH',
convertTo: 'SHB'
},
{
id: 31,
currencyName: 'BTC to USD',
convertFrom : 'BTC',
convertTo: 'USD'
},
{
id: 32,
currencyName: 'SHB to ETH',
convertFrom : 'SHB',
convertTo: 'ETH'
},
{
id: 33,
currencyName: 'ETH to SHB',
convertFrom: 'ETH',
convertTo: 'SHB'
},
{
id: 34,
currencyName: 'BTC to USD',
convertFrom : 'BTC',
convertTo: 'USD'
},
{
id: 35,
currencyName: 'SHB to ETH',
convertFrom : 'SHB',
convertTo: 'ETH'
},
{
id: 36,
currencyName: 'ETH to SHB',
convertFrom: 'ETH',
convertTo: 'SHB'
},
{
id: 37,
currencyName: 'BTC to USD',
convertFrom : 'BTC',
convertTo: 'USD'
},
{
id: 38,
currencyName: 'SHB to ETH',
convertFrom : 'SHB',
convertTo: 'ETH'
},
{
id: 39,
currencyName: 'ETH to SHB',
convertFrom: 'ETH',
convertTo: 'SHB'
},
{
id: 40,
currencyName: 'BTC to USD',
convertFrom : 'BTC',
convertTo: 'USD'
},
{
id: 41,
currencyName: 'SHB to ETH',
convertFrom : 'SHB',
convertTo: 'ETH'
},
{
id: 42,
currencyName: 'ETH to SHB',
convertFrom: 'ETH',
convertTo: 'SHB'
},
{
id: 43,
currencyName: 'BTC to USD',
convertFrom : 'BTC',
convertTo: 'USD'
},
{
id: 44,
currencyName: 'SHB to ETH',
convertFrom : 'SHB',
convertTo: 'ETH'
},
{
id: 45,
currencyName: 'ETH to SHB',
convertFrom: 'ETH',
convertTo: 'SHB'
},
{
id: 46,
currencyName: 'BTC to USD',
convertFrom : 'BTC',
convertTo: 'USD'
},
{
id: 47,
currencyName: 'SHB to ETH',
convertFrom : 'SHB',
convertTo: 'ETH'
},
{
id: 48,
currencyName: 'ETH to SHB',
convertFrom: 'ETH',
convertTo: 'SHB'
},
]