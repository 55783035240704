import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { commafy } from "../../utils/commafy";
import { Sparklines, SparklinesLine } from "react-sparklines";
import { toDecimals } from "../../utils/toDecimals";
import { Table } from "antd";
import {
  addWatchlist,
  resetWatchlist,
  watchlistMountRemove
} from "../../store/slices/watchlistSlice";
import CryptoIcon from "../../assets/icons/crypto-icon.svg";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import {
  useAddWatchlistMutation,
  useGetCoinLosersQuery,
  useGetWatchlistQuery
} from "../../store/services/services";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentTheme } from "../../store/slices/themeSlice";

const TopLosersCurrencyTable = () => {
  const [loading, setLoading] = useState(true);
  const [whatchlistLocalState, setWhatchlistLocalState] = useState();
  const { data: coins, isLoading } = useGetCoinLosersQuery();
  const watchlistMount = useSelector(state => state.watchlistCoins.watchlistMount);

  const user = useSelector(state => state.auth);
  const watchlistCoins = useSelector(state => state.watchlistCoins.watchlistCoins);

  const dispatch = useDispatch();
  const currentTheme = useSelector(selectCurrentTheme);

  // remove all watchlist
  useEffect(() => {
    if (user.userId === null) {
      dispatch(resetWatchlist());
    }
  }, [user.userId, dispatch]);

  useEffect(() => {
    const watchlistDataLocal = JSON.parse(localStorage.getItem("watchlist")) || [];
    setWhatchlistLocalState(watchlistDataLocal);
  }, []);

  // all items in localstorage
  const addWatchListLocal = item => {
    if (whatchlistLocalState.some(row => item.logo === row.logo)) {
      const updatedWatchlist = whatchlistLocalState.filter(
        coin => coin.logo !== item.logo
      );
      localStorage.setItem("watchlist", [[JSON.stringify(updatedWatchlist)]]);
      setWhatchlistLocalState(updatedWatchlist);
    } else {
      const updatedWatchlist = [...whatchlistLocalState, item];
      setWhatchlistLocalState(updatedWatchlist);
      localStorage.setItem("watchlist", [[JSON.stringify(updatedWatchlist)]]);
    }
  };

  const [addWatchlistApi] = useAddWatchlistMutation();

  const { data: getWatchlist, isLoading: getWatchlistIsLoading } = useGetWatchlistQuery(
    user.userId
  );

  // setTime for table
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (!getWatchlistIsLoading && user.userId && watchlistMount) {
      if (Array.isArray(getWatchlist)) {
        const coinList = getWatchlist?.map(item => item?.coin);
        dispatch(addWatchlist(coinList));
        dispatch(watchlistMountRemove());
      } else {
      }
    }
  }, [dispatch, getWatchlist, getWatchlistIsLoading, user.userId, watchlistMount]);

  const addWatchlistHandler = async logo => {
    dispatch(addWatchlist([logo]));
    await addWatchlistApi({ userId: user.userId, logo });
  };

  const inWatchlist = coin => {
    return watchlistCoins.includes(coin);
  };

  // change color of icons
  const starCondition = user.token
    ? "inWatchlist(row?.logo)"
    : "whatchlistLocalState?.some(item => item.logo === row.logo)";

  // top gainers table
  const columns = [
    {
      title: "",
      dataIndex: "logo",
      key: "logo",
      width: "1%",
      render: (logo, row) => (
        <div
          className={`cursor-pointer ${inWatchlist(logo) ? "text-primary" : ""}`}
          onClick={() => {
            user.token ? addWatchlistHandler(row?.logo) : addWatchListLocal(row);
          }}
        >
          <i
            className={`${
              eval(starCondition) ? "fa-solid text-primary" : "fa-regular"
            }  fa-star `}
          ></i>
        </div>
      ),
      fixed: "left",
      className: `${currentTheme === "dark" ? "table-dark" : "table-light"}`
    },
    {
      title: "#",
      dataIndex: "sequence_number",
      key: "sequence_number",
      width: "1%",
      fixed: "left",
      className: `${currentTheme === "dark" ? "table-dark" : "table-light"}`
    },
    {
      title: "Coin",
      dataIndex: "name",
      key: "name",
      width: "130px",
      fixed: "left",
      render: (name, row) => (
        <Link
          to={`/coin-detail/${row.logo}`}
          className="flex items-center h-8 sm:h-14 gap-[6px] before"
          style={{ color: "inherit", textDecoration: "none" }}
        >
          <img className="w-5" src={row.img ? row.img : CryptoIcon} alt="coin" />
          <div className="w-[150px] sm:w-[120px] flex  flex-wrap xs:flex-col xs:items-start xs:gap-0 gap-2 xs:justify-center ">
            <span className="font-medium">{row.name.substring(0, 10)}</span>
            <span className="">{row.logo}</span>
          </div>
        </Link>
      ),
      className: `${currentTheme === "dark" ? "table-dark" : "table-light"}`
    },
    {
      title: "Price",
      dataIndex: "price",
      width: "100px",
      key: "price",
      render: price => `${commafy(toDecimals(price))}`,
      className: `${currentTheme === "dark" ? "table-dark" : "table-light"}`,
      sorter: (a, b) => {
        const priceA = parseFloat(a.price.replace(/[^0-9.-]+/g, ""));
        const priceB = parseFloat(b.price.replace(/[^0-9.-]+/g, ""));

        return priceA - priceB;
      }
    },
    {
      title: "1h",
      dataIndex: "percentChangeHour",
      width: "100px",
      key: "percentChangeHour",
      render: percentChangeHour => (
        <span className={percentChangeHour > 0 ? "text-increase" : "text-decrease"}>
          {commafy(toDecimals(percentChangeHour))}%
        </span>
      ),
      className: `${currentTheme === "dark" ? "table-dark" : "table-light"}`,
      sorter: (a, b) => {
        const priceA = parseFloat(a.percentChangeHour.replace(/[^0-9.-]+/g, ""));
        const priceB = parseFloat(b.percentChangeHour.replace(/[^0-9.-]+/g, ""));

        return priceA - priceB;
      }
    },
    {
      title: "24h",
      dataIndex: "percentChangeDay",
      width: "100px",
      render: (_, row) => (
        <span className={row.percentChangeDay > 0 ? "text-increase" : "text-decrease"}>
          {commafy(toDecimals(row.percentChangeDay))}%
        </span>
      ),
      className: `${currentTheme === "dark" ? "table-dark" : "table-light"}`,
      sorter: (a, b) => parseFloat(a.percentChangeDay) - parseFloat(b.percentChangeDay)
    },
    {
      title: "7d",
      dataIndex: "percentChangeWeek",
      width: "100px",
      render: (_, row) => (
        <span className={row.percentChangeWeek > 0 ? "text-increase" : "text-decrease"}>
          {commafy(toDecimals(row.percentChangeWeek))}%
        </span>
      ),
      className: `${currentTheme === "dark" ? "table-dark" : "table-light"}`,
      sorter: (a, b) => parseFloat(a.percentChangeWeek) - parseFloat(b.percentChangeWeek)
    },
    {
      title: "24h Volume",
      dataIndex: "volume_24h",
      width: "150px",
      render: (_, row) => `$${commafy(toDecimals(row.volume_24h))}`,
      className: `${currentTheme === "dark" ? "table-dark" : "table-light"}`,
      sorter: (a, b) => parseFloat(a.volume_24h) - parseFloat(b.volume_24h)
    },
    {
      title: "Market Cap",
      width: "150px",
      dataIndex: "market_cap",
      render: (_, row) => `$${commafy(toDecimals(row.market_cap))}`,
      className: `${currentTheme === "dark" ? "table-dark" : "table-light"}`,
      sorter: (a, b) => parseFloat(a.market_cap) - parseFloat(b.market_cap)
    },
    {
      title: "Circulating Supply",
      dataIndex: "circulating_supply",
      width: "160px",
      render: (text, row) => (
        <div className="flex gap-2">
          <span>{commafy(toDecimals(text))}</span>
          <span className="">{row.logo}</span>
        </div>
      ),
      className: `${
        currentTheme === "dark" ? "table-dark bg-cardsColor" : "table-light bg-mainBg"
      }`,
      sorter: (a, b) =>
        parseFloat(a.circulating_supply.replace(/[^0-9.-]+/g, "")) -
        parseFloat(b.circulating_supply.replace(/[^0-9.-]+/g, ""))
    },
    {
      title: "Last 7 Days",
      width: "150px",
      render: (_, row) => {
        const sparklineData = JSON.parse(row?.sparkline_in_7d);

        if (
          !sparklineData ||
          !Array.isArray(sparklineData) ||
          sparklineData.length === 0
        ) {
          return null;
        }

        const color =
          sparklineData[sparklineData?.length - 1] <
          sparklineData[sparklineData?.length - 2]
            ? "#d2445b"
            : "#4ebf7e";

        return (
          <Sparklines data={sparklineData} width={100} height={20} margin={5}>
            <SparklinesLine color={color} />
          </Sparklines>
        );
      },
      className: `${currentTheme === "dark" ? "table-dark" : "table-light"}`
    }
  ];
  return (
    <div className="px-24 md:px-8 xs:px-4 mt-12">
      <div>
        <p
          className={`text-xl font-semibold pb-5 ${
            currentTheme === "dark" ? " text-mainBg" : "text-filter"
          }`}
        >
          Top Losers
        </p>
      </div>
      <div className="mb-8 overflow-x-auto scrollbar-hide w-full">
        {loading ? (
          <div className="w-full">
            <SkeletonTheme
              baseColor={`${currentTheme === "dark" ? "#323546" : "#d8d8d8"}`}
              highlightColor={`${currentTheme === "dark" ? "#444" : "#e0e0e0"}`}
            >
              <Skeleton width="100%" height={50} count={30} className="mt-1" />
            </SkeletonTheme>
          </div>
        ) : (
          <Table
            columns={columns}
            dataSource={coins?.data}
            pagination={false}
            scroll={{ x: "max-content" }}
            className={`mb-8 overflow-x-auto scrollbar-hide w-full ${
              currentTheme === "dark" ? "table-dark" : "table-light"
            }`}
          />
        )}
      </div>
    </div>
  );
};

export default TopLosersCurrencyTable;
