import React from "react";
import IcosProjectsTable from "../components/IcosProjectsTable/IcosProjectsTable";

const IcosProjectTable = () => {
  return (
    <div>
      <IcosProjectsTable />
    </div>
  );
};

export default IcosProjectTable;
