import { createSlice } from "@reduxjs/toolkit";

// initial state
const initialState = {
  portfolioChange: false,
  portfolioId: null,
};

const portfolioChangeSlice = createSlice({
  name: "portfolioChange",
  initialState,
  reducers: {
    portfolioChange: (state) => {
      state.portfolioChange = state.portfolioChange + 1;
    },
    portfolioReset: (state) => {
      state.portfolioChange = false;
    },
     portfolioCompareId: (state, action) => {
      state.portfolioId = action.payload;
    },
  },
});

export const { portfolioChange, portfolioReset,portfolioCompareId } = portfolioChangeSlice.actions;
export default portfolioChangeSlice.reducer;
