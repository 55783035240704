import React from "react";
import { useGetAllIcosQuery } from "../../store/services/services";
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import CryptoIcon from "../../assets/icons/crypto-icon.svg";

const OngoingProjectsTable = ({ limit }) => {
  const { data, isLoading, isError, error } = useGetAllIcosQuery();

  return (
    <div className="shadow-navbar-button rounded-[40px] pb-5 pt-5 md:pt-3 sm:pt-0 px-[31px] overflow-hidden">
      <div className="overflow-x-auto scrollbar whitespace-nowrap">
        <table className="text-sm text-left bg-mainBg w-full">
          <thead className="bg-mainBg">
            <tr className="border-b">
              <th scope="col" className="px-6 py-3 text-base">
                Project
              </th>
              <th scope="col" className="px-6 py-3 text-base">
                Stage
              </th>
              <th scope="col" className="px-1 py-3 text-base">
                Ends in
              </th>
              <th scope="col" className="px-6 py-3 text-base">
                Goal
              </th>
            </tr>
          </thead>
          {/* {isLoading && (
            <div className="w-full">
              <Skeleton width="100%" height={35} count={6} />
            </div>
          )} */}
          {/* {isError && <p>{error.message}</p>} */}
          {!isLoading && !isError && (
            <tbody>
              {data.slice(0, limit ? limit : data.length).map(items => {
                return (
                  <tr className="border-b" key={items.id}>
                    <Link to={`/icosSingleProduct/${items.id}`}>
                      <th
                        scope="row"
                        className="flex items-center px-6 py-4  whitespace-nowrap"
                      >
                        <div>
                          <img
                            src={items?.image ? items?.image : CryptoIcon}
                            alt="logo"
                            className="h-3.5"
                          />
                        </div>
                        <div className="pl-3">
                          <div className="text-base font-normal">
                            <span className="text-base font-semibold">{items?.name}</span>
                            {items?.alias}
                          </div>
                          {/* <div className="font-normal flex items-center bg-coinDetailBg gap-1 py-1 rounded-xl pl-4 pr-7 mt-1">
                              <img
                                src={bnbImg}
                                alt="bnb"
                                className="h-[13px]"
                              />
                              <p className="text-[10px] text-[#111111]">
                                {chain}
                              </p>
                            </div> */}
                        </div>
                      </th>
                    </Link>
                    {/* <td className="px-6 py-4 font-normal text-base">
                          {stage}
                        </td> */}
                    <td className="px-1 py-4">
                      <div className="items-center">
                        <div className="text-base font-normal mr-2">{items?.endTime}</div>
                        {/* <div className="text-[10px] mr-2">{date}</div> */}
                      </div>
                    </td>
                    <td className="px-6 py-4">
                      {/* <div className="text-base mr-2 font-normal">
                            {goal}
                          </div> */}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          )}
        </table>
        {isLoading && (
          <div className="w-full">
            <Skeleton width="100%" height={55} count={6} />
          </div>
        )}
        {isError && <p>{error.message}</p>}
      </div>
    </div>
  );
};

export default OngoingProjectsTable;
