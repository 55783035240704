import React from "react";
import { Pagination } from "antd";
import { useDispatch } from "react-redux";
import { changePageNumber } from "../../store/slices/coinMarketSlice";

const NewsPagination = ({ currentPage, setCurrentPage, total_coins, per_page }) => {
  const dispatch = useDispatch();
  const handlePageChange = page => {
    setCurrentPage(page);
    dispatch(changePageNumber(page));
  };
  return (
    <div className="flex justify-center">
      <Pagination
        current={currentPage}
        total={total_coins}
        showSizeChanger={false}
        defaultPageSize={per_page}
        onChange={handlePageChange}
      />
    </div>
  );
};

export default NewsPagination;
