import React, { useEffect, useState } from "react";
import Modal from "../../utils/Modal";
import { useCreatePortfolioMutation } from "../../store/services/services";
import { useDispatch, useSelector } from "react-redux";
import { portfolioChange } from "../../store/slices/portfolioChangeSlice";
import { closePortfolioCreate } from "../../store/slices/popupSlice";
import { selectCurrentTheme } from "../../store/slices/themeSlice";

const PortfolioCreatePopup = () => {
  const [enteredPortfolioName, setEnteredPortfolioName] = useState("");
  const portfolioNameHandler = e => {
    setEnteredPortfolioName(e.target.value);
  };
  const currentTheme = useSelector(selectCurrentTheme);
  const dispatch = useDispatch();

  const userId = useSelector(state => state.auth.userId);
   const [createPortfolio, { data, isSuccess, isLoading, isError, error }] =
     useCreatePortfolioMutation();
  console.log(isLoading, isError, error, isSuccess);
       const addPortfolioHandler = async () => {
         if (enteredPortfolioName !== "") {
           await createPortfolio({ name: enteredPortfolioName, userId });
           console.log("hello");
         }
       };
       !isLoading && console.log(data);
  useEffect(() => {
    if (isSuccess) {
      dispatch(portfolioChange());
      dispatch(closePortfolioCreate());
    }
  }, [isSuccess]);

  return (
    <Modal maxWidth={600}>
      <div className="p-7 xs:px-4">
        <div>
          <p
            className={`text-[22px] xs:text-xl font-semibold mb-4  ${
              currentTheme === "dark" ? " text-mainBg" : "text-secondary"
            }`}
          >
            Create Portfolio
          </p>
        </div>
        <div className="mb-5">
          <label
            htmlFor="portfolio-name"
            className={` xs:text-sm ${
              currentTheme === "dark" ? " text-mainBg" : "text-portfolioGrayText"
            }`}
          >
            Portfolio name
          </label>
          <input
            className={`w-full p-2 rounded-[6px] border border-darkBorder outline-none mt-1 mb-3 focus:border-primary ${
              currentTheme === "dark"
                ? "bg-inputColor text-mainBg"
                : "bg-mainBg text-filter"
            }`}
            id="portfolio-name"
            type="text"
            placeholder="Enter Your portfolio name"
            maxLength={32}
            onChange={portfolioNameHandler}
          />
          <p
            className={` xs:text-sm ${
              currentTheme === "dark" ? " text-mainBg" : "text-portfolioGrayText"
            }`}
          >
            {enteredPortfolioName.length}/32 characters
          </p>
        </div>
        <div>
          <button
            className="w-full text-center bg-primary py-2 rounded-[6px] text-popupText font-semibold disabled:bg-disabledButton"
            onClick={addPortfolioHandler}
            disabled={isLoading}
          >
            {isLoading ? "Creating..." : "Create Portfolio"}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default PortfolioCreatePopup;
