import React from "react";
import { Dropdown, Space } from "antd";
import StarOutlineIcon from "../../assets/icons/star-outline.svg";
import AttachmentIcon from "../../assets/icons/attachment.svg";
import LinkIcon from "../../assets/icons/link.svg";
import SearchIcon from "../../assets/icons/search.svg";
import UserIcon from "../../assets/icons/user.svg";
import CodeIcon from "../../assets/icons/code.svg";
import PaperIcon from "../../assets/icons/paper.svg";
import ChevronGreyIcon from "../../assets/icons/chevron-down-grey.svg";
import CoinTagsPopup from "../CoinTagsPopup/CoinTagsPopup";
import { useSelector, useDispatch } from "react-redux";
import { closeModal, coinTagsView } from "../../store/slices/popupSlice";
import { selectCurrentTheme } from "../../store/slices/themeSlice";
import CryptoIcon from "../../assets/icons/crypto-icon.svg";

const CoinTags = ({ coin }) => {
  const tagsArray = JSON.parse(coin.tags);
  const dispatch = useDispatch();
  const currentTheme = useSelector(selectCurrentTheme);

  // coin tags
  const coinTags = useSelector(state => state.popup.coinTagsVisible);

  return (
    <div>
      <div
        className={`p-10 border-r-[.5px] md:border-b-[.5px] md:border-r-0 xs:p-5 ${
          currentTheme === "dark" ? "border-darkBorder" : "border-lightBorder"
        }`}
      >
        <div>
          <div className="flex flex-wrap items-center gap-3 mb-4">
            <img
              className="w-[54px] xs:w-10"
              src={coin?.img ? coin?.img : CryptoIcon}
              alt="bitcoin-icon"
            />
            <p className="text-2xl font-semibold xs:text-xl">{coin?.name}</p>
            <p
              className={` px-3 rounded-[7px] xs:text-sm font-semibold ${
                currentTheme === "dark"
                  ? "text-mainBg bg-filter "
                  : "text-coinTagsText bg-icosProjectsGrey"
              }`}
            >
              {coin?.logo}
            </p>
          </div>
        </div>
        <div>
          <div className="flex gap-2 text-sm xs:text-xs mb-8">
            <p
              className={`px-3 py-[2px]  rounded-[5px] text-mainBg h-full ${
                currentTheme === "dark"
                  ? "text-mainBg bg-filter "
                  : "text-coinTagsText bg-grey"
              }`}
            >
              Rank {coin?.rank}
            </p>
            {/* <p className="px-3 py-[2px] bg-coinTagsBg rounded-[5px] h-full text-grey">
              Coin
            </p>
            <p className="px-3 py-[2px] bg-coinTagsBg rounded-[5px] text-grey">
              On 2,402,783 watchlists
            </p> */}
          </div>
          {/* <div className="flex flex-wrap gap-3 text-sm xs:text-xs mb-8 text-grey"> */}
          {/* <div className="flex gap-1 py-[2px] px-3 bg-coinTagsBg rounded-[5px] items-center">
              <img className="h-[13px]" src={AttachmentIcon} alt="attachment-icon" />
              <p>bitcoin.org</p>
              <img className="h-[13px]" src={LinkIcon} alt="link-icon" />
            </div> */}
          {/* <div>
              <img src={SearchIcon} alt="attachment-icon" />
              <p>Explorers</p>
              <img src={ChevronDownIcon} alt="link-icon" />
            </div> */}
          {/* <Dropdown
              className="cursor-pointer"
              menu={{
                items
              }}
              trigger={["click"]}
            >
              <div
                className="flex gap-1 py-[2px] px-3 bg-coinTagsBg rounded-[5px] items-center"
                onClick={e => e.preventDefault()}
              >
                <img className="h-[13px]" src={SearchIcon} alt="search-icon" />
                <Space>Explorers</Space>
                <img className="h-[6px]" src={ChevronGreyIcon} alt="down-icon" />
              </div>
            </Dropdown> */}
          {/* <Dropdown
              className="cursor-pointer"
              menu={{
                items
              }}
              trigger={["click"]}
            >
              <div
                className="flex items-center gap-1 py-[2px] px-3 bg-coinTagsBg rounded-[5px]"
                onClick={e => e.preventDefault()}
              >
                <img className="h-[13px]" src={UserIcon} alt="user-icon" />
                <Space>Community</Space>
                <img className="h-[6px]" src={ChevronGreyIcon} alt="down-icon" />
              </div>
            </Dropdown> */}
          {/* <div className="flex gap-1 py-[2px] px-3 bg-coinTagsBg rounded-[5px] items-center">
              <img className="h-[13px]" src={CodeIcon} alt="attachment-icon" />
              <p>Source Code</p>
              <img className="h-[13px]" src={LinkIcon} alt="link-icon" />
            </div>
            <div className="flex gap-1 py-[2px] px-3 bg-coinTagsBg rounded-[5px] items-center">
              <img className="h-[13px]" src={PaperIcon} alt="attachment-icon" />
              <p>Whitepaper</p>
              <img className="h-[13px]" src={LinkIcon} alt="link-icon" />
            </div> */}
          {/* </div> */}
          <div className="text-sm xs:text-xs ">
            <div>
              <p
                className={`font-semibold mb-2 ${
                  currentTheme === "dark" ? "text-mainBg " : "text-grey"
                }`}
              >
                Tags:
              </p>
            </div>
            {tagsArray.length === 0 ? (
              <p className="text-center font-semibold">
                There are no tags for {coin.name}
              </p>
            ) : (
              <div className="flex items-center flex-wrap gap-2">
                {tagsArray.slice(0, 6).map(item => (
                  <p
                    className={`py-[2px] px-3 bg-coinTagsBg rounded-[5px] ${
                      currentTheme === "dark" ? "bg-filter text-mainBg " : "bg-coinTagsBg"
                    }`}
                    key={item.id}
                  >
                    {item}
                  </p>
                ))}
                {tagsArray.length > 4 && (
                  <p
                    className="py-[2px] px-3 bg-blueBg text-blueColor rounded-[5px] cursor-pointer"
                    onClick={() => {
                      dispatch(closeModal());
                      dispatch(coinTagsView());
                    }}
                  >
                    View All
                  </p>
                )}
              </div>
            )}
            {coinTags && <CoinTagsPopup tagsArray={tagsArray} coin_name={coin.name} />}
          </div>
        </div>
      </div>
      {/* )} */}
    </div>
  );
};

export default CoinTags;
