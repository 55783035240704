import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { openUpcomingProject } from "../../store/slices/popupSlice";
import { useGetAllIcosQuery } from "../../store/services/services";
import UpcomingProjectsTable from "../UpcomingProjectsTable/UpcomingProjectsTable";
import { Link } from "react-router-dom";

const UpcomingProjects = () => {
  const dispatch = useDispatch();
  const { data } = useGetAllIcosQuery();
  return (
    <div className="w-[50%] md:w-full md:mt-5 sm:mt-3">
      <h1 className="text-2xl md:text-lg font-semibold mb-10 md:mb-5 sm:mb-3">
        Upcoming Projects
      </h1>
      <UpcomingProjectsTable limit={6} />

      <Link to="icos-project-table/upcoming">
        <div
          className="mt-10 md:mt-7 sm:mt-5 flex justify-center cursor-pointer"
          onClick={() => {
            dispatch(openUpcomingProject());
          }}
        >
          <p className="bg-icosProjectsGrey py-4 px-14 text-center rounded-[10px] font-medium text-icosProjectsGreyColor text-base xs:text-xs lg:text-sm">
            View all {data?.length} upcoming ICO
          </p>
        </div>
      </Link>
      {/* {upcomingPopup && <UpcomingProjectsModal data={data} />} */}
    </div>
  );
};

export default UpcomingProjects;
