import React from "react";
import OngoingProjectsTable from "../OngoingProjectsTable";
import UpcomingProjectsTable from "../UpcomingProjectsTable/UpcomingProjectsTable";
import EndedProjectsTable from "../EndedProjectsTable/EndedProjectsTable";
import { useParams } from "react-router-dom";
const IcosProjectsTable = () => {
  const { name } = useParams();
  return (
    <div className="px-12 md:px-8 xs:px-4 mt-14 md:mt-5 sm:mt-3">
      {name === "ongoing" && <OngoingProjectsTable />}
      {name === "upcoming" && <UpcomingProjectsTable />}
      {name === "ended" && <EndedProjectsTable />}
    </div>
  );
};

export default IcosProjectsTable;
