import React, { useEffect, useState } from "react";
import SingleNews from "../SingleNews/SingleNews";
import SingleNewsInfo from "../SingleNewsInfo/SingleNewsInfo";
// import Placeholder from "../Placeholder";
import Disclaimer from "../Disclaimer";

const MainSingleNews = () => {
  const [id, setId] = useState();

  useEffect(() => {
    const windowHeight = window.innerHeight;
    const pageHeight = document.documentElement.scrollHeight;
    const scrollToY = (pageHeight - windowHeight) / 9;

    window.scrollTo(0, scrollToY);
  }, [id]);

  const newsId = (id) => {
    setId(id);
  };

  return (
    <div className="px-24 md:px-8 xs:px-4 mt-[5rem] lg:mt-10 md:mt-8 xs:mt-6 sm:mt-7">
      <SingleNews newsId={newsId} />
      <SingleNewsInfo id={id} />
      {/* <div className="mt-24">
        <Placeholder />
      </div> */}
      <Disclaimer />
    </div>
  );
};

export default MainSingleNews;
