import React from "react";
import TopGainersCurrencyTable from "../components/TopGainersCurrencyTable/TopGainersCurrencyTable";

const TopGainersCurrencyTablePage = () => {
  return (
    <div>
      <TopGainersCurrencyTable />
    </div>
  );
};

export default TopGainersCurrencyTablePage;
