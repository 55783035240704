import React from "react";
import { commafy } from "../../utils/commafy";
import { toDecimals } from "../../utils/toDecimals";
import { Spin } from "antd";
import { useParams } from "react-router-dom";
import { useGetExchangesDataQuery } from "../../store/services/services";
import { selectCurrentTheme } from "../../store/slices/themeSlice";
import { useSelector } from "react-redux";

const ExchangeDetailCard = () => {
  const {
    data: exchangesTableData,
    isLoading,
    isError,
    error
  } = useGetExchangesDataQuery();
  
  const currentTheme = useSelector(selectCurrentTheme);
  const { id } = useParams();
  const exchangeItem = exchangesTableData?.data.find(item => item.id === id);

  return (
    <div className="px-24 md:px-8 xs:px-4 mt-12">
      {isLoading && (
        <div className="flex justify-center items-center h-[200px]">
          <Spin size="large" />
        </div>
      )}
      {isError && <p>{error.message}</p>}
      {!isLoading && !isError && (
        <div
          className={`shadow-navbar-button rounded-[24px] flex md:flex-col ${
            currentTheme === "dark" ? "bg-cardsColor" : "bg-mainBg"
          }`}
        >
          <div className="w-4/12 md:w-full p-10 lg:px-6 xs:px-2">
            <div className="flex items-center gap-3 font-semibold text-2xl mb-8">
              <img className="w-12" src={exchangeItem?.image} alt="logo" />
              <p
                className={`${currentTheme === "dark" ? " text-mainBg" : "text-filter"}`}
              >
                {exchangeItem?.name}
              </p>
            </div>

            <div className="mb-6 hover:underline">
              <a
                className={`text-xs  mb-1  px-2 rounded-[2px] py-[2px] inline-block ${
                  currentTheme === "dark"
                    ? "bg-filter text-mainBg"
                    : "bg-coinTagsBg text-filter"
                }`}
                target="_blank"
                href={exchangeItem.url}
                rel="noreferrer"
              >
                {exchangeItem.url}
              </a>
            </div>
            <div class="flex justify-between w-[46%] md:w-full items-center md:pb-4">
              <h2
                class={`text-lg font-semibold md:text-base ${
                  currentTheme === "dark" ? " text-mainBg" : "text-filter"
                }`}
              >
                Trust Score
              </h2>
              <p
                className={`px-4 py-1 rounded-lg ${
                  exchangeItem.trust_score > 0
                    ? "bg-increase text-mainBg"
                    : "bg-decrease text-mainBg"
                }`}
              >
                {exchangeItem?.trust_score}
              </p>
            </div>
          </div>
          <div
            className={`border-l md:border-l-0 md:border-t w-8/12 md:w-full ${
              currentTheme === "dark" ? "border-darkBorder" : "border-lightBorder"
            }`}
          >
            <div
              className={`border-b px-10 lg:px-6 xs:px-2 py-10 md:py-6 ${
                currentTheme === "dark" ? "border-darkBorder" : "border-lightBorder"
              }`}
            >
              <p className={` ${currentTheme === "dark" ? " text-mainBg" : "text-grey"}`}>
                Trading volume(24h) BTC
              </p>
              <p
                className={`text-[28px] sm:text-[22px] font-semibold ${
                  currentTheme === "dark" ? " text-mainBg" : "text-filter"
                }`}
              >
                ₿{commafy(toDecimals(exchangeItem?.trade_volume_24h_btc))}
              </p>
            </div>
            <div
              className={`p-10 lg:px-6 md:py-6 xs:px-2 ${
                currentTheme === "dark" ? " text-mainBg" : "text-filter"
              }`}
            >
              <p className="font-semibold text-[22px] mb-5 sm:text-[20px]">
                About {exchangeItem?.name}
              </p>
              <p className="text-[15px] sm:text-sm">
                <div class="flex flex-wrap md:block justify-between rounded-[24px] md:p-6 xs:p-4 mb-4 gap-6">
                  <div class="flex justify-between w-[46%] md:w-full items-center md:pb-4">
                    <h2 class="text-lg md:text-base font-semibold">Sequence</h2>
                    <p class="">{exchangeItem?.sequence}</p>
                  </div>
                  <div class="flex justify-between w-[46%] md:w-full items-center md:pb-4">
                    <h2 class="text-lg font-semibold md:text-base">Country</h2>
                    <p class="">{exchangeItem?.country?.slice(0, 13)}</p>
                  </div>
                  <div class="flex flex-wrap justify-between w-[46%] md:w-full items-center md:pb-4">
                    <h2 class="text-lg font-semibold md:text-base">24h Volume</h2>
                    <p className="">
                      ₿
                      {commafy(toDecimals(exchangeItem?.trade_volume_24h_btc_normalized))}
                    </p>
                  </div>
                  <div class="flex justify-between w-[46%] md:w-full items-center md:pb-4">
                    <h2 class="text-lg font-semibold md:text-base">Trust Score Rank</h2>
                    <p>{exchangeItem?.trust_score_rank}</p>
                  </div>
                </div>
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ExchangeDetailCard;
