import React from "react";
import accessDenied from "../../assets/images/access-denied.PNG";
import { useNavigate } from "react-router-dom";
const AccessDenied = () => {
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate(-1);
  };
  return (
    <div class="flex h-full w-full justify-center items-center">
      <div>
        <div class="w-[350px] flex justify-center items-center text-center m-auto">
          <img src={accessDenied} alt="accessDenied" />
        </div>
        <p class="text-lg text-black mb-6">
          The page you're trying to access has restricted access.
        </p>
        <div class="text-center" onClick={handleNavigate}>
          <button class="py-[10px] px-5 bg-primary text-mainBg rounded-[6px]">
            Go Back
          </button>
        </div>
      </div>
    </div>
  );
};

export default AccessDenied;
