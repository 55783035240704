import Equipments from '../assets/images/equipments.svg'
import BnbIcon from "../assets/icons/bnb-icon.svg";
import Algorithm from "../assets/icons/algorithm.svg";
import Hashes from "../assets/icons/hashes.svg";
import Cost from "../assets/icons/cost.svg";
import Usb from "../assets/icons/usb.svg";
import Power from "../assets/icons/power.svg";
import Currencies from "../assets/icons/currencies.svg";
import Ethereum from '../assets/icons/ethereum.svg'
import Polygon from "../assets/images/polygon.png";
import Avalanche from "../assets/images/avalanche.png";
import Graph from "../assets/images/graph.png";
import Aave from "../assets/images/aave.png";
import EthereumImg from '../assets/images/ethereumBanner.jpg'
import AvalancheImg from "../assets/images/avalancheBanner.jpg";
import GraphImg from '../assets/images/graphBanner.jpg'
import AaveImg from '../assets/images/aaveBanner.jpg'
import PolyGonImg from '../assets/images/polygonBanner.jpg'
import BitcoinBanner from '../assets/images/bitcoinBanner.jpg'

// Currencies vs USD Now
export const Currencies_Info = [
{
    id: 1,
    title: 'BTC vs USD Now',
    desc:'Its a match made in heaven: the worlds biggest cryptocurrency and the worlds largest fiat currency. BTC/USD is a major trading pair — and right here, youll find up-to-the-minute information on the latest conversion rates. Ever since Bitcoin launched in 2009, its value has often been conveyed in U.S. dollars. Comparing prices across exchanges helps guarantee youll get the best deal, as some platforms offer a better deal than others. Cryptocurrency adoption in the U.S. continues to rise — and in 2019, the number of people who owned digital assets doubled. With a population of 328.2 million, America is a massive and largely untapped market.'
},
{
    id: 2,
    title: 'SHB vs USD Now',
    desc:'As of my knowledge cutoff date of September 2021, the exchange rate between Shiba Inu (SHIB) and US dollars (USD) was approximately $0.000008 USD per SHIB. However, please note that cryptocurrency prices are highly volatile and subject to frequent fluctuations based on a variety of factors, including market demand, investor sentiment, and regulatory developments. Therefore, the current exchange rate between SHIB and USD may be different from what I have provided.'
},
{
    id: 3,
    title: 'ETH vs USD Now',
    desc:'As of my knowledge cutoff date of September 2021, the exchange rate between Ethereum (ETH) and US dollars (USD) was approximately $3,200 USD per ETH. However, please note that the value of cryptocurrencies such as ETH is highly volatile and subject to frequent fluctuations due to various factors such as market demand, investor sentiment, regulatory changes, and technological advancements. I suggest checking a reputable cryptocurrency exchange or financial news source for the most up-to-date information on the current exchange rate between ETH and USD.'
},
]

// Mining Info
export const Mining_Equipment = [
{
id: 1,
equipmentsImg: BitcoinBanner,
bnbImg: BnbIcon,
IconInfo: '53 TH/S',
title: 'Bitcoin Avalon 1146',
},
{
id: 2,
equipmentsImg: EthereumImg,
bnbImg: Ethereum,
IconInfo: '23 TH/S',
title: 'Ethereum Avalon 1146',
},
{
id: 3,
equipmentsImg: AvalancheImg,
bnbImg: Avalanche,
IconInfo: '34 TH/S',
title: 'Avalanche Avalon 1146',
},
{
id: 4,
equipmentsImg: GraphImg,
bnbImg: Graph,
IconInfo: '45 TH/S',
title: 'Graph Avalon 1146',
},
{
id: 5,
equipmentsImg: AaveImg,
bnbImg: Aave,
IconInfo: '66 TH/S',
title: 'Aave Avalon 1146',
},
{
id: 6,
equipmentsImg: PolyGonImg,
bnbImg: Polygon,
IconInfo: '34 TH/S',
title: 'Polygon Avalon 1146',
},
]
export const Mining_Info = [
{
id: 1,
logo: Algorithm,
logoName: 'Algorithm',
logoInfo: 'SHA-256',
},
{
id: 2,
logo: Hashes,
logoName: 'Hashes',
logoInfo: '70000000',
},
{
id: 3,
logo: Cost,
logoName: 'Cost',
logoInfo: '450 USD',
},
{
id: 4,
logo: Usb,
logoName: 'Type',
logoInfo: 'USB',
},
{
id: 5,
logo: Power,
logoName: 'Power',
logoInfo: '7',
},
{
id: 6,
logo: Currencies,
logoName: 'Currencies',
logoInfo: 'Bitcoin',
},
]