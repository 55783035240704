import React from "react";
import { selectCurrentTheme } from "../../store/slices/themeSlice";
import { useSelector } from "react-redux";

const Policy = () => {
  const currentTheme = useSelector(selectCurrentTheme);
  return (
    <div className="px-24 md:px-8 xs:px-4 py-7 mt-12 md:mt-10">
      <div
        className={`rounded-[28px] shadow-navbar-button py-8 px-12 md:px-8 xs:px-4 ${
          currentTheme === "dark" ? "bg-cardsColor" : "bg-mainBg"
        }`}
      >
        <h2
          className={`text-3xl sm:text-2xl font-bold mb-6 sm:mb-3 ${
            currentTheme === "dark" ? " text-mainBg" : "text-filter"
          }`}
        >
          Privacy Policy
        </h2>
        <p
          className={`mb-6 ${currentTheme === "dark" ? " text-mainBg" : "text-gray-700"}`}
        >
          Welcome to Coin Tracking Pro! This Privacy Policy is designed to help you
          understand how we collect, use, and safeguard your personal information. By
          using our website (<span className="text-primary">www.cointrackingpro.com</span>
          ) and related services (the "Service"), you agree to the terms outlined in this
          policy.
        </p>

        <h2
          className={`text-lg font-bold mb-2 ${
            currentTheme === "dark" ? " text-mainBg" : "text-filter"
          }`}
        >
          Information We Collect
        </h2>

        <h3
          className={`text-lg font-bold mb-2 ${
            currentTheme === "dark" ? " text-mainBg" : "text-filter"
          }`}
        >
          Personal Information
        </h3>
        <p
          className={`mb-6 ${currentTheme === "dark" ? " text-mainBg" : "text-gray-700"}`}
        >
          We may collect personal information, including but not limited to your name,
          email address, and contact details when you register an account with Coin
          Tracking Pro or subscribe to our newsletter.
        </p>

        <h3
          className={`text-lg font-bold mb-2 ${
            currentTheme === "dark" ? " text-mainBg" : "text-filter"
          }`}
        >
          Usage Data
        </h3>
        <p className={`mb-6 ${currentTheme === "dark" ? " text-mainBg" : "text-filter"}`}>
          We automatically collect information on how you interact with the Service. This
          may include your device's IP address, browser type, pages visited, and the time
          spent on those pages.
        </p>

        <h2
          className={`text-lg font-bold mb-2 ${
            currentTheme === "dark" ? " text-mainBg" : "text-filter"
          }`}
        >
          How We Use Your Information
        </h2>
        <p
          className={`mb-6 ${currentTheme === "dark" ? " text-mainBg" : "text-gray-700"}`}
        >
          We use the collected information for various purposes, including:
        </p>
        <ul
          className={`list-disc ml-6 mb-6 ${
            currentTheme === "dark" ? " text-mainBg" : "text-gray-700 "
          }`}
        >
          <li>
            Providing and maintaining the Service: To deliver, secure, and improve our
            services to you.
          </li>
          <li>
            Communication: To contact you regarding updates, changes, or informative
            content related to our services.
          </li>
          <li>
            Analysis: To understand how users interact with our Service, allowing us to
            enhance user experience and improve our offerings.
          </li>
        </ul>

        <h2
          className={`text-lg font-bold mb-2 ${
            currentTheme === "dark" ? " text-mainBg" : "text-filter"
          }`}
        >
          Data Security
        </h2>
        <p
          className={`mb-6 ${currentTheme === "dark" ? " text-mainBg" : "text-gray-700"}`}
        >
          We prioritize the security of your data and employ industry-standard measures to
          protect it from unauthorized access, disclosure, alteration, or destruction.
        </p>

        <h2
          className={`text-lg font-bold mb-2 ${
            currentTheme === "dark" ? " text-mainBg" : "text-filter"
          }`}
        >
          Sharing Your Information
        </h2>
        <p
          className={`mb-6 ${currentTheme === "dark" ? " text-mainBg" : "text-gray-700"}`}
        >
          Coin Tracking Pro does not sell or rent your personal information to third
          parties. We may share your data with trusted third-party service providers for
          specific purposes, such as analytics or communication services, but only to the
          extent necessary to support our services.
        </p>

        <h2
          className={`text-lg font-bold mb-2 ${
            currentTheme === "dark" ? " text-mainBg" : "text-filter"
          }`}
        >
          Cookies and Tracking Technologies
        </h2>
        <p
          className={`mb-6 ${currentTheme === "dark" ? " text-mainBg" : "text-gray-700"}`}
        >
          We use cookies and similar tracking technologies to enhance your experience on
          our website. You can modify your browser settings to control the use of cookies,
          but be aware that certain features of the Service may be affected.
        </p>

        <h2
          className={`text-lg font-bold mb-2 ${
            currentTheme === "dark" ? " text-mainBg" : "text-filter"
          }`}
        >
          Your Choices
        </h2>
        <p
          className={`mb-6 ${currentTheme === "dark" ? " text-mainBg" : "text-gray-700"}`}
        >
          You have the right to review, update, or delete your personal information. If
          you have any questions or concerns about your data, please contact us at{" "}
          <a href="mailto:contact@cointrackingpro.com" className="text-primary">
            contact@cointrackingpro.com
          </a>
          .
        </p>

        <h2
          className={`text-lg font-bold mb-2 ${
            currentTheme === "dark" ? " text-mainBg" : "text-filter"
          }`}
        >
          Changes to This Privacy Policy
        </h2>
        <p
          className={`mb-6 ${currentTheme === "dark" ? " text-mainBg" : "text-gray-700"}`}
        >
          We may update our Privacy Policy from time to time, and any changes will be
          effective when posted on this page. We encourage you to review this page
          periodically for the latest information.
        </p>
      </div>
    </div>
  );
};

export default Policy;
