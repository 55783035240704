import React from "react";
import { More_Info_Data } from "../../data/moreInfoData";

const MoreInfo = () => {
  return (
    <div className="shadow-navbar-button mt-10 sm:mt-6 rounded-[26px]">
      <div className="px-11 pt-7 pb-2">
        <h1 className="text-2xl font-bold sm:text-sm">Team</h1>
        <p className="text-lg font-medium sm:text-sm">Individuals</p>
      </div>
      <div className="pt-5 md:pt-3">
        <div className="flex flex-wrap border-t border-darkBorder items-center">
          {More_Info_Data.map((items) => {
            const { userImg, name, ceo } = items;
            return (
              <div
                className="w-1/3 xl:w-3/6 md:w-full px-10 py-5 sm:py-4 border-t border-r"
                key={items.id}
              >
                <div className="flex items-center gap-5">
                  <img src={userImg} alt="logo" className="h-10 w-10 rounded-full" />
                  <div>
                    <div className="font-medium text-lg sm:text-sm">{name}</div>
                    <div className="text-grey text-sm sm:text-xs">{ceo}</div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default MoreInfo;
