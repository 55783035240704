import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { NAVBAR_DATA } from "../../data/navbarMenuData";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { useDispatch } from "react-redux";
import { closeSidebarNavHandler } from "../../store/slices/sidebarSlice";
import { openLogin, openRegister } from "../../store/slices/popupSlice";
import { selectCurrentTheme } from "../../store/slices/themeSlice";
import { useSelector } from "react-redux";
import ThemeToggle from "../../components/ThemeToggle/ThemeToggle";
import Avatar from "../../assets/images/avatar.png";

const SidebarNav = ({ handleLogout, logoutQuery }) => {
  const [clickedId, setClickedId] = useState();
  const [active, setActive] = useState();
  const currentTheme = useSelector(selectCurrentTheme);
  const dropdownHandler = item => {
    if (item.dropDown) {
      setClickedId(item.id);
      setActive(!active);
    }
  };
  const token = useSelector(state => state.auth.token);
  const user = useSelector(state => state.auth);
  const dispatch = useDispatch();
  return (
    <div className={`${currentTheme === "dark" ? "bg-cardsColor" : "bg-mainBg"}`}>
      <div className="flex flex-col flex-nowrap mb-2">
        {NAVBAR_DATA.map((item, i) => (
          <div>
            <div
              className={`border-b px-7 ${
                currentTheme === "dark" ? "border-darkBorder" : "border-lightBorder"
              }`}
            >
              <NavLink
                key={item.id}
                to={item.page}
                className={({ isActive }) =>
                  `flex items-center gap-2 xs:px-0 py-4 text-primaryText py-2${
                    isActive ? "bg-primary" : "bg-mainBg"
                  } `
                }
                onClick={() => {
                  dropdownHandler(item);
                  item.page && dispatch(closeSidebarNavHandler());
                }}
              >
                <img
                  className="w-[20px] sm:w-[18px]"
                  //   src={
                  //     location.pathname === item.page ? item.iconAcive : item.icon
                  //   }
                  src={item.icon}
                  alt={item.name}
                />
                <div className={"flex justify-between w-full items-center"}>
                  <span className="text-[15px] sm:text-sm">{item.name}</span>
                  {item.dropDown && (
                    <FontAwesomeIcon icon={faCaretDown} className="text-sm" />
                  )}
                </div>
              </NavLink>
              {item.dropDown && (
                <div
                  className={`px-4 text-sm ${
                    clickedId === item.id && active ? "max-h-auto" : "max-h-0"
                  }  overflow-hidden duration-150`}
                >
                  {item.dropDownLinks.map(items => {
                    return (
                      <Link
                        to={items.page}
                        key={items.id}
                        className="block py-2 cursor-pointer"
                        onClick={() => {
                          items.page && dispatch(closeSidebarNavHandler());
                        }}
                      >
                        <p
                          className={({ isActive }) =>
                            ` rounded-full px-6 py-2 shadow-navbar-button ${
                              isActive ? "bg-primary" : "bg-mainBg"
                            }`
                          }
                        >
                          {items.linkName}
                        </p>
                      </Link>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        ))}
          <div className="py-5 flex justify-center items-center">
            <ThemeToggle />
          </div>
        <div className="px-7">
          {!token ? (
            <div className="flex items-center gap-2 text-base justify-center">
              <button
                className="w-[100px] h-10 border-[1px] border-primary rounded-full"
                onClick={() => {
                  dispatch(openLogin());
                  dispatch(closeSidebarNavHandler());
                }}
              >
                <span
                  className={`${
                    currentTheme === "dark" ? " text-mainBg" : "text-filter"
                  }`}
                >
                  Login
                </span>
              </button>
              <button
                className="w-[90px] h-10 border-[1px] border-primary rounded-full bg-primary"
                onClick={() => {
                  dispatch(openRegister());
                  dispatch(closeSidebarNavHandler());
                }}
              >
                <span>Sign Up</span>
              </button>
            </div>
          ) : (
            <>
              <div
                className={`flex items-center gap-3 ${
                  currentTheme === "dark" ? "bg-cardsColor" : "main-bg"
                }`}
              >
                <button className="w-full">
                  <div className="flex items-center">
                    <div className="flex items-center gap-2">
                      <div className="h-8 w-8">
                        <img src={Avatar} alt="avatar" />
                      </div>
                      <div className="">
                        <p
                          className={`font-semibold w-[100px] overflow-hidden overflow-ellipsis whitespace-nowrap ${
                            currentTheme === "dark" ? "text-mainBg" : "text-filter"
                          }`}
                        >
                          Hi, {user.name}fjriojfioerfjroeif
                        </p>
                      </div>
                    </div>
                  </div>
                </button>
                  <div className="flex justify-center">
          <button
            className="w-[90px] h-10 border-[1px] border-primary rounded-full bg-primary disabled:bg-disabledButton"
            onClick={handleLogout}
            disabled={logoutQuery.isLoading}
          >
            <span>Logout</span>
          </button>
        </div>
              </div>
            </>
          )}
        </div>
      
      </div>
    </div>
  );
};

export default SidebarNav;
