import { createSlice } from "@reduxjs/toolkit";
import { getCurrentTime } from "sassy-datepicker";

// initial state
const initialState = {
  selectedCoin: {},
  date: new Date(),
  time: getCurrentTime(),
};

const profileTransactionSlice = createSlice({
  name: "profileTransaction",
  initialState,
  reducers: {
    dateHandler: (state, action) => {
      state.date = action.payload;
    },
    timeHandler: (state, action) => {
      state.time = action.payload;
    },
    selectCoinHandler: (state, action) => {
      state.selectedCoin = action.payload;
    },
    resetDate: (state) => {
      state.date = initialState.date;
      state.time = initialState.time;
    },
  },
});

export const { dateHandler, timeHandler, selectCoinHandler, resetDate } =
  profileTransactionSlice.actions;
export default profileTransactionSlice.reducer;
