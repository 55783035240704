import React from "react";
import { useGetPortfolioByUserQuery } from "../../store/services/services";
import { useSelector } from "react-redux";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { selectCurrentTheme } from "../../store/slices/themeSlice";
const PortfolioAllocation = () => {
  const colors_data = [
    "#d1cb1f",
    "#51d11f",
    "#fa0c0c",
    "#0cad62",
    "#0c72ad",
    "#090fb8",
    "#6f09b8",
    "#fa0cfa",
    "#fa0c5f",
    "#d16ef0"
  ];
  const userId = useSelector(state => state.auth.userId);
  const { data, isLoading, isError, error } = useGetPortfolioByUserQuery(userId);
  const currentTheme = useSelector(selectCurrentTheme);
  return (
    <div className="px-24 sm:px-8 xs:px-4 mt-12">
      {isLoading && (
        <SkeletonTheme
          baseColor={`${currentTheme === "dark" ? "#323546" : "#d8d8d8"}`}
          highlightColor={`${currentTheme === "dark" ? "#444" : "#e0e0e0"}`}
        >
          <Skeleton width="100%" height={40} count={1} />
        </SkeletonTheme>
      )}
      {isError && <p>{error.message}</p>}
      {!isLoading && !isError && (
        <>
          {data?.coinCounts?.data && data?.coinCounts?.data.length > 0 && (
            <div
              className={` p-2 border flex border-primary rounded-[6px] ${
                currentTheme === "dark" ? "bg-cardsColor" : "bg-whiteCardsColor"
              }`}
            >
              {data?.coinCounts?.data.map((item, i) => (
                <div className="px-[2px]" style={{ width: item?.percentage + "%" }}>
                  <div
                    className="h-6 rounded-[3px]"
                    style={{ background: colors_data[i] }}
                  ></div>
                </div>
              ))}
            </div>
          )}

          <div className="flex flex-wrap gap-8">
            {data?.coinCounts?.data > 0 &&
              data?.coinCounts?.data.map((item, i) => (
                <div className="flex gap-2 items-center mt-3" key={i}>
                  <span
                    className="h-3 w-3 block"
                    style={{ background: colors_data[i] }}
                  ></span>
                  <span
                    className={`font-semibold ${
                      currentTheme === "dark" ? " text-mainBg" : "text-filter"
                    }`}
                  >
                    {item?.coin}
                  </span>
                  <span
                    className={` ${
                      currentTheme === "dark" ? " text-mainBg" : "text-lightGray"
                    }`}
                  >
                    {item?.percentage}%
                  </span>
                </div>
              ))}
          </div>
        </>
      )}
    </div>
  );
};

export default PortfolioAllocation;
