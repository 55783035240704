import React from "react";
import CurrencyConvertor from "../CurrencyConvertor";
import LatestRates from "../LatestRates";

const ToolsCurrencyConvertor = () => {
  return (
    <div className="px-12 md:px-8 xs:px-4 mt-10">
      <CurrencyConvertor />
      <LatestRates />
    </div>
  );
};
export default ToolsCurrencyConvertor;
