import React from 'react'
import Modal from "../../utils/Modal";
import { selectCurrentTheme } from "../../store/slices/themeSlice";
import { useSelector,useDispatch } from 'react-redux';
import { faCamera, faPencil } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { closeUserProfileView } from "../../store/slices/popupSlice";

const ProfileEditPopup = ({ handleProfilePhotoChange, profilePhoto }) => {
    const dispatch = useDispatch()
  const currentTheme = useSelector(selectCurrentTheme);
  return (
    <Modal maxWidth={600}>
      <div className="p-7 xs:px-4">
        <div className="mb-6">
          <p
            className={`text-[22px] xs:text-xl font-semibold mb-2  ${
              currentTheme === "dark" ? " text-mainBg" : "text-filter"
            }`}
          >
            Edit My Profile
          </p>
          <p
            className={`text-lg pb-2 ${
              currentTheme === "dark" ? " text-mainBg" : "text-filter"
            }`}
          >
            Your Avatar
          </p>
          <div
            className={`flex items-center gap-3 ${
              currentTheme === "dark" ? "bg-cardsColor" : "bg-mainBg"
            }`}
          >
            <input
              type="file"
              id="profilePhoto"
              accept="image/*"
              onChange={handleProfilePhotoChange}
              className="hidden"
            />
            <label
              htmlFor="profilePhoto"
              className="cursor-pointer block w-32 h-32 md:w-48 md:h-48 border border-inputBorder rounded-full overflow-hidden mb-4 sm:mx-0"
            >
              {profilePhoto ? (
                <img
                  src={URL.createObjectURL(profilePhoto)}
                  alt="Profile Photo"
                  className={`w-full h-full object-cover ${
                    currentTheme === "dark" ? "bg-cardsColor" : "bg-mainBg"
                  }`}
                />
              ) : (
                <div className="flex items-center justify-center h-full">
                  <span className="text-gray-500">
                    <FontAwesomeIcon icon={faCamera} className="text-gray-500 text-2xl" />
                  </span>
                </div>
              )}
            </label>
            <div className="bg-primary text-filter flex justify-center items-center px-4 py-1 rounded-lg cursor-pointer">
              <h2
                className={`text-xl font-bold  ${
                  currentTheme === "dark" ? " text-mainBg" : "text-filter"
                }`}
              >
                Edit Avatar
              </h2>
            </div>
          </div>
          <div>
            <div className="w-full mb-3">
              <label
                className={`font-medium  ${
                  currentTheme === "dark" ? " text-mainBg" : "text-secondary"
                }`}
              >
                Display Name
              </label>
              <div className="flex items-center border-2 mt-3 border-inputBorder h-[45px] sm:h-[30px] rounded-[10px] px-3 py-1 gap-3 focus-within:border-primary transition-all duration-200">
                <input
                  className={`border-0 outline-0 overflow-hidden bg-transparent text-base w-full lg:w-28 ${
                    currentTheme === "dark"
                      ? "bg-inputColor text-mainBg"
                      : "bg-mainBg text-filter"
                  }`}
                  type="text"
                  placeholder="name"
                />
              </div>
              <div className="w-full">
                <label
                  className={`font-medium  ${
                    currentTheme === "dark" ? " text-mainBg" : "text-secondary"
                  }`}
                >
                  Email
                </label>
                <div className="flex items-center border-2 mt-3 border-inputBorder h-[45px] sm:h-[30px] rounded-[10px] px-3 py-1 gap-3 focus-within:border-primary transition-all duration-200">
                  <input
                    className={`border-0 outline-0 overflow-hidden bg-transparent text-base w-full lg:w-28 ${
                      currentTheme === "dark"
                        ? "bg-inputColor text-mainBg"
                        : "bg-mainBg text-filter"
                    }`}
                    type="text"
                    placeholder="email"
                  />
                </div>
              </div>
            </div>
          </div>
          <button
            className={`bg-primary w-full p-3 rounded-[7px] text-primaryText font-medium text-lg mb-2 disabled:bg-disabledButton `}
          >
            Save
          </button>
          <button
            className={`w-full text-center py-2 rounded-[6px] font-semibold ${
              currentTheme === "dark"
                ? "bg-inputColor text-mainBg"
                : "bg-gray-200 text-filter"
            }`}
            onClick={() => dispatch(closeUserProfileView())}
          >
            Cancel
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ProfileEditPopup