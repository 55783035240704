import React, { useEffect, useState } from "react";
import CurrencyTable from "../CurrencyTable";
import { selectCurrentTheme } from "../../store/slices/themeSlice";
import { useDispatch, useSelector } from "react-redux";
import { resetWatchlist } from "../../store/slices/watchlistSlice";
import WatchListLocalTablePopup from "../WatchlistLocalTablePopup/WatchlistLocalTablePopup";
import {
  openRemoveLocalStorageWatchList,
  closeRemoveLocalStorageWatchList
} from "../../store/slices/popupSlice";

const WatchListLocalTable = () => {
  const [currentPage, setCurrentPage] = React.useState(1);
  const [watchlistCoins, setWatchlistCoins] = useState([]);
  const dispatch = useDispatch();
  const currentTheme = useSelector(selectCurrentTheme);
  const watchListLocalStoragePopup = useSelector(
    state => state.popup.removeLocalStorageWatchList
  );
  useEffect(() => {
    window.scrollTo(0, 0);
    const watchlistFromLocalStorage = localStorage.getItem("watchlist") || "[]";
    const coins = JSON.parse(watchlistFromLocalStorage).filter(item => item !== null);
    setWatchlistCoins(coins);
  }, []);
  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, []);
  // const watchlistFromLocalStorage = localStorage.getItem("watchlist") || "[]";
  // const coin = JSON.parse(watchlistFromLocalStorage).filter(
  //   item => item !== null
  // );

  // Clear all coins from Redux state and local storage
  const handleAllFiltersSelected = () => {
    localStorage.removeItem("watchlist");
    dispatch(resetWatchlist());
    setWatchlistCoins([]);
    dispatch(closeRemoveLocalStorageWatchList());
  };
  return (
    <div className="shadow-header-shadow px-4 pt-4 rounded-[20px]">
      <div className="flex justify-end pb-5">
        <div
          className={`rounded-[14px] px-3 py-2 cursor-pointer ${
            currentTheme === "dark"
              ? "bg-cardsColor text-mainBg"
              : "bg-coinTagsBg text-filter"
          }`}
          // onClick={handleAllFiltersSelected}
          onClick={() => dispatch(openRemoveLocalStorageWatchList())}
        >
          Clear Watchlist
        </div>
      </div>
      <CurrencyTable
        data={watchlistCoins}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
      {watchListLocalStoragePopup && (
        <WatchListLocalTablePopup handleAllFiltersSelected={handleAllFiltersSelected} />
      )}
    </div>
  );
};

export default WatchListLocalTable;
