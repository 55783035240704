import React from "react";
import { useGetCoinGainersQuery } from "../../store/services/services";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { commafy } from "../../utils/commafy";
import { toDecimals } from "../../utils/toDecimals";
import IncreaseChangeIcon from "../../assets/icons/increase-change.svg";
import decreaseChangeIcon from "../../assets/icons/decrease-change.svg";
import { selectCurrentTheme } from "../../store/slices/themeSlice";
import { useSelector } from "react-redux";
import CryptoIcon from "../../assets/icons/crypto-icon.svg";

const CurrencyInfo = () => {
  const { data, isLoading, isError, error } = useGetCoinGainersQuery();
  const currentTheme = useSelector(selectCurrentTheme);
  return (
    <div>
      {isLoading && (
        <div className="w-full">
          <SkeletonTheme
            baseColor={`${currentTheme === "dark" ? "#323546" : "#d8d8d8"}`}
            highlightColor={`${currentTheme === "dark" ? "#444" : "#e0e0e0"}`}
          >
            <Skeleton width="100%" height={50} count={1} />
          </SkeletonTheme>
        </div>
      )}
      {isError && <p>{error.message}</p>}
      {!isLoading && !isError && (
        <div
          className={`mt-14 sm:mt-6 px-10 py-5 flex shadow-header-shadow rounded-[13px] overflow-x-auto scrollbar ${
            currentTheme === "dark" ? "bg-cardsColor" : "bg-mainBg"
          }`}
        >
          {data?.data?.slice(0, 8).map(items => {
            return (
              <div
                key={items.id}
                className={`flex justify-between min-w-[250px] flex-nowrap items-center gap-4 border-r-[2px] mr-4 ${
                  currentTheme === "dark" ? "border-darkBorder" : "border-lightBorder"
                }`}
              >
                <div className="flex items-center gap-3">
                  <img
                    src={items?.img ? items?.img : CryptoIcon}
                    alt="bnb-icon"
                    className="h-6"
                  />
                  <div>
                    <h3
                      className={`text-xs font-semibold ${
                        currentTheme === "dark" ? " text-mainBg" : "text-filter"
                      }`}
                    >
                      {items?.name}
                    </h3>
                    <p className="text-sm font-normal text-opacityColor">{items?.logo}</p>
                  </div>
                </div>
                <div className="text-center mx-2 text-sm">
                  <p
                    className={`${
                      currentTheme === "dark" ? " text-mainBg" : "text-filter"
                    }`}
                  >
                    ${commafy(toDecimals(items?.price))}
                  </p>
                  <div className="flex items-center gap-1">
                    <img
                      className="sm:w-[6px]"
                      src={
                        items?.percentChangeDay > 0
                          ? IncreaseChangeIcon
                          : decreaseChangeIcon
                      }
                      alt={
                        items?.percentChangeDay > 0 ? "increase-icon" : "decrease-icon"
                      }
                    />
                    <p
                      className={` ${
                        items?.percentChangeDay > 0 ? "text-increase" : "text-decrease"
                      }`}
                    >
                      {commafy(toDecimals(items?.percentChangeDay))}%
                    </p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default CurrencyInfo;
