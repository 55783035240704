import React, { useEffect, useState } from "react";
import DatePicker, { TimePicker } from "sassy-datepicker";
import "../../../node_modules/sassy-datepicker/dist/styles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import {
  dateHandler,
  timeHandler,
} from "../../store/slices/profileTransactionSlice";
import { selectCurrentTheme } from "../../store/slices/themeSlice";

const PortfolioCalendarPopup = ({ onBack, transaction }) => {
  const time = useSelector(state => state.profileTransaction.time);
  const date = useSelector(state => state.profileTransaction.date);
  const [timeState, setTimeState] = useState(time);
  const [dateState, setDateState] = useState(date);

  const dispatch = useDispatch();
  const currentTheme = useSelector(selectCurrentTheme);
  const onChangeDate = newDate => {
    setDateState(newDate);
  };

  const onChangeTime = newTime => {
    setTimeState(newTime);
  };

  const addTimeHandler = () => {
    dispatch(timeHandler(timeState));
    dispatch(dateHandler(dateState));
    onBack();
  };

  useEffect(() => {
    setTimeState(time);
    setDateState(date);
  }, [date, time, transaction]);
  return (
    <div className="px-7 py-4 xs:px-4">
      <div className="flex items-center gap-3 mb-6 ">
        <FontAwesomeIcon
          className={`cursor-pointer ${
            currentTheme === "dark" ? "text-mainBg" : "text-filter"
          }`}
          icon={faChevronLeft}
          onClick={onBack}
        />
        <p
          className={`text-[22px] xs:text-xl font-semibold  ${
            currentTheme === "dark" ? "text-mainBg" : "text-secondary"
          }`}
        >
          Add Time
        </p>
      </div>
      <div
        className={`flex sm:flex-col-reverse sm:items-center  justify-center gap-8 mb-10 ${
          currentTheme === "dark" ? "darkDatePicker " : "lightDatePicker"
        }`}
      >
        <DatePicker className="" onChange={onChangeDate} value={dateState} />
        <TimePicker
          className="h-full"
          onChange={onChangeTime}
          value={timeState}
          minutesInterval={1}
        />
      </div>
      <div>
        <button
          className="w-full text-center bg-primary py-2 rounded-[6px] text-popupText font-semibold"
          onClick={addTimeHandler}
        >
          Add Time
        </button>
      </div>
    </div>
  );
};

export default PortfolioCalendarPopup;
