import { useDispatch } from "react-redux";
import { openRegister } from "../../store/slices/popupSlice";

const ProtectedRoute = ({ isLogin, children }) => {
  const dispatch = useDispatch();

  if (isLogin) {
    return children;
  } else {
    dispatch(openRegister());
  }
};

export default ProtectedRoute;
