import React, { useEffect, useState } from "react";
import Modal from "../../utils/Modal";
import { Select } from "antd";

import CalendarIcon from "../../assets/icons/calendar-icon.svg";
import DarkCalendarIcon from "../../assets/icons/dark-calendar-icon.svg";
import CoinIcon from "../../assets/icons/coin-grey.svg";
import DarkCoinIcon from "../../assets/icons/dark-coin-grey.svg";
import NotesIcon from "../../assets/icons/notes.svg";
import DarkNotesIcon from "../../assets/icons/dark-notes.svg";
import { useDispatch, useSelector } from "react-redux";
import { closeModal, closeTransaction } from "../../store/slices/popupSlice";
import PortfolioFeePopup from "../PortfolioFeePopup";
import PortfolioNotesPopup from "../PortfolioNotesPopup/PortfolioNotesPopup";
import PortfolioCalendarPopup from "../PortfolioCalendarPopup";
import { resetDate, selectCoinHandler } from "../../store/slices/profileTransactionSlice";
import {
  useGetCoinMarketQuery,
  usePostTransactionMutation
} from "../../store/services/services";
import { portfolioChange } from "../../store/slices/portfolioChangeSlice";
import { selectCurrentTheme } from "../../store/slices/themeSlice";

const PortfolioTransactionPopup = ({ activePortfolioId }) => {
  //////////////////////// states ////////////////////////
  const [transactionType, setTransactionType] = useState("buy");
  const [feeIsVisible, setFeeIsVisible] = useState(false);
  const [notesIsVisible, setNotesIsVisible] = useState(false);
  const [calendarIsVisible, setCalendarIsVisible] = useState(false);
  const [enteredFee, setEnteredFee] = useState("");
  const [enteredNote, setEnteredNote] = useState(null);
  const selectedCoin = useSelector(state => state.profileTransaction.selectedCoin);
  const userId = useSelector(state => state.auth.userId);
  const [enterdCoinValue, setEnterdCoinValue] = useState(selectedCoin.price);
  const [transferType, setTransferType] = useState("transferIn");
  const [coinValueIsValid, setCoinValueIsValid] = useState(true);
  const [enteredCoinQuantity, setEnteredCoinQuantity] = useState();
  const [coinQuantityIsValid, setCoinQuantityIsValid] = useState(false);
  //////////////////////// states ////////////////////////
  const dispatch = useDispatch();

  const [postTransaction, { isSuccess, isLoading, error }] = usePostTransactionMutation();

  const { data: getCoins, isLoading: getCoinsIsLoading } = useGetCoinMarketQuery();
  const currentTheme = useSelector(selectCurrentTheme);
  //////////////////////// time and data ////////////////////////
  const time = useSelector(state => state.profileTransaction.time);
  const date = useSelector(state => state.profileTransaction.date);
  const pm = time.hours > 12 ? true : false;

  const timeFiltered = `${(pm ? time.hours - 12 : time.hours)
    .toString()
    .padStart(2, "0")} : ${time.minutes.toString().padStart(2, "0")} ${pm ? "PM" : "AM"}`;
  const dateFiltered = date.toLocaleString("default", {
    day: "2-digit",
    month: "long",
    year: "numeric"
  });
  //////////////////////// time and data ////////////////////////

  //////////////////////// fee and note from popup ////////////////////////
  const feeHandler = fee => {
    setEnteredFee(fee);
  };
  const noteHandler = note => {
    setEnteredNote(note);
  };
  //////////////////////// fee and note from popup ////////////////////////

  // coin select handler
  const optionSelectCoinHandler = value => {
    const coin = getCoins?.data.find(item => item.logo === value);
    dispatch(selectCoinHandler(coin));
    setEnterdCoinValue(coin ? coin.price : "");
  };

  const coinValueChangeHanlder = e => {
    setEnterdCoinValue(e.target.value);

    enterdCoinValue !== "" && enterdCoinValue > 0
      ? setCoinValueIsValid(true)
      : setCoinValueIsValid(false);
  };
  const coinQuantityChangeHandler = e => {
    let inputValue = e.target.value;
    if (isNaN(inputValue)) {
      inputValue = "";
    } else {
      inputValue = inputValue.slice(0, 6);
    }

    setEnteredCoinQuantity(inputValue);
    setCoinQuantityIsValid(!isNaN(inputValue) && parseFloat(inputValue) >= 0);
  };

  const totalAmount =
    enterdCoinValue * (enteredCoinQuantity ? enteredCoinQuantity : 0) +
    (enteredFee ? +enteredFee : 0);

  const transferTypeHandler = value => {
    setTransferType(value);
  };

  const formData = {
    user_id: userId,
    portfolio_id: activePortfolioId,
    coin: selectedCoin.logo,
    quantity: enteredCoinQuantity,
    price: enterdCoinValue,
    type: transactionType,
    fee: enteredFee,
    note: enteredNote,
    transfer_type: transactionType === "transfer" ? transferType : null,
    date,
    time: JSON.stringify(time)
  };

  const transactionMenu = [
    {
      name: "Buy",
      value: "buy"
    },
    {
      name: "Sell",
      value: "sell"
    }
    // {
    //   name: "Transfer",
    //   value: "transfer",
    // },
  ];

  useEffect(() => {
    setEnteredCoinQuantity("");
    setEnteredNote("");
    setEnteredFee(null);
    const formattedPrice = parseFloat(selectedCoin.price).toFixed(9);
    setEnterdCoinValue(formattedPrice);

    dispatch(resetDate());
  }, [dispatch, selectedCoin.price, transactionType]);

  const addTransactionHandler = async () => {
    if (coinValueIsValid && coinQuantityIsValid) {
      await postTransaction({
        formData
      });
    } else {
      alert("enter valid data");
    }
  };
  useEffect(() => {
    if (isSuccess) {
      dispatch(closeModal());
      dispatch(portfolioChange());
      dispatch(closeTransaction());
    } else {
    }
  }, [isSuccess, error, dispatch]);

  return (
    <Modal maxWidth={600} closeable={false}>
      {!feeIsVisible && !notesIsVisible && !calendarIsVisible && (
        <div className="p-7 xs:px-4 relative">
          <div
            className={`mb-4 pt-8 pb-3 px-10 xs:px-4 fixed  z-10 top-0 left-0 w-full ${
              currentTheme === "dark" ? "bg-cardsColor" : "bg-mainBg"
            }`}
          >
            <div className="w-[80%]">
              <p
                className={`text-[22px] xs:text-lg sm:text-xl font-semibold ${
                  currentTheme === "dark" ? "text-mainBg" : "text-filter"
                }`}
              >
                Add Transaction
              </p>
            </div>
          </div>
          <div className="overflow-auto mb-4 mt-12 py-1">
            <div
              className={`flex justify-between text-lg gap-4 py-2 px-6 font-medium rounded-[8px] overflow-auto mb-4 ${
                currentTheme === "dark" ? "bg-filter" : "bg-socialMediaBg"
              }`}
            >
              {transactionMenu.map(item => (
                <div
                  key={item.id}
                  className={`${
                    transactionType === item.value
                      ? "bg-primary"
                      : currentTheme === "dark"
                      ? "bg-cardsColor text-mainBg"
                      : "bg-mainBg"
                  } w-[32%] text-center py-2 rounded-full cursor-pointer min-w-[100px]`}
                  onClick={() => setTransactionType(item.value)}
                >
                  <p>{item.name}</p>
                </div>
              ))}
            </div>
          </div>
          <div className={`${currentTheme === "dark" ? "darkSelect" : "lightSelect"}`}>
            <Select
              size="large"
              className={`w-full ${
                currentTheme === "dark" ? "dropdown-text-dark" : "dropdown-text-light"
              }`}
              defaultValue={`${selectedCoin.name} ${selectedCoin.logo}`}
              options={
                !getCoinsIsLoading &&
                getCoins?.data?.map(item => ({
                  value: item.logo,
                  label: `${item.name}   ${item.logo}`
                }))
              }
              onChange={optionSelectCoinHandler}
              dropdownStyle={{
                backgroundColor: currentTheme === "dark" ? " #222531 " : "#fff",
                color: currentTheme === "dark" ? "#fff !important" : "#000 !important"
              }}
            />
          </div>
          {transactionType === "transfer" && (
            <div className="mt-3">
              <p className="font-medium text-secondary">Transfer</p>
              <Select
                size="large"
                className="w-full"
                defaultValue="Transfer In"
                onChange={transferTypeHandler}
                options={[
                  { value: "transferIn", label: "Transfer In" },
                  { value: "transferOut", label: "Transfer Out" }
                ]}
              />
            </div>
          )}
          <div className="flex flex-wrap gap-y-2 justify-between mt-3 mb-3">
            <div
              className={`w-[49%] ${
                transactionType === "transfer" ? "w-full" : "w-[49%]"
              } xs:w-full`}
            >
              <label
                htmlFor="quantiy"
                className={`font-medium ${
                  currentTheme === "dark" ? " text-mainBg" : "text-secondary"
                }`}
              >
                Quantity
              </label>
              <div
                className={`w-full border border-lightBorder p-2 rounded-[7px] mt-1 focus-within:border-primary ${
                  currentTheme === "dark"
                    ? "bg-inputColor text-mainBg"
                    : "bg-mainBg text-filter"
                }`}
              >
                <input
                  className={`outline-none w-full no-arrows ${
                    currentTheme === "dark"
                      ? "bg-inputColor text-mainBg"
                      : "bg-mainBg text-filter"
                  }`}
                  type="number"
                  id="quantity"
                  placeholder="0.00"
                  value={enteredCoinQuantity}
                  min="0"
                  onChange={coinQuantityChangeHandler}
                />
              </div>
            </div>
            {transactionType !== "transfer" && (
              <div className="w-[49%] xs:w-full">
                <label
                  htmlFor="price"
                  className={`font-medium ${
                    currentTheme === "dark" ? " text-mainBg" : "text-secondary"
                  } `}
                >
                  Price Per Coin
                </label>
                <div
                  className={`w-full flex border border-lightBorder p-2 rounded-[7px] mt-1 focus-within:border-primary ${
                    currentTheme === "dark"
                      ? "bg-inputColor text-mainBg"
                      : "bg-mainBg text-filter"
                  }`}
                >
                  <span>$</span>
                  <input
                    className={`outline-none w-full no-arrows ${
                      currentTheme === "dark"
                        ? "bg-inputColor text-mainBg"
                        : "bg-mainBg text-filter"
                    }`}
                    type="number"
                    id="price"
                    min="0"
                    max="5"
                    placeholder="0.00"
                    value={enterdCoinValue}
                    onChange={coinValueChangeHanlder}
                  />
                </div>
              </div>
            )}
          </div>
          <div className="flex text-sm flex-wrap gap-y-2 text-transactionGreyText font-medium justify-between">
            <div
              className={`flex items-center gap-2  w-[49%] sm:w-full px-3 py-4 justify-center rounded-[7px] cursor-pointer ${
                currentTheme === "dark"
                  ? " bg-filter text-mainBg"
                  : "bg-transactionsBg text-transactionGreyText"
              }`}
              onClick={() => {
                setCalendarIsVisible(true);
              }}
            >
              <img
                className="w-5"
                src={currentTheme === "dark" ? DarkCalendarIcon : CalendarIcon}
                alt="calendar"
              />
              <p>
                {dateFiltered}, {timeFiltered}
              </p>
            </div>
            <div
              className={`flex items-center gap-2 w-[24%] sm:w-[49%] px-3 py-4 justify-center rounded-[7px] cursor-pointer ${
                currentTheme === "dark"
                  ? " bg-filter text-mainBg"
                  : "bg-transactionsBg text-transactionGreyText"
              }`}
              onClick={() => {
                setFeeIsVisible(true);
              }}
            >
              <img
                className="w-5"
                src={currentTheme === "dark" ? DarkCoinIcon : CoinIcon}
                alt="coin"
              />
              <p>Fee</p>
            </div>
            <div
              className={`flex items-center gap-2  w-[25%] sm:w-[49%] px-3 py-4 justify-center rounded-[7px] cursor-pointer ${
                currentTheme === "dark"
                  ? " bg-filter text-mainBg"
                  : "bg-transactionsBg text-transactionGreyText"
              }`}
              onClick={() => {
                setNotesIsVisible(true);
              }}
            >
              <img
                className="w-5"
                src={currentTheme === "dark" ? DarkNotesIcon : NotesIcon}
                alt="notes"
              />
              <p>Notes</p>
            </div>
          </div>
          {transactionType !== "transfer" && (
            <div
              className={`bg-coinVoteBg mt-3 p-4 rounded-[7px] ${
                currentTheme === "dark" ? " bg-filter " : "bg-transactionsBg"
              }`}
            >
              <p
                className={` font-medium mb-1 ${
                  currentTheme === "dark" ? "  text-mainBg" : " text-transactionGreyText"
                }`}
              >
                Total Spent
              </p>
              <p
                className={`font-medium  text-lg ${
                  currentTheme === "dark" ? "  text-mainBg" : " text-transactionDarkText"
                }`}
              >
                ${totalAmount ? totalAmount : "0.00"}
              </p>
            </div>
          )}
          <div className="mt-4">
            <button
              className={`bg-primary w-full p-3 rounded-[7px] text-primaryText font-medium text-lg mb-2 disabled:bg-disabledButton `}
              onClick={addTransactionHandler}
              disabled={isLoading}
            >
              {isLoading ? "Adding Transaction..." : "Add Transaction"}
            </button>
            <button
              className={` w-full p-3 rounded-[7px] font-medium text-lg ${
                currentTheme === "dark"
                  ? "bg-inputColor text-mainBg"
                  : "bg-gray-200 text-mainBlack"
              }`}
              onClick={() => {
                dispatch(closeModal());
                dispatch(closeTransaction());
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      )}
      {feeIsVisible && (
        <PortfolioFeePopup
          onBack={() => {
            setFeeIsVisible(false);
          }}
          enterFee={feeHandler}
          fee={enteredFee}
        />
      )}
      {notesIsVisible && (
        <PortfolioNotesPopup
          onBack={() => {
            setNotesIsVisible(false);
          }}
          enterNote={noteHandler}
          note={enteredNote}
        />
      )}
      {calendarIsVisible && (
        <PortfolioCalendarPopup
          onBack={() => {
            setCalendarIsVisible(false);
          }}
        />
      )}
    </Modal>
  );
};

export default PortfolioTransactionPopup;
