import React from "react";
import { Pagination } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { changePageNumber } from "../../store/slices/coinMarketSlice";
import { selectCurrentTheme } from "../../store/slices/themeSlice";

const TablePagination = ({ currentPage, setCurrentPage, per_page, total_coins }) => {
  const dispatch = useDispatch();
  const currentTheme = useSelector(selectCurrentTheme);
  const handlePageChange = page => {
    setCurrentPage(page);
    dispatch(changePageNumber(page));
  };

  return (
    <div
      className={`flex justify-center ${
        currentTheme === "dark" ? "dark" : "ant-pagination"
      }`}
    >
      <Pagination
        current={currentPage}
        total={total_coins}
        showSizeChanger={false}
        defaultPageSize={per_page}
        onChange={handlePageChange}
      />
    </div>
  );
};

export default TablePagination;
