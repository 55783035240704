import React, { useEffect, useState } from "react";
import Modal from "../../utils/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { selectCoinHandler } from "../../store/slices/profileTransactionSlice";
import { closeAddCoin, openTransaction } from "../../store/slices/popupSlice";
import {
  useGetCoinMarketQuery,
  useGetHeaderSearchCoinQuery
} from "../../store/services/services";
import { selectCurrentTheme } from "../../store/slices/themeSlice";
import { Spin } from "antd";
import CryptoIcon from "../../assets/icons/crypto-icon.svg";

const AddCoinPopup = () => {
  const [searchedTerm, setSearchedTerm] = useState("");
  const dispatch = useDispatch();
  const currentTheme = useSelector(selectCurrentTheme);
  const { data, isLoading, isError } = useGetCoinMarketQuery();

  // search coin
  const {
    data: searchCoin,
    isError: searchIsError,
    isLoading: searchIsLoading,
    isFetching: searchIsFetching,
    refetch: searchRefetch
  } = useGetHeaderSearchCoinQuery(searchedTerm);
  console.log(searchCoin, "this is searchCoin data");

  // search coin
  let filteredData;
  if (searchedTerm.length === 0 && !isLoading && !isError) {
    filteredData = data?.data;
  } else if (
    searchedTerm.length > 0 &&
    !searchIsLoading &&
    !searchIsError &&
    !searchIsFetching
  ) {
    filteredData = searchCoin;
  }
  useEffect(() => {
    if (searchedTerm.length === 0 && !isLoading && !isError) {
      filteredData = data?.data;
    } else if (
      searchedTerm.length > 0 &&
      !searchIsLoading &&
      !searchIsError &&
      !searchIsFetching
    ) {
      filteredData = searchCoin;
    }
  }, [searchCoin, data]);

  // Debounce function
  const debounce = (func, delay) => {
    let timer;
    return function (...args) {
      clearTimeout(timer);
      timer = setTimeout(() => func(...args), delay);
    };
  };

  // Debounced search function
  const debouncedSearch = debounce(() => {
    searchRefetch(); // Replace with the actual search function
  }, 300);

  // Handle search term change
  const searchCoinHandler = e => {
    setSearchedTerm(e.target.value);
    debouncedSearch();
  };
// add coin handler
  const addCoinHandler = item => {
    dispatch(closeAddCoin());
    dispatch(openTransaction());
    dispatch(selectCoinHandler(item));
  };

  return (
    <Modal maxWidth={550}>
      <div className="px-8 xs:px-6">
        <div
          className={`fixed w-full top-0 left-0 px-8 pb-1 pt-3 ${
            currentTheme === "dark" ? "bg-cardsColor" : "bg-mainBg"
          }`}
        >
          <div className="mt-7 mb-3">
            <p
              className={`font-semibold text-2xl ${
                currentTheme === "dark" ? " text-mainBg" : "text-filter"
              }`}
            >
              Add Coin
            </p>
          </div>
          <div>
            <input
              className={`w-full border border-darkBorder p-[6px] rounded-[7px] outline-none focus:border-primary ${
                currentTheme === "dark"
                  ? "bg-inputColor text-mainBg"
                  : "bg-mainBg text-filter"
              }`}
              type="text"
              placeholder="Search"
              onChange={searchCoinHandler}
              value={searchedTerm}
            />
          </div>
        </div>
        <div className="py-1 mt-[120px]">
          {(isLoading || searchIsLoading || searchIsFetching) && (
            <div className="flex justify-center items-center h-[500px]">
              <Spin size="large" />
            </div>
          )}
          {!isLoading &&
            !isError &&
            !searchIsError &&
            !searchIsLoading &&
            !searchIsFetching &&
            filteredData.map(item => (
              <div
                key={item.id}
                className={`flex border-b py-3 items-center justify-between cursor-pointer ${
                  currentTheme === "dark" ? "border-darkBorder" : "border-lightBorder"
                }`}
                onClick={() => addCoinHandler(item)}
              >
                <div className="flex gap-2 items-center">
                  <div className="w-6">
                    <img
                      className="w-full"
                      src={item?.img ? item?.img : CryptoIcon}
                      alt="coinImage"
                    />
                  </div>
                  <div>
                    <p
                      className={` font-semibold text-[17px] ${
                        currentTheme === "dark" ? " text-mainBg" : "text-secondary"
                      }`}
                    >
                      {item?.name}
                    </p>
                  </div>
                  <div className={`text-coinPopup font-medium text-[17px]`}>
                    {item?.logo}
                  </div>
                </div>
                <div className="text-portfolioCoinColor">
                  <FontAwesomeIcon icon={faAngleRight} />
                </div>
              </div>
            ))}
          {filteredData?.length === 0 && (
            <div className="h-52 flex items-center justify-center">
              <div>
                <p
                  className={`font-semibold text-xl text-exchangeDarkGreyColor ${
                    currentTheme === "dark"
                      ? " text-mainBg"
                      : "text-exchangeDarkGreyColor"
                  }`}
                >
                  No Coins Found
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default AddCoinPopup;
