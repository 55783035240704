import React, { useEffect } from "react";
import ExcludingBitcoin from "../components/ExcludingBitcoin/ExcludingBitcoin";

const ExcludingCap = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <ExcludingBitcoin />
    </div>
  );
};

export default ExcludingCap;
