import React, { useEffect } from "react";
import PortfolioLogin from "../components/PortfolioLogin";
import WatchlistCards from "../components/LoginPageCards";

const Portfolio = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <PortfolioLogin />
      <WatchlistCards />
    </div>
  );
};

export default Portfolio;
