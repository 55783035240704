import { createSlice } from "@reduxjs/toolkit";

// initial state
const initialState = {
  name: null,
  email: null,
  token: null,
  userId: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUser: (state, action) => {
      localStorage.setItem(
        "user",
        JSON.stringify({
          name: action.payload ? action.payload.name : null,
          email: action.payload ? action.payload.email : null,
          token: action.payload ? action.payload.token : null,
          userId: action.payload ? action.payload.userId : null,
        })
      );
      state.name = action.payload ? action.payload.name : null;
      state.email = action.payload ? action.payload.email : null;
      state.token = action.payload ? action.payload.token : null;
      state.userId = action.payload ? action.payload.userId : null;
    },
  },
});

export const { setUser } = authSlice.actions;
export default authSlice.reducer;
