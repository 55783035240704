import React, { useState } from "react";

const MENU_DATA = [
  {
    id: 1,
    name: "Overview",
    value: "overview"
  },
  {
    id: 2,
    name: "Market",
    value: "market"
  },
  {
    id: 3,
    name: "News",
    value: "news"
  },
  {
    id: 4,
    name: "ICO",
    value: "ico"
  },
  {
    id: 5,
    name: "Price Estimates",
    value: "price-estimates"
  },
  {
    id: 6,
    name: "More Info",
    value: "more-info-details"
  }
];

const IcosDetailMenu = ({ activeMenuHandler }) => {
  const [active, setActive] = useState("overview");
  activeMenuHandler(active);

  return (
    <>
      <div className="flex justify-between shadow-header-shadow rounded-full items-center overflow-hidden px-10 xs:px-3 gap-1 mt-16">
        <div className="flex gap-4 flex-nowrap overflow-auto scrollbar py-3 xs:py-2">
          {MENU_DATA.map((item) => (
            <div
              className={`${
                active === item.value
                  ? "bg-primary text-mainBg rounded-full border-[0.3 px]"
                  : "bg-none rounded-full text-black border-[1px]"
              } py-3 px-7 whitespace-nowrap cursor-pointer`}
              onClick={() => {
                setActive(item.value);
              }}
              key={item.id}
            >
              {item.name}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default IcosDetailMenu;
