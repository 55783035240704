import React from "react";
import ParticipateInfo from "../ParticipateInfo/ParticipateInfo";

const EarnCrypto = () => {
  return (
    <div>
      <ParticipateInfo />
    </div>
  );
};

export default EarnCrypto;
