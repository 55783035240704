import { faArrowsRotate } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import CompareSearch from "../CompareSearch";
import CompareSelected from "../ComapareSelected";
import CompareCoinTable from "../CompareCoinTable";
import { useDispatch, useSelector } from "react-redux";
import { resetCoins } from "../../store/slices/compareSlice";
import { selectCurrentTheme } from "../../store/slices/themeSlice";

const CompareCoin = () => {
  const selectedCoins = useSelector(state => state.compare.selectedCoins);
  const dispatch = useDispatch();
  const currentTheme = useSelector(selectCurrentTheme);
  return (
    <div className="px-24 md:px-8 xs:px-4 mt-12">
      <div
        className={`shadow-navbar-button px-6 xs:px-4 py-8 xs:py-4 rounded-[20px] ${
          currentTheme === "dark" ? "bg-cardsColor " : "bg-mainBg"
        }`}
      >
        <div className="flex sm:flex-col sm:items-start gap-y-4 items-center justify-between">
          <p
            className={`font-semibold text-2xl  ${
              currentTheme === "dark" ? "text-mainBg " : "text-secondary"
            }`}
          >
            Coin Compare
          </p>
          <div className="flex flex-wrap items-center gap-4">
            <button
              className="flex gap-2 items-center border border-primary p-1 px-4 rounded-full"
              onClick={() => dispatch(resetCoins())}
            >
              <FontAwesomeIcon className="text-primary" icon={faArrowsRotate} />
              <span
                className={` ${
                  currentTheme === "dark" ? "text-mainBg " : "text-compareCoinTextColor"
                }`}
              >
                Reset
              </span>
            </button>
            <CompareSearch />
          </div>
        </div>
        {selectedCoins.length === 0 && (
          <div className="h-80 flex items-center justify-center">
            <p
              className={`text-2xl font-semibold text-grey-700 ${
                currentTheme === "dark" ? "text-mainBg " : "text-filter"
              }`}
            >
              Select coins to compare
            </p>
          </div>
        )}
        {selectedCoins.length > 0 && (
          <div>
            <CompareSelected />
            <CompareCoinTable />
          </div>
        )}
      </div>
    </div>
  );
};

export default CompareCoin;
