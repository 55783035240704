import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import DecreaseChangeIcon from "../../assets/icons/decrease-change.svg";
import IncreaseChangeIcon from "../../assets/icons/increase-change.svg";
import decreaseChangeIcon from "../../assets/icons/decrease-change.svg";
import { commafy } from "../../utils/commafy";
import { toDecimals } from "../../utils/toDecimals";
import { useSelector } from "react-redux";
import { selectCurrentTheme } from "../../store/slices/themeSlice";

const CoinStatistics = ({ coin }) => {
  const currentTheme = useSelector(selectCurrentTheme);

// calculating coin price and percentage
  const calculateHighLowPrices = () => {
    const currentPrice = parseFloat(coin?.price);
    const percentChangeDay = parseFloat(coin?.percentChangeDay);

// getting high and low price
    if (!isNaN(currentPrice) && !isNaN(percentChangeDay)) {
      const highPrice = currentPrice * (1 + percentChangeDay / 100);
      const lowPrice = currentPrice * (1 - percentChangeDay / 100);

      return {
        high: highPrice.toFixed(2),
        low: lowPrice.toFixed(2)
      };
    } else {
      return {
        high: "N/A",
        low: "N/A"
      };
    }
  };

  const { high, low } = calculateHighLowPrices();
  return (
    <div
      className={`shadow-navbar-button px-5 py-6 text-[15px] rounded-[28px] ${
        currentTheme === "dark" ? "bg-cardsColor" : "bg-mainBg"
      }`}
    >
      <div>
        <p
          className={`text-xl font-semibold mb-5 ${
            currentTheme === "dark" ? "text-mainBg" : "text-filter"
          }`}
        >
          {coin.name} Price Statistics
        </p>
      </div>
      <div>
        <div
          className={`flex items-center justify-between border-t-[.5px] py-2 min-h-[60px] ${
            currentTheme === "dark" ? "border-darkBorder" : "border-lightBorder"
          }`}
        >
          <p
            className={`font-medium  w-[45%] ${
              currentTheme === "dark" ? "text-mainBg" : "text-coinStatisticsColor"
            }`}
          >
            Price
          </p>
          <p
            className={`text-right font-medium w-[45%] ${
              currentTheme === "dark" ? "text-mainBg" : "text-filter"
            }`}
          >
            ${commafy(toDecimals(coin?.price))}
          </p>
        </div>
        {/* <div className="flex items-center justify-between border-t-[.5px] border-lightBorder py-2 min-h-[60px]">
          <p className="font-medium text-coinStatisticsColor w-[45%]">Price Change</p>
          <div className="text-right w-[45%]">
            <p className="font-medium">${data.priceChange}</p>
            <p
              className={`font-medium  ${
                +data.priceChangePer > 0 ? "text-increase" : "text-decrease"
              }`}
            >
              <FontAwesomeIcon
                icon={+data.priceChangePer > 0 ? faCaretUp : faCaretDown}
              />{" "}
              {data.priceChangePer}%
            </p>
          </div>
        </div> */}
        <div
          className={`flex items-center justify-between border-t-[.5px] py-2 min-h-[60px] ${
            currentTheme === "dark" ? "border-darkBorder" : "border-lightBorder"
          }`}
        >
          <p
            className={`font-medium  w-[45%] ${
              currentTheme === "dark" ? "text-mainBg" : "text-coinStatisticsColor"
            }`}
          >
            24h Low / 24h High
          </p>
          <p
            className={`text-right font-medium w-[45%] ${
              currentTheme === "dark" ? "text-mainBg" : "text-filter"
            }`}
          >
            ${commafy(toDecimals(coin?.percentChangeDay < 0 ? low : high))} /<br /> $
            {commafy(toDecimals(coin?.percentChangeDay < 0 ? high : low))}
          </p>
        </div>
        {/* <div className="flex items-center justify-between border-t-[.5px] border-coinDetailBg py-2 min-h-[60px]">
          <p className="font-medium text-coinStatisticsColor w-[45%]">Trading Volume</p>
          <p className="text-right font-medium w-[45%]">${data.tradingVolume}</p>
        </div> */}
        <div
          className={`flex items-center justify-between border-t-[.5px] py-2 min-h-[60px] ${
            currentTheme === "dark" ? "border-darkBorder" : "border-lightBorder"
          }`}
        >
          <p
            className={`font-medium  w-[45%] ${
              currentTheme === "dark" ? "text-mainBg" : "text-coinStatisticsColor"
            }`}
          >
            Volume / Market Cap
          </p>
          <div className="flex items-center gap-1">
            <img
              className="sm:w-[6px]"
              src={
                coin?.volume_marketCap_24h > 0 ? IncreaseChangeIcon : decreaseChangeIcon
              }
              alt={coin?.change > 0 ? "increase-icon" : "decrease-icon"}
            />
            <p
              className={`${
                coin.volume_marketCap_24h > 0 ? "text-increase" : "text-decrease"
              } text-xs sm:text-[11px]`}
            >
              {commafy(toDecimals(coin?.volume_marketCap_24h))}%
              {/* {Math.abs(item.percentChangeDay)} */}
            </p>
          </div>
        </div>
        {/* <div className="flex items-center justify-between border-t-[.5px] border-lightBorder py-2 min-h-[60px]">
          <p className="font-medium text-coinStatisticsColor w-[45%]">Market Dominance</p>
          <p className="text-right font-medium w-[45%]">{coin?.marketDominance}</p>
        </div> */}
        <div
          className={`flex items-center justify-between border-t-[.5px] py-2 min-h-[60px] ${
            currentTheme === "dark" ? "border-darkBorder" : "border-lightBorder"
          }`}
        >
          <p
            className={`font-medium w-[45%] ${
              currentTheme === "dark" ? "text-mainBg" : "text-coinStatisticsColor"
            }`}
          >
            Market Rank
          </p>
          <p
            className={`text-right font-medium w-[45%] ${
              currentTheme === "dark" ? "text-mainBg" : "text-filter"
            }`}
          >
            #{coin?.rank}
          </p>
        </div>
        <div
          className={`flex items-center justify-between border-t-[.5px] py-2 min-h-[60px] ${
            currentTheme === "dark" ? "border-darkBorder" : "border-lightBorder"
          }`}
        >
          <p
            className={`font-medium w-[45%] ${
              currentTheme === "dark" ? "text-mainBg" : "text-coinStatisticsColor"
            }`}
          >
            Market Cap
          </p>
          <div className="text-right w-[45%]">
            <p
              className={`font-medium ${
                currentTheme === "dark" ? "text-mainBg" : "text-filter"
              }`}
            >
              ${commafy(toDecimals(coin?.market_cap))}
            </p>
            {/* <p
              className={`font-medium  ${
                +data.priceChangePer > 0 ? "text-increase" : "text-decrease"
              }`}
            >
              <FontAwesomeIcon
                icon={+data.marketCapChange > 0 ? faCaretUp : faCaretDown}
              />{" "}
              {data.marketCapChange}%
            </p> */}
          </div>
        </div>
        <div
          className={`flex items-center justify-between border-t-[.5px] py-2 min-h-[60px] ${
            currentTheme === "dark" ? "border-darkBorder" : "border-lightBorder"
          }`}
        >
          <p
            className={`font-medium w-[45%] ${
              currentTheme === "dark" ? "text-mainBg" : "text-coinStatisticsColor"
            }`}
          >
            Fully Diluted Market Cap
          </p>
          <div className="text-right w-[45%]">
            <p
              className={`font-medium ${
                currentTheme === "dark" ? "text-mainBg" : "text-filter"
              }`}
            >
              ${commafy(toDecimals(coin?.fully_diluted_market_cap))}
            </p>
            {/* <p
              className={`font-medium  ${
                +data.priceChangePer > 0 ? "text-increase" : "text-decrease"
              }`}
            >
              <FontAwesomeIcon
                icon={+data.fDMarketCapChange > 0 ? faCaretUp : faCaretDown}
              />{" "}
              {data.fDMarketCapChange}%
            </p> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoinStatistics;
