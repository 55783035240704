import {
  faBriefcase,
  faCirclePlus,
  faEllipsis,
  faPencil,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dropdown, Menu } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  closeAddCoin,
  openAddCoin,
  openPortfolioCreate,
  openPortfolioEdit,
  openPortfolioRemove,
  openTransaction
} from "../../store/slices/popupSlice";
import PortfolioCreatePopup from "../PortfolioCreatePopup";
import PortfolioRemovePopup from "../PortfolioRemovePopup";
import PortfolioEditPopup from "../PortfolioEditPopup";
import AddCoinPopup from "../PortfolioAddCoinPopup";
import PortfolioTransactionPopup from "../PortfolioTransactionPopup";
import { useGetPortfolioByUserQuery } from "../../store/services/services";
import {
  portfolioCompareId,
  portfolioReset
} from "../../store/slices/portfolioChangeSlice";
import { commafy } from "../../utils/commafy";
import { toDecimals } from "../../utils/toDecimals";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { selectCurrentTheme } from "../../store/slices/themeSlice";

const PortfolioCard = () => {
  const dispatch = useDispatch();
  const [coin, setCoin] = useState("");
  const [portfolioId, setPortfolioId] = useState(null);
  const [name, setName] = useState("");
  const activePortfolio_Id = JSON.parse(localStorage.getItem("activePortfolio"));
  const [activePortfolioId, setActivePortfolioId] = useState(activePortfolio_Id);
  const currentTheme = useSelector(selectCurrentTheme);
  const portfolioChange = useSelector(state => state.portfolioChange.portfolioChange);
  useEffect(() => {
    localStorage.setItem("activePortfolio", null);
    setActivePortfolio(null);
  }, []);

  // getting values from redux
  const portfolioCreate = useSelector(state => state.popup.portfolioCreateIsVisible);
  const portfolioRemove = useSelector(state => state.popup.portfolioRemoveIsVisible);
  const portfolioEdit = useSelector(state => state.popup.portfolioEditIsVisible);
  const addCoinPopup = useSelector(state => state.popup.addCoinIsVisible);
  const addTransaction = useSelector(state => state.popup.transactionIsVisible);

  useEffect(() => {
    dispatch(portfolioCompareId(activePortfolio_Id));
  }, [activePortfolio_Id, dispatch]);

  const userId = useSelector(state => state.auth.userId);

  const { data, refetch, isLoading, isFetching, isError, error } =
    useGetPortfolioByUserQuery(userId);

// add new coin handler
  const newCoinClickHanlder = coin => {
    dispatch(closeAddCoin());
    dispatch(openTransaction());
    setCoin(coin);
  };

  useEffect(() => {
    if (portfolioChange) {
      refetch();
      dispatch(portfolioReset());
    }

    if (!isLoading) {
      if (!data && !data && !data.some(item => item.id === activePortfolio_Id)) {
        setActivePortfolioId(null);
        localStorage.setItem("activePortfolio", null);
      }
    }
  }, [activePortfolio_Id, data, dispatch, isLoading, portfolioChange, refetch]);
  const setActivePortfolio = id => {
    setActivePortfolioId(id);
    localStorage.setItem("activePortfolio", id);
  };

  const selectDashboardHandler = () => {
    setActivePortfolio(null);
    localStorage.setItem("activePortfolio", null);
  };
  return (
    <div className="px-24 md:px-8 xs:px-4 mt-12">
      <div
        className={`shadow-navbar-button rounded-[20px] ${
          currentTheme === "dark" ? "bg-cardsColor" : "bg-mainBg"
        }`}
      >
        <div
          className={`flex sm:flex-col border-b ${
            currentTheme === "dark" ? "border-darkBorder" : "border-lightBorder"
          }`}
        >
          <div
            className={`p-12 border-r sm:border-r-0 sm:border-b w-5/12 sm:w-full ${
              currentTheme === "dark" ? "border-darkBorder" : "border-lightBorder"
            }`}
          >
            <div className="bg-primary w-[72px] rounded-full aspect-square flex justify-center items-center mb-6">
              {data?.portfolioData && data?.portfolioData.length > 0 ? (
                <p className="font-bold text-3xl text-mainBg">
                  {activePortfolio_Id ? (
                    data?.portfolioData
                      ?.find(item => item.id === activePortfolio_Id)
                      ?.name.slice(0, 1)
                      .toUpperCase()
                  ) : (
                    <FontAwesomeIcon icon={faBriefcase} />
                  )}
                </p>
              ) : (
                <p className="font-bold text-3xl text-mainBg">
                  <FontAwesomeIcon icon={faBriefcase} />
                </p>
              )}
            </div>

            {isLoading && (
              <div className="w-full">
                <SkeletonTheme
                  baseColor={`${currentTheme === "dark" ? "#323546" : "#d8d8d8"}`}
                  highlightColor={`${currentTheme === "dark" ? "#444" : "#e0e0e0"}`}
                >
                  <Skeleton width="80%" height={30} count={1} />
                </SkeletonTheme>
              </div>
            )}
            {isError && <p>{error.message}</p>}
            {!isLoading && !isError && (
              <div className="flex flex-wrap gap-3 items-center">
                <p
                  className={`text-2xl font-bold md:text-xl ${
                    currentTheme === "dark" ? " text-mainBg" : "text-filter"
                  }`}
                >
                  {activePortfolio_Id
                    ? data?.portfolioData?.find(item => item.id === activePortfolio_Id)
                        ?.name
                    : "Select a Portfolio"}
                </p>
                <div className=" bg-coinNewsGreyColor aspect-square rounded-full h-5 flex items-center justify-center">
                  <p className=" text-mainBg">i</p>
                </div>

                {data?.portfolioData.length > 0 ? (
                  <p
                    className={`text-lightGray ${
                      currentTheme === "dark" ? " text-mainBg" : "text-lightGray"
                    }`}
                  >
                    ≈$
                    {activePortfolio_Id
                      ? commafy(
                          toDecimals(
                            data?.portfolioData?.find(
                              item => item.id === activePortfolio_Id
                            )?.currentBalance
                          )
                        )
                      : commafy(toDecimals(data?.overallData?.overallCurrentBalance))}
                  </p>
                ) : (
                  <p
                    className={`${
                      currentTheme === "dark" ? " text-mainBg" : "text-lightGray"
                    }`}
                  >
                    $0
                  </p>
                )}
              </div>
            )}
          </div>
          <div className="p-12 xs:p-6 w-7/12 sm:w-full">
            <div>
              <p
                className={` ${
                  currentTheme === "dark" ? " text-mainBg" : "text-lightGray"
                }`}
              >
                Current Balance
              </p>
            </div>
            {data?.portfolioData.length > 0 ? (
              <div className="mb-4 xs:mb-6">
                <div className="flex items-center gap-2 mb-2">
                  {isLoading && (
                    <div className="w-full">
                      <SkeletonTheme
                        baseColor={`${currentTheme === "dark" ? "#323546" : "#d8d8d8"}`}
                        highlightColor={`${currentTheme === "dark" ? "#444" : "#e0e0e0"}`}
                      >
                        <Skeleton width="30%" height={40} count={1} />
                      </SkeletonTheme>
                    </div>
                  )}
                  {isError && <p>{error.message}</p>}
                  {!isLoading && !isError && (
                    <p
                      className={`text-[40px] sm:text-[20px] font-bold ${
                        currentTheme === "dark" ? " text-mainBg" : "text-lightGray"
                      }`}
                    >
                      $
                      {activePortfolio_Id
                        ? commafy(
                            toDecimals(
                              data?.portfolioData?.find(
                                item => item.id === activePortfolio_Id
                              )?.currentBalance
                            )
                          )
                        : commafy(toDecimals(data?.overallData?.overallCurrentBalance))}
                    </p>
                  )}
                </div>
              </div>
            ) : (
              <div>
                {isLoading && (
                  <div className="w-full">
                    <SkeletonTheme
                      baseColor={`${currentTheme === "dark" ? "#323546" : "#d8d8d8"}`}
                      highlightColor={`${currentTheme === "dark" ? "#444" : "#e0e0e0"}`}
                    >
                      <Skeleton width="30%" height={40} count={1} />
                    </SkeletonTheme>
                  </div>
                )}
                {isError && <p>{error.message}</p>}
                {!isLoading && !isError && (
                  <p
                    className={`${
                      currentTheme === "dark" ? " text-mainBg" : "text-lightGray"
                    }`}
                  >
                    $0
                  </p>
                )}
              </div>
            )}

            {activePortfolio_Id ? (
              <div className="flex xs:flex-col gap-2 justify-end">
                <button
                  className="flex gap-1 items-center justify-center py-1 bg-primary px-5 text-darkBlue rounded-[5px]"
                  onClick={() => dispatch(openAddCoin())}
                >
                  <FontAwesomeIcon icon={faCirclePlus} />
                  Add New
                </button>
              </div>
            ) : (
              <p className="text-decrease text-right mt-8">
                {data?.portfolioData && data?.portfolioData.length > 0 ? (
                  <p> Select a portfolio to add transaction.</p>
                ) : (
                  <p> Create a portfolio to add transaction.</p>
                )}
              </p>
            )}
          </div>
        </div>
        <div className="p-12 sm:p-4 flex gap-4 sm:flex-col justify-between">
          <div className="w-1/2 md:w-full">
            <div className="mb-6 cursor-pointer" onClick={selectDashboardHandler}>
              <div className="flex gap-3 items-center">
                <div className="w-6">
                  {!activePortfolio_Id && (
                    <span className="w-5 flex items-center justify-center aspect-square border rounded-full border-primary">
                      <span className=" block w-3 aspect-square rounded-full bg-primary"></span>
                    </span>
                  )}
                </div>
                <div className="w-[40px] aspect-square rounded-full flex items-center justify-center bg-primary text-mainBg">
                  <FontAwesomeIcon icon={faBriefcase} />
                </div>
                <div>
                  <p
                    className={`text-xl font-bold ${
                      currentTheme === "dark" ? " text-mainBg" : "text-filter"
                    }`}
                  >
                    Dashboard
                  </p>
                  {isLoading && (
                    <div className="w-full">
                      <SkeletonTheme
                        baseColor={`${currentTheme === "dark" ? "#323546" : "#d8d8d8"}`}
                        highlightColor={`${currentTheme === "dark" ? "#444" : "#e0e0e0"}`}
                      >
                        <Skeleton width="100%" height={20} count={1} />
                      </SkeletonTheme>
                    </div>
                  )}
                  {isError && <p>{error.message}</p>}
                  {!isLoading && !isError && (
                    <p
                      className={` text-base xs:text-sm ${
                        currentTheme === "dark" ? " text-mainBg" : "text-lightGray"
                      }`}
                    >
                      ≈${commafy(toDecimals(data?.overallData?.overallCurrentBalance))}
                    </p>
                  )}
                </div>
              </div>
            </div>
            <p
              className={`text-lg xs:text-base font-bold pb-3 ${
                currentTheme === "dark" ? " text-mainBg" : "text-filter"
              }`}
            >
              All Portfolios
            </p>
            {(isLoading || isFetching) && (
              <div className="pb-[2rem]">
                <SkeletonTheme
                  baseColor={`${currentTheme === "dark" ? "#323546" : "#d8d8d8"}`}
                  highlightColor={`${currentTheme === "dark" ? "#444" : "#e0e0e0"}`}
                >
                  <Skeleton width="100%" height={50} count={3} />
                </SkeletonTheme>
              </div>
            )}
            {!isFetching &&
              !isLoading &&
              data &&
              data?.portfolioData?.map(item => (
                <div
                  key={item.id}
                  className="flex justify-between mb-5 cursor-pointer"
                  onClick={() => setActivePortfolio(item.id)}
                >
                  <div className="flex items-center gap-3 w-9/12">
                    <div className="w-6">
                      {activePortfolio_Id === item.id && (
                        <span className="w-5 flex items-center justify-center aspect-square border rounded-full border-primary">
                          <span className=" block w-3 aspect-square rounded-full bg-primary"></span>
                        </span>
                      )}
                    </div>
                    <div>
                      <div className="bg-primary w-[35px]  rounded-full aspect-square flex justify-center items-center">
                        <p className="font-bold text-[20px] text-mainBg">
                          {item?.name.slice(0, 1).toUpperCase()}
                        </p>
                      </div>
                    </div>
                    <div className="flex flex-col gap-y-1">
                      <div className="flex items-center gap-2">
                        <p
                          className={`text-lg xs:text-base font-bold ${
                            currentTheme === "dark" ? " text-mainBg" : "text-filter"
                          }`}
                        >
                          {item.name}
                        </p>
                        <div className=" bg-coinNewsGreyColor aspect-square rounded-full h-4 text-sm flex items-center justify-center">
                          <p className=" text-mainBg">i</p>
                        </div>
                      </div>
                      <p
                        className={` text-base xs:text-sm ${
                          currentTheme === "dark" ? " text-mainBg" : "text-lightGray"
                        }`}
                      >
                        ≈${commafy(toDecimals(item?.currentBalance))}
                      </p>
                    </div>
                  </div>
                  <div>
                    <Menu
                      mode="horizontal"
                      triggerSubMenuAction="click"
                      theme={currentTheme === "dark" ? "dark" : "light"}
                      className={`cursor-pointer text-center bg-${
                        currentTheme === "dark" ? "darkDropdown" : "lightDropdown"
                      }`}
                      items={[
                        {
                          label: (
                            <FontAwesomeIcon
                              className={`text-2xl ${
                                currentTheme === "dark"
                                  ? " text-mainBg"
                                  : "text-portfolioIcon"
                              }`}
                              icon={faEllipsis}
                            />
                          ),
                          children: [
                            {
                              label: (
                                <button
                                  onClick={() => {
                                    dispatch(openPortfolioEdit());
                                    setPortfolioId(item.id);
                                    setName(item.name);
                                  }}
                                  className={`w-full ${
                                    currentTheme === "dark"
                                      ? " text-mainBg"
                                      : "text-lightGray"
                                  }`}
                                >
                                  <FontAwesomeIcon icon={faPencil} /> Edit portfolioht
                                </button>
                              ),
                              key: 1
                            },
                            {
                              label: (
                                <button
                                  className="text-decrease w-full"
                                  onClick={() => {
                                    dispatch(openPortfolioRemove());
                                    setPortfolioId(item.id);
                                  }}
                                >
                                  <FontAwesomeIcon icon={faTrash} /> Remove portfolio
                                </button>
                              ),
                              key: "1"
                            }
                          ],
                          key: "0"
                        }
                      ]}
                      trigger={["click"]}
                      placement="bottomRight"
                    ></Menu>
                  </div>
                </div>
              ))}
            <div>
              <button
                className={`flex items-center justify-center gap-1 xs:mx-auto xs:mt-4 border-lightBorder px-5 py-2 border rounded-[5px] ${
                  currentTheme === "dark" ? " text-mainBg" : "text-filter"
                }`}
                onClick={() => dispatch(openPortfolioCreate())}
              >
                <FontAwesomeIcon icon={faCirclePlus} />
                Create portfolio
              </button>
            </div>
          </div>
        </div>
      </div>
      {portfolioCreate && <PortfolioCreatePopup />}
      {portfolioRemove && (
        <PortfolioRemovePopup
          portfolioId={portfolioId}
          selectDashboardHandler={selectDashboardHandler}
        />
      )}
      {portfolioEdit && <PortfolioEditPopup portfolioId={portfolioId} name={name} />}
      {addCoinPopup && <AddCoinPopup onCoinClick={newCoinClickHanlder} />}
      {addTransaction && (
        <PortfolioTransactionPopup activePortfolioId={activePortfolio_Id} coin={coin} />
      )}
    </div>
  );
};

export default PortfolioCard;