import React from "react";
import CoinData from "../CoinData";
import CoinDetailCardMenu from "../CoinDetailCardMenu";
import CoinTags from "../CoinTags";
import { selectCurrentTheme } from "../../store/slices/themeSlice";
import { useSelector } from "react-redux";

const CoinDetailCard = ({ coin }) => {
  const currentTheme = useSelector(selectCurrentTheme);
  return (
    <div className="px-24 md:px-8 xs:px-4 mt-12">
      <div className="flex lg:flex-col-reverse items-cente gap-[3rem] lg:gap-0">
        {/* {isLoading && (
          <div className="w-full">
            <Skeleton width="100%" height={35} count={5} />
          </div>
        )}
        {isError && <p>{error.message}</p>}
        {!isError && !isError && ( */}
        <div
          className={`shadow-navbar-button rounded-[40px] items-center flex md:flex-col w-5/6 lg:w-full ${
            currentTheme === "dark" ? "bg-cardsColor text-mainBg " : "bg-mainBg"
          }`}
        >
          <div className="w-[45%] md:w-full">
            <CoinTags
              // isLoading={isLoading}
              // isError={isError}
              // error={error}
              coin={coin}
            />
          </div>
          <div className="w-[55%] md:w-full">
            <CoinData
              // isLoading={isLoading}
              // isError={isError}
              // error={error}
              coin={coin}
            />
          </div>
        </div>
        {/* )} */}
        <div className="w-1/6 lg:w-full">
          <CoinDetailCardMenu
          // isLoading={isLoading}
          // isError={isError}
          // error={error}
          />
        </div>
      </div>
    </div>
  );
};

export default CoinDetailCard;
