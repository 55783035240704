import React from "react";
import TrendingCoin from "../../assets/images/trending-coin.svg";
import { Latest_Aussie_Data } from "../../data/LatestAussieData";

const LatestAussieDigital = () => {
  return (
    <div className="mt-12 md:mt-6 sm:mt-4">
      <h1 className="text-2xl sm:mb-2 font-semibold sm:text-sm">
        Latest Aussie Digital News
      </h1>
      <p className="text-base text-grey">No recent news for Aussie Digital</p>
      <div className="shadow-navbar-button mt-10 sm:mt-6 rounded-[26px]">
        <div className="px-10 pt-7">
          <h1 className="text-2xl sm:mb-5 font-semibold sm:text-sm">
            Trending Coins and Tokens 🔥
          </h1>
          <div className="flex sm:block justify-between mt-4">
            <div className="flex gap-2">
              <img src={TrendingCoin} alt="trending" />
              <div>
                <h3 className="font-medium sm:text-sm text-[22px]">
                  Hold BNB on Binance
                </h3>
                <p className="text-base text-grey sm:text-sm">
                  And Get 25% Oee Trading Fees.
                </p>
              </div>
            </div>
            <button className="bg-primary px-8 sm:text-sm sm:mb-3 sm:mt-3 rounded-[40px] shadow-navbar-button text-primaryText font-semibold">
              Trade Now
            </button>
          </div>
        </div>
        <div className="pt-5 md:pt-3">
          <div className="flex flex-wrap border-t border-darkBorder items-center">
            {Latest_Aussie_Data.map(items => {
              const { coinLogo, coinName, coinTitle, number } = items;
              return (
                <div
                  className="w-1/3 xl:w-3/6 md:w-full px-10 py-5 sm:py-4 border-t border-r"
                  key={items.id}
                >
                  <div className="flex justify-between items-center">
                    <div className="flex items-center">
                      <img src={coinLogo} alt="logo" className="h-6" />
                      <div className="pl-1 flex items-center justify-between">
                        <div className="flex items-center gap-1">
                          <div className="font-medium text-lg sm:text-sm">{coinName}</div>
                          <div className="text-grey text-sm sm:text-xs">{coinTitle}</div>
                        </div>
                      </div>
                    </div>
                    <div className="text-base sm:text-xs bg-whiteCardsColor text-grey border-[0.3px] py-1 rounded-[40px] px-6">
                      {number}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LatestAussieDigital;
