import React from "react";
import EndedProjectsTable from "../EndedProjectsTable/EndedProjectsTable";
import { Link } from "react-router-dom";
import { useGetAllIcosQuery } from "../../store/services/services";

const EndedProjects = () => {
  const { data } = useGetAllIcosQuery();
  return (
    <>
      <div className="w-full md:w-full mt-10 md:mt-5 sm:mt-3">
        <h1 className="text-2xl mb-6 font-semibold md:text-lg">Ended Projects</h1>
        <EndedProjectsTable limit={5} />
      </div>
      <Link to="icos-project-table/ended">
        <div className="mt-10 md:mt-7 sm:mt-5 flex justify-center cursor-pointer">
          <p className="bg-icosProjectsGrey py-4 px-14 text-center rounded-[10px] font-medium text-icosProjectsGreyColor text-base xs:text-xs lg:text-sm">
            View all {data?.length} ended ICO
          </p>
        </div>
      </Link>
    </>
  );
};

export default EndedProjects;
