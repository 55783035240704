import { useEffect } from "react";
import MainRoutes from "./routes/MainRoutes";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "./store/slices/authSlice";
import { selectCurrentTheme } from "./store/slices/themeSlice";
import ReactGA from "react-ga4";

function App() {
  const dispatch = useDispatch();
  // accessing measurement id from env
  const googleAnalyticsMeasurementId =
    process.env.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID;
  // Google analytics
  ReactGA.initialize(googleAnalyticsMeasurementId);
  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname,
    title: "Custom Title"
  });
  const currentTheme = useSelector(selectCurrentTheme);
  let user = null;

  try {
    const storedUser = localStorage.getItem("user");
    if (storedUser) {
      user = JSON.parse(storedUser);
    }
  } catch (error) {
    console.error("Error parsing user from local storage:", error);
  }

  useEffect(() => {
    if (user) {
      dispatch(setUser(user));
    }
  }, [dispatch, user]);


  return (
    <div className={`bg-${currentTheme === "light" ? "mainBg" : "filter"}`}>
      <div className="min-w-1500 mx-auto">
        <MainRoutes />
      </div>
    </div>
  );
}

export default App;
