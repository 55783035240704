import React from "react";
import { Line } from "@ant-design/plots";
import { data } from "../../data/excludingChartData";

const ExcludingBitcoin = () => {
  const config = {
    data,
    padding: "auto",
    xField: "Date",
    yField: "Market Cap",
    xAxis: {
      tickCount: 5,
    },
    slider: {
      start: 0,
      end: 1,
    },
  };
  return (
    <div className="px-12 md:px-8 xs:px-4">
      <div className="text-center mt-10 mb-7">
        <h1 className="text-2xl font-semibold lg:text-base pb-5">
          Global Cryptocurrency Charts
        </h1>
        <h1 className="text-2xl font-semibold lg:text-base">
          Total Cryptocurrency Market Cap
        </h1>
      </div>
      <div className="flex items-center">
        <div>
          <h3 className="text-xs -rotate-90 font-semibold xl:hidden text-skyBlueColor">
            24h vol
          </h3>
        </div>
        <div className="w-[94%]">
          <Line {...config} />
        </div>
      </div>
      <h3 className="text-xs text-center text-skyBlueColor font-semibold hidden xl:block">
        24h vol
      </h3>
      <p className="text-center text-grey sm:text-sm pt-6">
        The above graph shows the total market cap of all&nbsp;
        <a
          href="https://coinmarketcap.com/alexandria/glossary/cryptoasset"
          target="_blank"
          className="text-blue-500"
          rel="noreferrer"
        >
          cryptoassets
        </a>
        , including&nbsp;
        <a
          href="https://coinmarketcap.com/alexandria/article/what-is-a-stablecoin"
          target="_blank"
          className="text-blue-500"
          rel="noreferrer"
        >
          stablecoins&nbsp;
        </a>
        and&nbsp;
        <a
          href="https://coinmarketcap.com/alexandria/glossary/token"
          target="_blank"
          className="text-blue-500"
          rel="noreferrer"
        >
          tokens.
        </a>
      </p>
      {/* second chart */}
      <div className="text-center mt-10 mb-7">
        <h1 className="text-2xl font-semibold lg:text-base pb-5">
          Total Cryptocurrency Market Capitalization (Excluding Bitcoin)
        </h1>
      </div>
      <div className="flex items-center">
        <div>
          <h3 className="text-xs text-skyBlueColor -rotate-90 font-semibold xl:hidden">
            24h vol
          </h3>
        </div>
        <div className="w-[94%]">
          <Line {...config} />
        </div>
      </div>
      <h3 className="text-xs text-center text-skyBlueColor font-semibold hidden xl:block">
        24h Vol
      </h3>
      <p className="text-center text-grey  sm:text-sm pt-6">
        The above chart shows the total cryptocurrency market capitalization, excluding
        Bitcoin. The total includes stablecoins and tokens. To many people in crypto this
        is a key Bitcoin graph to understand the entire space. It is also a way to view
        the total alt coin market cap.
      </p>
    </div>
  );
};

export default ExcludingBitcoin;
