import React from "react";
import Modal from "../../utils/Modal";
import { selectCurrentTheme } from "../../store/slices/themeSlice";
import { useSelector } from "react-redux";

const CoinTagsPopup = ({ tagsArray, coin_name }) => {
  const currentTheme = useSelector(selectCurrentTheme);
  return (
    <Modal maxWidth="850">
      <div className="p-10 xs:px-4 py-6 relative">
        <div
          className={`pb-5 font-bold text-lg ${
            currentTheme === "dark" ? "text-mainBg " : "text-filter"
          }`}
        >
          {coin_name} Tags
        </div>
        <div className="flex items-center flex-wrap gap-2">
          {tagsArray.map(item => (
            <p
              className={`py-[2px] px-3 bg-coinTagsBg rounded-[5px] cursor-pointer ${
                currentTheme === "dark"
                  ? "text-mainBg bg-filter "
                  : "text-coinTagsText bg-coinTagsBg"
              }`}
              key={item.id}
            >
              {item}
            </p>
          ))}
        </div>
      </div>
    </Modal>
  );
};

export default CoinTagsPopup;
