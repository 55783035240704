import React, { useEffect } from "react";
import SingleIcos from "../components/SingleIcos";

const IcosSingleProduct = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <SingleIcos />
    </div>
  );
};

export default IcosSingleProduct;
