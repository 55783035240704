import React from "react";
import { commafy } from "../../utils/commafy";
import { toDecimals } from "../../utils/toDecimals";
import { useSelector } from "react-redux";
import { selectCurrentTheme } from "../../store/slices/themeSlice";
import CryptoIcon from "../../assets/icons/crypto-icon.svg";
const TrendingCoinsCards = ({ name, image, price, change, chart }) => {
  const currentTheme = useSelector(selectCurrentTheme);
  return (
    <div>
      <div className="flex justify-between">
        <div className="flex items-center gap-1">
          <img src={image ? image : CryptoIcon} alt="coin-logo" className=" h-5" />
          <div>
            <p
              className={`text-sm ${
                currentTheme === "dark" ? "text-mainBg" : "text-filter"
              }`}
            >
              {name}
            </p>
            <p className={`${currentTheme === "dark" ? "text-mainBg" : "text-filter"}`}>
              ${commafy(toDecimals(price))}
            </p>
          </div>
        </div>
        <p className={`text-sm ${change > 0 ? "text-increase" : "text-decrease"} `}>
          {commafy(toDecimals(change))}%
        </p>
      </div>
    </div>
  );
};

export default TrendingCoinsCards;
