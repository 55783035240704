import React, { useEffect, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import Modal from "../../utils/Modal";
import { useDispatch, useSelector } from "react-redux";
import { closeLogin, openEmailVerification, openForgotPassword, openRegister } from "../../store/slices/popupSlice";
import {
  useGetUserQuery,
  useLoginMutation
} from "../../../../backend/src/store/services/services";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BinanceLogo from "../../assets/icons/binance.svg";
import WalletLogo from "../../assets/icons/wallet.svg";
import { setUser } from "../../store/slices/authSlice";
import { selectCurrentTheme } from "../../store/slices/themeSlice";
import { openResetPassword, openLoginPopup } from "../../store/slices/authPopupSlice";

const LoginPopup = ({ setUserEmail}) => {
  const [formData, setFormData] = useState({
    email: "",
    password: ""
  });
  const [showPassword, setShowPassword] = useState(false);
  const [token, setToken] = useState(null);
  const [recaptchaValue, setRecaptchaValue] = useState(null);
  const [login, { data, isLoading, isError, isSuccess }] = useLoginMutation();
  const dispatch = useDispatch();
  const currentTheme = useSelector(selectCurrentTheme);

  const handleChange = e => {
    if(e.target.name === "email"){
      setUserEmail(e.target.value);
    }
    const value = e.target.type === "checkbox" ? e.target.checked : e.target.value;
    setFormData({ ...formData, [e.target.name]: value });
    
  };
  const recaptchaSiteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;
  const handleLogin = async e => {
    e.preventDefault();
    // Validate reCAPTCHA response
   if (!recaptchaValue) {
   console.error("reCAPTCHA validation failed!");
      return;
    }
    if (formData.email && formData.password) {
      await login(formData)
        .unwrap()
        .then(() => {
          toast.success("Login successful!");
        })
        .catch(error => {
          toast.error(error);
        });
    } else {
      alert("Please fill all the fields");
    }
  };

  const getUsers = useGetUserQuery(token, { skip: !token });

  useEffect(() => {
    console.log("testing");
    if (isSuccess && data.token) {
      console.log("true");
      setToken(data.token);
    } else if (isSuccess && data.status === "success_verify") {
      console.log("true second");
      dispatch(closeLogin());
      dispatch(openEmailVerification());
    }
    console.log(token);
  }, [isSuccess, getUsers.isSuccess]);

  console.log("condition", isSuccess && data.setToken);

  useEffect(() => {
    if (getUsers.isSuccess && isSuccess) {
      dispatch(
        setUser({
          token: data.token,
          name: getUsers.data.name,
          email: getUsers.data.email,
          userId: getUsers.data.id
        })
      );
      dispatch(closeLogin());
    }
  }, [getUsers.isSuccess]);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  return (
    <Modal maxWidth="750">
      <div className="px-24 py-14 relative  md:px-16 sm:px-8 xs:px-4 ">
        <div className="text-center mb-5">
          <h3
            className={`font-semibold text-[26px] sm:text-2xl xs:text-xl ${
              currentTheme === "dark" ? " text-mainBg" : "text-filter"
            }`}
          >
            Please Login
          </h3>
        </div>
        <div>
          <div className="mb-5">
            <label
              className={`font-medium text-inputLabel text-sm ${
                currentTheme === "dark" ? " text-mainBg" : "text-filter"
              }`}
              htmlFor="login-email"
            >
              Email
            </label>
            <input
              className={`w-full h-[47px] border-[1px] mt-2 border-loginSignupBorder outline-none focus:border-primary py-1 px-2 rounded-[7px] shadow-input ${
                currentTheme === "dark"
                  ? "bg-inputColor text-mainBg"
                  : "bg-mainBg text-filter"
              }`}
              type="email"
              id="login-email"
              placeholder="Enter your email address"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
          <div className="relative mb-3">
            <div className="flex justify-between">
              <label
                className={`font-medium text-sm ${
                  currentTheme === "dark" ? " text-mainBg" : "text-filter"
                }`}
                htmlFor="login-password"
              >
                Password
              </label>
              {/* <button
                className={`text-sm  ${
                  currentTheme === "dark" ? " text-mainBg" : "text-eyeIconColor"
                }`}
                onClick={() => {
                  dispatch(closeLogin());
                  dispatch(openForgotPassword())
                }}
              >
                Forgot password?
              </button> */}
            </div>
            <input
              className={`w-full h-[47px] border-[1px] mt-2 border-loginSignupBorder outline-none focus:border-primary py-1 px-2 rounded-[7px] shadow-input ${
                currentTheme === "dark"
                  ? "bg-inputColor text-mainBg"
                  : "bg-mainBg text-filter"
              }`}
              type={showPassword ? "text" : "password"}
              id="login-password"
              placeholder="Enter your Password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              required
            />
            <span
              className="absolute right-2 top-[2.4rem] cursor-pointer text-eyeIconColor"
              onClick={togglePasswordVisibility}
            >
              {showPassword ? (
                <i class="fa-solid fa-eye"></i>
              ) : (
                <i class="fa-solid fa-eye-slash"></i>
              )}
            </span>
          </div>
          <div className="text-blueColor flex justify-end pb-3">
            <button
              className="text-sm text-blueColor"
              onClick={() => {
                dispatch(closeLogin());
                dispatch(openForgotPassword());
                dispatch(openLoginPopup());
                dispatch(openResetPassword());
              }}
            >
              Forgot password?
            </button>
          </div>

          <div className="flex justify-end mb-7">
            {/* <div className="flex items-center gap-2">
              <input
                type="checkbox"
                id="login-remember"
                name="checkbox"
                checked={formData.checkbox}
                onChange={handleChange}
              />
              <label
                htmlFor="login-remember"
                className={`text-sm ${
                  currentTheme === "dark" ? " text-mainBg" : "text-filter"
                }`}
              >
                Remember me
              </label>
            </div> */}
        <div>
              <ReCAPTCHA
                sitekey={recaptchaSiteKey}
                onChange={value => setRecaptchaValue(value)}
              />
            </div>
          </div>
          <div className="text-center mb-3">
            <button
              className="bg-primary w-full py-2 rounded-[7px] text-secondary mb-5 disabled:bg-disabledButton"
              onClick={handleLogin}
              disabled={isLoading || getUsers.isLoading}
            >
              {getUsers.isLoading || isLoading ? "Logging in..." : "Login"}
            </button>
            {isError && (
              <div
                className={`${currentTheme === "dark" ? " text-mainBg" : "text-filter"}`}
              >
                Error occurred during login.
              </div>
            )}
            <p
              className={`text-sm ${
                currentTheme === "dark" ? " text-mainBg" : "text-filter"
              }`}
            >
              Don't have an account yet?{" "}
              <button
                onClick={() => {
                  dispatch(closeLogin());
                  dispatch(openRegister());
                }}
                className="text-blueColor font-medium"
              >
                Register
              </button>
            </p>
          </div>
          {/* <div className="text-center relative h-10 mb-4">
            <span className="bg-mainBg z-10 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 inline-block w-16">
              or
            </span>
            <div className="h-[1px] w-full bg-[#dadada] absolute top-1/2 -translate-y-1/2"></div>
          </div>
          <div className="flex flex-col">
            <button className="flex items-center justify-center border-[1px] border-primary rounded-[7px] py-1 mb-4">
              <img className="h-8" src={BinanceLogo} alt="Binance-icon" />
              Continue with Binance
            </button>
            <button className="flex items-center justify-center border-[.5px] border-[#B9B9B9] rounded-[7px] py-1">
              <img className="h-8" src={WalletLogo} alt="Wallet-icon" />
              Continue with Wallet
            </button>
          </div> */}
        </div>
      </div>
    </Modal>
  );
};

export default LoginPopup;