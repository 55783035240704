import React, { useEffect, useRef, useState } from "react";
import { selectCurrentTheme } from "../../store/slices/themeSlice";
import SearchIcon from "../../assets/icons/search.svg";
import { Link } from "react-router-dom";
import { useGetHeaderSearchCoinQuery } from "../../store/services/services";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import {
  useGetCoinGainersQuery,
  useGetCoinLosersQuery
} from "../../store/services/services";
import { useSelector } from "react-redux";
import CryptoIcon from "../../assets/icons/crypto-icon.svg";

const HeaderSearch = () => {
  const [searchTerm, setSearchTerm] = useState("");

  const [searchItems, setSearchItems] = useState(false);

  const searchCoins = useRef();
  const currentTheme = useSelector(selectCurrentTheme);
  ////////////////////////// api for top gainers ///////////////////////
  const {
    data: topGainers,
    isLoading: gainersIsLoading,
    isError: gainersIsError
  } = useGetCoinGainersQuery();

  ////////////////////////// api for top losers ///////////////////////
  const { data: topLosers, isLoading: losersIsLoading } = useGetCoinLosersQuery();

  ////////////////////////// api for search coin ///////////////////////
  const {
    data: searchCoin,
    isError: searchIsError,
    isLoading: searchIsLoading,
    isFetching: searchIsFetching,
    refetch: searchRefetch
  } = useGetHeaderSearchCoinQuery(searchTerm);
  // Debounce function
  const debounce = (func, delay) => {
    let timeoutId;
    return function (...args) {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };
  // Search coin with debounce
  const debouncedSearch = useRef(
    debounce(value => {
      if (value.trim() !== "") {
        searchRefetch(value);
        setSearchItems(true);
      } else {
        setSearchItems(false);
      }
    }, 1000)
  ).current;

  // Handle input change with debouncing
  const searchHandler = e => {
    const { value } = e.target;
    setSearchTerm(value);
    debouncedSearch(value);
  };
  // // search coin
  // const searchHandler = e => {
  //   setSearchTerm(e.target.value);
  // };
  const searchFocusHandler = () => {
    setSearchItems(true);
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleClickOutside = event => {
    if (searchCoins.current && !searchCoins.current.contains(event.target)) {
      setSearchItems(false);
    }
  };

  const closeDropdown = () => {
    setSearchItems(false);
  };

  return (
    <div
      className={`h-full flex items-center px-3 py-4 xs:py-2 flex-col justify-center relative z-50 xs:absolute xs:top-20 xs:left-0 xs:px-8 xs:mt-6 xs:w-full ${
        currentTheme === "dark" ? "bg-cardsColor" : "bg-mainBg"
      }`}
      ref={searchCoins}
    >
      <div
        className={`flex items-center border-2 border-inputBorder h-[38px] rounded-full px-3 py-1 gap-3 xs:w-full focus-within:border-primary transition-all duration-300 z-50 ${
          currentTheme === "dark" ? "bg-[#323546]" : "bg-mainBg"
        }`}
      >
        <i class={"fas fa-search text-searchIconColor text-base"}></i>
        {/* <img src={SearchIcon} alt="search" /> */}
        <input
          className={`border-0 outline-0 overflow-hidden bg-transparent text-base w-44 sm:w-36 xs:w-full ${
            currentTheme === "dark" ? " text-mainBg" : "text-filter"
          } ${
            searchItems ? "w-96 lg:w-80 md:w-64 sm:w-44 xs:w-full" : ""
          } transition-all duration-300`}
          onFocus={searchFocusHandler}
          value={searchTerm}
          onChange={searchHandler}
          type="text"
        />
      </div>
      {
        <div
          className={`absolute top-0 shadow-dropdown z-30 ${
            searchItems ? "h-80 block py-5 " : "h-0 p-0"
          } w-full rounded-[20px] transition-all duration-200 overflow-hidden ${
            currentTheme === "dark" ? "bg-cardsColor" : "bg-mainBg"
          }`}
        >
          <div className="mt-16 max-h-56 overflow-auto scrollbar px-5 sm:px-2 text-sm">
            {searchTerm === "" && (
              <>
                <div className="mb-4 text-sm">
                  <h3
                    className={`font-semibold mb-3 ${
                      currentTheme === "dark" ? " text-mainBg" : "text-textGray"
                    }`}
                  >
                    Top Gainers
                  </h3>
                  <div className="flex flex-wrap">
                    {gainersIsLoading && (
                      <div className="w-full">
                        <SkeletonTheme
                          baseColor={`${currentTheme === "dark" ? "#323546" : "#d8d8d8"}`}
                          highlightColor={`${
                            currentTheme === "dark" ? "#444" : "#e0e0e0"
                          }`}
                        >
                          <Skeleton width="100%" height={35} count={3} />
                        </SkeletonTheme>
                      </div>
                    )}
                    {!gainersIsLoading &&
                      !gainersIsError &&
                      topGainers?.data?.map(item => (
                        <Link
                          className={`flex w-1/2 md:w-full xs:w-1/2 gap-2 border-b-[.5px] border-r-[.5px] p-3 items-center cursor-pointer ${
                            currentTheme === "dark"
                              ? "border-darkBorder"
                              : "border-lightBorder"
                          }`}
                          key={item.id}
                          to={`/coin-detail/${item?.logo}`}
                          onClick={closeDropdown}
                        >
                          <img
                            className="w-6"
                            src={item?.img ? item?.img : CryptoIcon}
                            alt="coin-icon"
                          />

                          <span
                            className={`font-medium w-4/5 overflow-hidden whitespace-nowrap overflow-ellipsis ${
                              currentTheme === "dark" ? "text-mainBg" : "text-filter"
                            }`}
                          >
                            {item?.name}
                          </span>
                          <span
                            className={`text-xs ${
                              currentTheme === "dark" ? "text-mainBg" : "text-filter"
                            }`}
                          >
                            {item?.logo}
                          </span>
                        </Link>
                      ))}
                  </div>
                </div>
                <div className="mb-4 ">
                  <h3
                    className={`font-semibold mb-3 ${
                      currentTheme === "dark" ? " text-mainBg" : "text-textGray"
                    }`}
                  >
                    Top Losers
                  </h3>
                  <div className="flex flex-wrap">
                    {losersIsLoading && (
                      <div className="w-full">
                        <SkeletonTheme
                          baseColor={`${currentTheme === "dark" ? "#323546" : "#d8d8d8"}`}
                          highlightColor={`${
                            currentTheme === "dark" ? "#444" : "#e0e0e0"
                          }`}
                        >
                          <Skeleton width="100%" height={35} count={3} />
                        </SkeletonTheme>
                      </div>
                    )}
                    {topLosers?.data?.map(item => (
                      <Link
                        className={`flex w-1/2 md:w-full xs:w-1/2 gap-2 border-b-[.5px] border-r-[.5px] p-3 items-center cursor-pointer ${
                          currentTheme === "dark"
                            ? "border-darkBorder"
                            : "border-lightBorder"
                        }`}
                        key={item.id}
                        to={`/coin-detail/${item.logo}`}
                        onClick={closeDropdown}
                      >
                        <img
                          className="w-6"
                          src={item?.img ? item?.img : CryptoIcon}
                          alt="coin-icon"
                        />

                        <span
                          className={`font-medium w-4/5 overflow-hidden whitespace-nowrap overflow-ellipsis ${
                            currentTheme === "dark" ? "text-mainBg" : "text-filter"
                          }`}
                        >
                          {item?.name}
                        </span>
                        <span
                          className={`text-xs ${
                            currentTheme === "dark" ? "text-mainBg" : "text-filter"
                          }`}
                        >
                          {item?.logo}
                        </span>
                      </Link>
                    ))}
                  </div>
                </div>
              </>
            )}
            {searchTerm !== "" && (
              <div>
                <h3
                  className={`font-semibold mb-3 ${
                    currentTheme === "dark" ? "text-mainBg" : "text-textGray"
                  }`}
                >
                  Search currencies
                </h3>
                {(searchIsLoading || searchIsFetching) && (
                  <div className="w-full">
                    <SkeletonTheme
                      baseColor={`${currentTheme === "dark" ? "#323546" : "#d8d8d8"}`}
                      highlightColor={`${currentTheme === "dark" ? "#444" : "#e0e0e0"}`}
                    >
                      <Skeleton width="100%" height={35} count={3} />
                    </SkeletonTheme>
                  </div>
                )}
                {!searchIsLoading && !searchIsError && !searchIsFetching && (
                  <div>
                    {" "}
                    {searchCoin?.length > 0 && (
                      <div className="flex flex-wrap">
                        {searchCoin?.map(item => (
                          <Link
                            to={`/coin-detail/${item.logo}`}
                            key={item.id}
                            className={`flex w-1/3 md:w-1/2 sm:w-full xs:w-1/2 gap-2 border-b-[.5px] border-r-[.5px] p-3 items-center cursor-pointer ${
                              currentTheme === "dark"
                                ? "border-darkBorder"
                                : "border-lightBorder"
                            }`}
                            onClick={closeDropdown}
                          >
                            <div className="w-5">
                              <img
                                className="w-full"
                                src={item.img ? item.img : CryptoIcon}
                                alt="currency-icon"
                              />
                            </div>
                            <div className="flex flex-col w-2/3">
                              <span
                                className={`font-medium w-full overflow-hidden whitespace-nowrap overflow-ellipsis ${
                                  currentTheme === "dark" ? "text-mainBg" : "text-filter"
                                }`}
                              >
                                {item?.name}
                              </span>
                              <span
                                className={`text-xs ${
                                  currentTheme === "dark" ? "text-mainBg" : "text-filter"
                                }`}
                              >
                                {item?.logo}
                              </span>
                            </div>
                          </Link>
                        ))}
                      </div>
                    )}
                    {searchCoin?.length === 0 && (
                      <div
                        className={`text-center my-10 font-medium text-base ${
                          currentTheme === "dark"
                            ? "text-mainBg"
                            : "text-currencyNotFoundText"
                        }`}
                      >
                        No Currencies Found
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      }
    </div>
  );
};

export default HeaderSearch;
