import React, { useState } from "react";
import TimeSvg from "../../assets/icons/time-icon.svg";
import LinkedinSvg from "../../assets/icons/linkedin-img.svg";
import YoutubeSvg from "../../assets/icons/youtube-img.svg";
import FacebookSvg from "../../assets/icons/facebook-img.svg";
import InstagramSvg from "../../assets/icons/instagram-img.svg";
import Avalanche from "../../assets/images/avalancheBanner.jpg";
import { useParams } from "react-router-dom";
import { useGetAllNewsPostQuery } from "../../store/services/services";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { selectCurrentTheme } from "../../store/slices/themeSlice";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterIcon,
  TwitterShareButton,
  LinkedinShareButton,
  LinkedinIcon,
  WhatsappShareButton,
  WhatsappIcon
} from "react-share";

const SingleNews = ({ newsId }) => {
  const [readMoreEnabled, setReadMoreEnabled] = useState(false);
  const [isUrlCopied, setIsUrlCopied] = useState(false);
  const { id } = useParams();
  const currentTheme = useSelector(selectCurrentTheme);
  const { page } = useParams();
  const { data, isLoading, isFaching, isError, error } = useGetAllNewsPostQuery(page);

  if (isError) {
    return <p>{error.message}</p>;
  }
  let filterItems;
  if (!isLoading && !isFaching && !isError) {
    filterItems = data?.data?.find(news => news.id === parseInt(id))
  }
  newsId(id);
  const url = window.location.href;
  const copyUrlToClipboard = () => {
    const textField = document.createElement("textarea");
    textField.innerText = url;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();
    setIsUrlCopied(true);
  };
  const truncatedDescription =
    filterItems?.description.length > 1000
      ? `${filterItems.description.substring(0, 1000)}...`
      : filterItems?.description;
  return (
    <div>
      <div>
        {/* <div>
          <p
            className={`text-base font-normal xs:text-sm ${
              currentTheme === "dark" ? "text-mainBg " : "text-filter"
            }`}
          >
            How-to Guides / Blog / Tech Deep Dives
          </p>
          <h1
            className={`text-2xl sm:text-2xl xs:text-sm font-semibold mt-2 ${
              currentTheme === "dark" ? "text-mainBg " : "text-filter"
            }`}
          >
            How To Use The AtomicHub Platform
          </h1>
        </div> */}
        <div
          className={`shadow-navbar-button rounded-[40px] p-8 sm:p-4 mt-10 ${
            currentTheme === "dark" ? "bg-cardsColor " : "bg-mainBg"
          }`}
        >
          {isLoading ? (
            <>
              <div>
                <SkeletonTheme
                  baseColor={`${currentTheme === "dark" ? "#323546" : "#d8d8d8"}`}
                  highlightColor={`${currentTheme === "dark" ? "#444" : "#e0e0e0"}`}
                >
                  <Skeleton width="13.5%" height={24} count={1} />
                </SkeletonTheme>
              </div>
              <div className="mt-14">
                <SkeletonTheme
                  baseColor={`${currentTheme === "dark" ? "#323546" : "#d8d8d8"}`}
                  highlightColor={`${currentTheme === "dark" ? "#444" : "#e0e0e0"}`}
                >
                  <Skeleton width="35%" height={500} count={1} borderRadius="30px" />
                </SkeletonTheme>
              </div>
              <div className="mt-6">
                <SkeletonTheme
                  baseColor={`${currentTheme === "dark" ? "#323546" : "#d8d8d8"}`}
                  highlightColor={`${currentTheme === "dark" ? "#444" : "#e0e0e0"}`}
                >
                  <Skeleton width="100%" height={24} count={3} />
                </SkeletonTheme>
              </div>
            </>
          ) : (
            <>
              <div className="flex justify-between sm:grid gap-6">
                <div
                  className={`flex items-center gap-2  py-2 px-5 rounded-[20.5px] ${
                    currentTheme === "dark"
                      ? "bg-filter text-mainBg "
                      : "bg-socialMediaBg text-filterSwitchColor"
                  }`}
                >
                  <img src={TimeSvg} alt="time-icon" />
                  <div className={"text-base"}>
                    {new Date(filterItems?.published_at)?.toDateString()}
                  </div>
                </div>
                <div className="flex items-center gap-3">
                  <div
                    className={`flex items-center py-2 px-5 rounded-[20.5px] cursor-pointer  ${
                      currentTheme === "dark"
                        ? "bg-filter text-mainBg "
                        : "bg-socialMediaBg text-filterSwitchColor"
                    }`}
                  >
                    <LinkedinShareButton url={url}>
                      <i class="fa-brands fa-linkedin border-r-[1px] border-lightBorder pr-3 pl-3 hover:text-primary"></i>
                    </LinkedinShareButton>
                    <FacebookShareButton url={url}>
                      <i class="fa-brands fa-facebook border-r-[1px] border-lightBorder pr-3 pl-3 hover:text-primary"></i>
                    </FacebookShareButton>
                    <WhatsappShareButton url={url}>
                      <i class="fa-brands fa-whatsapp border-r-[1px] border-lightBorder pr-3 pl-3 hover:text-primary"></i>
                    </WhatsappShareButton>
                    <TwitterShareButton url={url}>
                      <i class="fa-brands fa-twitter border-r-[1px] border-lightBorder pr-3 pl-3 hover:text-primary"></i>
                    </TwitterShareButton>
                    <i
                      className="fa-solid fa-link pr-3 pl-3 hover:text-primary"
                      onClick={copyUrlToClipboard}
                    ></i>
                  </div>
                  {/* <div className="mt-6">
                    <button
                      className={`w-full border text-center bg-${
                        isUrlCopied ? "" : "popupBg"
                      } py-3 rounded-lg  font-semibold  ${
                        currentTheme === "dark" ? "text-mainBg" : "text-popupText"
                      }`}
                      onClick={copyUrlToClipboard}
                    >
                      {isUrlCopied ? "URL Copied" : "Copy Link"}
                    </button>
                  </div> */}
                </div>
              </div>
              <div className="gap-6 xl:gap-8 md:gap-4 lg:gap-6 mt-14 lg:mt-10 xl:mt-12 sm:mt-8 xs:mt-6 pb-10">
                <div className="">
                  <img
                    src={
                      filterItems?.url_to_image ? filterItems?.url_to_image : Avalanche
                    }
                    alt="atomic-img"
                    className="w-[35%] rounded-[40px] sm:w-full"
                  />
                </div>
                <div className="mt-6">
                  <h1
                    className={`text-2xl sm:text-2xl xs:text-sm font-semibold ${
                      currentTheme === "dark" ? "text-mainBg " : "text-filter"
                    }`}
                  >
                    {filterItems?.title}
                  </h1>
                  <p
                    className={`text-base sm:text-sm xl:text-xl mt-4 ${
                      currentTheme === "dark" ? "text-mainBg " : "text-filter"
                    }`}
                  >
                    {readMoreEnabled ? filterItems?.description : truncatedDescription}
                  </p>
                  {filterItems?.description?.length > 1000 && (
                    <div className="mt-4 cursor-pointer font-semibold text-[18px] text-blueColor">
                      {readMoreEnabled ? (
                        <button
                          onClick={() => setReadMoreEnabled(false)}
                          className={` border border-primary font-medium text-xs px-4 py-1 rounded-[5px] ${
                            currentTheme === "dark"
                              ? "text-mainBg bg-cardsColor"
                              : "bg-coinNewsBgColor text-primaryText"
                          }`}
                        >
                          Read less
                        </button>
                      ) : (
                        <button
                          onClick={() => setReadMoreEnabled(true)}
                          className={` border border-primary font-medium text-xs px-4 py-1 rounded-[5px]  ${
                            currentTheme === "dark"
                              ? "text-mainBg bg-cardsColor"
                              : "bg-coinNewsBgColor text-primaryText"
                          }`}
                        >
                          Read more
                        </button>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
export default SingleNews;