import React,{useState} from 'react'
import { selectCurrentTheme } from "../../store/slices/themeSlice";
import { useDispatch,useSelector } from 'react-redux';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera, faPencil } from "@fortawesome/free-solid-svg-icons";
import ProfileEditPopup from '../ProfileEditPopup/ProfileEditPopup';
import {
  closeModal,
  openUserProfileView,
  userProfilePopup
} from "../../store/slices/popupSlice";

const UserProfile = () => {
     const currentTheme = useSelector(selectCurrentTheme);
     const [coverPhoto, setCoverPhoto] = useState(null);
     const [profilePhoto, setProfilePhoto] = useState(null);

   const dispatch = useDispatch()

     const handleCoverPhotoChange = e => {
       const file = e.target.files[0];
       setCoverPhoto(file);
     };

     const handleProfilePhotoChange = e => {
       const file = e.target.files[0];
       setProfilePhoto(file);
     };
 const openProfilePopup = useSelector(state => state.popup.userProfilePopup);
  return (
    <div
      className={`px-24 md:px-8 xs:px-4 mt-12 ${
        currentTheme === "dark" ? "text-mainBg" : "text-filter"
      }`}
    >
      <div className="flex flex-col md:flex-row">
        {/* Left Column - User Info */}
        <div className="md:w-1/4">
          {/* <label
              htmlFor="coverPhoto"
              className="block text-sm font-medium text-gray-600"
            >
              Cover Photo
            </label> */}
          <input
            type="file"
            id="coverPhoto"
            accept="image/*"
            onChange={handleCoverPhotoChange}
            className="hidden"
          />
          <label
            htmlFor="coverPhoto"
            className="cursor-pointer block w-full h-32 md:h-48 border border-lightBorder rounded-lg overflow-hidden mb-4"
          >
            {coverPhoto ? (
              <img
                src={URL.createObjectURL(coverPhoto)}
                alt="Cover Photo"
                className="w-full h-full object-cover"
              />
            ) : (
              <div className="flex items-center justify-center h-full">
                <span className="text-gray-500">
                  {" "}
                  <FontAwesomeIcon icon={faCamera} className="text-gray-500 text-2xl" />
                </span>
              </div>
            )}
          </label>
        </div>
      </div>
      <div className="flex justify-between items-center">
        <div className="flex items-center gap-3">
          <input
            type="file"
            id="profilePhoto"
            accept="image/*"
            onChange={handleProfilePhotoChange}
            className="hidden"
          />
          <label
            htmlFor="profilePhoto"
            className="cursor-pointer block w-32 h-32 md:w-48 md:h-48 border border-gray-300 rounded-full overflow-hidden mb-4 sm:mx-0"
          >
            {profilePhoto ? (
              <img
                src={URL.createObjectURL(profilePhoto)}
                alt="Profile Photo"
                className="w-full h-full object-cover"
              />
            ) : (
              <div className="flex items-center justify-center h-full">
                <span className="text-gray-500">
                  <FontAwesomeIcon icon={faCamera} className="text-gray-500 text-2xl" />
                </span>
              </div>
            )}
          </label>
          <div>
            <h2 className="text-2xl font-bold mb-2">James</h2>
            <h2>email123@gmail.com</h2>
          </div>
        </div>
        <div
          className="flex items-center gap-3 border border-primary px-3 py-1 cursor-pointer"
          onClick={() => {
            dispatch(closeModal());
            dispatch(openUserProfileView());
          }}
        >
          <div>
            <FontAwesomeIcon icon={faPencil} />
          </div>
          <p>Edit Profile</p>
        </div>
      </div>
      {openProfilePopup && (
        <ProfileEditPopup
          handleProfilePhotoChange={handleProfilePhotoChange}
          profilePhoto={profilePhoto}
        />
      )}
    </div>
  );
}

export default UserProfile