import React, { useEffect } from "react";
import MainSingleNews from "../components/MainSingleNews";
const SingleNewsProduct = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <MainSingleNews />
    </div>
  );
};

export default SingleNewsProduct;
