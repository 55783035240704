import React from "react";
import TopLosersCurrencyTable from "../components/TopLosersCurrencyTable/TopLosersCurrencyTable";

const FilterCurrencyTablePage = () => {
  return (
    <div>
      <TopLosersCurrencyTable />
    </div>
  );
};

export default FilterCurrencyTablePage;
