import React from "react";
import { commafy } from "../../utils/commafy";
import { toDecimals } from "../../utils/toDecimals";
import { useSelector } from "react-redux";
import { selectCurrentTheme } from "../../store/slices/themeSlice";

const CompareCoinTable = () => {
  const selectedCoins = useSelector(state => state.compare.selectedCoins);
  const currentTheme = useSelector(selectCurrentTheme);
  return (
    <div className="px-6 md:px-4 xs:px-0 mt-12 mb-8 xs:mb-0 ">
      <div className="overflow-auto w-full scrollbar mb-6">
        <table className="w-full  scrollbar" cellPadding={10} cellSpacing={10}>
          <thead>
            <tr
              className={`border-b-[1px] pb-2 text-sm xs:text-xs ${
                currentTheme === "dark" ? "border-darkBorder" : "border-lightBorder"
              }`}
            >
              <th
                className={`min-w-[100px] max-w-[] w-[200px] text-left pb-2 font-semibold sticky left-0 before ${
                  currentTheme === "dark" ? "bg-cardsColor text-mainBg " : "bg-mainBg"
                }`}
              ></th>
              {selectedCoins.map(item => (
                <th className="w-[150px] xs:max-w-[36px] text-left pb-2 font-semibold pl-10 xs:pl-8">
                  {item.code}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr
              className={`text-sm xs:text-xs border-b-[1px] ${
                currentTheme === "dark" ? "border-darkBorder" : "border-lightBorder"
              }`}
            >
              <td
                className={`py-3 font-semibold sticky left-0  before ${
                  currentTheme === "dark" ? "bg-cardsColor text-mainBg " : "bg-mainBg"
                }`}
              >
                Rank
              </td>
              {selectedCoins.map(item => (
                <td
                  key={item.id}
                  className={`pl-10 xs:pl-8 ${
                    currentTheme === "dark" ? "text-mainBg " : "text-filter"
                  }`}
                >
                  {item?.rank}
                </td>
              ))}
            </tr>
            <tr
              className={`text-sm xs:text-xs border-b-[1px] ${
                currentTheme === "dark" ? "border-darkBorder" : "border-lightBorder"
              }`}
            >
              <td
                className={`py-3 font-semibold  sticky left-0 before ${
                  currentTheme === "dark" ? "bg-cardsColor text-mainBg " : "bg-mainBg"
                }`}
              >
                Price
              </td>
              {selectedCoins.map(item => (
                <td
                  key={item.id}
                  className={`pl-10 xs:pl-8 ${
                    currentTheme === "dark" ? "text-mainBg " : "text-filter"
                  }`}
                >
                  ${commafy(toDecimals(item?.price))}
                </td>
              ))}
            </tr>
            <tr
              className={`text-sm xs:text-xs border-b-[1px] ${
                currentTheme === "dark" ? "border-darkBorder" : "border-lightBorder"
              }`}
            >
              <td
                className={`py-3 font-semibold  sticky left-0  before ${
                  currentTheme === "dark" ? "bg-cardsColor text-mainBg " : "bg-mainBg"
                }`}
              >
                Market Cap
              </td>
              {selectedCoins.map(item => (
                <td
                  key={item.id}
                  className={`pl-10 xs:pl-8 ${
                    currentTheme === "dark" ? "text-mainBg " : "text-filter"
                  }`}
                >
                  $
                  {Intl.NumberFormat("en", { notation: "compact" }).format(
                    item?.market_cap
                  )}
                </td>
              ))}
            </tr>
            <tr
              className={`text-sm xs:text-xs border-b-[1px] ${
                currentTheme === "dark" ? "border-darkBorder" : "border-lightBorder"
              }`}
            >
              <td
                className={`py-3 font-semibold  sticky left-0  before ${
                  currentTheme === "dark" ? "bg-cardsColor text-mainBg " : "bg-mainBg"
                }`}
              >
                Volume
              </td>
              {selectedCoins.map(item => (
                <td
                  key={item.id}
                  className={`pl-10 xs:pl-8 ${
                    currentTheme === "dark" ? "text-mainBg " : "text-filter"
                  }`}
                >
                  ${commafy(toDecimals(item?.volume_24h))}
                </td>
              ))}
            </tr>
            <tr
              className={`text-sm xs:text-xs border-b-[1px] ${
                currentTheme === "dark" ? "border-darkBorder" : "border-lightBorder"
              }`}
            >
              <td
                className={`py-3 font-semibold  sticky left-0  before ${
                  currentTheme === "dark" ? "bg-cardsColor text-mainBg " : "bg-mainBg"
                }`}
              >
                Vol/MCap
              </td>
              {selectedCoins.map(item => (
                <td
                  key={item.id}
                  className={`pl-10 xs:pl-8 ${
                    currentTheme === "dark" ? "text-mainBg " : "text-filter"
                  }`}
                >
                  {commafy(toDecimals(item?.volume_marketCap_24h))}%
                </td>
              ))}
            </tr>
            <tr
              className={`text-sm xs:text-xs border-b-[1px] ${
                currentTheme === "dark" ? "border-darkBorder" : "border-lightBorder"
              }`}
            >
              <td
                className={`py-3 font-semibold  sticky left-0  before ${
                  currentTheme === "dark" ? "bg-cardsColor text-mainBg " : "bg-mainBg"
                }`}
              >
                Circulating Supply
              </td>
              {selectedCoins.map(item => (
                <td key={item.id} className=" pl-10 xs:pl-8">
                  <div
                    className={`flex gap-2 ${
                      currentTheme === "dark" ? "text-mainBg " : "text-filter"
                    }`}
                  >
                    {commafy(toDecimals(item?.circulating_supply))}
                    <p>{item?.logo}</p>
                  </div>
                </td>
              ))}
            </tr>
            <tr
              className={`text-sm xs:text-xs border-b-[1px] ${
                currentTheme === "dark" ? "border-darkBorder" : "border-lightBorder"
              }`}
            >
              <td
                className={`py-3 font-semibold  sticky left-0  before ${
                  currentTheme === "dark" ? "bg-cardsColor text-mainBg " : "bg-mainBg"
                }`}
              >
                Total Supply
              </td>
              {selectedCoins.map(item => (
                <td
                  key={item.id}
                  className={`pl-10 xs:pl-8 ${
                    currentTheme === "dark" ? "text-mainBg " : "text-filter"
                  }`}
                >
                  <div className="flex gap-2">
                    {commafy(toDecimals(item?.total_supply))}
                    <p>{item?.logo}</p>
                  </div>
                </td>
              ))}
            </tr>
            <tr
              className={`text-sm xs:text-xs border-b-[1px] ${
                currentTheme === "dark" ? "border-darkBorder" : "border-lightBorder"
              }`}
            >
              <td
                className={`py-3 font-semibold  sticky left-0  before ${
                  currentTheme === "dark" ? "bg-cardsColor text-mainBg " : "bg-mainBg"
                }`}
              >
                Max Supply
              </td>
              {selectedCoins.map(item => (
                <td key={item.id} className=" pl-10 xs:pl-8">
                  <div
                    className={`flex gap-2 ${
                      currentTheme === "dark" ? "text-mainBg " : "text-filter"
                    }`}
                  >
                    {commafy(toDecimals(item?.max_supply))}
                    <p>{item?.logo}</p>
                  </div>
                </td>
              ))}
            </tr>
            {/* <tr className="text-sm xs:text-xs border-b-[1px] border-lightBorder">
              <td className="py-3 font-semibold  sticky left-0 bg-mainBg before">
                Supply Issued
              </td>
              {selectedCoins.map((item) => (
                <td key={item.id} className=" pl-10 xs:pl-8">
                  {item.supplyIssued}
                </td>
              ))}
            </tr> */}
            <tr
              className={`text-sm xs:text-xs border-b-[1px] ${
                currentTheme === "dark" ? "border-darkBorder" : "border-lightBorder"
              }`}
            >
              <td
                className={`py-3 font-semibold  sticky left-0  before ${
                  currentTheme === "dark" ? "bg-cardsColor text-mainBg " : "bg-mainBg"
                }`}
              >
                1h
              </td>
              {selectedCoins.map(item => (
                <td
                  key={item.id}
                  className={`pl-10 xs:pl-8 ${
                    item.percentChangeHour > 0 ? "text-increase" : "text-decrease"
                  }`}
                >
                  {commafy(toDecimals(item?.percentChangeHour))}%
                </td>
              ))}
            </tr>
            <tr
              className={`text-sm xs:text-xs border-b-[1px] ${
                currentTheme === "dark" ? "border-darkBorder" : "border-lightBorder"
              }`}
            >
              <td
                className={`py-3 font-semibold  sticky left-0  before ${
                  currentTheme === "dark" ? "bg-cardsColor text-mainBg " : "bg-mainBg"
                }`}
              >
                24h
              </td>
              {selectedCoins.map(item => (
                <td
                  key={item.id}
                  className={`pl-10 xs:pl-8 ${
                    item.percentChangeDay > 0 ? "text-increase" : "text-decrease"
                  }`}
                >
                  {commafy(toDecimals(item?.percentChangeDay))}%
                </td>
              ))}
            </tr>
            <tr
              className={`text-sm xs:text-xs border-b-[1px] ${
                currentTheme === "dark" ? "border-darkBorder" : "border-lightBorder"
              }`}
            >
              <td
                className={`py-3 font-semibold  sticky left-0  before ${
                  currentTheme === "dark" ? "bg-cardsColor text-mainBg " : "bg-mainBg"
                }`}
              >
                7d
              </td>
              {selectedCoins.map(item => (
                <td
                  key={item.id}
                  className={`pl-10 xs:pl-8 ${
                    item.percentChangeWeek > 0 ? "text-increase" : "text-decrease"
                  }`}
                >
                  {commafy(toDecimals(item?.percentChangeWeek))}%
                </td>
              ))}
            </tr>
            <tr
              className={`text-sm xs:text-xs border-b-[1px] ${
                currentTheme === "dark" ? "border-darkBorder" : "border-lightBorder"
              }`}
            >
              <td
                className={`py-3 font-semibold  sticky left-0  before ${
                  currentTheme === "dark" ? "bg-cardsColor text-mainBg " : "bg-mainBg"
                }`}
              >
                30d
              </td>
              {selectedCoins.map(item => (
                <td
                  key={item.id}
                  className={`pl-10 xs:pl-8 ${
                    item.percentChangeMonthly > 0 ? "text-increase" : "text-decrease"
                  }`}
                >
                  {commafy(toDecimals(item?.percentChangeMonthly))}%
                </td>
              ))}
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CompareCoinTable;
