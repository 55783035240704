import React, { useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { portfolioChange } from "../../store/slices/portfolioChangeSlice";
import { useDeleteSingleTransactionMutation } from "../../store/services/services";
import { closeSingleTransactionRemove } from "../../store/slices/popupSlice";
import Modal from "../../utils/Modal";
import { selectCurrentTheme } from "../../store/slices/themeSlice";

const RemoveSingleTransactionPopup = ({ transactionsId }) => {
  const dispatch = useDispatch();
  const [deleteSingleTransaction, { isLoading, isSuccess }] =
    useDeleteSingleTransactionMutation();
  const currentTheme = useSelector(selectCurrentTheme);
  const removeTransactionHandler = async () => {
    try {
      await deleteSingleTransaction(transactionsId);
    } catch (error) {}
  };

  useEffect(() => {
    if (isSuccess) {
      dispatch(portfolioChange());
      dispatch(closeSingleTransactionRemove());
    }
  }, [isSuccess, dispatch]);
  return (
    <div>
      <Modal maxWidth={600}>
        <div className="p-7 xs:px-4">
          <div className="mb-6">
            <p
              className={`text-[22px] xs:text-xl font-semibold mb-2 ${
                currentTheme === "dark" ? " text-mainBg" : "text-secondary"
              }`}
            >
              Remove Transaction
            </p>
            <p className={`${currentTheme === "dark" ? "text-mainBg " : "text-filter"}`}>
              Do you want to delete your Transaction? Performing this action, you
              understand that you would not be able to recover your Transaction.
            </p>
          </div>
          <div>
            <button
              className="w-full text-center bg-decrease py-2 rounded-[6px] mb-4 text-mainBg font-semibold disabled:bg-removeDisabledColor"
              onClick={removeTransactionHandler}
              disabled={isLoading}
            >
              {isLoading ? "Removing..." : "Remove"}
            </button>
            <button
              className={`w-full text-center py-2 rounded-[6px] font-semibold ${
                currentTheme === "dark"
                  ? "bg-inputColor text-mainBg"
                  : "bg-popupBg text-popupText"
              }`}
              onClick={() => dispatch(closeSingleTransactionRemove())}
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default RemoveSingleTransactionPopup