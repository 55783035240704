import React from "react";
import PortfolioMain from "../PortfolioMain";

const PortfolioAccessed = () => {
  return (
    <div>
      <PortfolioMain />
    </div>
  );
};

export default PortfolioAccessed;
