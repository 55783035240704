import React, { useState } from "react";
import PortfolioCard from "../PortfolioCard";
import PortfolioTable from "../PortfolioTable";
import PortfolioAllocation from "../PortfolioAllocation";
import PortfolioStatistics from "../PortfolioStatistics";
import TransactionsDetail from "../TransactionsDetail/TransactionsDetail";

const PortfolioMain = () => {
  const [isClicked, setIsClicked] = useState(false);
  const [selectedCoin, setSelectedCoin] = useState(null);

  const handleCoin = coin => {
    setSelectedCoin(coin);
    setIsClicked(true);
  };
  const goBack = () => {
    setIsClicked(false);
  };
  return (
    <div>
      {isClicked ? (
        <TransactionsDetail portfolioCoin={selectedCoin} goBack={goBack} />
      ) : (
        <>
          <PortfolioCard />
          <PortfolioStatistics />
          <PortfolioAllocation />
          <PortfolioTable handleCoin={handleCoin} />
        </>
      )}
    </div>
  );
};

export default PortfolioMain;
