import React, { useState } from "react";
import NewsCard from "../NewsCard";
import { useGetAllNewsPostQuery } from "../../store/services/services";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { useNavigate, useLocation } from "react-router-dom";
import NewsPagination from "../NewsPagination/NewsPagination";
import { selectCurrentTheme } from "../../store/slices/themeSlice";
import { useSelector } from "react-redux";

const AllNewsCard = ({
  isLoading,
  isFetching,
  isError,
  error,
  searchData,
  searchInput
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const {
    data,
    isLoading: allLoading,
    isFetching: allFetching,
    error: allError
  } = useGetAllNewsPostQuery(currentPage);
  const navigate = useNavigate();
  const currentTheme = useSelector(selectCurrentTheme);
  const paginate = pageNumber => {
    setCurrentPage(pageNumber);
    navigate(`${location}?page=${pageNumber}`);
  };
  const location = useLocation().pathname;

  return (
    <div>
      {isError && <p>{error.message}</p>}
      <>
        <div className="flex flex-wrap justify-center mt-7 gap-5">
          {(isLoading || allLoading || allFetching || isFetching) && (
            <SkeletonTheme
              baseColor={`${currentTheme === "dark" ? "#323546" : "#d8d8d8"}`}
              highlightColor={`${currentTheme === "dark" ? "#444" : "#e0e0e0"}`}
            >
              <Skeleton
                width="32%"
                height={450}
                count={6}
                inline={true}
                borderRadius="30px"
                className="w-[32%] sm:w-full min-w-[320px] mb-10 mr-4"
              />
            </SkeletonTheme>
          )}
          {!isLoading &&
          !allLoading &&
          !isFetching &&
          !allFetching &&
          !isError &&
          !allError &&
          (!searchInput ? data?.data?.length > 0 : searchData?.length > 0) ? (
            (!searchInput ? data?.data : searchData).map(items => {
              return (
                <div
                  key={items.id}
                  className={`w-[32%] sm:w-full min-w-[320px] pb-5 rounded-[15px]`}
                >
                  <NewsCard
                    key={items.id}
                    news={items}
                    total_coins={data?.total}
                    per_page={data?.per_page}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                  />
                </div>
              );
            })
          ) : (
            <p
              className={`text-2xl mt-5 ${
                currentTheme === "dark" ? " text-mainBg" : "text-filter"
              }`}
            >
              No news available
            </p>
          )}
        </div>
      </>
      {!searchInput && data?.data?.length > 0 && (
        <div className={`${currentTheme === "dark" ? "dark" : "ant-pagination"}`}>
          <NewsPagination
            total_coins={data?.total}
            currentPage={currentPage}
            setCurrentPage={paginate}
            per_page={data?.per_page}
          />
        </div>
      )}
    </div>
  );
};

export default AllNewsCard;
