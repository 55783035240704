import React from "react";
import Policy from "../components/Policy/Policy";

const PolicyPage = () => {
  return (
    <div>
      <Policy />
    </div>
  );
};

export default PolicyPage;
