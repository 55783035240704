import React from "react";
import NotFound from "../components/NotFound/NotFound";

const PageNotFound = () => {
  return (
    <div>
      <NotFound />
    </div>
  );
};

export default PageNotFound;
