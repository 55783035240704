import React, { useEffect, useState } from "react";
import Modal from "../../utils/Modal";
import BinanceLogo from "../../assets/icons/binance.svg";
import WalletLogo from "../../assets/icons/wallet.svg";
import { useDispatch, useSelector } from "react-redux";
import { closeRegister, openEmailVerification, openLogin } from "../../store/slices/popupSlice";
import { useRegisterMutation } from "../../../../backend/src/store/services/services";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { selectCurrentTheme } from "../../store/slices/themeSlice";

const SignupPopup = ({ setUserEmail }) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    password_confirmation: "",
    agree: false
  });
  const [passwordValidation, setPasswordValidation] = useState({
    capitalLetter: false,
    smallLetter: false,
    specialCharacter: false,
    number: false,
    length: false,
    errorMessage: ""
  });
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [emailValid, setEmailValid] = useState(false);
  const [buttonEnabled, setButtonEnabled] = useState(false);
  const [messageRes, setMessageRes] = useState(false)
  const currentTheme = useSelector(selectCurrentTheme);
  const dispatch = useDispatch();
  const [register, { isLoading, isError, error, isSuccess }] = useRegisterMutation();
  const handleChange = e => {
    const value = e.target.type === "checkbox" ? e.target.checked : e.target.value;
    setFormData({ ...formData, [e.target.name]: value });
    if (e.target.name === "password") {
      checkPasswordValidation(value);
    }

    if (e.target.name === "email") {
      checkEmailValidation(value);
    }
  };

// form validation
  const checkPasswordValidation = password => {
    const capitalLetterRegex = /[A-Z]/;
    const smallLetterRegex = /[a-z]/;
    const specialCharacterRegex = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]/;
    const numberRegex = /[0-9]/;

    const isValid =
      capitalLetterRegex.test(password) &&
      smallLetterRegex.test(password) &&
      specialCharacterRegex.test(password) &&
      numberRegex.test(password) &&
      password.length >= 8;

    setPasswordValidation({
      capitalLetter: capitalLetterRegex.test(password),
      smallLetter: smallLetterRegex.test(password),
      specialCharacter: specialCharacterRegex.test(password),
      number: numberRegex.test(password),
      length: password.length >= 8,
      errorMessage: isValid ? "" : "Password does not meet criteria."
    });

    setButtonEnabled(isValid && emailValid);
  };
  const checkEmailValidation = email => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    setEmailValid(emailRegex.test(email));
    setButtonEnabled(emailRegex.test(email) && passwordValidation.length);
  };

  if (!isLoading) {
  }
// handling signup
  const handleSubmit = async e => {
    e.preventDefault();
    if (
      formData.name &&
      formData.email &&
      formData.password &&
      formData.password_confirmation &&
      formData.agree
    ) {
      const res= await register(formData);
      setUserEmail(res?.data?.data?.email);
      setMessageRes(res?.error.data.data.email);
    } else {
      alert("plz fill all fields");
    }
  };

  useEffect(() => {
    if (isSuccess) {
      dispatch(closeRegister());
      dispatch(openEmailVerification());
      // dispatch(openLogin());
      toast.success("Sign-up successful!");
    }
  });

// handling password toggle
  const togglePasswordVisibility = passwordField => {
    if (passwordField === "newPassword") {
      setShowNewPassword(!showNewPassword);
    } else if (passwordField === "confirmPassword") {
      setShowConfirmPassword(!showConfirmPassword);
    }
  };
  return (
    <div>
      <Modal maxWidth="750">
        <div className="px-24 py-14 md:px-16 sm:px-8 xs:px-4 relative">
          <div className="text-center mb-5">
            <h3
              className={`font-semibold text-[26px] sm:text-2xl xs:text-xl ${
                currentTheme === "dark" ? " text-mainBg" : "text-filter"
              }`}
            >
              Please Signup
            </h3>
          </div>
          <div>
            <div className="mb-5">
              <label
                className={`font-medium text-sm ${
                  currentTheme === "dark" ? " text-mainBg" : "text-inputLabel"
                }`}
                htmlFor="signup-email"
              >
                Name
              </label>

              <input
                className={`w-full h-[47px] border-[1px] mt-2 border-loginSignupBorder outline-none focus:border-primary py-1 px-2 rounded-[7px] shadow-input ${
                  currentTheme === "dark"
                    ? "bg-inputColor text-mainBg"
                    : "bg-mainBg text-filter"
                }`}
                type="name"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                placeholder="Enter your name here"
                required
              />
            </div>
            <div className="mb-5">
              <label
                className={`font-medium text-sm ${
                  currentTheme === "dark" ? " text-mainBg" : "text-inputLabel"
                }`}
                htmlFor="signup-email"
              >
                Email
              </label>
              <input
                className={`w-full h-[47px] border-[1px] mt-2 border-loginSignupBorder outline-none focus:border-primary py-1 px-2 rounded-[7px] shadow-input ${
                  currentTheme === "dark"
                    ? "bg-inputColor text-mainBg"
                    : "bg-mainBg text-filter"
                }
                `}
                type="email"
                id="signup-email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="Enter your email address"
                required
              />
            <p className="text-decrease">{messageRes}</p>
            </div>
            <div className="mb-5 relative">
              <div className="flex justify-between">
                <label
                  className={`font-medium text-sm ${
                    currentTheme === "dark" ? " text-mainBg" : "text-inputLabel"
                  }`}
                  htmlFor="signup-password"
                >
                  Password
                </label>
              </div>
              <input
                className={`w-full h-[47px] border-[1px] mt-2 border-loginSignupBorder outline-none focus:border-primary py-1 px-2 rounded-[7px] shadow-input ${
                  currentTheme === "dark"
                    ? "bg-inputColor text-mainBg"
                    : "bg-mainBg text-filter"
                }`}
                type={showNewPassword ? "text" : "password"}
                id="signup-password"
                placeholder="Enter your Password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                required
              />
              <span
                className="absolute right-2 top-[2.4rem] cursor-pointer text-eyeIconColor"
                onClick={() => togglePasswordVisibility("newPassword")}
                aria-label={showNewPassword ? "Hide password" : "Show password"}
              >
                {showNewPassword ? (
                  <i className="fa-solid fa-eye"></i>
                ) : (
                  <i className="fa-solid fa-eye-slash"></i>
                )}
              </span>
            </div>
            <div className="mb-5 relative">
              <div className="flex justify-between">
                <label
                  className={`font-medium text-sm ${
                    currentTheme === "dark" ? " text-mainBg" : "text-inputLabel"
                  }`}
                  htmlFor="signup-password"
                >
                  Confirm Password
                </label>
              </div>
              <input
                className={`w-full h-[47px] border-[1px] mt-2 border-loginSignupBorder outline-none focus:border-primary py-1 px-2 rounded-[7px] shadow-input ${
                  currentTheme === "dark"
                    ? "bg-inputColor text-mainBg"
                    : "bg-mainBg text-filter"
                }`}
                type={showConfirmPassword ? "text" : "password"}
                id="signup-password"
                placeholder="Confirm Password"
                name="password_confirmation"
                value={formData.password_confirmation}
                onChange={handleChange}
                required
              />
              <span
                className="absolute right-2 top-[2.4rem] cursor-pointer text-eyeIconColor"
                onClick={() => togglePasswordVisibility("confirmPassword")}
                aria-label={showConfirmPassword ? "Hide password" : "Show password"}
              >
                {showConfirmPassword ? (
                  <i className="fa-solid fa-eye"></i>
                ) : (
                  <i className="fa-solid fa-eye-slash"></i>
                )}
              </span>
            </div>
            <div className="mb-3">
              <div className="flex justify-between">
                <label
                  className={`font-medium text-sm ${
                    currentTheme === "dark" ? " text-mainBg" : "text-inputLabel"
                  }`}
                  htmlFor="signup-password"
                >
                  Referral ID (Optional)
                </label>
              </div>
              <input
                className={`w-full h-[47px] border-[1px] mt-2 border-loginSignupBorder outline-none focus:border-primary py-1 px-2 rounded-[7px] shadow-input ${
                  currentTheme === "dark"
                    ? "bg-inputColor  text-mainBg"
                    : "bg-mainBg text-filter"
                }`}
                type="password"
                placeholder="Enter referral ID"
                name="referral_id"
                value={formData.referral_id}
                onChange={handleChange}
                required
              />
            </div>
            <div className="flex items-start justify-between mb-7">
              <div className="flex items-center gap-2">
                <input
                  type="checkbox"
                  id="signup-marketing"
                  name="agree"
                  checked={formData.checkbox}
                  onChange={handleChange}
                />
                <label
                  htmlFor="signup-marketing"
                  className={`text-sm sm:text-xs ${
                    currentTheme === "dark" ? " text-mainBg" : "text-inputLabel"
                  }`}
                >
                  I agree to receive marketing updates from Coin
                </label>
              </div>
            </div>
            <div className="flex items-center gap-2 pb-2 text-sm">
              <i
                className={`${
                  passwordValidation.capitalLetter
                    ? "fa-solid fa-circle-check text-increase"
                    : "fa-regular fa-circle-xmark text-decrease"
                }`}
              ></i>
              <p
                className={`text-xs ${
                  currentTheme === "dark" ? " text-mainBg" : "text-inputLabel"
                }`}
              >
                1 capital letter
              </p>
            </div>
            <div className="flex items-center gap-2 pb-2 text-sm">
              <i
                className={`${
                  passwordValidation.smallLetter
                    ? "fa-solid fa-circle-check text-increase"
                    : "fa-regular fa-circle-xmark text-decrease"
                }`}
              ></i>
              <p
                className={`text-xs ${
                  currentTheme === "dark" ? " text-mainBg" : "text-inputLabel"
                }`}
              >
                1 small letter
              </p>
            </div>
            <div className="flex items-center gap-2 pb-1 text-sm">
              <i
                className={`${
                  passwordValidation.specialCharacter
                    ? "fa-solid fa-circle-check text-increase"
                    : "fa-regular fa-circle-xmark text-decrease"
                }`}
              ></i>
              <p
                className={`text-xs ${
                  currentTheme === "dark" ? " text-mainBg" : "text-inputLabel"
                }`}
              >
                1 Special Character
              </p>
            </div>
            <div className="flex items-center gap-2 pb-2 text-sm">
              <i
                className={`${
                  passwordValidation.number
                    ? "fa-solid fa-circle-check text-increase"
                    : "fa-regular fa-circle-xmark text-decrease"
                }`}
              ></i>
              <p
                className={`text-xs ${
                  currentTheme === "dark" ? " text-mainBg" : "text-inputLabel"
                }`}
              >
                1 number
              </p>
            </div>
            <div className="flex items-center gap-2 pb-2 text-sm">
              <i
                className={`${
                  passwordValidation.length
                    ? "fa-solid fa-circle-check text-increase"
                    : "fa-regular fa-circle-xmark text-decrease"
                }`}
              ></i>
              <p
                className={`text-xs ${
                  currentTheme === "dark" ? " text-mainBg" : "text-inputLabel"
                }`}
              >
                at least 8 characters
              </p>
            </div>
            <div className="text-center mb-3">
              <button
                className="bg-primary w-full py-2 rounded-[7px] text-secondary mb-5 disabled:bg-disabledButton cursor-pointer"
                disabled={!buttonEnabled || isLoading}
                onClick={handleSubmit}
              >
                Create an account
              </button>
              {/* {isError && <div>Error: {error.message}</div>} */}
              <p
                className={`text-sm ${
                  currentTheme === "dark" ? " text-mainBg" : "text-inputLabel"
                }`}
              >
                Already have an account?{" "}
                <button
                  onClick={() => {
                    dispatch(closeRegister());
                    dispatch(openLogin());
                  }}
                  className="text-blueColor font-medium"
                >
                  Login
                </button>
              </p>
            </div>
            {/* <div className="text-center relative h-10 mb-4">
              <span className="bg-mainBg z-10 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 inline-block w-16">
                or
              </span>
              <div className="h-[1px] w-full bg-[#dadada] absolute top-1/2 -translate-y-1/2"></div>
            </div> */}
            {/* <div className="flex flex-col mb-4">
              <button className="flex items-center justify-center border-[1px] gap-1 border-primary rounded-[7px] py-1 mb-4">
                <img className="h-8" src={BinanceLogo} alt="Binance-icon" /> Continue with
                Binance
              </button>
              <button className="flex items-center justify-center border-[.5px] gap-1 border-[#B9B9B9] rounded-[7px] py-1">
                <img className="h-8" src={WalletLogo} alt="Wallet-icon" /> Continue with
                Wallet
              </button>
            </div> */}
            <div
              className={`text-center text-sm ${
                currentTheme === "dark" ? " text-mainBg" : "text-inputLabel"
              }`}
            >
              <p>
                By proceeding, you agree to Coin{" "}
                <span className="text-blueColor font-medium">Terms of Use</span> and{" "}
                <span className="text-blueColor font-medium">Privacy Policy</span>.
              </p>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default SignupPopup;
