import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addCoin } from "../../store/slices/compareSlice";
import {
  useGetCoinMarketQuery,
  useGetHeaderSearchCoinQuery
} from "../../store/services/services";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { selectCurrentTheme } from "../../store/slices/themeSlice";
import CryptoIcon from "../../assets/icons/crypto-icon.svg";
import { toast } from "react-toastify";

const CompareSearch = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchItems, setSearchItems] = useState(false);
  const { data, isLoading, isError, error } = useGetCoinMarketQuery();
  const compareCoins = useSelector(state => state.compare.selectedCoins);
  // search coin
  const {
    data: searchCoin,
    isError: searchIsError,
    isLoading: searchIsLoading,
    isFetching: searchIsFetching,
    refetch: searchRefetch
  } = useGetHeaderSearchCoinQuery(searchTerm);

  const dispatch = useDispatch();
  const currentTheme = useSelector(selectCurrentTheme);
  const searchCoins = useRef();

  const searchFocusHandler = () => {
    setSearchItems(true);
  };

  // Debounce function
  const debounce = (func, delay) => {
    let timeoutId;
    return function (...args) {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };
  // Search coin with debounce
  const debouncedSearch = useRef(
    debounce(value => {
      if (value.trim() !== "") {
        searchRefetch(value);
        setSearchItems(true);
      } else {
        setSearchItems(false);
      }
    }, 1000)
  ).current;

  // Handle input change with debouncing
  const searchHandler = e => {
    const { value } = e.target;
    setSearchTerm(value);
    debouncedSearch(value);
  };
  let filterSearches;
  if (!isLoading && !isError) {
    filterSearches = data?.data?.filter(
      item =>
        item?.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item?.logo.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }
  useEffect(() => {
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleClickOutside = event => {
    if (searchCoins.current && !searchCoins.current.contains(event.target)) {
      setSearchItems(false);
    }
  };
  // compare coins 

const compareCoinsHandler = item => {
  if (compareCoins.length < 6) {
    dispatch(addCoin(item));
  } else {
    toast.error("You cannot add more than 6 coins");
  }
};



  return (
    <div ref={searchCoins}>
      <div className=" h-full flex items-center px-2 xs:px-0 flex-col justify-center relative">
        <div
          className={`flex items-center border-2 border-inputBorder h-[38px] rounded-full px-3 py-5 gap-3 ${
            searchItems ? " border-mainBg" : ""
          } transition-all duration-300 z-40 `}
        >
          <i class={"fas fa-search text-grey text-base"}></i>
          {/* <img src={SearchIcon} alt="search" /> */}
          <input
            className={`border-0 outline-0 overflow-hidden bg-transparent text-base w-52 xs:w-full lg:w-28 focus transition-all duration-300 ${
              currentTheme === "dark"
                ? "bg-inputColor text-mainBg"
                : "bg-mainBg text-filter"
            }`}
            onFocus={searchFocusHandler}
            value={searchTerm}
            onChange={searchHandler}
            type="text"
            placeholder="Search Coin"
          />
        </div>
        {
          <div
            className={`absolute top-0 shadow-dropdown z-30  ${
              searchItems ? "h-88 block py-5 " : "h-0 p-0"
            } w-full rounded-[20px] transition-all duration-200 overflow-hidden ${
              currentTheme === "dark" ? "bg-cardsColor " : "bg-mainBg"
            }`}
          >
            <div className="mt-10 max-h-56 overflow-auto scrollbar px-5 text-sm">
              {searchTerm === "" && (
                <>
                  <div className="mb-4 text-sm">
                    <h3
                      className={`font-semibold  mb-3 ${
                        currentTheme === "dark"
                          ? "text-mainBg "
                          : "text-currencyNotFoundText"
                      }`}
                    >
                      Trending
                    </h3>
                    {isLoading && (
                      <div className="w-full">
                        <SkeletonTheme
                          baseColor={`${currentTheme === "dark" ? "#323546" : "#d8d8d8"}`}
                          highlightColor={`${
                            currentTheme === "dark" ? "#444" : "#e0e0e0"
                          }`}
                        >
                          <Skeleton width="100%" height={35} count={3} />
                        </SkeletonTheme>
                      </div>
                    )}
                    {isError && <p>{error.message}</p>}
                    {!isLoading && !isError && (
                      <div className="flex flex-wrap">
                        {data?.data?.map(item => (
                          <div
                            className={`flex w-full gap-2 border-b-[.5px] py-3 items-center cursor-pointer ${
                              currentTheme === "dark"
                                ? "border-darkBorder"
                                : "border-lightBorder"
                            }`}
                            onClick={() => compareCoinsHandler(item)}
                            key={item.id}
                          >
                            <img
                              className="w-5"
                              src={item?.img ? item?.img : CryptoIcon}
                              alt="coin-icon"
                            />
                            <span
                              className={`font-medium w-4/5 overflow-hidden whitespace-nowrap overflow-ellipsis ${
                                currentTheme === "dark" ? "text-mainBg " : "text-filter"
                              }`}
                            >
                              {item?.name}
                            </span>
                            <span
                              className={`text-xs ${
                                currentTheme === "dark" ? "text-mainBg " : "text-filter"
                              }`}
                            >
                              {item?.logo}
                            </span>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </>
              )}
              {searchTerm !== "" && (
                <div>
                  <h3
                    className={`font-semibold  mb-3 ${
                      currentTheme === "dark" ? "text-mainBg " : "text-textGray"
                    }`}
                  >
                    Search currencies
                  </h3>
                  {(searchIsLoading || searchIsFetching) && (
                    <div className="w-full">
                      <SkeletonTheme
                        baseColor={`${currentTheme === "dark" ? "#323546" : "#d8d8d8"}`}
                        highlightColor={`${currentTheme === "dark" ? "#444" : "#e0e0e0"}`}
                      >
                        <Skeleton width="100%" height={35} count={3} />
                      </SkeletonTheme>
                    </div>
                  )}
                  {!searchIsLoading && !searchIsError && !searchIsFetching && (
                    <div>
                      {searchCoin?.length > 0 && (
                        <div className="flex flex-wrap">
                          {searchCoin?.map(item => (
                            <div
                              className={`flex w-full gap-2 border-b-[.5px] py-3 items-center cursor-pointer ${
                                currentTheme === "dark"
                                  ? "border-darkBorder"
                                  : "border-lightBorder"
                              }`}
                              key={item.id}
                              onClick={() => compareCoinsHandler(item)}
                            >
                              <div className="w-5">
                                <img
                                  className="w-full"
                                  src={item?.img ? item?.img : CryptoIcon}
                                  alt="currency-icon"
                                />
                              </div>
                              <div className="flex flex-col w-2/3">
                                <span
                                  className={`font-medium w-full overflow-hidden whitespace-nowrap overflow-ellipsis ${
                                    currentTheme === "dark"
                                      ? "text-mainBg"
                                      : "text-filter"
                                  }`}
                                >
                                  {item?.name}
                                </span>
                                <span
                                  className={`text-xs ${
                                    currentTheme === "dark"
                                      ? "text-mainBg"
                                      : "text-filter"
                                  }`}
                                >
                                  {item?.code}
                                </span>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                      {searchCoin?.length === 0 && (
                        <div
                          className={`text-center my-10 font-medium text-base  ${
                            currentTheme === "dark"
                              ? "text-mainBg "
                              : "text-currencyNotFoundText"
                          }`}
                        >
                          No Currencies Found
                        </div>
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        }
      </div>
    </div>
  );
};

export default CompareSearch;
