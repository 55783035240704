import React from "react";
import { useGetCoinGlobalQuery } from "../../store/services/services";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import DecreaseIcon from "../../assets/icons/decrease.svg";
import { commafy } from "../../utils/commafy";
import { toDecimals } from "../../utils/toDecimals";
import { selectCurrentTheme } from "../../store/slices/themeSlice";
import { useSelector } from "react-redux";

const CryptoStatsCards = () => {
  const { data, isLoading, isError, error } = useGetCoinGlobalQuery();
  const currentTheme = useSelector(selectCurrentTheme);
  return (
    <div className="flex justify-center flex-wrap mt-8 gap-3">
      <div
        className={`rounded-[22px] w-[24%] min-w-[308px] p-5 flex flex-col justify-center ${
          currentTheme === "dark" ? "bg-cardsColor" : "mainBg"
        } shadow-navbar-button`}
      >
        {isLoading && (
          <div className="w-full mt-3">
            <SkeletonTheme
              baseColor={`${currentTheme === "dark" ? "#323546" : "#d8d8d8"}`}
              highlightColor={`${currentTheme === "dark" ? "#444" : "#e0e0e0"}`}
            >
              <Skeleton width="100%" height={25} count={1} />
            </SkeletonTheme>
          </div>
        )}
        {isError && <p>{error.message}</p>}
        {!isLoading &&
          !isError &&
          data?.data?.map(item => {
            return (
              <div className="flex text-base md:text-sm font-semibold gap-2">
                <p className={`text-${currentTheme === "light" ? "filter" : "mainBg"}`}>
                  ${commafy(toDecimals(item?.total_market_cap_usd))}
                </p>
                {/* <span className="flex text-decrease">
                  -1.23% <img src={DecreaseIcon} alt="change-icon" />
                </span> */}
              </div>
            );
          })}
        <p className={`text-${currentTheme === "light" ? "filter" : "mainBg"}`}>
          Market Capitalization
        </p>
      </div>
      <div
        className={`rounded-[22px] w-[24%] min-w-[308px] p-5 flex flex-col justify-center ${
          currentTheme === "dark" ? "bg-cardsColor" : "mainBg"
        } shadow-navbar-button`}
      >
        {/* {isLoading && (
          <div className="w-full mt-3">
            <Skeleton width="100%" height={25} count={1} />
          </div>
        )}
        {isError && <p>{error.message}</p>}
        {data?.data?.map(item => {
          return (
            <div className="flex text-base md:text-sm font-semibold gap-2">
              <p>${item?.btc_percentage_of_market_cap}</p>
            </div>
          );
        })} */}
        <div className="flex text-base md:text-sm font-semibold gap-2">
          {isLoading && (
            <div className="w-full mt-3">
              <SkeletonTheme
                baseColor={`${currentTheme === "dark" ? "#323546" : "#d8d8d8"}`}
                highlightColor={`${currentTheme === "dark" ? "#444" : "#e0e0e0"}`}
              >
                <Skeleton width="100%" height={25} count={1} />
              </SkeletonTheme>
            </div>
          )}
          {isError && <p>{error.message}</p>}
          {data?.data?.map(item => {
            return (
              <div className="flex text-base md:text-sm font-semibold gap-2">
                <p className={`text-${currentTheme === "light" ? "filter" : "mainBg"}`}>
                  ${commafy(toDecimals(item?.total_volume_usd_24h))}
                </p>
              </div>
            );
          })}
        </div>
        <p className={`text-${currentTheme === "light" ? "filter" : "mainBg"}`}>
          24h Trading Volume
        </p>
      </div>
      <div
        className={`rounded-[22px] w-[24%] min-w-[308px] p-5 flex flex-col justify-center ${
          currentTheme === "dark" ? "bg-cardsColor" : "mainBg"
        } shadow-navbar-button`}
      >
        <div className="flex text-base md:text-sm font-semibold gap-2">
          {isLoading && (
            <div className="w-full mt-3">
              <SkeletonTheme
                baseColor={`${currentTheme === "dark" ? "#323546" : "#d8d8d8"}`}
                highlightColor={`${currentTheme === "dark" ? "#444" : "#e0e0e0"}`}
              >
                <Skeleton width="100%" height={25} count={1} />
              </SkeletonTheme>
            </div>
          )}
          {isError && <p>{error.message}</p>}
          {data?.data?.map(item => {
            return (
              <div className="flex text-base md:text-sm font-semibold gap-2">
                <p className={`text-${currentTheme === "light" ? "filter" : "mainBg"}`}>
                  {commafy(toDecimals(item?.btc_percentage_of_market_cap))}%
                </p>
              </div>
            );
          })}
        </div>
        <p className={`text-${currentTheme === "light" ? "filter" : "mainBg"}`}>
          Bitcoin Market Cap Dominance
        </p>
      </div>
      <div
        className={`rounded-[22px] w-[24%] min-w-[308px] p-5 flex flex-col justify-center ${
          currentTheme === "dark" ? "bg-cardsColor" : "mainBg"
        } shadow-navbar-button`}
      >
        {isLoading && (
          <div className="w-full mt-3">
            <div className="w-full mt-3">
              <SkeletonTheme
                baseColor={`${currentTheme === "dark" ? "#323546" : "#d8d8d8"}`}
                highlightColor={`${currentTheme === "dark" ? "#444" : "#e0e0e0"}`}
              >
                <Skeleton width="100%" height={25} count={1} />
              </SkeletonTheme>
            </div>
          </div>
        )}
        {isError && <p>{error.message}</p>}
        {data?.data?.map(item => (
          <div className="flex text-base md:text-sm font-semibold gap-2">
            <p className={currentTheme === "light" ? "text-filter" : "text-mainBg"}>
              {commafy(toDecimals(item?.status_currencies))}
            </p>
          </div>
        ))}
        <p className={currentTheme === "light" ? "text-filter" : "text-mainBg"}>
          # of Coins
        </p>
      </div>
    </div>
  );
};

export default CryptoStatsCards;
