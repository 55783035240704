import React, { useEffect, useState } from "react";
import CurrencyTable from "../CurrencyTable/CurrencyTable";
import TableFilters from "../TableFilters";
import { useGetCoinMarketQuery, useGetHeaderSearchCoinQuery } from "../../store/services/services";
import { useDispatch, useSelector } from "react-redux";
import { closeFilter } from "../../store/slices/popupSlice";
import { toggleAllFilters } from "../../store/slices/filterSlice";
import { useLocation } from "react-router-dom";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { selectCurrentTheme } from "../../store/slices/themeSlice";

const HomeTable = () => {
  const [filterValue, setFilterValue] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [filterTableData, setFilterTableData] = useState([]);
  const [searchedTerm, setSearchedTerm] = useState("");
  // search coin
  const {
    data: searchCoin,
    isError: searchIsError,
    isLoading: searchIsLoading,
    isFetching: searchIsFetching,
    refetch: searchRefetch
  } = useGetHeaderSearchCoinQuery(searchedTerm);

  const dispatch = useDispatch();
   const currentTheme = useSelector(selectCurrentTheme);
  const location = useLocation();
  const currentRoute = location.pathname;
  console.log(currentRoute, "this is current route");
  const handleFilterChange = value => {
    setFilterValue(value);
  };
  const {
    data: coins,
    isError,
    isFetching,
    error,
    isLoading
  } = useGetCoinMarketQuery(currentPage);

  // search coin
  let filteredCoin;
  if (searchedTerm.length === 0 && !isLoading && !isError) {
    filteredCoin = coins?.data;
  } else if (
    searchedTerm.length > 0 &&
    !searchIsLoading &&
    !searchIsError &&
    !searchIsFetching
  ) {
    filteredCoin = searchCoin;
  }
  useEffect(() => {
    if (searchedTerm.length === 0 && !isLoading && !isError) {
      setFilterTableData(coins?.data)
      filteredCoin = coins?.data;
    } else if (
      searchedTerm.length > 0 &&
      !searchIsLoading &&
      !searchIsError &&
      !searchIsFetching
    ) {
      setFilterTableData(searchCoin)
      filteredCoin = searchCoin;
    }
  }, [searchCoin, coins]);

  // Debounce function
  const debounce = (func, delay) => {
    let timer;
    return function (...args) {
      clearTimeout(timer);
      timer = setTimeout(() => func(...args), delay);
    };
  };

  // Debounced search function
  const debouncedSearch = debounce(() => {
    searchRefetch(); // Replace with the actual search function
  }, 300);

  // Handle search term change
  const searchCoinHandler = e => {
    setSearchedTerm(e.target.value);
    debouncedSearch();
  };

  const priceMinNum = parseInt(useSelector(state => state.filter.priceMinNum));
  const priceMaxNum = parseInt(useSelector(state => state.filter.priceMaxNum));
  const marketCapMinNum = parseInt(useSelector(state => state.filter.marketCapMinNum));
  const marketCapMaxNum = parseInt(useSelector(state => state.filter.marketCapMaxNum));
  const changeMinNum = parseInt(useSelector(state => state.filter.changeMinNum));
  const changeMaxNum = parseInt(useSelector(state => state.filter.changeMaxNum));
  const volumeMinNum = parseInt(useSelector(state => state.filter.volumeMinNum));
  const volumeMaxNum = parseInt(useSelector(state => state.filter.volumeMaxNum));

  useEffect(() => {
    setFilterTableData(coins?.data);
  }, [isLoading, coins, isFetching]);

  //filter on coins
  const filterDataHandler = () => {
    dispatch(closeFilter());
    setFilterTableData(
      filteredCoin?.filter(item => {
        if (!isNaN(+priceMinNum) && !isNaN(+priceMaxNum)) {
          return +item.price >= priceMinNum && +item.price <= priceMaxNum;
        } else if (!isNaN(priceMinNum) && isNaN(priceMaxNum)) {
          return +item.price >= priceMinNum;
        } else if (!isNaN(priceMaxNum)) {
          return +item.price <= priceMaxNum;
        } else if (!isNaN(+marketCapMinNum) && !isNaN(+marketCapMaxNum)) {
          return (
            +item.market_cap >= marketCapMinNum && +item.market_cap <= marketCapMaxNum
          );
        } else if (!isNaN(marketCapMinNum) && isNaN(marketCapMaxNum)) {
          return +item.market_cap >= marketCapMinNum;
        } else if (!isNaN(marketCapMaxNum)) {
          return +item.market_cap <= marketCapMaxNum;
        } else if (!isNaN(+changeMinNum) && !isNaN(+changeMaxNum)) {
          return (
            +item.percentChangeDay >= changeMinNum &&
            +item.percentChangeDay <= changeMaxNum
          );
        } else if (!isNaN(changeMinNum) && isNaN(changeMaxNum)) {
          return +item.percentChangeDay >= changeMinNum;
        } else if (!isNaN(changeMaxNum)) {
          return +item.percentChangeDay <= changeMaxNum;
        } else if (!isNaN(+volumeMinNum) && !isNaN(+volumeMaxNum)) {
          return +item.volume_24h >= volumeMinNum && +item.volume_24h <= volumeMaxNum;
        } else if (!isNaN(volumeMinNum) && isNaN(volumeMaxNum)) {
          return +item.volume_24h >= volumeMinNum;
        } else if (!isNaN(volumeMaxNum)) {
          return +item.volume_24h <= volumeMaxNum;
        }
      })
    )
  };
  // clear all coins
  const handleAllFiltersSelected = () => {
    setFilterTableData(coins?.data);
    dispatch(toggleAllFilters());
    dispatch(closeFilter());
  };
  return (
    <>
      <div className="px-24 md:px-8 xs:px-4 mt-12">
        <TableFilters
          filterValue={filterValue}
          handleFilterChange={handleFilterChange}
          filterDataHandler={filterDataHandler}
          handleAllFiltersSelected={handleAllFiltersSelected}
          searchCoinHandler={searchCoinHandler}
          searchedTerm={searchedTerm}
        />
        {(searchIsLoading || isLoading || searchIsFetching) ? (
          <div className="w-full">
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column"
              }}
            >
              {Array.from({ length: 30 }).map((_, index) => (
                <SkeletonTheme
                  baseColor={`${currentTheme === "dark" ? "#323546" : "#d8d8d8"}`}
                  highlightColor={`${currentTheme === "dark" ? "#444" : "#e0e0e0"}`}
                >
                  <Skeleton key={index} width="100%" height={50} />
                </SkeletonTheme>
              ))}
            </div>
          </div>
        ) : (
          <CurrencyTable
            data={filterTableData}
            total_coins={coins?.total}
            per_page={coins?.per_page}
            isLoading={isLoading || isFetching}
            isError={isError}
            error={error}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            filteredCoin={filteredCoin}
          />
        )}
      </div>
    </>
  );
};

export default HomeTable;
