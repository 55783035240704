import ShibaIcon from "../assets/icons/shiba.svg";
import BitcoinIcon from "../assets/icons/bitcoin.svg";
import EthereumIcon from "../assets/icons/ethereum.svg";
import BnbIcon from "../assets/icons/dollar.svg";

export const EXCHANGE_RESERVE_DATA = [
  {
    id: "1",
    icon: ShibaIcon,
    name: "SHB",
    key: "TWd4WrZ9wn84f5x1hZhL4DHvk738ns5jwb",
    balance: 6800000000.2,
    price: 1.0005,
    value: 6803402292.612398923,
  },
  {
    id: "2",
    icon: BitcoinIcon,
    name: "BTC",
    key: "34xp4vRoCGJym3xR7yCVPFHoCNxv4Twseo",
    balance: 248597.39,
    price: 26363.2,
    value: 6553823616.66,
  },
  {
    id: "3",
    icon: EthereumIcon,
    name: "ETH",
    key: "bnb142q467df6jun6rt5u2ar58sp47hm5f9wvz2cvg",
    balance: 17185720.59,
    price: 1.0005,
    value: 5250944939.9,
  },
  {
    id: "4",
    icon: BnbIcon,
    name: "BNB",
    key: "TJCo98saj6WND61g1uuKwJ9GMWMT9WkJFo",
    balance: 4000000027.08,
    price: 1.0005,
    value: 4002021698.58,
  },
  {
    id: "5",
    icon: ShibaIcon,
    name: "SHB",
    key: "TWd4WrZ9wn84f5x1hZhL4DHvk738ns5jwb",
    balance: 6800000000.2,
    price: 1.0005,
    value: 6803402292.61,
  },
  {
    id: "6",
    icon: BitcoinIcon,
    name: "BTC",
    key: "34xp4vRoCGJym3xR7yCVPFHoCNxv4Twseo",
    balance: 248597.39,
    price: 26363.2,
    value: 6553823616.66,
  },
  {
    id: "7",
    icon: EthereumIcon,
    name: "ETH",
    key: "bnb142q467df6jun6rt5u2ar58sp47hm5f9wvz2cvg",
    balance: 17185720.59,
    price: 1.0005,
    value: 5250944939.9,
  },
  {
    id: "8",
    icon: BnbIcon,
    name: "BNB",
    key: "TJCo98saj6WND61g1uuKwJ9GMWMT9WkJFo",
    balance: 4000000027.08,
    price: 1.0005,
    value: 4002021698.58,
  },
];
