import React from "react";
import Modal from "../../utils/Modal";
import { Line } from "@ant-design/plots";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import TradingViewWidget, { Themes } from "react-tradingview-widget";
import ReactApexChart from "react-apexcharts";
import { selectCurrentTheme } from "../../store/slices/themeSlice";
import { useSelector } from "react-redux";

const TradingViewChartModal = ({
  tradingViewSymbol,
  periodActive,
  isLoading,
  isFetching,
  menuActive,
  setMenuActive,
  apiData,
  min,
  max,
  activeChartType,
  setPeriodActive,
  setActiveChartType,
  TIME_PERIOD_DATA,
  CHART_Type,
  CHART_MENU_DATA,
  options,
  series
}) => {
  const currentTheme = useSelector(selectCurrentTheme);
  const transformedData = [];
  const transformedColumnData = [];
  // convert time and date
  if (apiData) {
    apiData.forEach(item => {
      const timestamp = item.time * 1000;
      const date = new Date(timestamp);
      const formattedTimestamp = `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
      transformedData.push({
        time: formattedTimestamp,
        value: +item.close,
        field: "close"
      });
    });
  }
  if (apiData) {
    apiData.forEach(item => {
      const timestamp = item.time * 1000;
      const date = new Date(timestamp);
      const formattedTimestamp = `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
      transformedColumnData.push({
        time: formattedTimestamp,
        value: [+item.open, +item.close],
        field: "Open - Close"
      });
    });
  }
  // ApexCharts configuration for the line chart
  const apexLineChartConfig = {
    options: {
      chart: {
        zoom: {
          enabled: false
        }
      },
      xaxis: {
        type: "datetime"
      },
      yaxis: {
        min: min,
        max: max,
        labels: {
          formatter: value => Math.round(value)
        }
      },
      tooltip: {
        x: {
          format: "dd MMM yyyy HH:mm:ss"
        },
        y: {
          formatter: value => Math.round(value)
        }
      },
      stroke: {
        curve: "smooth"
      },
      theme: {
        mode: `${currentTheme === "light" ? "light" : "dark"}`
      }
    },
    series: [
      {
        name: "Price",
        data: transformedData.map(item => ({
          x: new Date(item.time).getTime(),
          y: item.value
        }))
      }
    ]
  };

  return (
    <Modal maxWidth={2500}>
      <div className="flex md:flex-col md:gap-3 xs:gap-5 justify-between px-24 mt-12">
        <div
          className={`text-sm xs:w-full ${
            currentTheme === "dark" ? "text-opacityColor" : "text-chartTextColor"
          }`}
        >
          <div
            className={`flex justify-between shadow-header-shadow rounded-full items-center overflow-hidden px-2 ${
              currentTheme === "dark" ? "bg-filter" : "bg-coinDetailChartBg"
            }`}
          >
            <div className="flex gap-1 flex-nowrap overflow-auto scrollbar py-2">
              {CHART_MENU_DATA.map(item => (
                <div
                  className={`py-1 px-3 whitespace-nowrap cursor-pointer rounded-full shadow-navbar-button ${
                    menuActive === item.value
                      ? `bg-${currentTheme === "dark" ? "cardsColor" : "mainBg"}`
                      : "bg-none"
                  } ${
                    menuActive === item.value && currentTheme === "dark"
                      ? "text-mainBg"
                      : "text-none"
                  }`}
                  onClick={() => {
                    setMenuActive(item.value);
                  }}
                  key={item.id}
                >
                  {item.name}
                </div>
              ))}
            </div>
          </div>
        </div>
        {menuActive === "price" && (
          <div
            className={`text-sm xs:w-full ${
              currentTheme === "dark" ? "text-opacityColor" : "text-chartTextColor"
            }`}
          >
            <div
              className={`flex justify-between shadow-header-shadow rounded-full items-center overflow-hidden px-2 ${
                currentTheme === "dark" ? "bg-filter" : "bg-coinDetailChartBg"
              }`}
            >
              <div className="flex gap-1 flex-nowrap overflow-auto scrollbar py-2">
                {CHART_Type.map(item => (
                  <div
                    className={`py-1 px-3 whitespace-nowrap cursor-pointer rounded-full shadow-navbar-button ${
                      activeChartType === item.value
                        ? `bg-${currentTheme === "dark" ? "cardsColor" : "mainBg"}`
                        : "bg-none"
                    } ${
                      activeChartType === item.value && currentTheme === "dark"
                        ? "text-mainBg"
                        : "text-none"
                    }`}
                    onClick={() => {
                      setActiveChartType(item.value);
                    }}
                    key={item.id}
                  >
                    {item.name}
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
        <div
          className={`flex justify-end text-sm w-[47%] xs:w-full ${
            currentTheme === "dark" ? "text-opacityColor" : "text-chartTextColor"
          }`}
        >
          <div
            className={`flex justify-between shadow-header-shadow rounded-full items-center overflow-hidden px-2 ${
              currentTheme === "dark" ? "bg-filter" : "bg-coinDetailChartBg"
            }`}
          >
            <div className="flex gap-1 flex-nowrap overflow-auto scrollbar py-2">
              {TIME_PERIOD_DATA.map(item => (
                <div
                  className={`py-1 px-3 whitespace-nowrap cursor-pointer rounded-full shadow-navbar-button ${
                    periodActive === item.value
                      ? `bg-${currentTheme === "dark" ? "cardsColor" : "mainBg"}`
                      : ""
                  } ${
                    periodActive === item.value && currentTheme === "dark"
                      ? "text-mainBg"
                      : ""
                  } flex items-center gap-1`}
                  onClick={() => {
                    setPeriodActive(item.value);
                  }}
                  key={item.id}
                >
                  {item.icon && (
                    <img className="w-4" src={item.icon} alt="calendar-icon" />
                  )}
                  {item.name}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="py-16 px-12">
        <div className="mb-4">
          {isLoading || isFetching ? (
            <SkeletonTheme
              baseColor={`${currentTheme === "dark" ? "#323546" : "#d8d8d8"}`}
              highlightColor={`${currentTheme === "dark" ? "#444" : "#e0e0e0"}`}
            >
              <Skeleton width="100%" height={400} count={1} />
            </SkeletonTheme>
          ) : menuActive === "price" ? (
            activeChartType === "line" ? (
              <ReactApexChart {...apexLineChartConfig} height={350} />
            ) : (
              <div>
                <ReactApexChart
                  options={options}
                  series={series}
                  type="candlestick"
                  height={350}
                />
              </div>
            )
          ) : menuActive === "trading-view" ? (
            <div className="mt-4">
              {tradingViewSymbol ? (
                <TradingViewWidget
                  symbol={tradingViewSymbol}
                  theme={`${currentTheme === "dark" ? Themes.DARK : Themes.LIGHT}`}
                  width="100%"
                  height={400}
                  locale="en"
                />
              ) : (
                "there is no Symbol"
              )}
            </div>
          ) : null}
        </div>
      </div>
    </Modal>
  );
};;

export default TradingViewChartModal;
