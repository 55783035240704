import React, { useEffect, useState } from "react";
import Modal from "../../utils/Modal";
import { selectCurrentTheme } from "../../store/slices/themeSlice";
import { useSelector,useDispatch } from "react-redux";
import { useResetPasswordMutation } from "../../store/services/services";
import { toast } from "react-toastify";
import { closeResetPassword, openLogin } from "../../store/slices/popupSlice";

const ResetPasswordPopup = ({ userEmail }) => {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [emailValid, setEmailValid] = useState(false);
  const [buttonEnabled, setButtonEnabled] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [passwordValidation, setPasswordValidation] = useState({
    capitalLetter: false,
    smallLetter: false,
    specialCharacter: false,
    number: false,
    length: false,
    errorMessage: ""
  });
  const currentTheme = useSelector(selectCurrentTheme);
  const [triggerResetPassword, { isError, isFetching, error, isLoading, isSuccess }] =
    useResetPasswordMutation();
  const dispatch = useDispatch();
  const handleResetPassword = async () => {
    try {
      if (newPassword !== confirmPassword) {
        toast.error("Passwords do not match");
        return;
      }

      const result = await triggerResetPassword({
        email: userEmail,
        password: newPassword,
        password_confirmation: confirmPassword
      });

      // Handle success
      console.log(result.data);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    if (isSuccess) {
      dispatch(closeResetPassword());
      dispatch(openLogin());
      // dispatch(openLogin());
      toast.success("New Password Updated Successfully");
    } else if (isError) {
      console.log(error);
      toast.error(error.data.message);
    }
  }, [isSuccess, isError]);
  const togglePasswordVisibility = passwordField => {
    if (passwordField === "newPassword") {
      setShowNewPassword(!showNewPassword);
    } else if (passwordField === "confirmPassword") {
      setShowConfirmPassword(!showConfirmPassword);
    }
  };
  // form validation

  // password validation
  const checkPasswordValidation = password => {
    const capitalLetterRegex = /[A-Z]/;
    const smallLetterRegex = /[a-z]/;
    const specialCharacterRegex = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]/;
    const numberRegex = /[0-9]/;

    const isValid =
      capitalLetterRegex.test(password) &&
      smallLetterRegex.test(password) &&
      specialCharacterRegex.test(password) &&
      numberRegex.test(password) &&
      password.length >= 8;

    setPasswordValidation({
      capitalLetter: capitalLetterRegex.test(password),
      smallLetter: smallLetterRegex.test(password),
      specialCharacter: specialCharacterRegex.test(password),
      number: numberRegex.test(password),
      length: password.length >= 8,
      errorMessage: isValid ? "" : "Password does not meet criteria."
    });

    setButtonEnabled(isValid && emailValid);
  };
// email validation
  const checkEmailValidation = email => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    setEmailValid(emailRegex.test(email));
    setButtonEnabled(emailRegex.test(email) && passwordValidation.length);
  };
useEffect(() => {
  // Check email validation initially
  checkEmailValidation(userEmail);
}, [userEmail]);

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
    checkPasswordMatching();
  };
  
// checking password is matching
  const checkPasswordMatching = () => {
    setButtonEnabled(
      passwordValidation.length && newPassword === confirmPassword && emailValid
    );
  };
  return (
    <Modal maxWidth="750">
      <div className="px-8 py-6 relative">
        <h2
          className={`text-center text-2xl font-semibold mb-4 text-primary ${
            currentTheme === "dark" ? "text-mainBg" : "text-filter"
          }`}
        >
          Reset Password
        </h2>
        <p
          className={`text-center mb-4 ${
            currentTheme === "dark" ? "text-mainBg" : "text-filter"
          }`}
        >
          Enter your new password below.
        </p>

        <div className="mb-4 relative">
          <label
            htmlFor="newPassword"
            className={`block text-sm font-medium ${
              currentTheme === "dark" ? "text-mainBg" : "text-filter"
            }`}
          >
            New Password
          </label>
          <input
            type={showNewPassword ? "text" : "password"}
            id="newPassword"
            className={`mt-1 p-2 w-full border border-lightBorder rounded focus:outline-none focus:border-primary ${
              currentTheme === "dark"
                ? "bg-inputColor text-mainBg"
                : "bg-mainBg text-filter"
            }`}
            placeholder="Enter your new password"
            value={newPassword}
            onChange={e => {
              setNewPassword(e.target.value);
              checkPasswordValidation(e.target.value);
            }}
          />
          <span
            className="absolute right-2 top-[2rem] cursor-pointer text-eyeIconColor"
            onClick={() => togglePasswordVisibility("newPassword")}
            aria-label={showNewPassword ? "Hide password" : "Show password"}
          >
            {showNewPassword ? (
              <i className="fa-solid fa-eye"></i>
            ) : (
              <i className="fa-solid fa-eye-slash"></i>
            )}
          </span>
        </div>

        <div className="mb-4 relative">
          <label
            htmlFor="confirmPassword"
            className={`block text-sm font-medium ${
              currentTheme === "dark" ? "text-mainBg" : "text-filter"
            }`}
          >
            Confirm Password
          </label>
          <input
            type={showConfirmPassword ? "text" : "password"}
            id="confirmPassword"
            className={`mt-1 p-2 w-full border border-lightBorder rounded focus:outline-none focus:border-primary ${
              currentTheme === "dark"
                ? "bg-inputColor text-mainBg"
                : "bg-mainBg text-filter"
            }`}
            placeholder="Confirm your password"
            value={confirmPassword}
            onChange={e => setConfirmPassword(e.target.value)}
          />
          <span
            className="absolute right-2 top-[2rem] cursor-pointer text-eyeIconColor"
            onClick={() => togglePasswordVisibility("confirmPassword")}
            aria-label={showConfirmPassword ? "Hide password" : "Show password"}
          >
            {showConfirmPassword ? (
              <i className="fa-solid fa-eye"></i>
            ) : (
              <i className="fa-solid fa-eye-slash"></i>
            )}
          </span>
        </div>
        <div className="flex items-center gap-2 pb-2 text-sm">
          <i
            className={`${
              passwordValidation.capitalLetter
                ? "fa-solid fa-circle-check text-increase"
                : "fa-regular fa-circle-xmark text-decrease"
            }`}
          ></i>
          <p
            className={`text-xs ${
              currentTheme === "dark" ? " text-mainBg" : "text-inputLabel"
            }`}
          >
            1 capital letter
          </p>
        </div>
        <div className="flex items-center gap-2 pb-2 text-sm">
          <i
            className={`${
              passwordValidation.smallLetter
                ? "fa-solid fa-circle-check text-increase"
                : "fa-regular fa-circle-xmark text-decrease"
            }`}
          ></i>
          <p
            className={`text-xs ${
              currentTheme === "dark" ? " text-mainBg" : "text-inputLabel"
            }`}
          >
            1 small letter
          </p>
        </div>
        <div className="flex items-center gap-2 pb-1 text-sm">
          <i
            className={`${
              passwordValidation.specialCharacter
                ? "fa-solid fa-circle-check text-increase"
                : "fa-regular fa-circle-xmark text-decrease"
            }`}
          ></i>
          <p
            className={`text-xs ${
              currentTheme === "dark" ? " text-mainBg" : "text-inputLabel"
            }`}
          >
            1 Special Character
          </p>
        </div>
        <div className="flex items-center gap-2 pb-2 text-sm">
          <i
            className={`${
              passwordValidation.number
                ? "fa-solid fa-circle-check text-increase"
                : "fa-regular fa-circle-xmark text-decrease"
            }`}
          ></i>
          <p
            className={`text-xs ${
              currentTheme === "dark" ? " text-mainBg" : "text-inputLabel"
            }`}
          >
            1 number
          </p>
        </div>
        <div className="flex items-center gap-2 pb-2 text-sm">
          <i
            className={`${
              passwordValidation.length
                ? "fa-solid fa-circle-check text-increase"
                : "fa-regular fa-circle-xmark text-decrease"
            }`}
          ></i>
          <p
            className={`text-xs ${
              currentTheme === "dark" ? " text-mainBg" : "text-inputLabel"
            }`}
          >
            at least 8 characters
          </p>
        </div>
        <div className="flex justify-end">
          <button
            className={`bg-primary text-mainBg px-4 py-2 rounded ${
              isLoading || !buttonEnabled ? "opacity-50 cursor-not-allowed" : ""
            }`}
            onClick={handleResetPassword}
            disabled={!buttonEnabled || isLoading}
          >
            {isLoading ? "Resetting..." : "Reset Password"}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ResetPasswordPopup;
