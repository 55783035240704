import React, { useEffect, useState } from "react";
import { selectCurrentTheme } from "../../store/slices/themeSlice";
import { useSelector } from "react-redux";
import { Select } from "antd";
import {
  useGetCoinMarketQuery,
  useGetHeaderSearchCoinQuery
} from "../../store/services/services";
import ConvertorIcon from "../../assets/icons/convertor.svg";
import { commafy } from "../../utils/commafy";
import { toDecimals } from "../../utils/toDecimals";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

const { Option } = Select;
const CurrencyConverterTools = () => {
  const currentTheme = useSelector(selectCurrentTheme);
  const { data: coins } = useGetCoinMarketQuery();

  const [fromCurrency, setFromCurrency] = useState(coins?.data[0]);
  const [toCurrency, setToCurrency] = useState(coins?.data[1]);
  const [searchFromTerm, setSearchFromTerm] = useState("");
  const [searchToTerm, setSearchToTerm] = useState("");
  const [amount, setAmount] = useState(1);
  const [isMounted, setIsMounted] = useState(false);
  const [canLoading, setCanLoading] = useState(false);
  console.log(searchToTerm, "this is searchToTerm");
  // search from coin
  const {
    data: searchFromCoin,
    isError: searchFromIsError,
    isLoading: searchFromIsLoading,
    isFetching: searchFromIsFetching
  } = useGetHeaderSearchCoinQuery(searchFromTerm);

  // search to coin
  const {
    data: searchToCoin,
    isError: searchToIsError,
    isLoading: searchToIsLoading,
    isFetching: searchIsToFetching
  } = useGetHeaderSearchCoinQuery(searchToTerm);
  console.log(searchToCoin, "this is searchToCoin");

  useEffect(() => {
    if (isMounted) {
      setCanLoading(true);
    } else {
      setIsMounted(true);
    }
  }, [searchFromTerm, searchToTerm]);

  const handleFromCurrency = value => {
    setFromCurrency(JSON.parse(value));
    setSearchFromTerm("");
  };
  const handleToCurrencyChange = value => {
    setToCurrency(JSON.parse(value));
    setSearchToTerm("");
  };

  const handleAmountChange = e => {
    const value = e.target.value;
    if (!isNaN(value) || value === "") {
      setAmount(value);
    }
  };
  const handleFromCurrencyChangeValue = value => {
    setSearchFromTerm(value);
  };
  const handleToCurrencyChangeValue = value => {
    setSearchToTerm(value);
  };

  const handleSwitch = () => {
    setFromCurrency(toCurrency);
    setToCurrency(fromCurrency);
  };

  const fromCurrencyRate = fromCurrency?.price || 1;
  const toCurrencyRate = toCurrency?.price || 1;
  const result = (amount * fromCurrencyRate) / toCurrencyRate;
  const formattedResult = isNaN(result) ? "Invalid calculation" : result.toFixed(2);

  return (
    <div className="px-24 md:px-8 xs:px-4 mt-12">
      <div
        className={`shadow-navbar-button px-6 xs:px-4 py-8 xs:py-4 rounded-[20px] ${
          currentTheme === "dark" ? "dark bg-cardsColor " : "bg-mainBg"
        }`}
      >
        <h1
          className={`text-2xl font-semibold lg:text-base pb-10 md:pb-7 sm:pb-3 ${
            currentTheme === "dark" ? "text-mainBg " : "text-filter"
          }`}
        >
          Cryptocurrency Converter Calculator
        </h1>

        <div className="flex items-center mt-3 border border-lightBorder h-[45px] rounded-[10px] px-3 py-1 gap-3 focus-within:border-primary transition-all duration-200">
          <input
            className={`border-0 outline-0 overflow-hidden bg-transparent text-base w-full ${
              currentTheme === "dark"
                ? "bg-inputColor text-mainBg"
                : "bg-mainBg text-filter"
            }`}
            type="text"
            value={amount}
            onChange={handleAmountChange}
          />
        </div>
        <div className="flex lg:block gap-3 items-center justify-between mt-5">
          <div
            className={`flex items-center relative mt-3  rounded-[10px] w-full py-1 gap-3 focus-within:border-primary transition-all duration-200 ${
              currentTheme === "dark" ? "darkSelect" : "lightSelect"
            }`}
          >
            {(searchFromIsLoading || searchFromIsFetching) && canLoading && (
              <div className="h-[300px] absolute overflow-hidden -bottom-[300px] select-dropdown-loader  shadow-selectDropdown bg-cardsColor w-full">
                <div className="w-full">
                  <SkeletonTheme
                    baseColor={`${currentTheme === "dark" ? "#323546" : "#d8d8d8"}`}
                    highlightColor={`${currentTheme === "dark" ? "#444" : "#e0e0e0"}`}
                  >
                    <Skeleton width="100%" height={35} count={10} />
                  </SkeletonTheme>
                </div>
              </div>
            )}
            <Select
              size="large"
              className={`w-full ${
                currentTheme === "dark" ? "dropdown-text-dark" : "dropdown-text-light"
              }`}
              value={fromCurrency?.name}
              onChange={handleFromCurrency}
              showSearch
              optionFilterProp="value"
              loading={searchFromIsError || searchFromIsFetching}
              onSearch={handleFromCurrencyChangeValue}
              dropdownStyle={{
                backgroundColor: currentTheme === "dark" ? " #222531 " : "#fff",
                color: currentTheme === "dark" ? "#fff !important" : "#000 !important"
              }}
            >
              {/* Display search results if there is a search term */}
              {(searchFromIsError || searchFromIsFetching) && (
                <div className="w-full">
                  <SkeletonTheme
                    baseColor={`${currentTheme === "dark" ? "#323546" : "#d8d8d8"}`}
                    highlightColor={`${currentTheme === "dark" ? "#444" : "#e0e0e0"}`}
                  >
                    <Skeleton width="100%" height={35} count={3} />
                  </SkeletonTheme>
                </div>
              )}
              {!searchFromIsLoading &&
              !searchFromIsError &&
              !searchFromIsFetching &&
              searchFromTerm &&
              searchFromCoin
                ? searchFromCoin?.map(currency => (
                    <Option
                      key={currency.id}
                      value={JSON.stringify(currency)}
                      className={`w-full ${
                        currentTheme === "dark"
                          ? "dropdown-text-dark"
                          : "dropdown-text-light"
                      }`}
                    >
                      {currency.name}
                    </Option>
                  ))
                : coins?.data?.map(currency => (
                    <Option
                      key={currency.id}
                      value={JSON.stringify(currency)}
                      className={`w-full ${
                        currentTheme === "dark"
                          ? "dropdown-text-dark"
                          : "dropdown-text-light"
                      }`}
                    >
                      {currency.name}
                    </Option>
                  ))}
            </Select>
          </div>
          <div className="flex justify-center">
            <div
              className="bg-primary flex justify-center items-center h-[45px] w-[5rem] rounded-[15px] mt-3 cursor-pointer"
              onClick={handleSwitch}
            >
              <img src={ConvertorIcon} alt="convertor-icon" className="h-6" />
            </div>
          </div>
          <div
            className={`flex items-center relative mt-3  rounded-[10px] w-full py-1 gap-3 focus-within:border-primary transition-all duration-200 ${
              currentTheme === "dark" ? "darkSelect" : "lightSelect"
            }`}
          >
            {(searchToIsLoading || searchIsToFetching) && canLoading && (
              <div className="h-[300px] absolute overflow-hidden -bottom-[300px] select-dropdown-loader  shadow-selectDropdown bg-cardsColor w-full">
                <div className="w-full">
                  <SkeletonTheme
                    baseColor={`${currentTheme === "dark" ? "#323546" : "#d8d8d8"}`}
                    highlightColor={`${currentTheme === "dark" ? "#444" : "#e0e0e0"}`}
                  >
                    <Skeleton width="100%" height={35} count={10} />
                  </SkeletonTheme>
                </div>
              </div>
            )}
            <Select
              size="large"
              className={`w-full ${
                currentTheme === "dark" ? "dropdown-text-dark" : "dropdown-text-light"
              }`}
              value={toCurrency?.name}
              onChange={handleToCurrencyChange}
              showSearch
              optionFilterProp="value"
              loading={searchToIsLoading || searchIsToFetching}
              onSearch={handleToCurrencyChangeValue}
              dropdownStyle={{
                backgroundColor: currentTheme === "dark" ? " #222531 " : "#fff",
                color: currentTheme === "dark" ? "#fff !important" : "#000 !important"
              }}
            >
              {/* Display search results if there is a search term */}
              {searchIsToFetching && (
                <div className="w-full">
                  <SkeletonTheme
                    baseColor={`${currentTheme === "dark" ? "#323546" : "#d8d8d8"}`}
                    highlightColor={`${currentTheme === "dark" ? "#444" : "#e0e0e0"}`}
                  >
                    <Skeleton width="100%" height={35} count={3} />
                  </SkeletonTheme>
                </div>
              )}
              {!searchToIsLoading &&
              !searchToIsError &&
              !searchIsToFetching &&
              searchToTerm &&
              searchToCoin
                ? searchToCoin?.map(currency => (
                    <Option
                      key={currency.id}
                      value={JSON.stringify(currency)}
                      className={`w-full ${
                        currentTheme === "dark"
                          ? "dropdown-text-dark"
                          : "dropdown-text-light"
                      }`}
                    >
                      {currency.name}
                    </Option>
                  ))
                : coins?.data?.map(currency => (
                    <Option
                      key={currency.id}
                      value={JSON.stringify(currency)}
                      className={`w-full ${
                        currentTheme === "dark"
                          ? "dropdown-text-dark"
                          : "dropdown-text-light"
                      }`}
                    >
                      {currency.name}
                    </Option>
                  ))}
            </Select>
          </div>
        </div>
        <div className="mt-8">
          <p
            className={`text-sm xs:text-xs text-center ${
              currentTheme === "dark" ? "text-mainBg" : "text-coinConverterColor"
            }`}
          >
            {amount} {fromCurrency?.name} =
            <span className="font-semibold">
              {" "}
              {commafy(toDecimals(formattedResult))}{" "}
            </span>
            {toCurrency?.name}
          </p>
        </div>
      </div>
    </div>
  );
};

export default CurrencyConverterTools;
