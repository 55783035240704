import React from "react";
import AccessDenied from "../components/AccessDenied/AccessDenied";

const AccessDeniedPage = () => {
  return (
    <div>
      <AccessDenied />
    </div>
  );
};

export default AccessDeniedPage;
