import React, { useEffect } from 'react';
import Modal from '../../utils/Modal';
import { useDeleteTransactionMutation } from '../../store/services/services';
import { useDispatch, useSelector } from "react-redux";
import { portfolioChange } from "../../store/slices/portfolioChangeSlice";
import { closeTransactionRemove } from "../../store/slices/popupSlice";
import { selectCurrentTheme } from "../../store/slices/themeSlice";

const TransactionRemovePopup = ({ transactionsId }) => {
  const dispatch = useDispatch();
  const currentTheme = useSelector(selectCurrentTheme);
  const [deleteTransaction, { isLoading, isSuccess }] = useDeleteTransactionMutation();

// handle remove transaction
  const removeTransactionHandler = async () => {
    try {
      await deleteTransaction(transactionsId);
    } catch (error) {}
  };

  useEffect(() => {
    if (isSuccess) {
      dispatch(portfolioChange());
      dispatch(closeTransactionRemove());
    }
  }, [isSuccess, dispatch]);

  return (
    <Modal maxWidth={600}>
      <div className="p-7 xs:px-4">
        <div className="mb-6">
          <p
            className={`text-[22px] xs:text-xl font-semibold mb-2  ${
              currentTheme === "dark" ? " text-mainBg" : "text-secondary"
            }`}
          >
            Remove Transaction
          </p>
          <p className={`${currentTheme === "dark" ? " text-mainBg" : "text-filter"}`}>
            Do you want to delete your Transaction? Performing this action, you understand
            that you would not be able to recover your Transaction.
          </p>
        </div>
        <div>
          <button
            className="w-full text-center bg-decrease py-2 rounded-[6px] mb-4 text-mainBg font-semibold disabled:bg-removeDisabledColor"
            onClick={removeTransactionHandler}
            disabled={isLoading}
          >
            {isLoading ? "Removing..." : "Remove"}
          </button>
          <button
            className={`w-full text-center py-2 rounded-[6px] font-semibold ${
              currentTheme === "dark"
                ? "bg-inputColor text-mainBg"
                : "bg-popupBg text-popupText"
            }`}
            onClick={() => dispatch(closeTransactionRemove())}
          >
            Cancel
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default TransactionRemovePopup;
