export const data = [
  {
    id: 1,
    Date: "2010-01",
    'Market Cap': 1998,
  },
  {
    id: 2,
    Date: "2010-02",
    'Market Cap': 1850,
  },
  {
    id: 3,
    Date: "2010-03",
    'Market Cap': 1720,
  },
  {
    id: 4,
    Date: "2010-04",
    'Market Cap': 1818,
  },
  {
    id: 5,
    Date: "2010-05",
    'Market Cap': 1920,
  },
  {
     id: 6,
    Date: "2010-06",
    'Market Cap': 1802,
  },
  {
    id: 7,
    Date: "2010-07",
    'Market Cap': 1945,
  },
  {
    id: 8,
    Date: "2010-08",
    'Market Cap': 1856,
  },
  {
    id: 9,
    Date: "2010-09",
    'Market Cap': 2107,
  },
  {
    id: 10,
    Date: "2010-10",
    'Market Cap': 2140,
  },
  {
    id: 11,
    Date: "2010-11",
    'Market Cap': 2311,
  },
  {
    id: 12,
    Date: "2010-12",
    'Market Cap': 1972,
  },
  {
    id: 13, 
    Date: "2011-01",
    'Market Cap': 1760,
  },
  {
     id: 14,
    Date: "2011-02",
    'Market Cap': 1824,
  },
  {
     id: 15,
    Date: "2011-03",
    'Market Cap': 1801,
  },
  {
     id: 16,
    Date: "2011-04",
    'Market Cap': 2001,
  },
  {
     id: 17,
    Date: "2011-05",
    'Market Cap': 1640,
  },
  {
     id: 18,
    Date: "2011-06",
    'Market Cap': 1502,
  },
  {
     id: 19,
    Date: "2011-07",
    'Market Cap': 1621,
  },
  {
     id: 20,
    Date: "2011-08",
    'Market Cap': 1480,
  },
  {
     id: 21,
    Date: "2011-09",
    'Market Cap': 1549,
  },
  {
     id: 22,
    Date: "2011-10",
    'Market Cap': 1390,
  },
  {
     id: 23,
    Date: "2011-11",
    'Market Cap': 1325,
  },
  {
     id: 24,
    Date: "2011-12",
    'Market Cap': 1250,
  },
  {
     id: 25,
    Date: "2012-01",
    'Market Cap': 1394,
  },
  {
     id: 26,
    Date: "2012-02",
    'Market Cap': 1406,
  },
  {
  id: 27,
    Date: "2012-03",
    'Market Cap': 1578,
  },
  {
     id: 28,
    Date: "2012-04",
    'Market Cap': 1465,
  },
  {
     id: 29,
    Date: "2012-05",
    'Market Cap': 1689,
  },
  {
     id: 30,
    Date: "2012-06",
    'Market Cap': 1755,
  },
  {
     id: 31,
    Date: "2012-07",
    'Market Cap': 1495,
  },
  {
     id: 32,
    Date: "2012-08",
    'Market Cap': 1508,
  },
  {
     id: 33,
    Date: "2012-09",
    'Market Cap': 1433,
  },
  {
     id: 34,
    Date: "2012-10",
    'Market Cap': 1344,
  },
  {
     id: 35,
    Date: "2012-11",
    'Market Cap': 1201,
  },
  {
     id: 36,
    Date: "2012-12",
    'Market Cap': 1065,
  },
  {
     id: 37,
    Date: "2013-01",
    'Market Cap': 1255,
  },
  {
     id: 38,
    Date: "2013-02",
    'Market Cap': 1429,
  },
  {
     id: 39,
    Date: "2013-03",
    'Market Cap': 1398,
  },
  {
     id: 40,
    Date: "2013-04",
    'Market Cap': 1678,
  },
  {
     id: 41,
    Date: "2013-05",
    'Market Cap': 1524,
  },
  {
     id: 42,
    Date: "2013-06",
    'Market Cap': 1688,
  },
  {
     id: 43,
    Date: "2013-07",
    'Market Cap': 1500,
  },
  {
     id: 44,
    Date: "2013-08",
    'Market Cap': 1670,
  },
  {
     id: 45,
    Date: "2013-09",
    'Market Cap': 1734,
  },
  {
     id: 46,
    Date: "2013-10",
    'Market Cap': 1699,
  },
  {
     id: 47,
    Date: "2013-11",
    'Market Cap': 1508,
  },
  {
     id: 48,
    Date: "2013-12",
    'Market Cap': 1680,
  },
  {
     id: 49,
    Date: "2014-01",
    'Market Cap': 1750,
  },
  {
     id: 50,
    Date: "2014-02",
    'Market Cap': 1602,
  },
  {
     id: 51,
    Date: "2014-03",
    'Market Cap': 1834,
  },
  {
     id: 52,
    Date: "2014-04",
    'Market Cap': 1722,
  },
  {
     id: 53,
    Date: "2014-05",
    'Market Cap': 1430,
  },
  {
     id: 54,
    Date: "2014-06",
    'Market Cap': 1280,
  },
  {
     id: 55,
    Date: "2014-07",
    'Market Cap': 1367,
  },
  {
     id: 56,
    Date: "2014-08",
    'Market Cap': 1155,
  },
  {
     id: 57,
    Date: "2014-09",
    'Market Cap': 1289,
  },
  {
     id: 58,
    Date: "2014-10",
    'Market Cap': 1104,
  },
  {
     id: 59,
    Date: "2014-11",
    'Market Cap': 1246,
  },
  {
     id: 60,
    Date: "2014-12",
    'Market Cap': 1098,
  },
  {
     id: 61,
    Date: "2015-01",
    'Market Cap': 1189,
  },
  {
     id: 62,
    Date: "2015-02",
    'Market Cap': 1276,
  },
  {
     id: 63,
    Date: "2015-03",
    'Market Cap': 1033,
  },
  {
     id: 64,
    Date: "2015-04",
    'Market Cap': 956,
  },
  {
     id: 65,
    Date: "2015-05",
    'Market Cap': 845,
  },
  {
     id: 66,
    Date: "2015-06",
    'Market Cap': 1089,
  },
  {
     id: 67,
    Date: "2015-07",
    'Market Cap': 944,
  },
  {
     id: 68,
    Date: "2015-08",
    'Market Cap': 1043,
  },
  {
     id: 69,
    Date: "2015-09",
    'Market Cap': 893,
  },
  {
     id: 70,
    Date: "2015-10",
    'Market Cap': 840,
  },
  {
     id: 71,
    Date: "2015-11",
    'Market Cap': 934,
  },
  {
     id: 72,
    Date: "2015-12",
    'Market Cap': 810,
  },
  {
     id: 73,
    Date: "2016-01",
    'Market Cap': 782,
  },
  {
     id: 74,
    Date: "2016-02",
    'Market Cap': 1089,
  },
  {
     id: 75,
    Date: "2016-03",
    'Market Cap': 745,
  },
  {
     id: 76,
    Date: "2016-04",
    'Market Cap': 680,
  },
  {
     id: 77,
    Date: "2016-05",
    'Market Cap': 802,
  },
  {
     id: 78,
    Date: "2016-06",
    'Market Cap': 697,
  },
  {
     id: 79,
    Date: "2016-07",
    'Market Cap': 583,
  },
  {
     id: 80,
    Date: "2016-08",
    'Market Cap': 456,
  },
  {
     id: 81,
    Date: "2016-09",
    'Market Cap': 524,
  },
  {
     id: 82,
    Date: "2016-10",
    'Market Cap': 398,
  },
  {
     id: 83,
    Date: "2016-11",
    'Market Cap': 278,
  },
  {
     id: 84,
    Date: "2016-12",
    'Market Cap': 195,
  },
  {
     id: 85,
    Date: "2017-01",
    'Market Cap': 145,
  },
  {
     id: 86,
    Date: "2017-02",
    'Market Cap': 207,
  },
];