import React from "react";
import { selectCurrentTheme } from "../../store/slices/themeSlice";
import ReactDOM from "react-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  closeAddCoin,
  closeCustomize,
  closeFilter,
  closeLogin,
  closePortfolioCreate,
  closePortfolioEdit,
  closePortfolioRemove,
  closeTransactionRemove,
  closeRegister,
  closeTransaction,
  closeOngoingProject,
  closeUpcomingProject,
  closeSingleTransactionRemove,
  closeSingleTransactionEdit,
  closeTransactionPopup,
  closeTransactionNotePopup,
  closeSocialMediaSharing,
  closeTadingView,
  closecoinTagsView,
  closeUserProfileView,
  closeEmailVerification,
  closeForgotPassword,
  closeResetPassword,
  closeRemoveWatchList,
  closeRemoveLocalStorageWatchList
} from "../../store/slices/popupSlice";

import crossIcon from "../../assets/icons/cross.svg";

const Backdrop = ({ closeable }) => {
  const dispatch = useDispatch();

  return (
    <div
      className="h-screen w-screen fixed bg-modalBackdrop"
      onClick={() => {
        if (closeable !== false) {
          dispatch(closeLogin());
          dispatch(closeRegister());
          dispatch(closeCustomize());
          dispatch(closeFilter());
          dispatch(closeAddCoin());
          dispatch(closePortfolioCreate());
          dispatch(closePortfolioRemove());
          dispatch(closeTransactionRemove());
          dispatch(closePortfolioEdit());
          dispatch(closeTransaction());
          dispatch(closeOngoingProject());
          dispatch(closeUpcomingProject());
          dispatch(closeSingleTransactionRemove());
          dispatch(closeSingleTransactionEdit());
          dispatch(closeTransactionPopup());
          dispatch(closeTransactionNotePopup());
          dispatch(closeSocialMediaSharing());
          dispatch(closeTadingView());
          dispatch(closecoinTagsView())
          dispatch(closeUserProfileView());
          dispatch(closeEmailVerification());
          dispatch(closeForgotPassword());
          dispatch(closeResetPassword());
          dispatch(closeRemoveWatchList());
          dispatch(closeRemoveLocalStorageWatchList());
        }
      }}
    ></div>
  );
};

const Overlay = ({ children, maxWidth, closeable }) => {
  const dispatch = useDispatch();
  const currentTheme = useSelector(selectCurrentTheme);
  return (
    <div
      className={`fixed xs:w-full w-[90%]  xs:h-full xs:flex xs:items-center justify-center xs:max-h-full xs:rounded-none overflow-hidden top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2  rounded-[25px] py-2 ${
        currentTheme === "dark" ? "bg-cardsColor" : "bg-mainBg"
      }`}
      style={{ maxWidth: `${maxWidth}px` }}
    >
      {closeable !== false && (
        <div className={` ${currentTheme === "dark" ? "bg-cardsColor" : "bg-mainBg"}`}>
          <button
            className="absolute top-10 right-10 z-50 xs:top-6 xs:right-6"
            onClick={() => {
              dispatch(closeLogin());
              dispatch(closeRegister());
              dispatch(closeCustomize());
              dispatch(closeFilter());
              dispatch(closeAddCoin());
              dispatch(closePortfolioCreate());
              dispatch(closePortfolioRemove());
              dispatch(closeTransactionRemove());
              dispatch(closePortfolioEdit());
              dispatch(closeTransaction());
              dispatch(closeOngoingProject());
              dispatch(closeUpcomingProject());
              dispatch(closeSingleTransactionRemove());
              dispatch(closeSingleTransactionEdit());
              dispatch(closeTransactionPopup());
              dispatch(closeTransactionNotePopup());
              dispatch(closeSocialMediaSharing());
              dispatch(closeTadingView());
              dispatch(closecoinTagsView());
              dispatch(closeUserProfileView());
               dispatch(closeEmailVerification());
               dispatch(closeForgotPassword());
               dispatch(closeResetPassword());
                dispatch(closeRemoveWatchList());
                 dispatch(closeRemoveLocalStorageWatchList());
            }}
          >
            <img className="h-4" src={crossIcon} alt="cross-icon" />
          </button>
        </div>
      )}

      <div className="max-h-[90vh] xs:max-h-screen overflow-x-auto w-full scrollbar-hide ">
        {children}
      </div>
    </div>
  );
};

const Modal = ({ children, onClose, maxWidth, closeable }) => {
  const modal = useSelector((state) => state.popup.modalIsVisible);

  if (!modal) return null;

  return (
    <>
      {ReactDOM.createPortal(
        <Backdrop onClose={onClose} closeable={closeable} />,
        document.getElementById("backdrop")
      )}
      {ReactDOM.createPortal(
        <Overlay
          children={children}
          maxWidth={maxWidth}
          closeable={closeable}
        />,
        document.getElementById("overlay")
      )}
    </>
  );
};

export default Modal;
