import React, { useEffect, useState } from "react";
import TodayNews from "../TodayNews/TodayNews";
import CurrencyInfo from "../CurrencyInfo/CurrencyInfo";
import AllNewsCard from "../AllNewsCard";
// import Placeholder from "../Placeholder";
import Disclaimer from "../Disclaimer";
import { useGetSearchNewsQuery } from "../../store/services/services";

const MainNews = ({ searchCoin }) => {
  const [category, setCategory] = useState("");
  const [searchInput, setSearchInput] = useState(searchCoin ? searchCoin : "");

  useEffect(() => {
  setSearchInput(category);
  }, [category]);
  const {
    data: searchData,
    isError,
    isLoading,
    isFetching,
    error
  } = useGetSearchNewsQuery(searchInput);

  const { data: catData } = useGetSearchNewsQuery(category);

  // Fetch the search data
  const fetchSearchData = () => {
    setSearchInput(category);
  };

  return (
    <>
      <TodayNews
        category={category}
        setCategory={setCategory}
        searchInput={searchInput}
        catData={catData}
        setSearchInput={setSearchInput}
        fetchSearchData={fetchSearchData}
      />
      <div className="px-24 md:px-8 xs:px-4 mt-[5rem] sm:mt-[.5rem]">
        <CurrencyInfo />
        <AllNewsCard
          category={category}
          catData={catData}
          searchData={searchData}
          isLoading={isLoading}
          isFetching={isFetching}
          isError={isError}
          error={error}
          searchInput={searchInput}
        />
        {/* <div className="mt-24">
      <Placeholder />
    </div> */}
        <Disclaimer />
      </div>
    </>
  );
};

export default MainNews;
