import React from "react";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";

const IcosData = ({ filterData }) => {
  // const [readMoreEnabled, setReadMoreEnabled] = useState(false);
  return (
    <div className="">
      <div className="px-5 py-3 border-lightBorder">
        <p className="text-grey mb-3 xs:text-sm">Status</p>
        <div className="flex items-center gap-4 mt-2">
          <h3 className="font-semibold text-[26px] xs:text-base">ICO</h3>
          <button className="bg-primary border-[1px] py-2 px-6 rounded-[40px] cursor-pointer text-lg font-semibold xs:text-base">
            Ongoing
          </button>
        </div>
        <p className="text-base pt-1 text-grey xs:text-sm md:pt-4">
          As the project is relatively new, please review our
          <span className="text-blueColor"> disclaimer</span>
        </p>
        <p className="text-grey text-base pt-5 md:pt-4 font-semibold xs:text-sm">
          About the {filterData.coin}
        </p>
        <p className="text-grey text-base pt-9 md:pt-4 xs:text-sm">
          {filterData.desc}
          {/* &nbsp; &nbsp;
          <button
            onClick={() => setReadMoreEnabled(!readMoreEnabled)}
            className="mt-4 cursor-pointer font-semibold text-[18px] text-blueColor"
          >
            {readMoreEnabled ? (
              <p>
                Show Less <FontAwesomeIcon icon={faChevronUp} />
              </p>
            ) : (
              <p>
                Read More <FontAwesomeIcon icon={faChevronDown} />
              </p>
            )}
          </button> */}
        </p>
      </div>
    </div>
  );
};

export default IcosData;
