import React, { useState } from "react";
import IcosTags from "../IcosTags";
import IcosData from "../IcosData/IcosData";
import IcosDetailCardMenu from "../IcosDetailCardMenu";
import IcosDetailMenu from "../IcosDetailMenu/IcosDetailMenu";
import ParticipateInfo from "../ParticipateInfo/ParticipateInfo";
import AussieDigitalMarkets from "../AussieDigitalMarkets";
import LatestAussieDigital from "../LatestAussieDigital/LatestAussieDigital";
import EarnCrypto from "../EarnCrypto";
import SocialPriceEstimates from "../SocialPriceEstimates/SocialPriceEstimates";
import MoreInfoDetails from "../MoreInfoDetails/MoreInfoDetails";
import { useParams } from "react-router-dom";
import { ended_table_data } from "../../data/icosData";

const SingleIcos = () => {
  const [activeMenu, setActiveMenu] = useState();
  const activeMenuHandler = (menu) => {
    setActiveMenu(menu);
  };
  const { id } = useParams();
  const filterData = ended_table_data.find(
    (project) => project.id === parseInt(id)
  );

  return (
    <div className="px-12 md:px-8 xs:px-4 mt-16 md:mt-1">
      <div className="flex lg:flex-col-reverse gap-[3rem]">
        <div className="shadow-navbar-button rounded-[40px] flex lg:block w-5/6 lg:w-full">
          <div className="w-[45%] lg:w-full">
            <IcosTags filterData={filterData} />
          </div>
          <div className="w-[55%] lg:w-full">
            <IcosData filterData={filterData} />
          </div>
        </div>
        <div className="w-1/6 lg:w-full">
          <IcosDetailCardMenu />
        </div>
      </div>
      <div>
        <IcosDetailMenu activeMenuHandler={activeMenuHandler} />
        {activeMenu === "overview" && <ParticipateInfo />}
        {activeMenu === "market" && <AussieDigitalMarkets />}
        {activeMenu === "news" && <LatestAussieDigital />}
        {activeMenu === "ico" && <EarnCrypto />}
        {activeMenu === "price-estimates" && <SocialPriceEstimates />}
        {activeMenu === "more-info-details" && <MoreInfoDetails />}
      </div>
    </div>
  );
};

export default SingleIcos;
