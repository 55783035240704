import React, { useState } from "react";
import {
  useGetMiningEquipmentsQuery,
  useGetFngDataQuery
} from "../../store/services/services";
import Skeleton from "react-loading-skeleton";

const MiningEquipments = () => {
  const [periodActive, setPeriodActive] = useState("7d");
  const { data: mininig_Data, isLoading, isError, error } = useGetMiningEquipmentsQuery();
  const { data: fngData } = useGetFngDataQuery(periodActive);

  const apiData = fngData?.data || [];
  const transformedData = [];
  if (apiData) {
    apiData.forEach(item => {
      const timestamp = item.timestamp * 1000;
      const date = new Date(timestamp);
      const formattedTimestamp = `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
      transformedData.push(
        { time: formattedTimestamp, value: +item.value, field: "Fear & Greed" },
        {
          time: formattedTimestamp,
          value: item.value_classification,
          field: "value_classification"
        }
      );
    });
  }
  return (
    <div className=" text-secondary px-12 md:px-8 xs:px-4 mt-12 mb-16">
      <h3 className="font-semibold text-2xl sm:text-[22px]">Mining Equipments</h3>
      {isLoading && (
        <div className="w-full">
          <Skeleton width="100%" height={35} count={5} />
        </div>
      )}
      {isError && <p>{error.message}</p>}
      {!isLoading && !isError && (
        <div className="flex flex-wrap justify-center mt-7 gap-5">
          {mininig_Data?.slice(0, 8).map(items => {
            return (
              <div
                key={items.id}
                className="w-[32%] sm:w-full min-w-[320px] shadow-navbar-button pb-5 rounded-[15px]"
              >
                <div className="relative">
                  <div className="flex items-center absolute top-10 left-11 gap-2">
                    {/* <img src={bnbImg} alt="bnbIcon" className="h-6" />
                  <p className="text-base text-mainBg">{IconInfo}</p> */}
                  </div>
                  {/* <img
                  src={equipmentsImg}
                  alt="equipments"
                  className="w-full h-[226px]"
                /> */}
                </div>
                <h3 className="text-[22px] text-center xs:text-lg font-semibold">
                  {items?.company}
                </h3>
                <div
                  key={items.id}
                  className="flex flex-wrap px-5 justify-between items-center mt-5"
                >
                  {mininig_Data?.slice(0, 6).map(items => {
                    // const { logo, logoName, logoInfo } = items;
                    return (
                      <div className="flex w-1/2 items-center mt-5 gap-2" key={items.id}>
                        {/* <img src={logo} alt="power" className="h-6" /> */}
                        <div>
                          <p className="text-base font-semibold text-secondary">
                            {items?.algorithm}
                          </p>
                          <p className="text-sm text-coinConverterColor">{items?.cost}</p>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div className="px-5">
                  <button className="bg-primary text-lg text-secondary py-2 text-center rounded-[15px] font-semibold mt-5 w-full">
                    Buy Now
                  </button>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default MiningEquipments;
