import React, { useState, useRef, useEffect } from "react";
import NewsIcon from "../../assets/icons/news-icon.svg";
import DownIcon from "../../assets/icons/down-icon.svg";
import DarkCalendarIcon from "../../assets/icons/dark-calendar-icon.svg";
import DropdownMenu from "../../utils/DropdownMenu/DropdownMenu";
import RightArrow from "../../assets/icons/right-arrow.svg";
import { useGetCoinMarketQuery, useGetHeaderSearchCoinQuery } from "../../store/services/services";
import { selectCurrentTheme } from "../../store/slices/themeSlice";
import { useSelector } from "react-redux";
import CryptoIcon from "../../assets/icons/crypto-icon.svg";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

const TodayNews = ({ category, setCategory, searchInput, setSearchInput }) => {
  const [search, setSearch] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [searchItems, setSearchItems] = useState(false);
  const [toggle, setToggle] = useState(false);

  // search coin
  const {
    data: searchCoin,
    isError: searchIsError,
    isLoading: searchIsLoading,
    isFetching: searchIsFetching,
    refetch: searchRefetch
  } = useGetHeaderSearchCoinQuery(searchTerm);

  const dropdownRef = useRef(null);
  const { data, isLoading, isError, error } = useGetCoinMarketQuery();
  const currentTheme = useSelector(selectCurrentTheme);
  const handleClickOutside = event => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setToggle(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
     const searchFocusHandler = () => {
       setSearchItems(true);
     };

     // Debounce function
     const debounce = (func, delay) => {
       let timeoutId;
       return function (...args) {
         if (timeoutId) {
           clearTimeout(timeoutId);
         }
         timeoutId = setTimeout(() => {
           func(...args);
         }, delay);
       };
     };
     // Search coin with debounce
     const debouncedSearch = useRef(
       debounce(value => {
         if (value.trim() !== "") {
           searchRefetch(value);
           setSearchItems(true);
         } else {
           setSearchItems(false);
         }
       }, 1000)
     ).current;

     // Handle input change with debouncing
     const searchHandler = e => {
       const { value } = e.target;
       setSearchTerm(value);
       debouncedSearch(value);
     };
  let filterdCurrencies;
  if (!isLoading && !isError) {
    filterdCurrencies = data?.data?.filter(
      item =>
        item?.name.toLowerCase().includes(search.toLowerCase()) ||
        item?.logo.toLowerCase().includes(search.toLowerCase())
    );
  }
  return (
    <div className="px-24 md:px-8 xs:px-4 mt-[5rem] sm:mt-8 flex xl:block justify-between items-center">
      <div className="xl:pb-3.5">
        <div className="flex items-center gap-3">
          <img
            className="h-6"
            src={currentTheme === "dark" ? DarkCalendarIcon : NewsIcon}
            alt="Binance-icon"
          />
          <h3
            className={`font-semibold text-2xl sm:text-[22px] ${
              currentTheme === "dark" ? " text-mainBg" : "text-filter"
            }`}
          >
            Today News
          </h3>
        </div>
        <p
          className={`text-base sm:text-sm font-normal md:pt-2.5 ${
            currentTheme === "dark" ? " text-mainBg" : "text-filter"
          }`}
        >
          Insights into the biggest events shaping the crypto industry.
        </p>
      </div>
      <div className="flex items-center gap-5 xl:pb-3 sm:flex sm:justify-center sm:items-end">
        <div
          className={`flex items-center border-2 border-inputBorder h-[45px] sm:h-[30px] rounded-[10px] px-3 py-1 gap-3 focus-within:border-primary transition-all duration-200 ${
            currentTheme === "dark"
              ? "bg-inputColor text-mainBg"
              : "bg-mainBg text-filter"
          }`}
        >
          <input
            className={`border-0 outline-0 overflow-hidden bg-transparent text-base w-[360px] sm:w-[20rem] xs:sm:w-[15rem] ${
              currentTheme === "dark"
                ? "bg-inputColor text-mainBg"
                : "bg-mainBg text-filter"
            }`}
            type="text"
            placeholder="search"
            value={searchInput}
            onChange={e => setSearchInput(e.target.value)}
          />
        </div>
      </div>

      <div className="relative sm:flex md:justify-center" ref={dropdownRef}>
        <button
          className="flex items-center bg-primary rounded-[9px] w-44 md:w-[120px] justify-between py-1 px-4"
          onClick={() => setToggle(!toggle)}
        >
          <p className="text-lg md:text-sm text-secondary">
            {category === "" ? "All Coins" : category}
          </p>
          <img className="h-[7px]" src={DownIcon} alt="down-icon" />
        </button>
        {toggle && (
          <div>
            <DropdownMenu>
              <div>
                <div className="flex justify-between w-[450px] md:w-[300px] sm:w-[230px]">
                  <h1
                    className={`text-2xl md:text-lg font-semibold  ${
                      currentTheme === "dark" ? " text-mainBg" : "text-secondary"
                    }`}
                  >
                    Select Coin
                  </h1>
                  <button
                    className="bg-primary rounded-[9px] justify-between py-1 px-4"
                    onClick={() => setCategory("")}
                  >
                    <p className="text-lg md:text-sm text-secondary">All Coins</p>
                  </button>
                </div>
                <div className="flex items-center border-2 mt-3 border-inputBorder h-[45px] sm:h-[30px] rounded-[10px] px-3 py-1 gap-3 focus-within:border-primary transition-all duration-200">
                  <input
                    className="border-0 outline-0 overflow-hidden bg-transparent text-base w-[450px] lg:w-28"
                    type="text"
                    placeholder="search"
                    onFocus={searchFocusHandler}
                    value={searchTerm}
                    onChange={searchHandler}
                  />
                </div>
                {searchTerm === "" && (
                  <div>
                    {isLoading && (
                      <div className="w-full">
                        <SkeletonTheme
                          baseColor={`${currentTheme === "dark" ? "#323546" : "#d8d8d8"}`}
                          highlightColor={`${
                            currentTheme === "dark" ? "#444" : "#e0e0e0"
                          }`}
                        >
                          <Skeleton width="100%" height={35} count={3} />
                        </SkeletonTheme>
                      </div>
                    )}
                    {isError && <p>{error.message}</p>}
                    {!isLoading && !isError && (
                      <div>
                        {data?.data?.map(item => {
                          return (
                            <div
                              key={item.id}
                              className={`flex justify-between items-center mt-5 border-b cursor-pointer pb-5 sm:pb-2 ${
                                currentTheme === "dark"
                                  ? "border-darkBorder"
                                  : "border-lightBorder"
                              }`}
                              onClick={() => {
                                setCategory(item.name);
                                setToggle(false);
                              }}
                            >
                              <div className="flex items-center gap-3">
                                <img
                                  src={item?.img ? item?.img : CryptoIcon}
                                  alt="bitcoin"
                                  className="h-6"
                                />
                                <h1 className="text-lg md:text-base sm:text-xs font-semibold">
                                  {item?.name}
                                </h1>
                                <p
                                  className={`text-lg md:text-base sm:text-xs font-semibold text-coinPopup`}
                                >
                                  {item?.logo}
                                </p>
                              </div>
                              <div>
                                <img
                                  src={RightArrow}
                                  alt="arrow-img"
                                  className="h-4 sm:h-3"
                                />
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div>
                )}
                {searchTerm !== "" && searchItems && (
                  <div>
                    <h3
                      className={`font-semibold  mb-3 ${
                        currentTheme === "dark" ? "text-mainBg " : "text-textGray"
                      }`}
                    >
                      Search currencies
                    </h3>
                    {(searchIsLoading || searchIsFetching) && (
                      <div className="w-full">
                        <SkeletonTheme
                          baseColor={`${currentTheme === "dark" ? "#323546" : "#d8d8d8"}`}
                          highlightColor={`${
                            currentTheme === "dark" ? "#444" : "#e0e0e0"
                          }`}
                        >
                          <Skeleton width="100%" height={35} count={3} />
                        </SkeletonTheme>
                      </div>
                    )}
                    {!searchIsLoading && !searchIsError && !searchIsFetching && (
                      <div>
                        {searchCoin?.length > 0 && (
                          <div className="">
                            {searchCoin?.map(item => {
                              return (
                                <div
                                  key={item.id}
                                  className={`flex justify-between items-center mt-5 border-b cursor-pointer pb-5 sm:pb-2 ${
                                    currentTheme === "dark"
                                      ? "border-darkBorder"
                                      : "border-lightBorder"
                                  }`}
                                  onClick={() => {
                                    setCategory(item.name);
                                    setToggle(false);
                                  }}
                                >
                                  <div className="flex items-center gap-3">
                                    <img
                                      src={item?.img ? item?.img : CryptoIcon}
                                      alt="bitcoin"
                                      className="h-6"
                                    />
                                    <h1 className="text-lg md:text-base sm:text-xs font-semibold">
                                      {item?.name}
                                    </h1>
                                    <p
                                      className={`text-lg md:text-base sm:text-xs font-semibold text-coinPopup`}
                                    >
                                      {item?.logo}
                                    </p>
                                  </div>
                                  <div>
                                    <img
                                      src={RightArrow}
                                      alt="arrow-img"
                                      className="h-4 sm:h-3"
                                    />
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                )}
                {searchTerm?.length === 0 && (
                  <div
                    className={`text-center my-10 font-medium text-base  ${
                      currentTheme === "dark"
                        ? " text-mainBg"
                        : "text-currencyNotFoundText"
                    }`}
                  >
                    No Currencies Found
                  </div>
                )}
              </div>
            </DropdownMenu>
          </div>
        )}
      </div>
    </div>
  );
};

export default TodayNews;
