import React from "react";
import WatchListTable from "../WatchListTable";

const WatchListAccessed = () => {
  return (
    <div>
      <WatchListTable />
    </div>
  );
};

export default WatchListAccessed;
