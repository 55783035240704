import BitcoinLogo from "../assets/icons/bitcoin.svg";
import TableChartImage from "../assets/images/table-chart.svg";
import Ethereum from "../assets/icons/ethereum.svg";
import EthCart from "../assets/images/ethChart.svg";
import TethChart from "../assets/images/tethChart.svg";
import Tether from "../assets/images/tether.png";
import XRP from "../assets/images/xrp.png";
import OKB from "../assets/images/okb.png";
import Polygon from "../assets/images/polygon.png";
import Solana from "../assets/images/solana.png";
import Avalanche from "../assets/images/avalanche.png";
import Aptos from "../assets/images/aptos.png";
import Graph from "../assets/images/graph.png";

import BinanceLogo from "../assets/icons/binance.svg";
import KucoinLogo from "../assets/icons/kuCoin.png";
import GeminiLogo from "../assets/icons/gemini.png";
import BitgetLogo from "../assets/icons/bitget.png";
import OkxLogo from "../assets/icons/okb.png";
import BitfinexLogo from "../assets/icons/bitfinex.png";
import BybitLogo from "../assets/icons/bybit.png";
import BitstampLogo from "../assets/icons/bitstamp.png";
import HuobiLogo from "../assets/icons/huobi.png";

export const CURRENCY_TABLE_DATA = [
  {
    id: 1,
    coin: "OKB",
    coinCode: "OKB",
    title: "OKB Price (OKB)",
    info: "11.01 OKB",
    lowPrice: 11407.11,
    highPrice: 12407.12,
    coinImage: OKB,
    price: 0.000009002,
    oneh: 0.6,
    twentyfourh: -2.2,
    sevend: -15.5,
    "twentyfourh-volume": 666971578,
    "mkt-cap": 10372237918,
    "last-7-days": TableChartImage,
  },
  {
    id: 2,
    coin: "Bitcoin",
    coinCode: "BTC",
    title: "Bitcoin Price (BTC)",
    info: "12.02 BTC",
    lowPrice: 22407.22,
    highPrice: 24407.24,
    coinImage: BitcoinLogo,
    price: 27947.28,
    oneh: 0.7,
    twentyfourh: 2.3,
    sevend: -12.5,
    "twentyfourh-volume": 586971578,
    "mkt-cap": 11372237918,
    "last-7-days": TableChartImage,
  },
  {
    id: 3,
    coin: "Ethereum",
    coinCode: "ETH",
    title: "Ethereum Price (ETH)",
    info: "13.03 ETH",
    lowPrice: 33407.33,
    highPrice: 35407.35,
    coinImage: Ethereum,
    price: 1867.31,
    oneh: 0.1,
    twentyfourh: 2.7,
    sevend: -14.5,
    "twentyfourh-volume": 666971578,
    "mkt-cap": 19372237918,
    "last-7-days": EthCart,
  },
  {
    id: 4,
    coin: "Tether",
    coinCode: "USDC",
    title: "Tether Price (USDC)",
    info: "14.04 USDC",
    lowPrice: 44407.44,
    highPrice: 4640746,
    coinImage: Tether,
    price: 1.0,
    oneh: -0.7,
    twentyfourh: 9.2,
    sevend: 25.5,
    "twentyfourh-volume": 816971578,
    "mkt-cap": 30372237918,
    "last-7-days": TethChart,
  },
  {
    id: 5,
    coin: "XRP",
    coinCode: "XRP",
    title: "XRP Price (XRP)",
    info: "15.05 XRP",
    lowPrice: 55407.55,
    highPrice: 57407.57,
    coinImage: XRP,
    price: 0.4356,
    oneh: 2.6,
    twentyfourh: -2.2,
    sevend: -22.5,
    "twentyfourh-volume": 216971578,
    "mkt-cap": 81372237918,
    "last-7-days": TableChartImage,
  },
  {
    id: 6,
    coin: "Polygon",
    coinCode: "MATIC",
    title: "Polygon Price (MATIC)",
    info: "16.06 MATIC",
    lowPrice: 6640.66,
    highPrice: 68407.68,
    coinImage: Polygon,
    price: 3.000003,
    oneh: 2.16,
    twentyfourh: -4.2,
    sevend: -11.5,
    "twentyfourh-volume": 333971578,
    "mkt-cap": 19372237918,
    "last-7-days": TableChartImage,
  },
  {
    id: 7,
    coin: "Solana",
    coinCode: "SOL",
    title: "Solana Price (SOL)",
    info: "17.07 SOL",
    lowPrice: 707.77,
    highPrice: 80407.8,
    coinImage: Solana,
    price: 21.3,
    oneh: 2.6,
    twentyfourh: -3.2,
    sevend: "-25.5",
    "twentyfourh-volume": 786971578,
    "mkt-cap": 32232237918,
    "last-7-days": EthCart,
  },
  {
    id: 8,
    coin: "Avalanche",
    coinCode: "AVAX",
    title: "Avalanche Price (AVAX)",
    info: "18.08 AVAX",
    lowPrice: 82407.82,
    highPrice: 84407.84,
    coinImage: Avalanche,
    price: 15.85,
    oneh: 3.6,
    twentyfourh: 2.2,
    sevend: -3.5,
    "twentyfourh-volume": 324971578,
    "mkt-cap": 19372237918,
    "last-7-days": TableChartImage,
  },
  {
    id: 9,
    coin: "Aptos",
    coinCode: "APT",
    title: "Aptos Price (APT)",
    info: "19.09 APT",
    lowPrice: 90407.9,
    highPrice: 92407.92,
    coinImage: Aptos,
    price: 8.78,
    oneh: 5.6,
    twentyfourh: -82.2,
    sevend: -21.5,
    "twentyfourh-volume": 121971578,
    "mkt-cap": 62322237918,
    "last-7-days": TableChartImage,
  },
  {
    id: 10,
    coin: "The Graph",
    title: "APT Price (GRT)",
    coinCode: "GRT",
    info: "10.10 GRT",
    lowPrice: 10407.1,
    highPrice: 12407.12,
    coinImage: Graph,
    price: 0.1198,
    oneh: 1.6,
    twentyfourh: -12.2,
    sevend: -25.5,
    "twentyfourh-volume": 329971578,
    "mkt-cap": 4635237291,
    "last-7-days": TableChartImage,
  },
];

export const EXCHANGE_TABLE_DATA = [
  {
    id: 1,
    name: "Binance",
    icon: BinanceLogo,
    score: 9.9,
    "twentyfourh-volume-24h": 666971578,
    change: 145.46,
    "avg-liquidity": 909,
    "weekly-visits": 13722005,
    markets: 2665,
    coins: 1631,
    "fiat-support": "KRW, EUR",
    "last-7-days": TableChartImage,
  },
  {
    id: 2,
    name: "Kucoin",
    icon: KucoinLogo,
    score: -3.9,
    "twentyfourh-volume-24h": 321971258,
    change: -146.12,
    "avg-liquidity": 309,
    "weekly-visits": 13.722005,
    markets: 1665,
    coins: 1632,
    "fiat-support": "KRW, EUR",
    "last-7-days": EthCart,
  },
  {
    id: 3,
    name: "Bitfinex",
    icon: BitfinexLogo,
    score: 1.9,
    "twentyfourh-volume-24h": 333971432,
    change: 995.32,
    "avg-liquidity": 902,
    "weekly-visits": 23722005,
    markets: 2465,
    coins: 1431,
    "fiat-support": "KRW, EUR",
    "last-7-days": TableChartImage,
  },
  {
    id: 4,
    name: "Bitget",
    icon: BitgetLogo,
    score: -8.9,
    "twentyfourh-volume-24h": 654971234,
    change: -513.54,
    "avg-liquidity": 409,
    "weekly-visits": 25722105,
    markets: 2165,
    coins: 1341,
    "fiat-support": "KRW, EUR",
    "last-7-days": TableChartImage,
  },
  {
    id: 5,
    name: "Huobi",
    icon: HuobiLogo,
    score: 2.2,
    "twentyfourh-volume-24h": 999345578,
    change: 213.98,
    "avg-liquidity": 201,
    "weekly-visits": 45342005,
    markets: 6165,
    coins: 1561,
    "fiat-support": "KRW, EUR",
    "last-7-days": TableChartImage,
  },
  {
    id: 6,
    name: "Gemini",
    icon: GeminiLogo,
    score: 5.3,
    "twentyfourh-volume-24h": 121954378,
    change: 456.23,
    "avg-liquidity": 647,
    "weekly-visits": 13754365,
    markets: 2445,
    coins: 1761,
    "fiat-support": "KRW, EUR",
    "last-7-days": TethChart,
  },
  {
    id: 7,
    name: "OKX",
    icon: OkxLogo,
    score: 5.9,
    "twentyfourh-volume-24h": 278971321,
    change: 987.51,
    "avg-liquidity": 709,
    "weekly-visits": 87212443,
    markets: 7165,
    coins: 8631,
    "fiat-support": "KRW, EUR",
    "last-7-days": TableChartImage,
  },
  {
    id: 8,
    name: "Bybit",
    icon: BybitLogo,
    score: 9.9,
    "twentyfourh-volume-24h": 558971841,
    change: 112.05,
    "avg-liquidity": 789,
    "weekly-visits": 43599305,
    markets: 4355,
    coins: 2390,
    "fiat-support": "KRW, EUR",
    "last-7-days": TableChartImage,
  },
  {
    id: 9,
    name: "Bitstamp",
    icon: BitstampLogo,
    score: 8.1,
    "twentyfourh-volume-24h": 450926783,
    change: 213.41,
    "avg-liquidity": 409,
    "weekly-visits": 21332015,
    markets: 1345,
    coins: 5631,
    "fiat-support": "KRW, EUR",
    "last-7-days": TableChartImage,
  },
];

export const WATCHLIST_TABLE_DATA = [
  // {
  //   coin: "Shiba Inu",
  //   coinCode: "SHB",
  //   coinImage: ShibaLogo,
  //   price: "$0.000003",
  //   oneh: "0.6",
  //   twentyfourh: "-2.2",
  //   sevend: "-15.5",
  //   "twentyfourh-volume": "$666,971,578",
  //   "mkt-cap": "$10,372,237,918",
  //   "last-7-days": TableChartImage,
  // },
  // {
  //   coin: "Shiba Inu",
  //   coinCode: "SHB",
  //   coinImage: ShibaLogo,
  //   price: "$0.000003",
  //   oneh: "0.6",
  //   twentyfourh: "-2.2",
  //   sevend: "-15.5",
  //   "twentyfourh-volume": "$666,971,578",
  //   "mkt-cap": "$10,372,237,918",
  //   "last-7-days": TableChartImage,
  // },
];
