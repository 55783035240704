import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// import Placeholder from "../../components/Placeholder";
import Footer from "../../layouts/Footer";
import Header from "../../layouts/Header";
import HeaderStats from "../../layouts/HeaderStats";
import Navbar from "../../layouts/Navbar";
import CoinDetail from "../../pages/CoinDetail";
import Exchanges from "../../pages/Exchanges";
import Home from "../../pages/Home";
// import Portfolio from "../../pages/Portfolio";
// import WatchList from "../../pages/WatchList";
import News from "../../pages/News";
import SingleNewsProduct from "../../pages/SingleNewsProduct";
import IcosDetail from "../../pages/IcosDetail";
import IcosSingleProduct from "../../pages/IcosSingleProduct";
import CurrencyConvertor from "../../pages/CurrencyConvertor";
import WatchListTable from "../../components/WatchListTable";
import PortfolioMain from "../../components/PortfolioMain";
import Compare from "../../pages/Compare";
import CurrencyConverterTool from "../../pages/CurrencyConverterTool";
import MarketCap from "../../pages/MarketCap";
import ExcludingCap from "../../pages/ExcludingCap";
import ExchangeDetail from "../../pages/ExchangeDetail";
import MiningEquipment from "../../pages/MiningEquipment";
import IcosProjectTable from "../../pages/IcosProjectTable";
import TransactionDetail from "../../pages/TransactionDetail";
import ProtectedRoute from "../../components/ProtectedRoute/ProtectedRoute";
import AccessDeniedPage from "../../pages/AccessDeniedPage";
import PageNotFound from "../../pages/PageNotFound";
// import PortfolioAccessed from "../../components/PortfolioAccessed/PortfolioAccessed";
// import { selectIsLoggedIn } from "../../store/slices/authSelectors";
// import { useSelector } from "react-redux";
import PorfolioAccessedPage from "../../pages/PorfolioAccessedPage";
import { useSelector } from "react-redux";
import WatchListAccessed from "../../components/WatchListAccessed/WatchListAccessed";
import WatchListAccessedPage from "../../pages/WatchListAccessedPage";
import FilterCurrencyTablePage from "../../pages/FilterCurrencyTablePage";
import TopGainersCurrencyTablePage from "../../pages/TopGainersCurrencyTablePage";
import { ToastContainer } from "react-toastify";
import AboutUsPage from "../../pages/AboutUsPage";
import PolicyPage from "../../pages/PolicyPage";
// import BecomeInvestorPage from "../../pages/BecomeInvestorPage";
// import CareerPage from "../../pages/CareerPage";
import GeneralQuestions from "../../components/GeneralQuestions/GeneralQuestions";
import UserProfilePage from "../../pages/UserProfilePage";

const MainRoutes = () => {
  const token = useSelector(state => state.auth.token);
  return (
    <>
      <Router>
        <ToastContainer />
        <Header />
        <HeaderStats />
        {/* <Placeholder /> */}
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="top-gainers" element={<TopGainersCurrencyTablePage />} />
          <Route path="top-losers" element={<FilterCurrencyTablePage />} />
          <Route path="/watchlist" element={<WatchListAccessedPage />} />
          {token && (
            <Route
              path="/watchlist-table"
              element={<ProtectedRoute element={<WatchListTable />} />}
            />
          )}

          {/* <Route path="/watchlist-table" element={<WatchListTable />} /> */}
          <Route path="/exchanges" element={<Exchanges />} />
          <Route path="/exchange-detail/:id" element={<ExchangeDetail />} />
          <Route path="/portfolio" element={<PorfolioAccessedPage />} />
          <Route
            path="/portfolio-main"
            element={<ProtectedRoute element={<PortfolioMain />} />}
          />
          {token && (
            <Route
              path="/transactions-detail/:coin"
              element={
                <ProtectedRoute isLogin={token}>
                  <TransactionDetail />
                </ProtectedRoute>
              }
            />
          )}
          {/* <Route path="/portfolio-main" element={<PortfolioMain />} /> */}
          {/* <Route path="/transactions-detail/:coin" element={<TransactionDetail />} /> */}
          <Route path="/coin-detail/:id" element={<CoinDetail />} />
          <Route path="/news" element={<News />} />
          <Route path="/singleNewsProduct/:page/:id" element={<SingleNewsProduct />} />
          <Route path="/ico's" element={<IcosDetail />} />
          <Route path="/icosSingleProduct/:id" element={<IcosSingleProduct />} />
          <Route path="/compare" element={<Compare />} />
          <Route path="/converter" element={<CurrencyConverterTool />} />
          <Route path="/currency-convertor" element={<CurrencyConvertor />} />
          <Route path="/mining-equipment" element={<MiningEquipment />} />
          <Route path="/market-cap" element={<MarketCap />} />
          <Route path="/excluding-cap" element={<ExcludingCap />} />
          <Route path="/about-us" element={<AboutUsPage />} />
          <Route path="/privacy-policy" element={<PolicyPage />} />
          <Route path="/profile" element={<UserProfilePage />} />
          {/* <Route path="/become-investor" element={<BecomeInvestorPage />} /> */}
          {/* <Route path="/career" element={<CareerPage />} /> */}
          <Route path="/faq" element={<GeneralQuestions />} />
          <Route path="/ico's/icos-project-table/:name" element={<IcosProjectTable />} />
          <Route path="/access-denied" element={<AccessDeniedPage />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
        <Footer />
      </Router>
    </>
  );
};

export default MainRoutes;
