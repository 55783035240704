import React, { useState, useEffect } from "react";
import TableFilters from "../TableFilters";
import CurrencyTable from "../CurrencyTable";
import AddCoinPopup from "../PortfolioAddCoinPopup";
import { openAddCoin, openRemoveWatchList } from "../../store/slices/popupSlice";
import {
  useGetCoinMarketQuery,
  useGetWatchlistQuery,
  useRemoveAllWatchlistMutation
} from "../../store/services/services";
import { useDispatch, useSelector } from "react-redux";
import { Spin } from "antd";
import { selectCurrentTheme } from "../../store/slices/themeSlice";
import { resetWatchlist } from "../../store/slices/watchlistSlice";
import WatchListClearPopup from "../WatchListClearPopup/WatchListClearPopup";


const WatchListTable = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [filterTableData, setFilterTableData] = useState([]);


  const { data: coins, isError, error, isLoading } = useGetCoinMarketQuery(currentPage);
    const userId = useSelector(state => state.auth.userId);
    const watchListPopup = useSelector(state => state.popup.removeAllWatchlist);
  const {
    removeAllWatchlist,
    isError: watchListIsError,
    isLoading: watchListIsLoading,
    isSuccess: watchlistIsSuccess
  } = useRemoveAllWatchlistMutation(userId);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const user = useSelector(state => state.auth);
  const dispatch = useDispatch();
   const currentTheme = useSelector(selectCurrentTheme);
  // get coin
  const watchlistCoins = useSelector(state => state.watchlistCoins.watchlistCoins);

  // getWatchlist api
  const {
    data: getWatchlist,
    isLoading: getWatchlistIsLoading,
    isError: getWatchlistIsError,
    refetch: refetchWatchlist
  } = useGetWatchlistQuery(user.userId);
  const {
    data: getCoins,
    isLoading: getCoinsIsLoading,
    isFetching
  } = useGetCoinMarketQuery();
  useEffect(() => {
    refetchWatchlist();
  }, []);
  if (getWatchlistIsLoading) {
    return (
      <div className="flex justify-center items-center h-[500px]">
        <Spin size="large" />
      </div>
    );
  }

  if (getWatchlistIsError) {
    return;
  }
  if (!getWatchlistIsLoading && !getCoinsIsLoading && !isFetching) {
  }

  let filteredCoins;

  if (!getCoinsIsLoading && !getCoinsIsLoading) {
    filteredCoins = getCoins?.data.filter(item =>
      watchlistCoins?.some(filterItem => filterItem === item.logo)
    );
  }
  // // clear all coins
  const handleAllFiltersSelected = () => {
  //   // Clear the watchlistCoins array by dispatching the resetWatchlist action
  dispatch(resetWatchlist());
   };
  return (
    <div className="px-24 md:px-8 xs:px-4 mt-12">
      <div className="flex justify-end pb-5">
        <div
          className={`rounded-[14px] px-3 py-2 cursor-pointer ${
            currentTheme === "dark"
              ? "bg-cardsColor text-mainBg"
              : "bg-coinTagsBg text-filter"
          }`}
          onClick={() => dispatch(openRemoveWatchList())}
        >
          Clear Watchlist
        </div>
      </div>
      <div className="shadow-header-shadow pt-4 rounded-[20px]">
        <CurrencyTable
          data={getWatchlist?.data}
          isLoading={getWatchlistIsLoading && getCoinsIsLoading}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />

        {!getWatchlistIsLoading && !getCoinsIsLoading && getWatchlist?.data === 0 && (
          <div className="text-center h-80 xs:h-72 flex flex-col justify-center">
            <p className="font-bold text-[30px] mb-1 sm:text-[25px] xs:text-xl">
              Your watchlist is empty
            </p>
            <p className="font-medium text-xl sm:text-[17px] xs:text-base mb-4">
              Start building your watchlist by clicking button below
            </p>
            <div>
              <button
                className="text-base xs:text-sm text-primaryText bg-primary px-7 py-2 rounded-full"
                onClick={() => dispatch(openAddCoin())}
              >
                Add Coin
              </button>
            </div>
          </div>
        )}
      </div>
      {/* {AddCoinPopup && <AddCoinPopup />} */}
      {watchListPopup && (
        <WatchListClearPopup
          handleAllFiltersSelected={handleAllFiltersSelected}
          watchListIsLoading={watchListIsLoading}
          watchlistIsSuccess={watchlistIsSuccess}
          removeAllWatchlist={removeAllWatchlist}
        />
      )}
    </div>
  );
};

export default WatchListTable;
