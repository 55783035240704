import React, { useState } from "react";
import { selectCurrentTheme } from "../../store/slices/themeSlice";
import { useSelector } from "react-redux";
const FilterRangeInputs = ({
  minNumValue,
  maxNumValue,
  minPlaceHolder,
  maxPlaceHolder,
  ranges,
  unit
}) => {
  const [minValue, setMinValue] = useState("");
  const [maxValue, setMaxValue] = useState("");
  const [isNegative, setIsNegative] = useState(false);
  const currentTheme = useSelector(selectCurrentTheme);
  const numberInCommas = val => {
    return val?.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const negativeHanlder = e => {
    e.keyCode === 189 && setIsNegative(!isNegative);
  };

  const valToNum = val => {
    if (isNegative) {
      return val?.replace(/[^\d.-]/g, "");
    } else {
      return val?.replace(/\D/g, "");
    }
  };

  maxNumValue(valToNum(maxValue));
  minNumValue(valToNum(minValue));

  return (
    <div>
      <div className="pb-6">
        <div className="mb-5">
          <div>
            <div className="flex items-center gap-3">
              <div
                className={`flex items-center border focus-within:border-primary rounded-[7px] overflow-hidden ${
                  currentTheme === "dark"
                    ? "bg-inputColor text-mainBg"
                    : "bg-mainBg text-filter"
                }`}
              >
                {unit && <div className="pl-2 font-medium">{unit}</div>}
                <input
                  type="text"
                  className={`outline-none pr-2 ${
                    unit ? "px-0" : "px-2"
                  }  py-2 w-40 font-medium ${
                    currentTheme === "dark"
                      ? "bg-inputColor text-mainBg"
                      : "bg-mainBg text-filter"
                  }`}
                  placeholder={minPlaceHolder}
                  inputMode="numeric"
                  onChange={e => {
                    setMinValue(e.target.value);
                  }}
                  onKeyDown={negativeHanlder}
                  value={(isNegative ? "-" : "") + numberInCommas(minValue)}
                />
              </div>
              <p
                className={`font-semibold ${
                  currentTheme === "dark" ? " text-mainBg" : "text-filter"
                }`}
              >
                to
              </p>
              <div
                className={`flex items-center border ${
                  2 !== 1 + 1 ? "border-decrease" : "focus-within:border-primary"
                }  rounded-[7px] overflow-hidden ${
                  currentTheme === "dark"
                    ? "bg-inputColor text-mainBg"
                    : "bg-mainBg text-filter"
                }`}
              >
                {unit && <div className="pl-2 font-medium">{unit}</div>}
                <input
                  type="text"
                  className={`outline-none pr-2 ${
                    unit ? "px-0" : "px-2"
                  } py-2 w-40 font-medium ${
                    currentTheme === "dark"
                      ? "bg-inputColor text-mainBg"
                      : "bg-mainBg text-filter"
                  }`}
                  placeholder={maxPlaceHolder}
                  inputMode="numeric"
                  onChange={e => {
                    setMaxValue(e.target.value);
                  }}
                  value={numberInCommas(maxValue + "%")}
                />
              </div>
            </div>
            {2 !== 1 + 1 && (
              <p className="text-decrease text-sm mt-1">Please enter valid inputs</p>
            )}
          </div>
        </div>
        <div>
          <div
            className={`mb-3 font-medium ${
              currentTheme === "dark" ? " text-mainBg" : "text-opacityColor"
            }`}
          >
            <p>Most searched ranges</p>
          </div>
          <div className="flex gap-x-4 gap-y-3 flex-wrap">
            {ranges.map(item => (
              <div
                className={`px-3 py-1 text-sm rounded-[5px] font-medium cursor-pointer ${
                  currentTheme === "dark"
                    ? "bg-filter text-opacityColor"
                    : "bg-filterBg text-filter"
                }`}
                onClick={() => {
                  setMinValue(item.minAmount);
                  setMaxValue(item.maxAmount);
                }}
                key={item.id}
              >
                <p>{item.name}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilterRangeInputs;
