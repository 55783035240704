import React, { useEffect, useRef, useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { selectCurrentTheme } from "../../store/slices/themeSlice";
import { useSelector } from "react-redux";
import CoinsIcon from "../../assets/icons/coins.svg";
import CoinsIconActive from "../../assets/icons/coins-active.svg";
import WatchlistIcon from "../../assets/icons/watchlist.svg";
import WatchlistIconActive from "../../assets/icons/watchlist-active.svg";
import ExchangesIcon from "../../assets/icons/exchanges.svg";
import ExchangesIconActive from "../../assets/icons/exchanges-active.svg";
import PortfolioIcon from "../../assets/icons/portfolio.svg";
import PortfolioIconActive from "../../assets/icons/portfolio-active.svg";
import NewsIcon from "../../assets/icons/news.svg";
import NewsIconActive from "../../assets/icons/news-active.svg";
import IcosIcon from "../../assets/icons/icos.svg";
import IcosIconActive from "../../assets/icons/icos-active.svg";
import ToolsIcon from "../../assets/icons/tools.svg";
import ToolsIconActive from "../../assets/icons/tools-active.svg";
import CompareIcon from "../../assets/icons/compare.svg";
import CompareIconActive from "../../assets/icons/compare-active.svg";
import LightConverter from "../../assets/images/converter-light.png";
import DarkConverter from "../../assets/images/converter-dark.png"; 
// import DropdownMenu from "../../utils/DropdownMenu/DropdownMenu";

const NAVBAR_DATA = [
  {
    id: 1,
    name: "Coins",
    page: "/",
    icon: CoinsIcon,
    iconAcive: CoinsIconActive,
    dropDown: false
  },
  {
    id: 2,
    name: "Watch List",
    page: "/watchlist",
    icon: WatchlistIcon,
    iconAcive: WatchlistIconActive,
    dropDown: false
  },
  {
    id: 3,
    name: "Exchanges",
    page: "/exchanges",
    icon: ExchangesIcon,
    iconAcive: ExchangesIconActive,
    dropDown: false
  },
  {
    id: 4,
    name: "Portfolio",
    page: "/portfolio",
    icon: PortfolioIcon,
    iconAcive: PortfolioIconActive,
    dropDown: false
  },
  {
    id: 5,
    name: "News",
    page: "/news",
    icon: NewsIcon,
    iconAcive: NewsIconActive,
    dropDown: false
  },
  // {
  //   id: 6,
  //   name: "ICO'S",
  //   page: "/ico's",
  //   icon: IcosIcon,
  //   iconAcive: IcosIconActive,
  //   dropDown: false
  // },
  {
    id: 7,
    name: "Compare",
    page: "/compare",
    icon: CompareIcon,
    iconAcive: CompareIconActive,
    dropDown: false
  },
  {
    id: 8,
    name: "Converter",
    page: "/converter",
    icon: DarkConverter,
    iconAcive: LightConverter,
    dropDown: false
  }
  // {
  //   id: 8,
  //   name: "Tools",
  //   icon: ToolsIcon,
  //   iconAcive: ToolsIconActive,
  //   dropDown: true,
  //   dropDownLinks: [
  //     // { id: 9, linkName: "Currency Converter", page: "/currency-convertor" },
  //     { id: 10, linkName: "Mining Equipment", page: "/mining-equipment" },
  //   ],
  // },
];

const Navbar = () => {
  const location = useLocation();

  const [clickedId, setClickedId] = useState();
  const [active, setActive] = useState();
  const currentTheme = useSelector(selectCurrentTheme);
  const dropdownHandler = item => {
    if (item.dropDown) {
      setClickedId(item.id);
      setActive(!active);
    }
  };

  const dropdownRef = useRef();
  const dropLinkRef = useRef();

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleClickOutside = event => {
    // if (
    //   languageDropdownRef.current &&
    //   !languageDropdownRef.current.contains(event.target)
    // ) {
    //   setLanguageDropdown(false);
    // }
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target) &&
      dropLinkRef.current &&
      !dropLinkRef.current.contains(event.target)
    ) {
      setActive(false);
    }
  };
  // useEffect(() => {
  //   document.addEventListener("click", handleClickOutside);

  //   return () => {
  //     document.removeEventListener("click", handleClickOutside);
  //   };
  // }, []);

  // const handleClickOutside = (event) => {
  //   // if (
  //   //   languageDropdownRef.current &&
  //   //   !languageDropdownRef.current.contains(event.target)
  //   // ) {
  //   //   setLanguageDropdown(false);
  //   // }
  //   if (
  //     dropLinkRef.current &&
  //     !dropLinkRef.current.contains(event.target) &&
  //     dropdownRef.current &&
  //     !dropdownRef.current.contains(event.target)
  //   ) {
  //     setActive(false);
  //   }
  //   // if () {
  //   //   setActive(false);
  //   // }
  // };

  return (
    <div>
      <div className="px-12 md:px-8 xs:px-4 mt-5 lg:hidden">
        <div className=" z-10 overflow-y-visible">
          <div className="flex gap-y-5 flex-nowrap gap-3 justify-center mb-2">
            {NAVBAR_DATA.map((item, i) => (
              <div className="relative" key={item.id} ref={dropLinkRef}>
                <NavLink
                  to={item.page}
                  className={({ isActive }) =>
                    `flex items-center justify-center gap-1 h-10 xs:h-10 shadow-navbar-button whitespace-nowrap rounded-full px-5  ${
                      location.pathname === item.page
                        ? "bg-primary"
                        : currentTheme === "dark"
                        ? "bg-cardsColor"
                        : "bg-mainBg"
                    }`
                  }
                  onClick={() => dropdownHandler(item)}
                >
                  <img
                    className="w-[20px] sm:w-[18px]"
                    src={location.pathname === item.page ? item.iconAcive : item.icon}
                    alt={item.name}
                  />
                  <span
                    className={`text-sm sm:text-sm ${
                      location.pathname === item.page
                        ? "text-primaryText"
                        : currentTheme === "dark"
                        ? "text-mainBg"
                        : "text-primaryText"
                    }
                    `}
                  >
                    {item.name}
                  </span>
                </NavLink>
                {""}
                {clickedId === item.id && active && (
                  <div
                    className="absolute top-11 max-w-96 w-48 z-50 text-sm bg-mainBg shadow-header-shadow px-5 py-4 text-primaryText rounded-[6px] right-0"
                    ref={dropdownRef}
                  >
                    <div>
                      {item.dropDownLinks.map(items => (
                        <Link
                          to={items.page}
                          key={items.id}
                          onClick={() => setActive(false)}
                        >
                          <div key={items.id} className="py-2 cursor-pointer">
                            <p
                              className={({ isActive }) =>
                                ` rounded-full px-4 py-2 shadow-navbar-button ${
                                  isActive ? "bg-primary" : "bg-mainBg"
                                }`
                              }
                            >
                              {items.linkName}
                            </p>
                          </div>
                        </Link>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
