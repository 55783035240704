import React from 'react'
import TransactionsDetail from '../components/TransactionsDetail/TransactionsDetail'

const TransactionDetail = () => {
  return (
    <div>
    <TransactionsDetail />
    </div>
  )
}

export default TransactionDetail