import React, { useState, useRef, useEffect } from "react";
import Modal from "../../utils/Modal";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentTheme } from "../../store/slices/themeSlice";
import { useVerifyOTPMutation } from "../../store/services/services";
import { closeEmailVerification, openLogin, openResetPassword } from "../../store/slices/popupSlice";
import { toast } from "react-toastify";

const VerifyEmailPopup = ({ userEmail }) => {
  const dispatch = useDispatch();
  const { isResetPasswordOpen  } = useSelector(state => state.authPopup);
  console.log(isResetPasswordOpen , 'this is useSelector');
  const user = useSelector(state => state.auth);
  const currentTheme = useSelector(selectCurrentTheme);
  const [verificationCode, setVerificationCode] = useState(["", "", "", "", "", ""]);

  const [triggerVerifyOtp, { data, isError, isFetching, error, isLoading, isSuccess }] =
    useVerifyOTPMutation();
  const inputRefs = useRef([]);

// handling handle change
  const handleChange = (index, value) => {
    if (/^\d$/.test(value) || value === "") {
      setVerificationCode(prevCodes => {
        const newCodes = [...prevCodes];
        newCodes[index] = value;
        return newCodes;
      });
      if (index < verificationCode.length - 1) {
        inputRefs.current[index + 1].focus();
      }
    }
  };
console.log(verificationCode.join(""), "this is verificationCode");
const handleVerification = async () => {
  const otp = verificationCode.join("");
  console.log("Request Payload:", { email: userEmail, code: otp });

  try {
    const response = await triggerVerifyOtp({ email: userEmail, code: otp 
    });
    console.log("Verification successful", response.data);
  } catch (error) {
    console.error("Verification failed", error);
  }
};
console.log(user.email, 'this is user email');
// console.log(userEmail, "this is userEmail");
  useEffect(() => {
    if (isSuccess) {
      dispatch(closeEmailVerification());
      if (isResetPasswordOpen) {
        console.log("Dispatching openResetPassword");
        dispatch(openResetPassword());
      } else {
         console.log("Dispatching openLogin");
        dispatch(openLogin());
      }

      toast.success("OTP Added Successfully");
    } else if (isError) {
      console.log(error);
      toast.error(error.data.message);
    }
  }, [isSuccess, isError, isResetPasswordOpen, dispatch]);
  return (
    <Modal maxWidth="750">
      <div className="px-8 py-6 relative">
        <h2
          className={`text-center text-2xl font-semibold mb-4 ${
            currentTheme === "dark" ? "text-mainBg" : "text-filter"
          }`}
        >
          Verify Email
        </h2>
        <p
          className={`text-gray-600 text-center mb-4 ${
            currentTheme === "dark" ? "text-mainBg" : "text-filter"
          }`}
        >
          Enter the verification code sent to your email.
        </p>

        <div className="flex space-x-2 justify-center">
          {verificationCode.map((code, index) => (
            <input
              key={index}
              ref={el => (inputRefs.current[index] = el)}
              type="text"
              maxLength="1"
              value={code}
              onChange={e => handleChange(index, e.target.value)}
              className={`w-10 h-10 border border-inputBorder rounded text-center focus:outline-none focus:border-primary ${
                currentTheme === "dark"
                  ? "bg-inputColor text-mainBg"
                  : "bg-mainBg text-filter"
              }`}
            />
          ))}
        </div>

        <div className="flex justify-end mt-4">
          <button
            className={`bg-primary text-mainBg px-4 py-2 rounded ${
              isLoading ? "opacity-50 cursor-not-allowed" : ""
            }`}
            onClick={handleVerification}
            disabled={isLoading}
          >
            {isLoading ? "Verifying..." : "Verify"}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default VerifyEmailPopup;
