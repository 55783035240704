import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { useGetPortfolioByUserQuery } from "../../store/services/services";
import { useSelector } from "react-redux";
import { commafy } from "../../utils/commafy";
import { toDecimals } from "../../utils/toDecimals";
import { selectCurrentTheme } from "../../store/slices/themeSlice";
import CryptoIcon from "../../assets/icons/crypto-icon.svg";

const PortfolioStatistics = () => {
  const userId = useSelector(state => state.auth.userId);
  const comparePortfolio = useSelector(state => state.portfolioChange.portfolioId);
  const { data, isLoading, isError, error } = useGetPortfolioByUserQuery(userId);
  let filteredTransactions;
  if (!isLoading && !isError) {
    filteredTransactions = data?.portfolioData?.find(
      item => item.id === comparePortfolio
    );
  }
  const currentTheme = useSelector(selectCurrentTheme);
  return (
    <div className="px-24 sm:px-10 xs:px-4 mt-12">
      {/* // without id */}
      {comparePortfolio === null ? (
        <div className="flex flex-wrap items-center gap-10 text-lg xs:text-base">
          {isLoading && (
            <div className="w-full">
              <SkeletonTheme
                baseColor={`${currentTheme === "dark" ? "#323546" : "#d8d8d8"}`}
                highlightColor={`${currentTheme === "dark" ? "#444" : "#e0e0e0"}`}
              >
                <Skeleton width="50%" height={50} count={1} />
              </SkeletonTheme>
            </div>
          )}
          {isError && <p>{error.message}</p>}
          {!isLoading && !isError && (
            <>
              <div>
                <div>
                  {!data?.overallData?.overallAllTimeProfitLoss ? (
                    <p
                      className={`text-lg ${
                        currentTheme === "dark"
                          ? " text-portfolioStatisticsColor"
                          : "text-lightGray"
                      }`}
                    >
                      There is no profit loss
                    </p>
                  ) : (
                    <div>
                      {" "}
                      <p
                        className={` ${
                          currentTheme === "dark"
                            ? " text-portfolioStatisticsColor"
                            : "text-lightGray"
                        }`}
                      >
                        All Time Profit
                      </p>
                      <div
                        className={`flex items-center gap-2 font-semibold ${
                          data?.overallData?.overallAllTimeProfitLoss > 0
                            ? "text-increase"
                            : "text-decrease"
                        }`}
                      >
                        {data?.overallData?.overallAllTimeProfitLoss > 0 ? (
                          <FontAwesomeIcon icon={faCaretUp} />
                        ) : (
                          <FontAwesomeIcon icon={faCaretDown} />
                        )}

                        <p>
                          {commafy(
                            toDecimals(data?.overallData?.overallAllTimeProfitLoss)
                          )}
                          %
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="flex items-center gap-3">
                {!data?.overallData.overallBestPerformer ? (
                  <p
                    className={`text-lg ${
                      currentTheme === "dark"
                        ? " text-portfolioStatisticsColor"
                        : "text-lightGray"
                    }`}
                  >
                    There is no best performer
                  </p>
                ) : (
                  <>
                    <div>
                      <img
                        className="w-8"
                        src={data?.overallData?.overallBestPerformerImg}
                        alt="bitcoin"
                      />
                    </div>
                    <div>
                      <p
                        className={`text-lightGray ${
                          currentTheme === "dark" ? " text-mainBg" : "text-lightGray"
                        }`}
                      >
                        Best Performer
                      </p>
                      <div className="flex items-center gap-2 text-increase font-semibold">
                        <FontAwesomeIcon icon={faCaretUp} />
                        <p>{data?.overallData?.overallBestPerformer}</p>
                      </div>
                    </div>
                  </>
                )}
              </div>
              <div className="flex items-center gap-3">
                {!data?.overallData.overallWorstPerformer ? (
                  <p
                    className={`${
                      currentTheme === "dark"
                        ? " text-portfolioStatisticsColor"
                        : "text-lightGray"
                    }`}
                  >
                    There is no wrost performer
                  </p>
                ) : (
                  <>
                    <div>
                      <img
                        className="w-8"
                        src={data?.overallData?.overallWorstPerformerImg}
                        alt="ethereum"
                      />
                    </div>
                    <div>
                      <p
                        className={`${
                          currentTheme === "dark" ? " text-mainBg" : "text-lightGray"
                        }`}
                      >
                        Worst Performer
                      </p>
                      <div className="flex items-center gap-2 text-decrease font-semibold">
                        <FontAwesomeIcon icon={faCaretDown} />
                        <p>{data?.overallData?.overallWorstPerformer}</p>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </>
          )}
        </div>
      ) : (
        <div className="flex flex-wrap items-center gap-10 text-lg xs:text-base">
          {isLoading && (
            <div className="w-full">
              <SkeletonTheme
                baseColor={`${currentTheme === "dark" ? "#323546" : "#d8d8d8"}`}
                highlightColor={`${currentTheme === "dark" ? "#444" : "#e0e0e0"}`}
              >
                <Skeleton width="50%" height={50} count={1} />
              </SkeletonTheme>
            </div>
          )}
          {isError && <p>{error.message}</p>}
          {!isLoading && !isError && (
            <>
              {filteredTransactions?.allTimeProfitLoss ? (
                <div>
                  <p
                    className={` ${
                      currentTheme === "dark" ? " text-mainBg" : "text-lightGray"
                    }`}
                  >
                    All Time Profit
                  </p>
                  <div
                    className={`flex items-center gap-2 font-semibold ${
                      filteredTransactions?.allTimeProfitLoss > 0
                        ? "text-increase "
                        : "text-decrease "
                    }`}
                  >
                    {filteredTransactions?.allTimeProfitLoss > 0 ? (
                      <FontAwesomeIcon icon={faCaretUp} />
                    ) : (
                      <FontAwesomeIcon icon={faCaretDown} />
                    )}

                    <p>{commafy(toDecimals(filteredTransactions?.allTimeProfitLoss))}$</p>
                  </div>
                </div>
              ) : (
                <p
                  className={`${
                    currentTheme === "dark"
                      ? " text-portfolioStatisticsColor"
                      : "text-lightGray"
                  }`}
                >
                  {" "}
                  There is no profit loss
                </p>
              )}

              <div className="flex items-center gap-3">
                {!data?.overallData.overallBestPerformer ? (
                  <p
                    className={`text-lg text-lightGray ${
                      currentTheme === "dark"
                        ? " text-portfolioStatisticsColor"
                        : "text-lightGray"
                    }`}
                  >
                    There is no best performer
                  </p>
                ) : (
                  <>
                    <div>
                      <img
                        className="w-8"
                        src={filteredTransactions?.bestPerformerImg}
                        alt="bitcoin"
                      />
                    </div>
                    <div>
                      <p
                        className={`text-lightGray ${
                          currentTheme === "dark" ? " text-mainBg" : "text-lightGray"
                        }`}
                      >
                        Best Performer
                      </p>
                      <div className="flex items-center gap-2 text-increase font-semibold">
                        <FontAwesomeIcon icon={faCaretUp} />
                        <p>{filteredTransactions?.bestPerformer}</p>
                      </div>
                    </div>
                  </>
                )}
              </div>
              <div className="flex items-center gap-3">
                {!data?.overallData.overallWorstPerformer ? (
                  <p
                    className={`text-lg text-lightGray ${
                      currentTheme === "dark"
                        ? " text-portfolioStatisticsColor"
                        : "text-lightGray"
                    }`}
                  >
                    There is no wrost performer
                  </p>
                ) : (
                  <>
                    {filteredTransactions?.worstPerformer ? (
                      <>
                        <div>
                          <img
                            className="w-8"
                            src={
                              filteredTransactions?.worstPerformerImg
                                ? filteredTransactions?.worstPerformerImg
                                : CryptoIcon
                            }
                            alt="pic"
                          />
                        </div>
                        <div>
                          <p
                            className={`${
                              currentTheme === "dark" ? " text-mainBg" : "text-lightGray"
                            }`}
                          >
                            Worst Performer
                          </p>
                          <div className="flex items-center gap-2 text-decrease font-semibold">
                            <>
                              <FontAwesomeIcon icon={faCaretDown} />
                              <p>{filteredTransactions?.worstPerformer}</p>
                            </>
                          </div>
                        </div>
                      </>
                    ) : (
                      <p
                        className={`${
                          currentTheme === "dark" ? " text-mainBg" : "text-lightGray"
                        }`}
                      >
                        There is no Wrost Performer
                      </p>
                    )}
                  </>
                )}
              </div>
            </>
          )}
        </div>
      )}

      {/* with id */}
    </div>
  );
};

export default PortfolioStatistics;
