import React from "react";
import { useSelector } from "react-redux";
import PortfolioAccessed from "../components/PortfolioAccessed/PortfolioAccessed";
import Portfolio from "./Portfolio";

const PorfolioAccessedPage = () => {
  const token = useSelector(state => state.auth.token);
  return <div>{token ? <PortfolioAccessed /> : <Portfolio />}</div>;
};

export default PorfolioAccessedPage;
