import React from "react";
import DecreaseIcon from "../../assets/icons/decrease.svg";
import GasIcon from "../../assets/icons/gas-icon.svg";
import { Link } from "react-router-dom";
import { useGetCoinGlobalQuery } from "../../store/services/services";
import { commafy } from "../../utils/commafy";
import { toDecimals } from "../../utils/toDecimals";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { selectCurrentTheme } from "../../store/slices/themeSlice";
import { useSelector } from "react-redux";
const HeaderStats = () => {
  const { data, isLoading, isError, error } = useGetCoinGlobalQuery();
  const currentTheme = useSelector(selectCurrentTheme);

  return (
    <div className="px-12 md:px-8 xs:px-4">
      {isLoading && (
        <div className="w-full mt-3">
          <SkeletonTheme
            baseColor={`${currentTheme === "dark" ? "#323546" : "#d8d8d8"}`}
            highlightColor={`${currentTheme === "dark" ? "#444" : "#e0e0e0"}`}
          >
            <Skeleton width="100%" height={25} count={1} />
          </SkeletonTheme>
        </div>
      )}
      {isError && <p>{error.message}</p>}
      {!isLoading && !isError && (
        <div>
          {data?.data?.map(item => {
            return (
              <div
                className={`flex items-center py-5 justify-center flex-nowrap gap-x-7 gap-y-4 text-[13px] sm:text-xs md:justify-start overflow-x-auto scrollbar whitespace-nowrap`}
                key={item.id}
              >
                <div className={`flex items-center gap-2`}>
                  <span
                    className={`text-${currentTheme === "light" ? "filter" : "mainBg"}`}
                  >
                    Coins:
                  </span>
                  <span className="text-blueColor">
                    {commafy(toDecimals(item?.status_currencies))}
                  </span>
                </div>
                <div className={`flex items-center gap-2`}>
                  <span
                    className={`text-${currentTheme === "light" ? "filter" : "mainBg"}`}
                  >
                    Exchanges:
                  </span>
                  <span className="text-blueColor">
                    {commafy(toDecimals(item?.status_exchanges))}
                  </span>
                </div>
                {/* <Link to="./market-cap"> */}
                <div className={`flex items-center gap-2`}>
                  <span
                    className={`text-${currentTheme === "light" ? "filter" : "mainBg"}`}
                  >
                    Market Cap
                  </span>
                  <span className="text-blueColor">
                    ${commafy(toDecimals(item?.total_market_cap_usd))}
                  </span>
                  {/* <div className="flex items-center text-decrease">
                    <span>-1.0%</span>
                    <span>
                      <img src={DecreaseIcon} alt="decrease" />
                    </span>
                  </div> */}
                </div>
                {/* </Link> */}
                {/* <Link to="./excluding-cap"> */}
                <div className={`flex items-center gap-2`}>
                  <span
                    className={`text-${currentTheme === "light" ? "filter" : "mainBg"}`}
                  >
                    24h Vol:
                  </span>
                  <span className="text-blueColor">
                    ${commafy(toDecimals(item?.total_volume_usd_24h))}
                  </span>
                </div>
                <div className={`flex items-center gap-2`}>
                  <span
                    className={`text-${currentTheme === "light" ? "filter" : "mainBg"}`}
                  >
                    Dominance:
                  </span>
                  <span className="text-blueColor">
                    BTC&nbsp;
                    {commafy(toDecimals(item?.btc_percentage_of_market_cap))}%
                  </span>
                  <span className="text-blueColor">
                    ETH&nbsp;
                    {commafy(toDecimals(item?.eth_percentage_of_market_cap))}%
                  </span>
                </div>
                {/* </Link> */}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default HeaderStats;
