import React from 'react'
import Modal from "../../utils/Modal";
import { selectCurrentTheme } from "../../store/slices/themeSlice";
import { useSelector } from "react-redux";

const TransactionNotePopup = ({ note }) => {
  const currentTheme = useSelector(selectCurrentTheme);
  return (
    <Modal maxWidth={600}>
      <div className="p-7 xs:px-4">
        <div className="mb-6">
          <p
            className={`text-[22px] xs:text-xl font-semibold mb-2 ${
              currentTheme === "dark" ? "text-mainBg " : "text-secondary"
            }`}
          >
            User's Note
          </p>
          <div className="px-2 py-2 border">
            <p className={`${currentTheme === "dark" ? "text-mainBg " : "text-filter"}`}>
              {note ? note : "no note from user side"}
            </p>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default TransactionNotePopup