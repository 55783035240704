import React from "react";
import { selectCurrentTheme } from "../../store/slices/themeSlice";
import { useSelector } from "react-redux";

const GeneralQuestions = () => {
  const currentTheme = useSelector(selectCurrentTheme);
  return (
    <div className="px-24 md:px-8 xs:px-4 py-7 mt-12 md:mt-10">
      <div
        className={`rounded-[28px] shadow-navbar-button py-8 px-12 md:px-8 xs:px-4 ${
          currentTheme === "dark" ? "bg-cardsColor" : "bg-mainBg"
        }`}
      >
        <h2
          className={`text-3xl sm:text-xl font-bold mb-6 sm:mb-3 ${
            currentTheme === "dark" ? " text-mainBg" : "text-filter"
          }`}
        >
          Frequently Asked Questions (FAQ)
        </h2>

        {/* Question 1 */}
        <div className="mb-6 sm:mb-2">
          <h2
            className={`text-lg font-bold mb-2 ${
              currentTheme === "dark" ? " text-mainBg" : "text-filter"
            }`}
          >
            1. What is Coin Tracking Pro?
          </h2>
          <p className={`${currentTheme === "dark" ? " text-mainBg" : "text-gray-700"}`}>
            Coin Tracking Pro is a comprehensive platform designed for cryptocurrency
            enthusiasts and investors. It offers advanced tracking tools, insightful
            market analysis, and a user-friendly interface to help you manage and optimize
            your crypto portfolio.
          </p>
        </div>

        {/* Question 2 */}
        <div className="mb-6 sm:mb-2">
          <h2
            className={`text-lg font-bold mb-2 ${
              currentTheme === "dark" ? " text-mainBg" : "text-filter"
            }`}
          >
            2. Is Coin Tracking Pro free to use?
          </h2>
          <p className={`${currentTheme === "dark" ? " text-mainBg" : "text-gray-700"}`}>
            We offer a free basic version of Coin Tracking Pro with limited features. To
            unlock the full potential of our advanced tracking tools and premium features,
            we offer subscription plans. Check out our pricing page for more details.
          </p>
        </div>

        {/* Account and Security */}
        <h2
          className={`text-lg font-bold mb-4 ${
            currentTheme === "dark" ? " text-mainBg" : "text-filter"
          }`}
        >
          Account and Security
        </h2>

        {/* Question 3 */}
        <div className="mb-6 sm:mb-2">
          <h2
            className={`text-lg font-bold mb-2 ${
              currentTheme === "dark" ? " text-mainBg" : "text-filter"
            }`}
          >
            3. How do I sign up for an account?
          </h2>
          <p className={`${currentTheme === "dark" ? " text-mainBg" : "text-gray-700"}`}>
            Signing up for a Coin Tracking Pro account is easy. Click on the "Sign Up"
            button on our homepage, fill in the required information, and you're ready to
            get started.
          </p>
        </div>

        {/* Question 4 */}
        <div className="mb-6 sm:mb-2">
          <h2
            className={`text-lg font-bold mb-2 ${
              currentTheme === "dark" ? " text-mainBg" : "text-filter"
            }`}
          >
            4. Is my personal information secure?
          </h2>
          <p className={`${currentTheme === "dark" ? " text-mainBg" : "text-gray-700"}`}>
            Yes, we take the security of your personal information seriously. Coin
            Tracking Pro employs industry-standard security measures to protect your data.
            Refer to our Privacy Policy for more details.
          </p>
        </div>

        {/* Tracking and Analysis */}
        <h2
          className={`text-lg font-bold mb-4 ${
            currentTheme === "dark" ? " text-mainBg" : "text-filter"
          }`}
        >
          Tracking and Analysis
        </h2>

        {/* Question 5 */}
        <div className="mb-6 sm:mb-2">
          <h2
            className={`text-lg font-bold mb-2 ${
              currentTheme === "dark" ? " text-mainBg" : "text-filter"
            }`}
          >
            5. What tracking tools does Coin Tracking Pro offer?
          </h2>
          <p className={`${currentTheme === "dark" ? " text-mainBg" : "text-gray-700"}`}>
            Coin Tracking Pro provides real-time portfolio monitoring, transaction
            tracking, and performance analysis. You can also customize and categorize your
            transactions for a detailed overview.
          </p>
        </div>

        {/* Question 6 */}
        <div className="mb-6 sm:mb-2">
          <h2
            className={`text-lg font-bold mb-2 ${
              currentTheme === "dark" ? " text-mainBg" : "text-filter"
            }`}
          >
            6. How can I stay updated on market trends?
          </h2>
          <p className={`${currentTheme === "dark" ? " text-mainBg" : "text-gray-700"}`}>
            Our platform offers curated market analysis and insights to keep you informed
            about the latest trends and news in the cryptocurrency space. Explore our
            dashboard regularly for the most up-to-date information.
          </p>
        </div>

        {/* Technical Support */}
        <h2
          className={`text-lg font-bold mb-4 ${
            currentTheme === "dark" ? " text-mainBg" : "text-filter"
          }`}
        >
          Technical Support
        </h2>

        {/* Question 7 */}
        <div className="mb-6 sm:mb-2">
          <h2
            className={`text-lg font-bold mb-2 ${
              currentTheme === "dark" ? " text-mainBg" : "text-filter"
            }`}
          >
            7. How can I get technical support?
          </h2>
          <p className={`${currentTheme === "dark" ? " text-mainBg" : "text-gray-700"}`}>
            If you encounter any issues or have questions, our support team is here to
            help. Visit our Support page to access resources or reach out to our support
            team via email at{" "}
            <a href="mailto:support@cointrackingpro.com" className="text-primary">
              support@cointrackingpro.com
            </a>
            .
          </p>
        </div>

        {/* Question 8 */}
        <div className="mb-6 sm:mb-2">
          <h2
            className={`text-lg font-bold mb-2 ${
              currentTheme === "dark" ? " text-mainBg" : "text-filter"
            }`}
          >
            8. Do you have a community forum?
          </h2>
          <p className={`${currentTheme === "dark" ? " text-mainBg" : "text-gray-700"}`}>
            Yes, we have a vibrant community forum where users can share insights, ask
            questions, and connect with fellow investors. Join the conversation and become
            part of the Coin Tracking Pro community.
          </p>
        </div>
      </div>
    </div>
  );
};

export default GeneralQuestions;
