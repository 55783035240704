import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { openLogin, openRegister } from "../../store/slices/popupSlice";
import HeaderSearch from "../../components/HeaderSearch";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../../assets/images/coinlogo.png";
import DarkLogo from "../../assets/images/coinlogo-dark.png";
import SignupPopup from "../../components/SignupPopup";
import LoginPopup from "../../components/LoginPopup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import Sidebar from "../../utils/Sidebar";
import SidebarNav from "../SidebarNav/SidebarNav";
import {
  closeSidebarNavHandler,
  openSidebarNavHandler
} from "../../store/slices/sidebarSlice";
import { useLogoutQuery } from "../../store/services/services";
import { setUser } from "../../store/slices/authSlice";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ThemeToggle from "../../components/ThemeToggle/ThemeToggle";
import { selectCurrentTheme } from "../../store/slices/themeSlice";
import DropdownMenu from "../../utils/DropdownMenu/DropdownMenu";
import BuyCrypto from "../../assets/images/buy-crypto.svg";
import VerifyEmailPopup from "../../components/VerifyEmailPopup/VerifyEmailPopup";
import ForgotPasswordPopup from "../../components/ForgotPasswordPopup/ForgotPasswordPopup";
import ResetPasswordPopup from "../../components/ResetPasswordPopup/ResetPasswordPopup";
import Avatar from '../../assets/images/avatar.png'

const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const [headerSearch, setHeaderSearch] = useState(false);
 const [buyToggle, setBuyToggle] = useState(false);
  const loginPopup = useSelector(state => state.popup.loginIsVisible);
  const registerPopup = useSelector(state => state.popup.registerIsVisible);
  const sidebarNav = useSelector(state => state.sidebar.sidebarNav);

  const user = useSelector(state => state.auth);
   const forgotPopup = useSelector(state => state.popup.forgotPassword);
   const resetPassword = useSelector(state => state.popup.resetPassword);
  const [isLogout, setIsLogout] = useState(false);
  const [userEmail, setUserEmail] = useState();
  const token = useSelector(state => state.auth.token);
  const currentTheme = useSelector(selectCurrentTheme);
  ////////////////////////// close navbar in small screen //////////////////////////
   const openEmailVerificationPopup = useSelector(state => state.popup.emailVerification);
    const buyDropdownRef = useRef();
     useEffect(() => {
       document.addEventListener("click", handleClickOutside);

       return () => {
         document.removeEventListener("click", handleClickOutside);
       };
     }, []);
      const handleClickOutside = event => {
        if (buyDropdownRef.current && !buyDropdownRef.current.contains(event.target)) {
          setBuyToggle(false);
        }
      };
  useEffect(() => {
    function myFunction() {
      dispatch(closeSidebarNavHandler());
    }
    function checkScreenSize() {
      if (window.innerWidth >= 1024) {
        myFunction();
      }
    }
    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);
    return () => {
      window.removeEventListener("resize", checkScreenSize);
    };
  }, [dispatch]);
  ////////////////////////// close navbar in small screen //////////////////////////

  const logoutQuery = useLogoutQuery(token, { skip: !isLogout });

  useEffect(() => {
    if (logoutQuery.isSuccess && !logoutQuery.isLoading) {
      dispatch(setUser({ name: null, userId: null, token: null, email: null }));
      setIsLogout(false);
    }
  }, [dispatch, logoutQuery.isLoading, logoutQuery.isSuccess]);

  // toastify
  const handleLogout = async () => {
    try {
      setIsLogout(true);
      toast.success("Logged out successfully!");
    } catch (error) {
      toast.error("Logout failed. Please try again.");
    }
  };
  // move to profile page
    const handleProfileNavigation = () => {
      // Close the dropdown before navigating
      setBuyToggle(false);
      navigate("/profile"); // Use navigate to move to the profile page
    };
  return (
    <header className="text-2xl px-12 md:px-8 xs:px-4 pt-8 relative">
      <div
        className={`px-10 md:px-5 py-0 flex justify-between rounded-full ${
          currentTheme === "dark" ? "bg-cardsColor" : "main-bg"
        } shadow-header-shadow`}
      >
        <Link to="/">
          <div className="min-w-[100px] py-4">
            <img
              className="w-[200px] md:w-150 sm:w-100 xs:w-40"
              src={currentTheme === "dark" ? DarkLogo : Logo}
              alt="main-logo"
            />
          </div>
        </Link>
        <div className="flex items-center gap-2 xs:gap-5 justify-end">
          <FontAwesomeIcon
            icon={faMagnifyingGlass}
            className="text-lg text-menuIconColor hidden xs:block"
            onClick={() => setHeaderSearch(!headerSearch)}
          />
          <div className="xs:hidden">
            <HeaderSearch />
          </div>
          {headerSearch && (
            <div className="hidden xs:block">
              <HeaderSearch />
            </div>
          )}
          <div className="lg:hidden">
            <ThemeToggle />
          </div>
          <div className="flex items-center gap-2 text-base lg:hidden">
            {!token ? (
              <>
                <button
                  className="w-[90px] h-10 border-[1px] border-primary rounded-full"
                  onClick={() => {
                    dispatch(openLogin());
                  }}
                >
                  <span
                    className={`text-${currentTheme === "light" ? "filter" : "mainBg"}`}
                  >
                    Login
                  </span>
                </button>
                <button
                  className="w-[90px] h-10 border-[1px] border-primary rounded-full bg-primary"
                  onClick={async () => {
                    await dispatch(openRegister());
                  }}
                >
                  <span
                    className={`text-${currentTheme === "light" ? "filter" : "mainBg"}`}
                  >
                    Sign Up
                  </span>
                </button>
              </>
            ) : (
              <>
                <>
                  {/* <p
                    className={`mr-6 ${
                      currentTheme === "dark" ? "text-mainBg" : "text-filter"
                    }`}
                  >
                    Hi, {user.name}
                  </p> */}
                  {/* <button
                    className="w-[90px] h-10 border-[1px] border-primary rounded-full bg-primary disabled:bg-disabledButton"
                    onClick={handleLogout}
                    disabled={logoutQuery.isLoading}
                  >
                    <span>Logout</span>
                  </button> */}
                </>
                <div
                  className={`relative ${
                    currentTheme === "dark" ? "bg-cardsColor" : "main-bg"
                  }`}
                  ref={buyDropdownRef}
                >
                  <div
                    className={`flex items-center lg:min-w-[130px] justify-center cursor-pointer gap-2 py-4 md:py-3 text-center ${
                      currentTheme === "dark" ? "text-mainBg" : "text-filter"
                    }`}
                    onClick={() => setBuyToggle(!buyToggle)}
                  >
                    <span className="w-10 h-10 flex items-center justify-center">
                      <img src={Avatar} alt="avatar" />
                    </span>
                  </div>

                  {buyToggle && (
                    <DropdownMenu>
                      <div
                        className={`w-[250px] md:w-[150px] ${
                          currentTheme === "dark" ? "bg-cardsColor" : "main-bg"
                        }`}
                      >
                        <button className="w-full">
                          <div className="border-b pb-5 flex items-center">
                            <div className="flex items-center gap-2">
                              <div className="h-8 w-8">
                                <img src={Avatar} alt="avatar" />
                              </div>
                              <div>
                                <p className={"font-semibold"}> Hi, {user.name}</p>
                              </div>
                            </div>
                          </div>
                        </button>
                        <div>
                          <button
                            className="w-[90px] h-10 border-[1px] border-primary rounded-full bg-primary disabled:bg-disabledButton mt-6"
                            onClick={handleLogout}
                            disabled={logoutQuery.isLoading}
                          >
                            <span>Logout</span>
                          </button>
                        </div>
                      </div>
                    </DropdownMenu>
                  )}
                </div>
              </>
            )}
          </div>
          <div className="relative hidden lg:block">
            <FontAwesomeIcon
              icon={faBars}
              onClick={() => {
                dispatch(openSidebarNavHandler());
              }}
              className="cursor-pointer text-xl text-menuIconColor"
            />

            {sidebarNav && (
              <Sidebar>
                <SidebarNav logoutQuery={logoutQuery} handleLogout={handleLogout} />
              </Sidebar>
            )}
          </div>
        </div>
      </div>
      {openEmailVerificationPopup && <VerifyEmailPopup userEmail={userEmail} />}
      {loginPopup && <LoginPopup setUserEmail={setUserEmail} />}
      {registerPopup && <SignupPopup setUserEmail={setUserEmail} />}
      {forgotPopup && (
        <ForgotPasswordPopup userEmail={userEmail} setUserEmail={setUserEmail} />
      )}
      {resetPassword && <ResetPasswordPopup userEmail={userEmail} />}
    </header>
  );
};

export default Header;
