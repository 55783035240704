import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { removeCoin } from "../../store/slices/compareSlice";
import { selectCurrentTheme } from "../../store/slices/themeSlice";
import CryptoIcon from "../../assets/icons/crypto-icon.svg";
const CompareSelected = () => {
  const comparedCoins = useSelector(state => state.compare.selectedCoins);

  const dispatch = useDispatch();
  const currentTheme = useSelector(selectCurrentTheme);
  return (
    <div>
      <div className="flex gap-3 mt-4 py-4 overflow-auto">
        {comparedCoins.map(item => (
          <div
            key={item.logo}
            className={`flex min-w-[120px] sm:text-sm items-center gap-2 px-4 py-2 shadow-header-shadow rounded-full ${
              currentTheme === "dark" ? "bg-filter text-mainBg " : "bg-mainBg"
            }`}
          >
            <FontAwesomeIcon
              className="cursor-pointer"
              icon={faXmark}
              onClick={() => {
                dispatch(removeCoin(item.logo));
              }}
            />
            <img
              className="w-6 sm:w-5"
              src={item?.img ? item?.img : CryptoIcon}
              alt="coin"
            />
            <p className="font-semibold">{item?.logo}</p>
          </div>
        ))}
        
      </div>
    </div>
  );
};

export default CompareSelected;
