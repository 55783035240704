import React from "react";
import { useState } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { useGetSearchNewsCoinQuery } from "../../store/services/services";
import { useSelector } from "react-redux";
import { selectCurrentTheme } from "../../store/slices/themeSlice";
import CryptoIcon from "../../assets/icons/crypto-icon.svg";

const NEWS_MENU = [
  {
    id: "1",
    name: "Latest News",
    value: "latest-news"
  }
];
const CoinNews = ({ id, coin }) => {
  const [menuActive, setMenuActive] = useState("latest-news");
  const [readMoreEnabled, setReadMoreEnabled] = useState([]);
  const { data, isLoading, isError, error } = useGetSearchNewsCoinQuery(id);
  const [displayedNews, setDisplayedNews] = useState(2);
  const currentTheme = useSelector(selectCurrentTheme);

  const toggleReadMore = index => {
    const updatedReadMore = [...readMoreEnabled];
    updatedReadMore[index] = !updatedReadMore[index];
    setReadMoreEnabled(updatedReadMore);
  };
    const loadMore = () => {
      setDisplayedNews(prevCount => prevCount + 2);
    };
     const showLess = () => {
       setDisplayedNews(prevCount => Math.max(prevCount - 2, 2));
     };
  return (
    <div className="px-24 md:px-8 xs:px-4 mt-12">
      <div className="flex items-center gap-5 mb-7">
        <p
          className={`font-semibold text-xl ${
            currentTheme === "dark" ? "text-mainBg" : "text-filter"
          }`}
        >
          {coin} News
        </p>
        <div>
          <div
            className={`text-sm ${
              currentTheme === "dark" ? "text-mainBg" : "text-chartTextColor"
            }`}
          >
            <div
              className={`flex justify-between shadow-header-shadow rounded-full items-center overflow-hidden px-2 ${
                currentTheme === "dark" ? "bg-cardsColor" : "bg-coinDetailChartBg"
              }`}
            >
              <div className="flex gap-1 flex-nowrap overflow-auto scrollbar py-2">
                {NEWS_MENU.map(item => (
                  <div
                    className={`py-1 px-3 whitespace-nowrap cursor-pointer rounded-full shadow-navbar-button ${
                      menuActive === item.value
                        ? `bg-${currentTheme === "dark" ? "cardsColor" : "white"}`
                        : "bg-none"
                    } ${
                      menuActive === item.value && currentTheme === "dark"
                        ? "text-mainColor"
                        : "text-none"
                    }`}
                    onClick={() => {
                      setMenuActive(item.value);
                    }}
                    key={item.id}
                  >
                    {item.name}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      {data?.length === 0 ? (
        <p
          className={`text-center text-2xl font-semibold ${
            currentTheme === "dark" ? "text-mainBg" : "text-filter"
          }`}
        >
          No news for {coin}
        </p>
      ) : (
        <div>
          <div className="flex">
            {isLoading && (
              <div className="w-full">
                <SkeletonTheme
                  baseColor={`${currentTheme === "dark" ? "#323546" : "#d8d8d8"}`}
                  highlightColor={`${currentTheme === "dark" ? "#444" : "#e0e0e0"}`}
                >
                  <Skeleton width="100%" height={100} count={2} />
                </SkeletonTheme>
              </div>
            )}
            {isError && <p>{error.message}</p>}
            {!isLoading && !isError && (
              <div>
                <div className="flex flex-col gap-5 md:w-full md:px-0">
                  {data?.slice(0, displayedNews).map((item, index) => (
                    <div
                      className={`flex justify-between p-5 gap-5 rounded-[35px] sm:flex-col-reverse sm: items-center ${
                        currentTheme === "dark"
                          ? "bg-cardsColor"
                          : "bg-coinNewsCardBgColor"
                      }`}
                      key={item.id}
                    >
                      <div className="w-[73%] sm:w-full">
                        <div className="mb-4">
                          <p
                            className={`text-lg font-semibold mb-3 ${
                              currentTheme === "dark" ? "text-mainBg" : "text-filter"
                            }`}
                          >
                            {item?.title}
                          </p>
                          <div className="text-sm">
                            <p
                              className={`text-sm ${
                                currentTheme === "dark"
                                  ? "text-mainBg"
                                  : "text-coinEstimateGreyColor"
                              }`}
                            >
                              {readMoreEnabled[index]
                                ? item.description
                                : item.description.length > 500
                                ? `${item.description.substring(0, 500)}...`
                                : item.description}
                            </p>
                            {item.description && item.description.length > 500 && (
                              <div className="mt-4 cursor-pointer font-semibold text-[18px] text-blueColor">
                                {item.description.length > 500 && (
                                  <div className="mt-4 cursor-pointer font-semibold text-[18px] text-blueColor">
                                    <button
                                      className={`border border-primary font-medium text-xs px-4 py-1 rounded-[5px] ${
                                        currentTheme === "dark"
                                          ? "text-mainBg bg-filter"
                                          : "bg-coinNewsBgColor text-filter"
                                      }`}
                                      onClick={() => toggleReadMore(index)}
                                    >
                                      {readMoreEnabled[index] ? "Read less" : "Read more"}
                                    </button>
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="flex items-center gap-4 text-sm xs:text-xs">
                          <div className="flex sm:block gap-3">
                            <p
                              className={`font-semibold sm:pb-2 ${
                                currentTheme === "dark" ? "text-mainBg" : "text-filter"
                              }`}
                            >
                              {item?.author}{" "}
                            </p>
                            <p
                              className={` ${
                                currentTheme === "dark" ? "text-mainBg" : "text-grey"
                              }`}
                            >
                              {new Date(item?.published_at).toLocaleString()}
                            </p>
                          </div>
                          <div
                            className={`flex items-center gap-1 px-2 py-[2px] rounded-full font-semibold ${
                              currentTheme === "dark"
                                ? "bg-filter"
                                : "bg-coinEstimateBgColor"
                            }`}
                          >
                            <img
                              className="w-4"
                              src={item?.url_to_image ? item?.url_to_image : CryptoIcon}
                              alt="logo"
                            />
                            <p>{item.coinName}</p>
                          </div>
                        </div>
                      </div>
                      <div className="w-[10%] sm:w-1/2 xs:w-full">
                        <img
                          className="max-w-full"
                          src={item?.url_to_image ? item?.url_to_image : CryptoIcon}
                          alt="mews"
                        />
                      </div>
                    </div>
                  ))}
                  {data?.length > displayedNews && (
                    <div className="flex items-center gap-3 justify-center">
                      {displayedNews > 2 && (
                        <div className="flex">
                          <button
                            className={`border border-primary font-medium text-xs px-4 py-1 rounded-[5px] ml-2 ${
                              currentTheme === "dark"
                                ? "bg-cardsColor text-mainBg"
                                : "bg-coinNewsBgColor text-primaryText"
                            }`}
                            onClick={showLess}
                          >
                            Load Less
                          </button>
                        </div>
                      )}
                      <div className="text-center">
                        <button
                          className={` border border-primary font-medium text-xs px-4 py-1 rounded-[5px] ${
                            currentTheme === "dark"
                              ? "bg-cardsColor text-mainBg"
                              : "bg-coinNewsBgColor text-primaryText"
                          }`}
                          onClick={loadMore}
                        >
                          Load More
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default CoinNews;
