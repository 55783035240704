import React from "react";
import { Currencies_Info } from "../../data/miningEquipment";
const LatestRates = () => {
  return (
    <div className="mt-10 sm:mt-5">
      <div className="text-secondary">
        {Currencies_Info.map(items => {
          return (
            <div key={items.id}>
              <h3 className="font-semibold text-2xl mt-5 sm:text-[22px]">
                {items.title}
              </h3>
              <p className=" sm:mt-2 xs:text-sm">{items.desc}</p>
            </div>
          );
        })}
      </div>
      <p className="mt-5 sm:mt-2 text-blueColor xs:text-sm">
        Click here to read more about BTC
      </p>
      <div className="mt-5 sm:mt-2 text-secondary">
        <h3 className="text-[22px] xs:text-lg font-semibold">
          Do You Need To Convert BTC to USD Today?
        </h3>
        <p className="pt-2 xs:text-sm">
          Are you hoping to sell some crypto today — or are you interested in buying some
          Bitcoin for the first time?
        </p>
      </div>
    </div>
  );
};

export default LatestRates;
