import { createSlice } from "@reduxjs/toolkit";

// Function to load theme from local storage
const loadThemeFromLocalStorage = () => {
  const storedTheme = localStorage.getItem("theme");
  return storedTheme || "light";
};

const initialState = {
  currentTheme: loadThemeFromLocalStorage()
};

const themeSlice = createSlice({
  name: "theme",
  initialState,
  reducers: {
    toggleTheme: state => {
      state.currentTheme = state.currentTheme === "light" ? "dark" : "light";
      localStorage.setItem("theme", state.currentTheme)
    }
  }
});

export const { toggleTheme } = themeSlice.actions;
export const selectCurrentTheme = state => state.theme.currentTheme;
export default themeSlice.reducer;
