export const MARKET_CAP_RANGES = [
  {
    id:1,
    name: "> $10B",
    minAmount: "10000000000",
    maxAmount: "",
  },
  {
    id:2,
    name: "$1B - $10B",
    minAmount: "1000000000",
    maxAmount: "10000000000",
  },
  {
    id:3,
    name: "$100M - $1B",
    minAmount: "100000000",
    maxAmount: "1000000000",
  },
  {
    id:4,
    name: "$10M - $100M",
    minAmount: "10000000",
    maxAmount: "100000000",
  },
  {
    id:5,
    name: "$1M - $10M",
    minAmount: "1000000",
    maxAmount: "10000000",
  },
  {
    id:6,
    name: "< $1M",
    minAmount: "",
    maxAmount: "1000000",
  },
];

export const PRICE_RANGES = [
  {
    id:1,
    name: "$0 - $1",
    minAmount: "0",
    maxAmount: "1",
  },
  {
    id:2,
    name: "$1 - $100",
    minAmount: "1",
    maxAmount: "100",
  },
  {
    id:3,
    name: "$101 - $1,000",
    minAmount: "101",
    maxAmount: "1000",
  },
  {
    id:4,
    name: "$1,000 +",
    minAmount: "1000",
    maxAmount: "",
  },
];

export const PER_RANGES = [
  {
    id:1,
    name: "+50%",
    minAmount: "50",
    maxAmount: "",
  },
  {
     id:2,
    name: "+10% to +50%",
    minAmount: "10",
    maxAmount: "50",
  },
  {
     id:3,
    name: "0% to +10%",
    minAmount: "0",
    maxAmount: "10",
  },
  {
     id:4,
    name: "-10% to 0%",
    minAmount: "-10",
    maxAmount: "0",
  },
  {
     id:5,
    name: "-50% to -10%",
    minAmount: "-50",
    maxAmount: "-10",
  },
  {
     id:6,
    name: "-50%",
    minAmount: "",
    maxAmount: "-50",
  },
];

export const VOLUME_RANGES = [
  {
     id:1,
    name: "> $10B",
    minAmount: "10000000000",
    maxAmount: "",
  },
  {
     id:2,
    name: "$1B - $10B",
    minAmount: "1000000000",
    maxAmount: "10000000000",
  },
  {
     id:3,
    name: "$100M - $1B",
    minAmount: "100000000",
    maxAmount: "1000000000",
  },
  {
    id:4,
    name: "$10M - $100M",
    minAmount: "10000000",
    maxAmount: "100000000",
  },
  {
    id:5,
    name: "$1M - $10M",
    minAmount: "1000000",
    maxAmount: "10000000",
  },
  {
    id:6,
    name: "< $1M",
    minAmount: "",
    maxAmount: "1000000",
  },
];

export const CIRCULATING_SUPPLY_RANGES = [
  {
    id:1,
    name: "> $10B",
    minAmount: "10000000000",
    maxAmount: "",
  },
  {
    id:2,
    name: "$1B - $10B",
    minAmount: "1000000000",
    maxAmount: "10000000000",
  },
  {
    id:3,
    name: "$100M - $1B",
    minAmount: "100000000",
    maxAmount: "1000000000",
  },
  {
    id:4,
    name: "$10M - $100M",
    minAmount: "10000000",
    maxAmount: "100000000",
  },
  {
    id:5,
    name: "$1M - $10M",
    minAmount: "1000000",
    maxAmount: "10000000",
  },
  {
    id:6,
    name: "< $1M",
    minAmount: "",
    maxAmount: "1000000",
  },
];
