import React, { useState } from "react";
import MainNews from "../MainNews";
import { useSelector, useDispatch } from "react-redux";
import SocialMediaSharingPopup from "../SocialMediaSharingPopup/SocialMediaSharingPopup";
import { socialMediaSharing } from "../../store/slices/popupSlice";
import { selectCurrentTheme } from "../../store/slices/themeSlice";

const MENU_DATA = [
  {
    id: 1,
    name: "Overview",
    value: "overview"
  },
  {
    id: 2,
    name: "News",
    value: "news"
  }
];

const CoinDetailMenu = ({ activeMenuHandler, coin }) => {
  const [active, setActive] = useState("overview");
  const currentTheme = useSelector(selectCurrentTheme);

  activeMenuHandler(active);
  const dispatch = useDispatch();

  // social media sharing
  const socialMediaOpen = useSelector(state => state.popup.socialMediaSharingIsVisible);

  return (
    <>
      <div className="px-24 md:px-8 xs:px-4 mt-16 text-sm">
        <div
          className={`flex justify-between shadow-header-shadow rounded-full items-center overflow-hidden px-10 xs:px-3 gap-1 ${
            currentTheme === "dark" ? "bg-cardsColor" : "bg-mainBg"
          }`}
        >
          <div className="flex gap-6 flex-nowrap overflow-auto scrollbar py-3 xs:py-2">
            {MENU_DATA.map(item => (
              <div
                className={`${
                  active === item.value
                    ? "bg-primary text-mainBg rounded-full"
                    : "bg-none text-filter"
                } py-3 px-7 whitespace-nowrap cursor-pointer ${
                  currentTheme === "dark" ? "text-mainBg" : "text-filter"
                }`}
                onClick={() => {
                  setActive(item.value);
                }}
                key={item.id}
              >
                {item.name}
              </div>
            ))}
            <div className="w-[10%] min-w-20 text-right">
              <button
                className={`border-[1px] rounded-full py-3 px-5 ${
                  currentTheme === "dark"
                    ? "text-mainBg  border-mainBg"
                    : "text-filter  border-filter"
                }`}
                onClick={() => dispatch(socialMediaSharing())}
              >
                Share
              </button>
            </div>
          </div>
          {/* Conditionally render News component */}
        </div>
      </div>
      <div>
        {active === "news" && <MainNews searchCoin={coin} />}
        {socialMediaOpen && <SocialMediaSharingPopup />}
      </div>
    </>
  );
};

export default CoinDetailMenu;
