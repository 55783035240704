import React, { useEffect } from "react";
import CompareCoin from "../components/CompareCoin";
import CompareOrderbook from "../components/CompareOrderbook";
import CompareMarket from "../components/CompareMarket";
import { useSelector } from "react-redux";

const Compare = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const selectedCoins = useSelector((state) => state.compare.selectedCoins);

  return (
    <div>
      <CompareCoin />
      {/* {selectedCoins.length > 0 && (
        <div>
          <CompareOrderbook />
          <CompareMarket />
        </div>
      )} */}
    </div>
  );
};

export default Compare;
