import React from "react";
import { selectCurrentTheme } from "../../store/slices/themeSlice";
import { useSelector } from "react-redux";

const DropdownMenu = ({ children }) => {
  const currentTheme = useSelector(selectCurrentTheme);
  return (
    <div
      className={`absolute left-0 z-50  top-[0px] right-0 ${
        currentTheme === "dark" ? "bg-cardsColor" : "main-bg"
      }`}
    >
      <div className="relative">
        <div
          className={`absolute top-12 right-1/2 translate-x-1/2 w-4 h-4 rotate-45 shadow-dropdown rounded-sm z-50 ${
            currentTheme === "dark"
              ? "bg-cardsColor text-mainBg"
              : "bg-mainBg text-filter"
          }`}
        ></div>
        <div
          className={`absolute top-[54px] right-0 shadow-dropdown  rounded-[20px] max-w-[500px] z-40 overflow-hidden py-3 ${
            currentTheme === "dark"
              ? "bg-cardsColor text-mainBg"
              : "bg-mainBg text-filter"
          }`}
        >
          <div className="max-h-80 overflow-auto scrollbar p-5">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default DropdownMenu;
