import React from "react";
import calendar from "../../assets/icons/calendar.svg";
import UserWatch from "../UserWatch/UserWatch";

const Participate_Info_Data = [
  {
    id: 1,
    tokenInfo: "Tokens for Sale",
    priceInfo: "50,000,000 AUD",
  },
  {
    id: 2,
    tokenInfo: "ICO Price",
    priceInfo: "$0.010000",
  },
  {
    id: 3,
    tokenInfo: "Soft Cap",
    priceInfo: "$2,000,000",
  },
  {
    id: 4,
    tokenInfo: "Fundraising Goal",
    priceInfo: "$5,000,000",
  },
  {
    id: 5,
    tokenInfo: "Personal Cap",
    priceInfo: "10USD",
  },
  {
    id: 6,
    tokenInfo: "Tokens Sold",
    priceInfo: "N/A",
  },
  {
    id: 7,
    tokenInfo: "Where to buy",
    priceInfo: "Ignition (Paid Network)",
  },
  {
    id: 8,
    tokenInfo: "% of Total Supply",
    priceInfo: "0.02%",
  },
  {
    id: 9,
    tokenInfo: "Accept ",
    priceInfo: "BTC ETH BNB LTC BUSD SOL DASH TRX",
  },
  {
    id: 10,
    tokenInfo: "Access",
    priceInfo: "Public",
  },
];

const ParticipateInfo = () => {
  return (
    <>
      <div className="mt-12 py-12 shadow-navbar-button md:px-8 xs:px-4 overflow-hidden rounded-[40px]">
        <div className="px-12 md:px-0">
          <div className="flex items-center gap-4">
            <h3 className="font-semibold text-[26px] xs:text-base">ICO</h3>
            <button className="bg-primary border-[1px] py-2 px-6 rounded-[40px] cursor-pointer text-lg font-semibold xs:text-base">
              Ongoing
            </button>
          </div>
          <div className="flex items-center gap-2 mt-8">
            <img src={calendar} alt="calendar" className="h-[13px]" />
            <p className="text-sm text-grey">
              Mar 13, 2023 - Mar 15, 2023 , Ends in 12 hours
            </p>
          </div>
        </div>
        <div className="mt-4">
          <div className="overflow-x-auto scrollbar">
            <div className="flex flex-wrap justify-between items-center">
              {Participate_Info_Data.map(items => {
                const { tokenInfo, priceInfo } = items;
                return (
                  <div
                    key={items.id}
                    className="w-6/12 lg:w-full sm:min-w-[400px] px-12 flex border-t-[.5px] items-center py-4 justify-between border-r"
                  >
                    <p className="text-grey text-sm lg:text-xs">{tokenInfo}</p>
                    <p className="text-grey font-semibold lg:text-xs">{priceInfo}</p>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="mt-10 md:mt-6 px-12 md:px-0">
          <h1 className="text-2xl font-semibold sm:text-sm">How to Participate?</h1>
          <ul className="mt-4 mb-7 text-grey text-text-lg list-disc mx-6 sm:text-sm">
            <li className="">Login/Register your account : www.aussiedigital.io</li>
            <li>Click "Buy Tokens" on your dashboard</li>
            <li>Click a payment method (eg. BTC, ETH, BNB, etc.)</li>
            <li>Enter the desired number of tokens/coins</li>
            <li>Click Place Order</li>
          </ul>
          <button className="bg-coinNewsBgColor border border-primary font-medium text-xs px-4 py-1 rounded-[5px] text-primaryText">
            Read more
          </button>
        </div>
      </div>
      <div>
        <UserWatch />
      </div>
    </>
  );
};

export default ParticipateInfo;
