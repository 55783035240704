import React from "react";
import { Pie, measureTextWidth } from "@ant-design/plots";
import { EXCHANGE_RESERVE_DATA } from "../../data/exchangeReserveData";

const data = EXCHANGE_RESERVE_DATA.slice(0, 4).map((item) => {
  return { type: item.name, value: item.value };
});

const ExchangeDetailAllocation = () => {
  function renderStatistic(containerWidth, text, style) {
    const { width: textWidth, height: textHeight } = measureTextWidth(
      text,
      style
    );
    const R = containerWidth / 2; // r^2 = (w / 2)^2 + (h - offsetY)^2

    let scale = 1;

    if (containerWidth < textWidth) {
      scale = Math.min(
        Math.sqrt(
          Math.abs(
            Math.pow(R, 2) /
              (Math.pow(textWidth / 2, 2) + Math.pow(textHeight, 2))
          )
        ),
        1
      );
    }

    const textStyleStr = `width:${containerWidth}px;`;
    return `<div style="${textStyleStr};font-size:${scale}em;line-height:${
      scale < 1 ? 1 : "inherit"
    };">${text}</div>`;
  }
  const config = {
    appendPadding: 10,
    data,
    angleField: "value",
    colorField: "type",
    radius: 1,
    innerRadius: 0.64,
    meta: {
      value: {
        formatter: (v) => `${v} ¥`,
      },
    },
    label: {
      type: "inner",
      offset: "-50%",
      style: {
        textAlign: "center",
      },
      autoRotate: false,
      content: "",
    },
    statistic: {
      title: {
        offsetY: -4,
        customHtml: (container, view, datum) => {
          const { width, height } = container.getBoundingClientRect();
          const d = Math.sqrt(Math.pow(width / 2, 2) + Math.pow(height / 2, 2));
          const text = datum ? datum.type : "Total";
          return renderStatistic(d, text, {
            fontSize: 28,
          });
        },
      },
      content: {
        offsetY: 4,
        style: {
          fontSize: "20px",
          fontWeight: "400",
          color: "#666",
        },
        customHtml: (container, view, datum, data) => {
          const { width } = container.getBoundingClientRect();
          const text = datum
            ? `<span style={{fontWeight: 400}}>${Intl.NumberFormat("en", {
                notation: "compact",
              }).format(datum.value)}</span><br/>
              ${(
                (datum.value / data.reduce((r, d) => r + d.value, 0)) *
                100
              ).toFixed(2)}% `
            : `${Intl.NumberFormat("en", { notation: "compact" }).format(
                data.reduce((r, d) => r + d.value, 0)
              )}`;
          return renderStatistic(width, text, {
            fontSize: 24,
          });
        },
      },
    },
    // 添加 中心统计文本 交互
    interactions: [
      {
        type: "element-selected",
      },
      {
        type: "element-active",
      },
      {
        type: "pie-statistic-active",
      },
    ],
  };
  return (
    <div className="w-1/3 lg:w-full mt-12 flex flex-col lg:flex-row sm:flex-col gap-4">
      <div className="shadow-navbar-button rounded-[24px] px-4 pt-8 w-full lg:w-5/12 md:w-7/12 sm:w-8/12 xs:w-full">
        <div>
          <div>
            <p className="text-xl font-semibold">Token Allocation</p>
          </div>
          <Pie {...config} />
        </div>
      </div>
      <div className="mt-4 lg:w-5/12 sm:w-full">
        <p className="text-xs text-exchangeDetailGreyColor font-medium">
          Disclaimer: <br /> All information and data relating to the holdings in the
          third-party wallet addresses are based on public third party information.
          CoinMarketCap does not confirm or verify the accuracy or timeliness of such
          information and data. <br />
          <br /> CoinMarketCap shall have no responsibility or liability for this public
          third party information and data. CoinMarketCap shall have no duty to review,
          confirm, verify or otherwise perform any inquiry or investigation as to the
          completeness, accuracy, sufficiency, integrity, reliability or timeliness of any
          such information or data provided.
        </p>
      </div>
    </div>
  );
};

export default ExchangeDetailAllocation;
