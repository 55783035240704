import React from "react";
import { Social_Price_Estimate } from "../../data/SocialPriceEstimate";
import UserWatch from "../UserWatch/UserWatch";
const SocialPriceEstimates = () => {
  return (
    <div>
      <div className="flex xs:block items-center mt-10 gap-2">
        <h1 className="text-2xl sm:pb-2 font-semibold sm:text-sm">
          Social Aussie Digital Price Estimates
        </h1>
        <div className="flex">
          <div className="bg-pinkColor sm:text-sm text-mainBg py-1 px-4 text-base font-semibold rounded-[40px]">
            Beta
          </div>
        </div>
      </div>
      <p className="text-lightGray pt-2 text-base sm:text-sm">
        This feature is in beta testing. Place your estimates for next 6 months and see
        what other’s are thinking about it. Data displayed are based on user input
        compiled by CoinMarketCap. The cut-off for estimates for each month-end is on the
        21st of each month.
      </p>
      <div className="flex flex-wrap gap-7 justify-center mt-10">
        {Social_Price_Estimate.map(items => {
          const { calendarIcon, PeopleIcon, date, title, desc, estimate, vote } = items;
          return (
            <div
              className="w-[32%] sm:w-full min-w-[320px] shadow-navbar-button px-10 py-8 rounded-[40px]"
              key={items.id}
            >
              <div className="flex justify-between items-center border-b border-darkBorder pb-3">
                <div>
                  <h1 className="text-2xl font-semibold lg:text-base">{date}</h1>
                  <div className="flex items-center text-lightGray gap-1 text-base">
                    <img src={PeopleIcon} alt="people-icon" className="h-3.5" />
                    <p>{vote}</p>
                  </div>
                </div>
                <div className="w-12 h-12 flex justify-center items-center bg-socialPriceEstimate rounded-full">
                  <img
                    src={calendarIcon}
                    alt="calendar-icon"
                    className="h-[22px] lg:h-4"
                  />
                </div>
              </div>
              <h1 className="text-2xl sm:pb-2 font-semibold sm:text-sm pt-3 lg:text-base">
                {title}
              </h1>
              <p className="text-lightGray text-sm lg:text-xs">{desc}</p>
              <div className="mt-20">
                <p className="text-lightGray lg:text-xs text-sm bg-socialPriceEstimate py-4 text-center rounded-[11px]">
                  {estimate}
                </p>
              </div>
            </div>
          );
        })}
      </div>
      <div className="mt-12 sm:mt-8 min-h-[457px] flex justify-center items-center shadow-navbar-button rounded-[40px]">
        <div>
          <h1 className="text-2xl font-semibold sm:text-sm text-center">
            Place your estimate
          </h1>
          <p className="text-lightGray text-lg sm:text-sm">
            At least 1 to see how others estimate
          </p>
        </div>
      </div>
      <UserWatch />
    </div>
  );
};

export default SocialPriceEstimates;
