import React from "react";
import { Accordion, AccordionItem as Item } from "@szhsin/react-accordion";
import chevron from "../../assets/icons/chevron-down.svg";
import { useSelector } from "react-redux";
import { selectCurrentTheme } from "../../store/slices/themeSlice";

const AccordionItem = ({ header, ...rest }) => (
  <Item
    {...rest}
    header={({ state: { isEnter } }) => (
      <>
        {header}
        <img
          className={`w-5 ml-auto transition-transform duration-200 ease-in-out -rotate-90 ${
            isEnter && "rotate-0"
          }`}
          src={chevron}
          alt="Chevron"
        />
      </>
    )}
    className={({ isEnter }) =>
      ` shadow-accordion mb-6 rounded-[30px] px-3 xs:px-1 overflow-hidden sm:text-sm xs:text-xs  ${
        isEnter ? "border-[1px] border-primary  " : ""
      }`
    }
    buttonProps={{
      className: ({ isEnter }) =>
        `flex items-center w-full p-4 text-left font-semibold  gap-3 ${
          isEnter && "border-b border-b-[1px] border-[#d3d3d3]"
        }`
    }}
    contentProps={{
      className: "transition-height duration-200 ease-in-out"
    }}
    panelProps={{ className: "p-4" }}
  />
);

const FAQ = () => {
  const currentTheme = useSelector(selectCurrentTheme);
  return (
    <div className="mx-2  px-24 md:px-8 xs:px-4  my-14">
      <h2
        className={`font-semibold text-[26px] md:text-[24px] sm:text-[22px] mb-10 ${
          currentTheme === "dark" ? "text-mainBg" : "text-filter"
        }`}
      >
        Frequently Asked Questions
      </h2>
      <Accordion
        transition
        transitionTimeout={200}
        className={` ${currentTheme === "dark" ? "text-mainBg" : "text-secondary"}`}
      >
        <AccordionItem header={`How can I use market cap?`} initialEntered>
          As a financial metric, market cap allows you to compare the total circulating
          value of one cryptocurrency with another. Large cap cryptocurrencies such as
          Bitcoin and Ethereum have a market cap of over $10 billion. They typically
          consist of protocols that have demonstrated track record, and have a vibrant
          ecosystem of developers maintaining and enhancing the protocol, as well as
          building new projects on top of them. From a trading perspective, large caps
          would typically be hosted on more exchanges, have higher liquidity, and are less
          volatile when compared against other mid and small cap cryptocurrencies. While
          market cap is a simple and intuitive comparison metric, it is not a perfect
          point of comparison. Some cryptocurrency projects may appear to have inflated
          market cap through price swings and the tokenomics of their supply. As such, it
          is best to use this metric as a reference alongside other metrics such as
          trading volume, liquidity, fully diluted valuation, and fundamentals during your
          research process.
        </AccordionItem>

        {/* <AccordionItem
          header={`Why I no longer see the affiliate links for Exchanges?`}
        >
          Circulating Supply is the best approximation of the number of coins
          that are circulating in the market and in the general public's hands.
          Total Supply is the total amount of coins in existence right now
          (minus any coins that have been verifiably burned). Max Supply is the
          best approximation of the maximum amount of coins that will ever exist
          in the lifetime of the cryptocurrency.
        </AccordionItem> */}

        {/* <AccordionItem
          header={`Why aren't you listing [insert random cryptocurrency]?`}
        >
          While we strive to add every single cryptocurrency in the universe,
          it's virtually impossible to list everything. Listing cryptocurrencies
          is largely a manual process that takes time and resources to ensure
          the accuracy of our data. Please refer to the Listings Criteria
          section of the methodology for detailed information on this topic.
        </AccordionItem> */}

        <AccordionItem
          header={`What is "Market Capitalization" and how is it calculated?`}
        >
          Market Capitalization is one way to rank the relative size of a cryptocurrency.
          It's calculated by multiplying the Price by the Circulating Supply. <br />
          <br /> Market Cap = Price X Circulating Supply.
        </AccordionItem>
      </Accordion>
    </div>
  );
};

export default FAQ;
