import React, { useState } from "react";
import CoinConverter from "../CoinConverter/CoinConverter";
import CoinDetailCard from "../CoinDetailCard/CoinDetailCard";
import CoinDetailChart from "../CoinDetailChart/CoinDetailChart";
import CoinDetailMenu from "../CoinDetailMenu/CoinDetailMenu";
// import CoinVote from "../components/CoinVote";
// import CoinDetailFAQ from "../components/CoinDetailFAQ";
import CoinStatistics from "../CoinStatistics/CoinStatistics";
import CoinDetailTrending from "../CoinDetailTrending/CoinDetailTrending";
// import CoinDetailTable from "../components/CoinDetailTable";
// import CoinPriceEstimate from "../components/CoinPriceEstimate";
import CoinNews from "../CoinNews/CoinNews";
// import CoinDetailRelated from "../components/CoinDetailRelated";
import { useParams } from "react-router-dom";
import { useGetSingleCoinInfoQuery } from "../../store/services/services";
import { Spin } from "antd";

const CoinDetails = () => {
  const [activeMenu, setActiveMenu] = useState();
  const { id } = useParams();
  const { data, isLoading, isError, error } = useGetSingleCoinInfoQuery(id);

  let coin;

  if (!isLoading) {
    coin = data?.data;
  }

  const activeMenuHandler = menu => {
    setActiveMenu(menu);
  };

  return (
    <div>
      {isLoading && (
        <div className="flex justify-center items-center h-[300px]">
          <Spin size="large" />
        </div>
      )}
      {isError && <p>{error.message}</p>}
      {!isLoading && !isError && (
        <>
          <CoinDetailCard coin={coin} />

          <CoinDetailMenu coin={coin.name} activeMenuHandler={activeMenuHandler} />
          {activeMenu === "overview" && (
            <div className="flex px-24 md:px-8 xs:px-4 mt-16">
              <div className="w-[70%] lg:w-full pr-4 lg:pr-0">
                <CoinDetailChart coin={coin} coin_name={coin.logo} />
                <CoinConverter coin={coin} />
                <div className="hidden lg:flex gap-6 mb-8 xs:flex-col sm:flex-col">
                  <CoinStatistics coin={coin} />
                  <CoinDetailTrending />
                </div>
                {/* <CoinVote /> */}
                {/* <CoinDetailFAQ filterCoin={filterCoin} /> */}
              </div>
              <div className="w-[30%] lg:hidden">
                <CoinStatistics coin={coin} />
                <div>
                  <CoinDetailTrending />
                </div>
              </div>
            </div>
          )}
          {/* <CoinPriceEstimate /> */}
          {/* <CoinDetailTable /> */}
          <CoinNews id={id} coin={coin.name} />
          {/* <CoinDetailRelated /> */}
        </>
      )}
    </div>
  );
};

export default CoinDetails;
