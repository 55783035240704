import React from "react";
import TrendingCoinsCards from "../TrendingCoinsCards/TrendingCoinsCards";
import { useGetCoinGainersQuery } from "../../store/services/services";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectCurrentTheme } from "../../store/slices/themeSlice";

const TrendingCoins = () => {
  const { data, isLoading, isError, error } = useGetCoinGainersQuery();
  const currentTheme = useSelector(selectCurrentTheme);

  return (
    <div className="px-24 md:px-8 xs:px-4 mt-12">
      <h2
        className={`font-semibold text-[26px] mb-6  ${
          currentTheme === "dark" ? "text-mainBg" : "text-secondary"
        }`}
      >
        Top Gainers
      </h2>
      {isLoading && (
        <div className="w-full">
          <SkeletonTheme
            baseColor={`${currentTheme === "dark" ? "#323546" : "#d8d8d8"}`}
            highlightColor={`${currentTheme === "dark" ? "#444" : "#e0e0e0"}`}
          >
            <Skeleton width="100%" height={37} count={2} />
          </SkeletonTheme>
        </div>
      )}
      {isError && <p>{error.message}</p>}
      {!isLoading && !isError && (
        <div className="flex flex-wrap justify-center gap-3 gap-y-4">
          {data?.data?.slice(0, 8).map(item => (
            <div
              key={item.id}
              className={`w-[24%] min-w-[280px]  shadow-navbar-button rounded-[28px] px-5 py-3 ${
                currentTheme === "dark" ? "bg-cardsColor" : "bg-mainBg"
              }`}
            >
              <Link to={`/coin-detail/${item?.logo}`}>
                <TrendingCoinsCards
                  name={item?.name}
                  image={item?.img}
                  price={item?.price}
                  change={item?.percentChangeDay}
                />
              </Link>
            </div>
          ))}
        </div>
      )}
      <p
        className={`text-sm my-6  ${
          currentTheme === "dark" ? "text-mainBg" : "text-secondary"
        }`}
      >
        Market cap is one of the most popular metrics in the industry that is used to
        gauge the value of an asset. The market cap of a cryptocurrency is calculated
        based on the coin's total circulating supply multiplied by the current price. For
        detailed examples on how the market capitalization of a coin is calculated, please
        view our <span className="text-increase">methodology</span> page.
      </p>
    </div>
  );
};

export default TrendingCoins;
