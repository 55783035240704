import { createSlice } from "@reduxjs/toolkit";

// initial state
const initialState = {
  pageNumber: 1
};

const coinMarketSlice = createSlice({
  name: "coin market",
  initialState,
  reducers: {
    changePageNumber: (state, action) => {
      state.pageNumber = action.payload;
    }
  }
});

export default coinMarketSlice.reducer;
export const { changePageNumber } = coinMarketSlice.actions;
