import React from "react";
import { Dropdown, Space } from "antd";
import Download from "../../assets/icons/download.svg";
import StarOutlineIcon from "../../assets/icons/star-outline.svg";
import AttachmentIcon from "../../assets/icons/attachment.svg";
import LinkIcon from "../../assets/icons/link.svg";
import UserIcon from "../../assets/icons/user.svg";
import CodeIcon from "../../assets/icons/code.svg";
import PaperIcon from "../../assets/icons/paper.svg";
import ChevronGreyIcon from "../../assets/icons/chevron-down-grey.svg";
import Badge from "../../assets/icons/badge.svg";
import Dog from "../../assets/icons/dog.svg";
import SelectSearch from "../../assets/icons/select-search.svg";

const items = [
  {
    label: <a href="https://www.antgroup.com">1st menu item</a>,
    key: "0",
  },
  {
    label: <a href="https://www.aliyun.com">2nd menu item</a>,
    key: "1",
  },
  {
    type: "divider",
  },
  {
    label: "3rd menu item",
    key: "3",
  },
];

const tags = ["Mineable", "poW", "SHA-256", "Store Of Value", "SHA-257"];
const self_tags = ["poW", "Marketing", "Commodities", "Fashion", "View all"];

const IcosTags = ({ filterData }) => {
  return (
    <div className="p-10 border-r-[.5px] lg:border-r-0 border-lightBorder xs:p-5">
      <div>
        <div className="flex items-center gap-3 mb-4">
          <img className="w-[54px] xs:w-10" src={filterData.bnbImg} alt="bitcoin-icon" />
          <p className="text-2xl font-semibold xs:text-xl">{filterData.coin}</p>
          <p className="bg-icosProjectsGrey px-3 rounded-[7px] text-coinTagsText xs:text-sm font-semibold">
            {filterData.coinCode}
          </p>
          <img
            className="border-[1px] border-darkBorder p-1 rounded-[6px] cursor-pointer"
            src={StarOutlineIcon}
            alt="star-icon"
          />
          <img
            className="p-1 rounded-[6px] cursor-pointer h-[30px]"
            src={Download}
            alt="star-icon"
          />
        </div>
      </div>
      <div>
        <div className="flex gap-2 text-sm xs:text-xs mb-8">
          <p className="px-3 py-[2px] bg-grey rounded-[5px] text-mainBg h-full">
            Rank #1
          </p>
          <p className="px-3 py-[2px] bg-coinTagsBg rounded-[5px] h-full text-grey">
            Coin
          </p>
          <p className="px-3 py-[2px] bg-coinTagsBg rounded-[5px] text-grey">
            On 2,402,783 watchlists
          </p>
        </div>
        <div className="flex flex-wrap gap-3 text-sm xs:text-xs mb-8 text-grey">
          <div className="flex gap-1 py-[2px] px-3 bg-coinTagsBg rounded-[5px] items-center">
            <img className="h-[13px]" src={AttachmentIcon} alt="attachment-icon" />
            <p>bitcoin.org</p>
            <img className="h-[13px]" src={LinkIcon} alt="link-icon" />
          </div>
          <Dropdown
            className="cursor-pointer"
            menu={{
              items
            }}
            trigger={["click"]}
          >
            <p
              className="flex gap-1 py-[2px] px-3 bg-coinTagsBg rounded-[5px] items-center"
              onClick={e => e.preventDefault()}
            >
              <img className="h-[13px]" src={SelectSearch} alt="search-icon" />
              <Space>Explorers</Space>
              <img className="h-[6px]" src={ChevronGreyIcon} alt="down-icon" />
            </p>
          </Dropdown>
          <Dropdown
            className="cursor-pointer"
            menu={{
              items
            }}
            trigger={["click"]}
          >
            <p
              className="flex items-center gap-1 py-[2px] px-3 bg-coinTagsBg rounded-[5px]"
              onClick={e => e.preventDefault()}
            >
              <img className="h-[13px]" src={UserIcon} alt="user-icon" />
              <Space>Community</Space>
              <img className="h-[6px]" src={ChevronGreyIcon} alt="down-icon" />
            </p>
          </Dropdown>
          <div className="flex gap-1 py-[2px] px-3 bg-coinTagsBg rounded-[5px] items-center">
            <img className="h-[13px]" src={CodeIcon} alt="attachment-icon" />
            <p>Source Code</p>
            <img className="h-[13px]" src={LinkIcon} alt="link-icon" />
          </div>
          <div className="flex gap-1 py-[2px] px-3 bg-coinTagsBg rounded-[5px] items-center">
            <img className="h-[13px]" src={PaperIcon} alt="attachment-icon" />
            <p>Whitepaper</p>
            <img className="h-[13px]" src={LinkIcon} alt="link-icon" />
          </div>
        </div>
        <div className="text-sm xs:text-xs text-grey">
          <div>
            <p className="font-semibold mb-2">Tags:</p>
          </div>
          <div className="flex items-center flex-wrap gap-2">
            {tags.slice(0, 4).map(item => (
              <p className="py-[2px] px-3 bg-coinTagsBg rounded-[5px]" key={item.id}>
                {item}
              </p>
            ))}
            {tags.length > 4 && (
              <p className="py-[2px] px-3 bg-blueBg text-blueColor rounded-[5px]">
                View All
              </p>
            )}
          </div>
          <div className="mt-6">
            <h3 className="mb-3 text-sm font-semibold">Contracts</h3>
            <div className="flex">
              <div className="flex flex-wrap pb-2 gap-1 items-center py-1 px-3 text-grey text-sm bg-coinTagsBg rounded-[7px]">
                <img src={filterData.bnbImg} alt="bitcoinlogo" className="h-3" />
                <p className="pr-2">{filterData.coin}0x4bb2...e5f5e0b</p>
                <img src={Badge} alt="badge" />
                <img src={Dog} alt="dog" />
              </div>
            </div>
          </div>
          <div className="text-sm xs:text-xs mt-6 text-grey">
            <div>
              <p className="font-semibold mb-2">Self-Reported Tags</p>
            </div>
            <div className="flex items-center flex-wrap gap-2">
              {self_tags.slice(0, 4).map(item => (
                <p className="py-[2px] px-3 bg-coinTagsBg rounded-[5px]" key={item.id}>
                  {item}
                </p>
              ))}
              {tags.length > 4 && (
                <p className="py-[2px] px-3 text-blueColor bg-coinTagsBg rounded-[5px]">
                  View All
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IcosTags;
