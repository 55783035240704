import React from "react";
import { selectCurrentTheme } from "../../store/slices/themeSlice";
import { useSelector } from "react-redux";

const AboutUs = () => {
  const currentTheme = useSelector(selectCurrentTheme);

  return (
    <div className="px-24 md:px-8 xs:px-4 py-7 mt-12 md:mt-10">
      <div
        className={`rounded-[28px] shadow-navbar-button py-8 px-12 md:px-8 xs:px-4 ${
          currentTheme === "dark" ? "bg-cardsColor" : "bg-mainBg"
        }`}
      >
        <h2
          className={`text-3xl sm:text-2xl font-bold mb-6 sm:mb-3 ${
            currentTheme === "dark" ? "text-mainBg" : "text-filter"
          }`}
        >
          About Us - CoinTracking Pro
        </h2>
        <p
          className={`mb-4 ${currentTheme === "dark" ? "text-mainBg" : "text-gray-700"}`}
        >
          Welcome to CoinTracking Pro, where innovation meets your cryptocurrency
          management needs. As a leading platform in the crypto space, we're dedicated to
          providing a comprehensive solution that not only simplifies but also elevates
          your experience in the dynamic world of digital assets.
        </p>
        <p
          className={`mb-4 ${currentTheme === "dark" ? "text-mainBg" : "text-gray-700"}`}
        >
          At CoinTracking Pro, we understand the challenges of navigating the intricacies
          of the cryptocurrency market. With a commitment to excellence, we've
          meticulously designed our platform to cater to the needs of both beginners and
          seasoned investors. Whether you're a casual enthusiast exploring the
          possibilities of crypto or a professional trader optimizing your strategy,
          CoinTracking Pro is your dedicated partner in managing and maximizing your
          cryptocurrency portfolio.
        </p>

        <h3
          className={`text-lg font-bold mb-2 ${
            currentTheme === "dark" ? "text-mainBg" : "text-filter"
          }`}
        >
          Features
        </h3>
        <ul
          className={`mb-4 list-disc ml-6 text-sm ${
            currentTheme === "dark" ? "text-mainBg" : "text-gray-700"
          }`}
        >
          <li>
            <strong>Coins:</strong> Explore real-time data on thousands of coins,
            including up-to-the-minute prices, historical performance charts, and
            comprehensive metrics.
          </li>
          <li>
            <strong>Watchlist:</strong> Personalize your watchlist to track the coins that
            matter most to you, and receive instant alerts on price fluctuations, market
            trends, and breaking news.
          </li>
          <li>
            <strong>Portfolio:</strong> Take command of your crypto investments with our
            Portfolio feature, managing and monitoring your holdings across various
            wallets and exchanges.
          </li>
          <li>
            <strong>Exchanges:</strong> Simplify and streamline your trading activities by
            connecting seamlessly with multiple exchanges, providing a centralized view of
            your operations.
          </li>
          <li>
            <strong>News:</strong> Stay ahead of the curve with curated articles, market
            analyses, and updates from reputable sources within the crypto space.
          </li>
          <li>
            <strong>Compare:</strong> Refine and optimize your investment strategy with
            our powerful Compare feature, evaluating the performance of different coins,
            portfolios, or exchanges side by side.
          </li>
        </ul>

        <h3
          className={`text-lg font-bold mb-2 ${
            currentTheme === "dark" ? "text-mainBg" : "text-filter"
          }`}
        >
          Why Choose CoinTracking Pro?
        </h3>
        <p
          className={`mb-4 ${currentTheme === "dark" ? "text-mainBg" : "text-gray-700"}`}
        >
          CoinTracking Pro is not just a platform; it's a cutting-edge solution for the
          modern crypto enthusiast. Our commitment to innovation is reflected in the
          following key elements:
        </p>

        <ul
          className={`mb-4 list-disc ml-6 text-sm ${
            currentTheme === "dark" ? "text-mainBg" : "text-filter"
          }`}
        >
          <li>
            <strong>Intuitive Interface:</strong> Our platform is designed to be
            intuitive, ensuring a seamless experience for both novice and experienced
            users.
          </li>
          <li>
            <strong>Holistic Data Access:</strong> Gain access to comprehensive data, from
            real-time prices to historical trends, empowering you with the information
            needed to make strategic decisions.
          </li>
          <li>
            <strong>Top-Tier Security:</strong> Your security is our top priority. Benefit
            from advanced security measures to protect your data and assets, providing you
            with peace of mind in your crypto journey.
          </li>
          <li>
            <strong>Advanced Analytics:</strong> Leverage our advanced analytics tools to
            gain valuable insights into your portfolio and the broader cryptocurrency
            market, enhancing your decision-making capabilities in an ever-changing
            landscape.
          </li>
        </ul>

        <h3
          className={`text-lg font-bold mb-2 ${
            currentTheme === "dark" ? "text-mainBg" : "text-filter"
          }`}
        >
          Join the Revolution
        </h3>
        <p
          className={`mb-4 ${currentTheme === "dark" ? "text-mainBg" : "text-gray-700"}`}
        >
          CoinTracking Pro isn't just a platform; it's a revolution in cryptocurrency
          management. Join us and experience a new era where innovation, personalization,
          and empowerment converge to redefine how you interact with the crypto landscape.
        </p>

        <p
          className={`mb-4 ${currentTheme === "dark" ? "text-mainBg" : "text-gray-700"}`}
        >
          CoinTracking Pro - Your Catalyst in Crypto Evolution. Revolutionize your
          approach. Revolutionize your results. Join us today!
        </p>

        <p
          className={`italic ${
            currentTheme === "dark" ? "text-mainBg" : "text-gray-700"
          }`}
        >
          CoinTracking Pro Team
        </p>
      </div>
    </div>
  );
};

export default AboutUs;
