import React, { useEffect } from "react";
import MainIcos from "../components/MainIcos/MainIcos";

const IcosDetail = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <MainIcos />
    </div>
  );
};

export default IcosDetail;
