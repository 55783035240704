import { createSlice } from "@reduxjs/toolkit";

// initial state
const initialState = {
  loginIsVisible: false,
  registerIsVisible: false,
  customizeIsVisible: false,
  modalIsVisible: false,
  filterIsVisible: false,
  addCoinIsVisible: false,

  // portfolio popups

  portfolioCreateIsVisible: false,
  portfolioRemoveIsVisible: false,
  portfolioEditIsVisible: false,
  transactionIsVisible: false,

  // transaction popups

  TransactionRemoveIsVisible: false,

  // singleTransactionRemove

  singleTransactionRemoveIsVisible: false,
  singleTransactionEditIsVisible: false,
  // transaction popups
  transactionPopupIsVisible: false,
  // icos popups
  ongoingProjectIsVisible: false,
  upcomingProjectIsVisible: false,
  // transaction note
  transactionNotePopupIsVisible: false,

  // social media share
  socialMediaSharingIsVisible: false,

  // chart and trading view
  tradingViewChart: false,

  // coin tags
  coinTagsVisible: false,

  // user profile
  userProfilePopup: false,

  // Email Verification
  emailVerification:false,

  // forgot password
  forgotPassword:false,

  // reset password
  resetPassword:false,

  // remove all watchlist
  removeAllWatchlist: false,

  // localStorage popup
  removeLocalStorageWatchList: false
};

const popupSlice = createSlice({
  name: "popup",
  initialState,
  reducers: {
    openLogin: state => {
      state.loginIsVisible = true;
      state.modalIsVisible = true;
    },
    closeLogin: state => {
      state.loginIsVisible = false;
      state.modalIsVisible = false;
    },
    openRegister: state => {
      state.registerIsVisible = true;
      state.modalIsVisible = true;
    },
    closeRegister: state => {
      state.registerIsVisible = false;
      state.modalIsVisible = false;
    },
    openCustomize: state => {
      state.customizeIsVisible = true;
      state.modalIsVisible = true;
    },
    closeCustomize: state => {
      state.customizeIsVisible = false;
      state.modalIsVisible = false;
    },
    openFilter: state => {
      state.filterIsVisible = true;
      state.modalIsVisible = true;
    },
    closeFilter: state => {
      state.filterIsVisible = false;
      state.modalIsVisible = false;
    },
    openAddCoin: state => {
      state.addCoinIsVisible = true;
      state.modalIsVisible = true;
    },
    closeAddCoin: state => {
      state.addCoinIsVisible = false;
      state.modalIsVisible = false;
    },

    // portfolio popups

    openPortfolioCreate: state => {
      state.portfolioCreateIsVisible = true;
      state.modalIsVisible = true;
    },
    closePortfolioCreate: state => {
      state.portfolioCreateIsVisible = false;
      state.modalIsVisible = false;
    },
    openPortfolioRemove: state => {
      state.portfolioRemoveIsVisible = true;
      state.modalIsVisible = true;
    },
    closePortfolioRemove: state => {
      state.portfolioRemoveIsVisible = false;
      state.modalIsVisible = false;
    },
    openPortfolioEdit: state => {
      state.portfolioEditIsVisible = true;
      state.modalIsVisible = true;
    },
    closePortfolioEdit: state => {
      state.portfolioEditIsVisible = false;
      state.modalIsVisible = false;
    },
    openTransaction: state => {
      state.transactionIsVisible = true;
      state.modalIsVisible = true;
    },
    closeTransaction: state => {
      state.transactionIsVisible = false;
      state.modalIsVisible = false;
    },

    closeModal: state => {
      state.modalIsVisible = false;
    },

    // transaction popups
    openTransactionRemove: state => {
      state.TransactionRemoveIsVisible = true;
      state.modalIsVisible = true;
    },
    closeTransactionRemove: state => {
      state.TransactionRemoveIsVisible = false;
      state.modalIsVisible = false;
    },

    // transaction note
    openTransactionNotePopup: state => {
      state.transactionNotePopupIsVisible = true;
      state.modalIsVisible = true;
    },
    closeTransactionNotePopup: state => {
      state.transactionNotePopupIsVisible = false;
      state.modalIsVisible = false;
    },

    // single transaction
    openSingleTransactionRemove: state => {
      state.singleTransactionRemoveIsVisible = true;
      state.modalIsVisible = true;
    },
    closeSingleTransactionRemove: state => {
      state.singleTransactionRemoveIsVisible = false;
      state.modalIsVisible = false;
    },
    openSingleTransactionEdit: state => {
      state.singleTransactionEditIsVisible = true;
      state.modalIsVisible = true;
    },
    closeSingleTransactionEdit: state => {
      state.singleTransactionEditIsVisible = false;
      state.modalIsVisible = false;
    },
    // transaction popups
    openTransactionPopup: state => {
      state.transactionPopupIsVisible = true;
      state.modalIsVisible = true;
    },
    closeTransactionPopup: state => {
      state.transactionPopupIsVisible = false;
      state.modalIsVisible = false;
    },
    // icos popups
    openOngoingProject: state => {
      state.ongoingProjectIsVisible = true;
      state.modalIsVisible = true;
    },
    closeOngoingProject: state => {
      state.ongoingProjectIsVisible = false;
      state.modalIsVisible = false;
    },
    openUpcomingProject: state => {
      state.upcomingProjectIsVisible = true;
      state.modalIsVisible = true;
    },
    closeUpcomingProject: state => {
      state.upcomingProjectIsVisible = false;
      state.modalIsVisible = false;
    },

    // socail media sharing
    socialMediaSharing: state => {
      state.socialMediaSharingIsVisible = true;
      state.modalIsVisible = true;
    },
    closeSocialMediaSharing: state => {
      state.socialMediaSharingIsVisible = false;
      state.modalIsVisible = false;
    },

    // trading view
    tradingView: state => {
      state.tradingViewChart = true;
      state.modalIsVisible = true;
    },
    closeTadingView: state => {
      state.tradingViewChart = false;
      state.modalIsVisible = false;
    },
    // coin tags view
    coinTagsView: state => {
      state.coinTagsVisible = true;
      state.modalIsVisible = true;
    },
    closecoinTagsView: state => {
      state.coinTagsVisible = false;
      state.modalIsVisible = false;
    },

    // user profile
    openUserProfileView: state => {
      state.userProfilePopup = true;
      state.modalIsVisible = true;
    },
    closeUserProfileView: state => {
      state.userProfilePopup = false;
      state.modalIsVisible = false;
    },
    // email verification
    openEmailVerification: state => {
      state.emailVerification = true;
      state.modalIsVisible = true;
    },
    closeEmailVerification: state => {
      state.emailVerification = false;
      state.modalIsVisible = false;
    },
    openForgotPassword: state => {
      state.forgotPassword = true;
      state.modalIsVisible = true;
    },
    closeForgotPassword: state => {
      state.forgotPassword = false;
      state.modalIsVisible = false;
    },
    openResetPassword: state => {
      state.resetPassword = true;
      state.modalIsVisible = true;
    },
    closeResetPassword: state => {
      state.resetPassword = false;
      state.modalIsVisible = false;
    },
    openRemoveWatchList: state => {
      state.removeAllWatchlist = true;
      state.modalIsVisible = true;
    },
    closeRemoveWatchList: state => {
      state.removeAllWatchlist = false;
      state.modalIsVisible = false;
    },
    // remove from local storage
    openRemoveLocalStorageWatchList: state => {
      state.removeLocalStorageWatchList = true;
      state.modalIsVisible = true;
    },
    closeRemoveLocalStorageWatchList: state => {
      state.removeLocalStorageWatchList = false;
      state.modalIsVisible = false;
    }
  }
});

export const {
  openLogin,
  closeLogin,
  openRegister,
  closeRegister,
  closeModal,
  openCustomize,
  closeCustomize,
  openFilter,
  closeFilter,
  openAddCoin,
  closeAddCoin,

  // portfolio popups
  openPortfolioCreate,
  closePortfolioCreate,
  openPortfolioRemove,
  closePortfolioRemove,
  openPortfolioEdit,
  closePortfolioEdit,
  openTransaction,
  closeTransaction,

  // transaction popups
  openTransactionRemove,
  closeTransactionRemove,

  // transaction note
  openTransactionNotePopup,
  closeTransactionNotePopup,

  // transaction
  openTransactionPopup,
  closeTransactionPopup,
  // single transaction popups
  openSingleTransactionRemove,
  closeSingleTransactionRemove,
  openSingleTransactionEdit,
  closeSingleTransactionEdit,
  // icos projects
  openOngoingProject,
  closeOngoingProject,
  openUpcomingProject,
  closeUpcomingProject,
  // social media sharing
  socialMediaSharing,
  closeSocialMediaSharing,

  // trading view
  tradingView,
  closeTadingView,

  // coin tags
  coinTagsView,
  closecoinTagsView,
  // user profile
  openUserProfileView,
  closeUserProfileView,
  // user email verification
  openEmailVerification,
  closeEmailVerification,
  // forgot password
  openForgotPassword,
  closeForgotPassword,

  // reset password
  openResetPassword,
  closeResetPassword,
  // watchlist popup
  openRemoveWatchList,
  closeRemoveWatchList,
  // remove from local storage
  openRemoveLocalStorageWatchList,
  closeRemoveLocalStorageWatchList
} = popupSlice.actions;
export default popupSlice.reducer