export const data = [
  {
    Date: "2010-01",
    'Market Cap': 1998,
  },
  {
    Date: "2010-02",
    'Market Cap': 1850,
  },
  {
    Date: "2010-03",
    'Market Cap': 1720,
  },
  {
    Date: "2010-04",
    'Market Cap': 1818,
  },
  {
    Date: "2010-05",
    'Market Cap': 1920,
  },
  {
    Date: "2010-06",
    'Market Cap': 1802,
  },
  {
    Date: "2010-07",
    'Market Cap': 1945,
  },
  {
    Date: "2010-08",
    'Market Cap': 1856,
  },
  {
    Date: "2010-09",
    'Market Cap': 2107,
  },
  {
    Date: "2010-10",
    'Market Cap': 2140,
  },
  {
    Date: "2010-11",
    'Market Cap': 2311,
  },
  {
    Date: "2010-12",
    'Market Cap': 1972,
  },
  {
    Date: "2011-01",
    'Market Cap': 1760,
  },
  {
    Date: "2011-02",
    'Market Cap': 1824,
  },
  {
    Date: "2011-03",
    'Market Cap': 1801,
  },
  {
    Date: "2011-04",
    'Market Cap': 2001,
  },
  {
    Date: "2011-05",
    'Market Cap': 1640,
  },
  {
    Date: "2011-06",
    'Market Cap': 1502,
  },
  {
    Date: "2011-07",
    'Market Cap': 1621,
  },
  {
    Date: "2011-08",
    'Market Cap': 1480,
  },
  {
    Date: "2011-09",
    'Market Cap': 1549,
  },
  {
    Date: "2011-10",
    'Market Cap': 1390,
  },
  {
    Date: "2011-11",
    'Market Cap': 1325,
  },
  {
    Date: "2011-12",
    'Market Cap': 1250,
  },
  {
    Date: "2012-01",
    'Market Cap': 1394,
  },
  {
    Date: "2012-02",
    'Market Cap': 1406,
  },
  {
    Date: "2012-03",
    'Market Cap': 1578,
  },
  {
    Date: "2012-04",
    'Market Cap': 1465,
  },
  {
    Date: "2012-05",
    'Market Cap': 1689,
  },
  {
    Date: "2012-06",
    'Market Cap': 1755,
  },
  {
    Date: "2012-07",
    'Market Cap': 1495,
  },
  {
    Date: "2012-08",
    'Market Cap': 1508,
  },
  {
    Date: "2012-09",
    'Market Cap': 1433,
  },
  {
    Date: "2012-10",
    'Market Cap': 1344,
  },
  {
    Date: "2012-11",
    'Market Cap': 1201,
  },
  {
    Date: "2012-12",
    'Market Cap': 1065,
  },
  {
    Date: "2013-01",
    'Market Cap': 1255,
  },
  {
    Date: "2013-02",
    'Market Cap': 1429,
  },
  {
    Date: "2013-03",
    'Market Cap': 1398,
  },
  {
    Date: "2013-04",
    'Market Cap': 1678,
  },
  {
    Date: "2013-05",
    'Market Cap': 1524,
  },
  {
    Date: "2013-06",
    'Market Cap': 1688,
  },
  {
    Date: "2013-07",
    'Market Cap': 1500,
  },
  {
    Date: "2013-08",
    'Market Cap': 1670,
  },
  {
    Date: "2013-09",
    'Market Cap': 1734,
  },
  {
    Date: "2013-10",
    'Market Cap': 1699,
  },
  {
    Date: "2013-11",
    'Market Cap': 1508,
  },
  {
    Date: "2013-12",
    'Market Cap': 1680,
  },
  {
    Date: "2014-01",
    'Market Cap': 1750,
  },
  {
    Date: "2014-02",
    'Market Cap': 1602,
  },
  {
    Date: "2014-03",
    'Market Cap': 1834,
  },
  {
    Date: "2014-04",
    'Market Cap': 1722,
  },
  {
    Date: "2014-05",
    'Market Cap': 1430,
  },
  {
    Date: "2014-06",
    'Market Cap': 1280,
  },
  {
    Date: "2014-07",
    'Market Cap': 1367,
  },
  {
    Date: "2014-08",
    'Market Cap': 1155,
  },
  {
    Date: "2014-09",
    'Market Cap': 1289,
  },
  {
    Date: "2014-10",
    'Market Cap': 1104,
  },
  {
    Date: "2014-11",
    'Market Cap': 1246,
  },
  {
    Date: "2014-12",
    'Market Cap': 1098,
  },
  {
    Date: "2015-01",
    'Market Cap': 1189,
  },
  {
    Date: "2015-02",
    'Market Cap': 1276,
  },
  {
    Date: "2015-03",
    'Market Cap': 1033,
  },
  {
    Date: "2015-04",
    'Market Cap': 956,
  },
  {
    Date: "2015-05",
    'Market Cap': 845,
  },
  {
    Date: "2015-06",
    'Market Cap': 1089,
  },
  {
    Date: "2015-07",
    'Market Cap': 944,
  },
  {
    Date: "2015-08",
    'Market Cap': 1043,
  },
  {
    Date: "2015-09",
    'Market Cap': 893,
  },
  {
    Date: "2015-10",
    'Market Cap': 840,
  },
  {
    Date: "2015-11",
    'Market Cap': 934,
  },
  {
    Date: "2015-12",
    'Market Cap': 810,
  },
  {
    Date: "2016-01",
    'Market Cap': 782,
  },
  {
    Date: "2016-02",
    'Market Cap': 1089,
  },
  {
    Date: "2016-03",
    'Market Cap': 745,
  },
  {
    Date: "2016-04",
    'Market Cap': 680,
  },
  {
    Date: "2016-05",
    'Market Cap': 802,
  },
  {
    Date: "2016-06",
    'Market Cap': 697,
  },
  {
    Date: "2016-07",
    'Market Cap': 583,
  },
  {
    Date: "2016-08",
    'Market Cap': 456,
  },
  {
    Date: "2016-09",
    'Market Cap': 524,
  },
  {
    Date: "2016-10",
    'Market Cap': 398,
  },
  {
    Date: "2016-11",
    'Market Cap': 278,
  },
  {
    Date: "2016-12",
    'Market Cap': 195,
  },
  {
    Date: "2017-01",
    'Market Cap': 145,
  },
  {
    Date: "2017-02",
    'Market Cap': 207,
  },
];