import React,{useEffect} from "react";
import Modal from "../../utils/Modal";
import { selectCurrentTheme } from "../../store/slices/themeSlice";
import { useSelector,useDispatch } from "react-redux";
import { useForgotPasswordMutation } from "../../store/services/services";
import { closeForgotPassword, openEmailVerification } from "../../store/slices/popupSlice";
import { toast } from "react-toastify";

const ForgotPasswordPopup = ({ userEmail,setUserEmail }) => {
  const currentTheme = useSelector(selectCurrentTheme);
  //   const [userEmail, setUserEmail] = useState();
  const [
    triggerForgetPassword,
    { data, isError, error, isLoading, isSuccess }
  ] = useForgotPasswordMutation();
  const dispatch = useDispatch();

  const handleForgetPassword = e => {
    e.preventDefault();
    triggerForgetPassword({ email: userEmail })
      .then(res => console.log("Res", res))
      .catch(err => console.log("err", err));
    };
    useEffect(() => {
      if (isSuccess && data) {
        dispatch(closeForgotPassword());
        dispatch(openEmailVerification());
      // dispatch(openLogin());
      toast.success("Email Reset Successfully");
    }else if(isError){
      console.log(error)
       toast.error(error.data.message);
    }
  },[isSuccess, isError]);

    useEffect(() => {
      setUserEmail("");
    }, []);
  return (
    <Modal maxWidth="750">
      <div className="px-8 py-6 relative">
        <h2
          className={`text-2xl font-semibold mb-4 ${
            currentTheme === "dark" ? " text-mainBg" : "text-filter"
          }`}
        >
          Forgot Password
        </h2>
        <p
          className={`text-gray-600 mb-4 ${
            currentTheme === "dark" ? " text-mainBg" : "text-filter"
          }`}
        >
          Enter your email address below
        </p>

        <form className="flex flex-col space-y-4">
          <div className="w-full">
            <label
              htmlFor="quantiy"
              className={`font-medium  ${
                currentTheme === "dark" ? " text-mainBg" : "text-secondary"
              }`}
            >
              Email:
            </label>
            <div className="flex items-center border-2 mt-3 border-inputBorder h-[45px] sm:h-[30px] rounded-[10px] px-3 py-1 gap-3 focus-within:border-primary transition-all duration-200">
              <input
                className={`border-0 outline-0 overflow-hidden bg-transparent text-base w-full ${
                  currentTheme === "dark"
                    ? "bg-inputColor text-mainBg"
                    : "bg-mainBg text-filter"
                }`}
                type="text"
                placeholder="email your email"
                value={userEmail}
                onChange={e => setUserEmail(e.target.value)}
              />
            </div>
          </div>

          <button
            type="submit"
            className={`px-4 py-2 rounded bg-primary ${
              isLoading ? "opacity-50 cursor-not-allowed" : ""
            }`}
            onClick={handleForgetPassword}
            disabled={isLoading}
          >
            {isLoading ? "Resetting..." : "Reset Password"}
          </button>
        </form>
      </div>
    </Modal>
  );
};

export default ForgotPasswordPopup;
