import React, { useState, useRef, useEffect } from "react";
import BitcoinLogo from "../../assets/icons/bitcoin-logo.svg";
import BuyIcon from "../../assets/icons/buy-arrow.svg";
import DropDown from "../../assets/icons/drop-down.svg";
import DropdownMenu from "../../utils/DropdownMenu/DropdownMenu";
import BuyCrypto from "../../assets/images/buy-crypto.svg";
import Binance from "../../assets/icons/binance.svg";
import ByBit from "../../assets/images/bybit.svg";
import ByBitIcon from "../../assets/icons/buybit-icon.svg";

const IcosDetailCardMenu = () => {
  const [buyToggle, setBuyToggle] = useState(false);
  const [exchangeToggle, setExchangeToggle] = useState(false);
  const [gamingToggle, setGamingToggle] = useState(false);
  const [earnToggle, setEarnToggle] = useState(false);

  const buyDropdownRef = useRef();
  const exchangeDropdownRef = useRef();
  const gamingDropdownRef = useRef();
  const earnDropdownRef = useRef();

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (
      buyDropdownRef.current &&
      !buyDropdownRef.current.contains(event.target)
    ) {
      setBuyToggle(false);
    }
    if (
      exchangeDropdownRef.current &&
      !exchangeDropdownRef.current.contains(event.target)
    ) {
      setExchangeToggle(false);
    }
    if (
      gamingDropdownRef.current &&
      !gamingDropdownRef.current.contains(event.target)
    ) {
      setGamingToggle(false);
    }
    if (
      earnDropdownRef.current &&
      !earnDropdownRef.current.contains(event.target)
    ) {
      setEarnToggle(false);
    }
  };

  return (
    <div className="lg:hidden">
      <div className="relative" ref={buyDropdownRef}>
        <div
          className="flex mt-[7rem] items-center lg:min-w-[130px] justify-center cursor-pointer gap-2 py-4 md:py-3 bg-primary text-mainBg shadow-navbar-button rounded-full text-center"
          onClick={() => setBuyToggle(!buyToggle)}
        >
          Buy
          <img src={BuyIcon} alt="buy" />
        </div>
        {buyToggle && (
          <DropdownMenu>
            <div className="border-b pb-5">
              <div className="flex mr-24 gap-2">
                <img src={BuyCrypto} alt="buy-crypto" />

                <div>
                  <div className="flex justify-between items-center w-[350px]">
                    <div className="flex gap-1">
                      <img src={Binance} alt="binance" className="h-[20px]" />
                      <p className="font-semibold text-sm">Binance</p>
                    </div>

                    <div className="flex gap-1 mr-8">
                      <img src={BitcoinLogo} alt="logo" />
                      <p className="text-sm text-grey font-semibold">Sponsored</p>
                    </div>
                  </div>

                  <div>
                    <p className="font-semibold">Buy crypto with Fees as Low as 0%</p>

                    <p className="text-base pt-1 text-grey">
                      Buy crypto with a bank transfer, credit or debit card, P2P exchange,
                      and more. Not investment advice.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex mt-5 gap-2">
              <img src={ByBit} alt="by-bit" />

              <div>
                <div className="flex justify-between items-center w-[350px]">
                  <div className="flex gap-1">
                    <img src={ByBitIcon} alt="by-bit-icon" />
                    <p className="font-semibold text-sm">ByBit</p>
                  </div>

                  <div className="flex gap-1 mr-8">
                    <img src={BitcoinLogo} alt="logo" />
                    <p className="text-sm text-grey font-semibold">Sponsored</p>
                  </div>
                </div>

                <div>
                  <p className="font-semibold">Buy crypto with Fees as Low as 0%</p>

                  <p className="text-base pt-1 text-grey">
                    Buy crypto with a bank transfer, credit or debit card, P2P exchange,
                    and more. Not investment advice.
                  </p>
                </div>
              </div>
            </div>
          </DropdownMenu>
        )}
      </div>
      <div className="relative" ref={exchangeDropdownRef}>
        <div
          className="flex items-center mt-4 lg:min-w-[130px] justify-center gap-2 py-4 md:py-3 cursor-pointer bg-mainBg text-icosBlackColor shadow-navbar-button rounded-full text-center"
          onClick={() => setExchangeToggle(!exchangeToggle)}
        >
          Exchange
          <img src={DropDown} alt="buy" />
        </div>
        {exchangeToggle && (
          <DropdownMenu>
            <div className="border-b pb-5">
              <div className="flex mr-24 gap-2">
                <img src={BuyCrypto} alt="buy-crypto" />

                <div>
                  <div className="flex justify-between items-center w-[350px]">
                    <div className="flex gap-1">
                      <img src={Binance} alt="binance" className="h-[20px]" />
                      <p className="font-semibold text-sm">Binance</p>
                    </div>

                    <div className="flex gap-1 mr-8">
                      <img src={BitcoinLogo} alt="logo" />
                      <p className="text-sm text-grey font-semibold">Sponsored</p>
                    </div>
                  </div>

                  <div>
                    <p className="font-semibold">Buy crypto with Fees as Low as 0%</p>

                    <p className="text-base pt-1 text-grey">
                      Buy crypto with a bank transfer, credit or debit card, P2P exchange,
                      and more. Not investment advice.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex mt-5 gap-2">
              <img src={ByBit} alt="by-bit" />

              <div>
                <div className="flex justify-between items-center w-[350px]">
                  <div className="flex gap-1">
                    <img src={ByBitIcon} alt="by-bit-icon" />
                    <p className="font-semibold text-sm">ByBit</p>
                  </div>

                  <div className="flex gap-1 mr-8">
                    <img src={BitcoinLogo} alt="logo" />
                    <p className="text-sm text-grey font-semibold">Sponsored</p>
                  </div>
                </div>

                <div>
                  <p className="font-semibold">Buy crypto with Fees as Low as 0%</p>

                  <p className="text-base pt-1 text-grey">
                    Buy crypto with a bank transfer, credit or debit card, P2P exchange,
                    and more. Not investment advice.
                  </p>
                </div>
              </div>
            </div>
          </DropdownMenu>
        )}
      </div>
      <div className="relative" ref={gamingDropdownRef}>
        <div
          className="flex items-center mt-4 lg:min-w-[130px] justify-center cursor-pointer gap-2 py-4 md:py-3 bg-mainBg text-icosBlackColor shadow-navbar-button rounded-full text-center"
          onClick={() => setGamingToggle(!gamingToggle)}
        >
          Gaming
          <img src={DropDown} alt="buy" />
        </div>
        {gamingToggle && (
          <DropdownMenu>
            <div className="border-b pb-5">
              <div className="flex mr-24 gap-2">
                <img src={BuyCrypto} alt="buy-crypto" />

                <div>
                  <div className="flex justify-between items-center w-[350px]">
                    <div className="flex gap-1">
                      <img src={Binance} alt="binance" className="h-[20px]" />
                      <p className="font-semibold text-sm">Binance</p>
                    </div>

                    <div className="flex gap-1 mr-8">
                      <img src={BitcoinLogo} alt="logo" />
                      <p className="text-sm text-grey font-semibold">Sponsored</p>
                    </div>
                  </div>

                  <div>
                    <p className="font-semibold">Buy crypto with Fees as Low as 0%</p>

                    <p className="text-base pt-1 text-grey">
                      Buy crypto with a bank transfer, credit or debit card, P2P exchange,
                      and more. Not investment advice.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex mt-5 gap-2">
              <img src={ByBit} alt="by-bit" />

              <div>
                <div className="flex justify-between items-center w-[350px]">
                  <div className="flex gap-1">
                    <img src={ByBitIcon} alt="by-bit-icon" />
                    <p className="font-semibold text-sm">ByBit</p>
                  </div>

                  <div className="flex gap-1 mr-8">
                    <img src={BitcoinLogo} alt="logo" />
                    <p className="text-sm text-grey font-semibold">Sponsored</p>
                  </div>
                </div>

                <div>
                  <p className="font-semibold">Buy crypto with Fees as Low as 0%</p>

                  <p className="text-base pt-1 text-grey">
                    Buy crypto with a bank transfer, credit or debit card, P2P exchange,
                    and more. Not investment advice.
                  </p>
                </div>
              </div>
            </div>
          </DropdownMenu>
        )}
      </div>
      <div className="relative" ref={earnDropdownRef}>
        <div
          className="flex items-center mt-4 lg:min-w-[130px] justify-center gap-2 py-4 md:py-3 bg-mainBg text-icosBlackColor cursor-pointer shadow-navbar-button rounded-full text-center"
          onClick={() => setEarnToggle(!earnToggle)}
        >
          Earn Crypto
          <img src={DropDown} alt="buy" />
        </div>
        {earnToggle && (
          <DropdownMenu>
            <div className="border-b pb-5">
              <div className="flex mr-24 gap-2">
                <img src={BuyCrypto} alt="buy-crypto" />

                <div>
                  <div className="flex justify-between items-center w-[350px]">
                    <div className="flex gap-1">
                      <img src={Binance} alt="binance" className="h-[20px]" />
                      <p className="font-semibold text-sm">Binance</p>
                    </div>

                    <div className="flex gap-1 mr-8">
                      <img src={BitcoinLogo} alt="logo" />
                      <p className="text-sm text-grey font-semibold">Sponsored</p>
                    </div>
                  </div>

                  <div>
                    <p className="font-semibold">Buy crypto with Fees as Low as 0%</p>

                    <p className="text-base pt-1 text-grey">
                      Buy crypto with a bank transfer, credit or debit card, P2P exchange,
                      and more. Not investment advice.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex mt-5 gap-2">
              <img src={ByBit} alt="by-bit" />

              <div>
                <div className="flex justify-between items-center w-[350px]">
                  <div className="flex gap-1">
                    <img src={ByBitIcon} alt="by-bit-icon" />
                    <p className="font-semibold text-sm">ByBit</p>
                  </div>

                  <div className="flex gap-1 mr-8">
                    <img src={BitcoinLogo} alt="logo" />
                    <p className="text-sm text-grey font-semibold">Sponsored</p>
                  </div>
                </div>

                <div>
                  <p className="font-semibold">Buy crypto with Fees as Low as 0%</p>

                  <p className="text-base pt-1 text-grey">
                    Buy crypto with a bank transfer, credit or debit card, P2P exchange,
                    and more. Not investment advice.
                  </p>
                </div>
              </div>
            </div>
          </DropdownMenu>
        )}
      </div>
    </div>
  );
};

export default IcosDetailCardMenu;
