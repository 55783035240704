import React from "react";
import GoldLogo from "../../assets/images/gold.svg";
import BlackArrow from "../../assets/icons/black-arrow.svg";
import GoldChart from "../../assets/images/gold-chart.svg";
import AaveChart from "../../assets/images/aaveChart.svg";

const User_Data = [
  {
    id: 1,
    logo: GoldLogo,
    name: "GoldMint",
    price: 0.0619,
    percentPrice: -35.01,
    arrow: BlackArrow,
    chartImg: GoldChart,
  },
  {
    id: 2,
    logo: GoldLogo,
    name: "GoldMint",
    price: 57483,
    percentPrice: -36.01,
    arrow: BlackArrow,
    chartImg: AaveChart,
  },
  {
    id: 3,
    logo: GoldLogo,
    name: "GoldMint",
    price: 298123,
    percentPrice: -37.01,
    arrow: BlackArrow,
    chartImg: GoldChart,
  },
  {
    id: 4,
    logo: GoldLogo,
    name: "GoldMint",
    price: 98324,
    percentPrice: -38.01,
    arrow: BlackArrow,
    chartImg: AaveChart,
  },
];
const UserWatch = () => {
  return (
    <div className="mt-10 md:mt-6">
      <h1 className="text-2xl font-semibold sm:text-sm">People Also Watch</h1>
      <div className="flex flex-wrap mt-8 gap-5">
        {User_Data.map((items) => {
          const { logo, name, price, percentPrice, arrow, chartImg } = items;
          return (
            <div
              key={items.id}
              className="w-[23%] sm:w-full min-w-[220px] shadow-navbar-button bg-mainBg rounded-[40px] py-7 px-5 text-center"
            >
              <div className="flex items-center justify-center gap-2 pb-3 text-2xl">
                <img src={logo} alt="logo-svg" className="w-[50px]" />
                <div>
                  <p className="xs:text-base">{name}</p>
                  <p className="font-extrabold xs:text-base">{price}</p>
                </div>
              </div>
              <div className="flex py-1 px-6 bg-primary justify-center">
                <img src={arrow} alt="arrow-svg" />
                <p className="xs:text-base">{percentPrice}</p>
              </div>
              <div className="flex justify-center">
                <img src={chartImg} alt="chat-svg" className="pt-5" />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default UserWatch;
