import React, { useEffect } from "react";
import ExchangeDetailCard from "../components/ExchangeDetailCard";
import ExchangeDetailTable from "../components/ExchangeDetailTable";
import ExchangeDetailAllocation from "../components/ExchangeDetailAllocation";
import { useParams } from "react-router-dom";
import { EXCHANGE_TABLE_DATA } from "../data/tableData";

const ExchangeDetail = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { id } = useParams();

  const [filteredExchange] = EXCHANGE_TABLE_DATA.filter(
    (item) => item.id === +id
  );

  return (
    <div>
      <ExchangeDetailCard exchange={filteredExchange} />
      {/* <div className="flex lg:flex-col px-12 md:px-8 xs:px-4 mt-12 mb-8">
        <ExchangeDetailTable exchange={filteredExchange} />
        <ExchangeDetailAllocation exchange={filteredExchange} />
      </div> */}
    </div>
  );
};

export default ExchangeDetail;
