import React, { useEffect } from "react";
import ExchangeHead from "../components/ExchangeHead";
// import ExchangeMenu from "../../components/ExchangeMenu";
import ExchangeTable from "../components/ExchangeTable";

const Exchanges = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <ExchangeHead />
      {/* <ExchangeMenu /> */}
      <ExchangeTable />
    </div>
  );
};

export default Exchanges;
