import Calendar from "../assets/icons/calendar-icon.svg";
import PeopleIcon from '../assets/icons/people-icon.svg'

export const Social_Price_Estimate = [
  {
    id: 1,
    calendarIcon: Calendar,
    PeopleIcon: PeopleIcon,
    date: "3/31/2023",
    title: "Place your estimate",
    desc: "At least 1 to see how others estimate",
    vote: "0 voted",
    estimate: "Submit estimate",
  },
    {
    id: 2,
    calendarIcon: Calendar,
    PeopleIcon: PeopleIcon,
    date: "3/31/2023",
    title: "Place your estimate",
    desc: "At least 1 to see how others estimate",
    vote: "0 voted",
    estimate: "Submit estimate",
  },
    {
    id: 3,
    calendarIcon: Calendar,
    PeopleIcon: PeopleIcon,
    date: "3/31/2023",
    title: "Place your estimate",
    desc: "At least 1 to see how others estimate",
    vote: "0 voted",
    estimate: "Submit estimate",
  },
    {
    id: 4,
    calendarIcon: Calendar,
    PeopleIcon: PeopleIcon,
    date: "3/31/2023",
    title: "Place your estimate",
    desc: "At least 1 to see how others estimate",
    vote: "0 voted",
    estimate: "Submit estimate",
  },
    {
    id: 5,
    calendarIcon: Calendar,
    PeopleIcon: PeopleIcon,
    date: "3/31/2023",
    title: "Place your estimate",
    desc: "At least 1 to see how others estimate",
    vote: "0 voted",
    estimate: "Submit estimate",
  },
    {
    id: 6,
    calendarIcon: Calendar,
    PeopleIcon: PeopleIcon,
    date: "3/31/2023",
    title: "Place your estimate",
    desc: "At least 1 to see how others estimate",
    vote: "0 voted",
    estimate: "Submit estimate",
  },
];