import React from "react";
import TwitterLogo from "../../assets/icons/twitter.svg";
import LinkedinLogo from "../../assets/icons/linkedin.svg";
import FacebookLogo from "../../assets/icons/facebook.svg";
import { Link } from "react-router-dom";
import Logo from "../../assets/images/coinlogo.png";
import DarkLogo from "../../assets/images/coinlogo-dark.png";
// import DarkLogo from "../../assets/images/coinlogo-dark.png";
import { useSelector } from "react-redux";
import { selectCurrentTheme } from "../../store/slices/themeSlice";

const Footer = () => {
  const currentTheme = useSelector(selectCurrentTheme);
  return (
    <footer className="px-12 md:px-8 xs:px-4 mt-14">
      <div
        className={`flex flex-wrap justify-between xs:flex-col border-t-[1px] py-6 ${
          currentTheme === "dark" ? "border-darkBorder" : "border-lightBorder"
        }`}
      >
        <div className="w-2/6 md:w-1/2 pr-10 md:pr-5 xs:pr-0 xs:w-full md:mb-5 xs:mb-2">
          <div className="mb-1">
            <Link to="/">
              <div className="min-w-[100px] py-4">
                <img
                  className="w-[200px] md:w-150 sm:w-100 xs:w-40"
                  src={currentTheme === "dark" ? DarkLogo : Logo}
                  alt="main-logo"
                />
              </div>
            </Link>
          </div>
          <div className="mb-5 xs:mb-5">
            <p
              className={`text-sm  ${
                currentTheme === "dark" ? "text-mainBg" : "text-textGray"
              }`}
            >
              Unleash the potential of crypto with Coin Tracking Pro — your ultimate
              companion in the decentralized world. Experience seamless tracking, deep
              insights, and secure investing. Join our thriving community today and be
              part of the future of finance powered by cryptocurrencies.
            </p>
          </div>
          <div className="flex gap-2">
            <img className="w-10 xs:w-7" src={FacebookLogo} alt="facebook-logo" />
            <img className="w-10 xs:w-7" src={TwitterLogo} alt="twitter-logo" />
            <img className="w-10 xs:w-7" src={LinkedinLogo} alt="linkedin-logo" />
          </div>
        </div>
        <div className="w-1/6 md:w-1/3 mt-4 xs:w-full xs:mb-0">
          <h3
            className={`font-semibold mb-6 xs:mb-2 ${
              currentTheme === "dark" ? "text-mainBg" : "text-filter"
            }`}
          >
            Company
          </h3>
          <ul className="text-sm">
            <Link to="/about-us">
              <li
                className={` ${currentTheme === "dark" ? "text-mainBg" : "text-filter"}`}
              >
                About Us
              </li>
            </Link>
            {/* <Link to="/career">
              <li
                className={` ${currentTheme === "dark" ? "text-mainBg" : "text-filter"}`}
              >
                Career
              </li>
            </Link> */}
            {/* <Link to="/become-investor">
              <li
                className={` ${currentTheme === "dark" ? "text-mainBg" : "text-filter"}`}
              >
                Become Investor
              </li>
            </Link> */}
          </ul>
        </div>
        <div className="w-1/6 md:w-1/3 mt-4 xs:w-full xs:mb-0">
          <h3
            className={`font-semibold mb-6 xs:mb-2 ${
              currentTheme === "dark" ? "text-mainBg" : "text-filter"
            }`}
          >
            Support
          </h3>
          <ul className="text-sm">
            <Link to="/faq">
              <li
                className={`${currentTheme === "dark" ? "text-mainBg" : "text-filter"}`}
              >
                FAQ
              </li>
            </Link>
            <Link to="/privacy-policy">
              <li
                className={`${currentTheme === "dark" ? "text-mainBg" : "text-filter"}`}
              >
                Policy
              </li>
            </Link>
          </ul>
        </div>
        <div className="w-1/6 md:w-1/3 mt-4 xs:mb-0">
          <h3
            className={`font-semibold mb-6 xs:mb-2 ${
              currentTheme === "dark" ? "text-mainBg" : "text-filter"
            }`}
          >
            Contact
          </h3>
          <ul className="text-sm">
            <li className={`${currentTheme === "dark" ? "text-mainBg" : "text-filter"}`}>
              <a href="mailto:support@bitsclan.com">contact@cointrackingpro.com</a>
            </li>
          </ul>
        </div>
      </div>
      <div
        className={`text-center border-t-[1px] py-4 ${
          currentTheme === "dark" ? "border-darkBorder" : "border-lightBorder"
        }`}
      >
        <p className="text-sm text-footerText xs:text-xs">Copyright © 2023 Bitsclan</p>
      </div>
    </footer>
  );
};

export default Footer;
