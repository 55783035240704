import UsdCoin from '../assets/images/usd-coin.svg'
import BitcoinLogo from "../assets/icons/bitcoin.svg";
import Ethereum from '../assets/icons/ethereum.svg'
import Tether from "../assets/images/tether.png";
import XRP from "../assets/images/xrp.png";
import OKB from "../assets/images/okb.png";
import Polygon from "../assets/images/polygon.png";
import Solana from "../assets/images/solana.png";
import Avalanche from "../assets/images/avalanche.png";
import Aptos from "../assets/images/aptos.png";
import Graph from "../assets/images/graph.png";
import Aave from "../assets/images/aave.png";

export const Latest_Aussie_Data = [
{
id: 1,
coinLogo: UsdCoin,
coinName: 'USD Coin',
coinTitle:'USDC',
number: '#1'
},
{
id: 2,
coinLogo: BitcoinLogo,
coinName: 'BTC Coin',
coinTitle:'BTC',
number: '#2'
},
{
id: 3,
coinLogo: Ethereum,
coinName: 'ETH Coin',
coinTitle:'ETH',
number: '#3'
},
{
id: 4,
coinLogo: Tether,
coinName: 'USDC Coin',
coinTitle:'USDC',
number: '#4'
},
{
id: 5,
coinLogo: XRP,
coinName: 'XRP Coin',
coinTitle:'XRP',
number: '#5'
},
{
id: 6,
coinLogo: OKB,
coinName: 'OKB Coin',
coinTitle:'OKB',
number: '#6'
},
{
id: 7,
coinLogo: Polygon,
coinName: 'MATIC Coin',
coinTitle:'MATIC',
number: '#7'
},
{
id: 8,
coinLogo: Solana,
coinName: 'SOL Coin',
coinTitle:'SOL',
number: '#8'
},
{
id: 9,
coinLogo: Avalanche,
coinName: 'AVAX Coin',
coinTitle:'AVAX',
number: '#9'
},
{
id: 10,
coinLogo: Aptos,
coinName: 'APT Coin',
coinTitle:'APT',
number: '#10'
},
{
id: 11,
coinLogo: Graph,
coinName: 'GRT Coin',
coinTitle:'GRT',
number: '#11'
},
{
id: 12,
coinLogo: Aave,
coinName: 'AAVE Coin',
coinTitle:'AAVE',
number: '#12'
},
]