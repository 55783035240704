import { createSlice } from "@reduxjs/toolkit";

// initial state
const initialState = {
  watchlistCoins: [],
  watchlistMount: true
};

const watchlistSlice = createSlice({
  name: "watchlistCoins",
  initialState,
  reducers: {
    addWatchlist: (state, action) => {
      state.watchlistCoins.includes(...action.payload)
        ? (state.watchlistCoins = state.watchlistCoins.filter(
            item => !action.payload.includes(item)
          ))
        : (state.watchlistCoins = [...state.watchlistCoins, ...action.payload]);
    },
    removeWatchlist: (state, action) => {
      state.watchlistCoins = state.watchlistCoins.filter(
        item => !action.payload.includes(item)
      );
    },
    resetWatchlist: state => {
      state.watchlistCoins = [];
    },
    watchlistMountRemove: state => {
      state.watchlistMount = false;
    },
    watchlistMountAdd: state => {
      state.watchlistMount = true;
    }
  }
});

export const { addWatchlist, resetWatchlist, watchlistMountRemove, watchlistMountAdd } =
  watchlistSlice.actions;

export default watchlistSlice.reducer;
