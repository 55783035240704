import { faExpand } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import TradingViewWidget, { Themes } from "react-tradingview-widget";
import { Line } from "@ant-design/plots";
import ReactApexChart from "react-apexcharts";
import { useGetChartDataQuery } from "../../store/services/services";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { useSelector } from "react-redux";
import TradingViewChartModal from "../TradingViewChartModal/TradingViewChartModal";
import { tradingView } from "../../store/slices/popupSlice";
import { useDispatch } from "react-redux";
import { selectCurrentTheme } from "../../store/slices/themeSlice";

const CHART_MENU_DATA = [
  { id: 1, name: "Price", value: "price" },
  { id: 3, name: "Trading View", value: "trading-view" }
];
const CHART_Type = [
  { id: 1, name: "Line", value: "line" },
  { id: 3, name: "Candle", value: "candle" }
];
const TIME_PERIOD_DATA = [
  { name: "1D", value: "1d" },
  { name: "7D", value: "7d" },
  { name: "1M", value: "30d" },
  { name: "3M", value: "3m" },
  { name: "1Y", value: "1y" },
  { name: "ALL", value: "all" }
];

const CoinDetailChart = ({ coin, coin_name }) => {
  const [menuActive, setMenuActive] = useState("price");
  const [activeChartType, setActiveChartType] = useState("line");
  const [hoveredData, setHoveredData] = useState(null);

  const [periodActive, setPeriodActive] = useState("1d");
  const dispatch = useDispatch();
  const [tradingViewSymbol, setTradingViewSymbol] = useState(
    `BINANCE:${coin_name}USDTPERP`
  );
  const currentTheme = useSelector(selectCurrentTheme);
  const {
    data: chartData,
    isLoading,
    isFetching
  } = useGetChartDataQuery({ coin_name, periodActive });

  const apiData = chartData?.Data || [];
  const transformedData = [];
  const transformedColumnData = [];
  // convert date and time
  if (apiData) {
    apiData.forEach(item => {
      const timestamp = item.time * 1000;
      const date = new Date(timestamp).toISOString();
      transformedData.push({
        time: date,
        value: +item.close,
        field: "close"
      });
    });
  }
  // if (apiData) {
  //   apiData.forEach(item => {
  //     const timestamp = item.time * 1000;
  //     const isoDateString = new Date(timestamp).toISOString();
  //     const date = isoDateString.substring(0, isoDateString.indexOf("T")); // Extract the date part
  //     console.log(date);
  //     transformedColumnData.push({
  //       time: date,
  //       value: [+item.open, +item.close],
  //       field: "Open - Close"
  //     });
  //   });
  // }

  const min = chartData?.minClose - chartData?.minClose / 500;
  const max = chartData?.maxClose + chartData?.minClose / 500;

  // line chart
  // const lineChartConfig = {
  //   data: transformedData || [],
  //   xField: "time",
  //   yField: "value",
  //   seriesField: "field",
  //   columnStyle: {
  //     radius: [20, 20, 0, 0]
  //   },

  //   xAxis: {
  //     label: {
  //       formatter: text => {
  //         const date = new Date(text);
  //         const isoDateString = date.toISOString();
  //         return isoDateString.substring(0, isoDateString.indexOf("T")); // Extract the date part
  //       }
  //     }
  //   },
  //   value: {
  //     formatter: text => Math.round(text)
  //   },
  //   yAxis: {
  //     minLimit: min,
  //     maxLimit: max,
  //     tickCount: 10,
  //     label: {
  //       formatter: text => Math.round(text)
  //     }
  //   },
  //   slider: {
  //     start: 0,
  //     end: 1,
  //     formatter: text => {
  //       const date = new Date(text);
  //       const isoDateString = date.toISOString();
  //       return isoDateString.substring(0, isoDateString.indexOf("T"));
  //     }
  //   },
  //   tooltip: {
  //     label: {
  //       formatter: text => {
  //         const date = new Date(text);
  //         const isoDateString = date.toISOString();
  //         return isoDateString.substring(0, isoDateString.indexOf("T"));
  //       }
  //     }
  //   },
  //   events: {
  //     onDataPointHover: ev => {
  //       if (ev.data && ev.data.data) {
  //         const { data } = ev.data;
  //         console.log("Hovered Data Point:", data);
  //         setHoveredData(data);
  //       }
  //     }
  //   },
  //   theme: currentTheme === "light" ? "light" : "dark"
  // };

  const apexLineChartConfig = {
    options: {
      chart: {
        zoom: {
          enabled: false
        }
      },
      xaxis: {
        type: "datetime"
      },
      yaxis: {
        min: min,
        max: max,
        labels: {
          formatter: value => Math.round(value)
        }
      },
      tooltip: {
        x: {
          format: "dd MMM yyyy HH:mm:ss"
        },
        y: {
          formatter: value => Math.round(value)
        }
      },
      theme: {
        mode: `${currentTheme === "light" ? "light" : "dark"}`
      }
    },
    series: [
      {
        data: transformedData.map(item => ({
          x: new Date(item.time).getTime(),
          y: item.value
        }))
      }
    ]
  };

  const options = {
    chart: {
      zoom: {
        enabled: true,
        type: "x",
        resetIcon: {
          offsetX: -10,
          offsetY: 0,
          fillColor: "#fff",
          strokeColor: "#37474F"
        },
        selection: {
          border: "#0D47A1"
        }
      }
    },
    xaxis: {
      type: "datetime"
    },
    tooltip: {
      x: {
        format: "dd MMM yyyy HH:mm:ss"
      },
      y: {
        formatter: value => Math.round(value)
      }
    },
    theme: {
      mode: `${currentTheme === "light" ? "light" : "dark"}`
    }
  };

  const apexData = chartData?.Data?.map(item => ({
    x: new Date(item.time * 1000).toISOString(),
    y: [item.open, item.high, item.low, item.close]
  }));
  const series = [
    {
      data: apexData
    }
  ];

  // modal
  const tradingViewChartModal = useSelector(state => state.popup.tradingViewChart);
  return (
    <div>
      <div
        className={`shadow-navbar-button rounded-[35px] p-8 xs:p-5 ${
          currentTheme === "dark" ? "bg-cardsColor" : "bg-mainBg"
        }`}
      >
        <div className="flex items-center justify-between mb-8">
          <div className="">
            <p
              className={`text-[26px] sm:text-lg font-semibold ${
                currentTheme === "dark" ? "text-mainBg" : "text-filter"
              }`}
            >
              {coin.name} to USD Chart
            </p>
          </div>
          {chartData?.Data && (
            <div className="flex gap-3">
              <div
                onClick={() => {
                  dispatch(tradingView());
                }}
              >
                <FontAwesomeIcon
                  className={`text-lg p-3 rounded-full cursor-pointer ${
                    currentTheme === "dark"
                      ? "bg-filter text-mainBg"
                      : "bg-coinVoteBg text-chartTextColor"
                  }`}
                  icon={faExpand}
                />
              </div>
            </div>
          )}
        </div>
        {chartData?.Data && (
          <div className="flex md:flex-col md:gap-3 xs:gap-5 justify-between mb-10">
            <div
              className={`text-sm xs:w-full ${
                currentTheme === "dark" ? "text-opacityColor" : "text-chartTextColor"
              }`}
            >
              <div
                className={`flex justify-between shadow-header-shadow rounded-full items-center overflow-hidden px-2 ${
                  currentTheme === "dark" ? "bg-filter" : "bg-coinDetailChartBg"
                }`}
              >
                <div className="flex gap-1 flex-nowrap overflow-auto scrollbar py-2">
                  {CHART_MENU_DATA.map(item => (
                    <div
                      className={`py-1 px-3 whitespace-nowrap cursor-pointer rounded-full shadow-navbar-button ${
                        menuActive === item.value
                          ? `bg-${currentTheme === "dark" ? "cardsColor" : "mainBg"}`
                          : "bg-none"
                      } ${
                        menuActive === item.value && currentTheme === "dark"
                          ? "text-mainBg"
                          : "text-none"
                      }`}
                      onClick={() => {
                        setMenuActive(item.value);
                      }}
                      key={item.id}
                    >
                      {item.name}
                    </div>
                  ))}
                </div>
              </div>
            </div>
            {menuActive === "price" && (
              <div
                className={`text-sm xs:w-full ${
                  currentTheme === "dark" ? "text-opacityColor" : "text-chartTextColor"
                }`}
              >
                <div
                  className={`flex justify-between shadow-header-shadow rounded-full items-center overflow-hidden px-2 ${
                    currentTheme === "dark" ? "bg-filter" : "bg-coinDetailChartBg"
                  }`}
                >
                  <div className="flex gap-1 flex-nowrap overflow-auto scrollbar py-2">
                    {CHART_Type.map(item => (
                      <div
                        className={`py-1 px-3 whitespace-nowrap cursor-pointer rounded-full shadow-navbar-button ${
                          activeChartType === item.value
                            ? `bg-${currentTheme === "dark" ? "cardsColor" : "mainBg"}`
                            : "bg-none"
                        } ${
                          activeChartType === item.value && currentTheme === "dark"
                            ? "text-mainBg"
                            : "text-none"
                        }`}
                        onClick={() => {
                          setActiveChartType(item.value);
                        }}
                        key={item.id}
                      >
                        {item.name}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
            <div
              className={`flex justify-end text-sm w-[47%] xs:w-full ${
                currentTheme === "dark" ? "text-opacityColor" : "text-chartTextColor"
              }`}
            >
              <div
                className={`flex justify-between shadow-header-shadow rounded-full items-center overflow-hidden px-2 bg-coinDetailChartBg ${
                  currentTheme === "dark" ? "bg-filter" : "bg-coinDetailChartBg"
                }`}
              >
                <div className="flex gap-1 flex-nowrap overflow-auto scrollbar py-2">
                  {/* {TIME_PERIOD_DATA.map(item => (
                  <div
                    className={`${
                      periodActive === item.value
                        ? "bg-mainBg rounded-full shadow-navbar-button"
                        : ""
                    } py-1 px-3 whitespace-nowrap cursor-pointer flex items-center gap-1`}
                    onClick={() => {
                      setPeriodActive(item.value);
                    }}
                    key={item.id}
                  >
                    {item.icon && (
                      <img className="w-4" src={item.icon} alt="calendar-icon" />
                    )}
                    {item.name}
                  </div>
                ))} */}
                  {TIME_PERIOD_DATA.map(item => (
                    <div
                      className={`py-1 px-3 whitespace-nowrap cursor-pointer rounded-full shadow-navbar-button ${
                        periodActive === item.value
                          ? `bg-${currentTheme === "dark" ? "cardsColor" : "mainBg"}`
                          : ""
                      } ${
                        periodActive === item.value && currentTheme === "dark"
                          ? "text-mainBg"
                          : ""
                      } flex items-center gap-1`}
                      onClick={() => {
                        setPeriodActive(item.value);
                      }}
                      key={item.id}
                    >
                      {item.icon && (
                        <img className="w-4" src={item.icon} alt="calendar-icon" />
                      )}
                      {item.name}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Trading View button */}
        <div className={`mb-4`}>
          {isLoading || isFetching ? (
            <SkeletonTheme
              baseColor={`${currentTheme === "dark" ? "#323546" : "#d8d8d8"}`}
              highlightColor={`${currentTheme === "dark" ? "#444" : "#e0e0e0"}`}
            >
              <Skeleton width="100%" height={400} count={1} />
            </SkeletonTheme>
          ) : (
            <>
              {chartData?.Data ? (
                menuActive === "price" ? (
                  activeChartType === "line" ? (
                    <ReactApexChart {...apexLineChartConfig} height={350} />
                  ) : (
                    <div>
                      <ReactApexChart
                        options={options}
                        series={series}
                        type="candlestick"
                        height={350}
                      />
                    </div>
                  )
                ) : menuActive === "trading-view" ? (
                  <div className="mt-4">
                    {coin_name ? (
                      <div
                        className={`${
                          currentTheme === "dark" ? "bg-cardsColor" : "bg-mainBg"
                        }`}
                      >
                        <TradingViewWidget
                          symbol={tradingViewSymbol}
                          width="100%"
                          height={400}
                          theme={`${
                            currentTheme === "dark" ? Themes.DARK : Themes.LIGHT
                          }`}
                          locale="en"
                        />
                      </div>
                    ) : (
                      <p>There is no Symbol</p>
                    )}
                  </div>
                ) : null
              ) : (
                <p
                  className={`${
                    currentTheme === "dark" ? " text-mainBg" : "text-coinDetailChart"
                  } items-center flex h-[40vh] justify-center text-2xl`}
                >
                  There is no Chart Data of {coin?.name}
                </p>
              )}
            </>
          )}
        </div>

        {chartData?.Data && (
          <div
            className={`flex justify-between text-xs ${
              currentTheme === "dark" ? " text-mainBg" : "text-coinDetailChart"
            }`}
          >
            <div>
              <p>
                Want more data?
                <span className="text-blueColor">Check out our API</span>
              </p>
            </div>
          </div>
        )}
      </div>
      {tradingViewChartModal && (
        <TradingViewChartModal
          tradingViewSymbol={tradingViewSymbol}
          periodActive={periodActive}
          isLoading={isLoading}
          isFetching={isFetching}
          menuActive={menuActive}
          apiData={apiData}
          activeChartType={activeChartType}
          min={min}
          max={max}
          setActiveChartType={setActiveChartType}
          CHART_MENU_DATA={CHART_MENU_DATA}
          CHART_Type={CHART_Type}
          TIME_PERIOD_DATA={TIME_PERIOD_DATA}
          setMenuActive={setMenuActive}
          setPeriodActive={setPeriodActive}
          options={options}
          series={series}
        />
      )}
    </div>
  );
};

export default CoinDetailChart;
