import { createSlice } from "@reduxjs/toolkit";

// initial state
const initialState = {
  pageNumber: 1
};

const newsSlice = createSlice({
  name: "new",
  initialState,
  reducers: {
    changePageNumber: (state, action) => {
      state.pageNumber = action.payload;
    }
  }
});

export default newsSlice.reducer;
export const { changePageNumber } = newsSlice.actions;
